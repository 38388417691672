<template>
    <div class="app-container" style="height: 100%">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="24">
                <el-form :inline="true">
                    <el-form-item label="">
                        <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                    </el-form-item>
                    <el-button-group style="float: right">
                        <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                    </el-button-group>
                </el-form>
            </el-col>
        </el-row>
        <el-row :gutter="8" class="row-container">
            <el-col>
                <el-table
                    :key="tableKey"
                    v-loading="tableLoading"
                    :data="tableData"
                    border
                    fit
                    highlight-current-row
                    style="width: 100%;"
                    ref="elementTable"
                    row-key="menuId"
                    :indent="20"
                    @sort-change="sortChange"
                >
                    <el-table-column label="名称" prop="id" align="left" width="280" :show-overflow-tooltip="true">
                        <template slot-scope="{row}">
                            <span>{{ row.menuName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="编号" min-width="60px" align="center">
                        <template slot-scope="{row}">
                            <span>{{ row.menuCode}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="图标" width="60px" align="center">
                        <template slot-scope="{row}">
                            <item :icon="row.menuIcon" />
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" width="110px" align="center">
                        <template slot-scope="{row}">
                            <el-tag :type="row.menuType | colorFilter">
                                {{ row.menuType  | typeFilter}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="排序号" width="80px" align="center">
                        <template slot-scope="{row}">
                            {{ row.orderNum }}
                        </template>
                    </el-table-column>
                    <el-table-column label="路由" min-width="100">
                        <template slot-scope="{row}">
                            {{ row.menuRouter }}
                        </template>
                    </el-table-column>
                    <el-table-column label="授权" min-width="100">
                        <template slot-scope="{row}">
                            {{ row.menuPerms }}
                        </template>
                    </el-table-column>
                    <el-table-column label="所属模块" prop="moduleName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="操作时间" width="140px" align="center">
                        <template slot-scope="{row}">
                            <span>{{ row.updateTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="260" fixed="right" class-name="small-padding fixed-width">
                        <template slot-scope="{row}">
                            <el-button type="success" plain size="mini" icon="el-icon-edit" @click="toEdit(row.menuId)">修改</el-button>
                            <el-button v-if="row.deleteStatus===0" type="danger" plain size="mini"  icon="el-icon-delete" @click="toDelete(row.menuId, 1)">删除</el-button>
                            <el-button type="success" plain size="mini" icon="el-icon-edit" @click="toCopy(row)">复制</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                            :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                            @pagination="initTable"/>
            </el-col>
        </el-row>

        <el-dialog
            title="菜单编辑"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
                <el-row>
<!--                    <el-col :span="12">-->
<!--                        <el-form-item label="所属模块" prop="moduleId">-->
<!--                            <el-select v-model="info.moduleId" placeholder="请选择">-->
<!--                                <el-option-->
<!--                                    v-for="item in moduleList"-->
<!--                                    :key="item.moduleId"-->
<!--                                    :label="item.moduleName"-->
<!--                                    :value="item.moduleId">-->
<!--                                </el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :span="24">
                        <el-form-item label="类型" prop="menuType">
                            <el-radio-group v-model="info.menuType">
                                <el-radio :label="9">模块子系统</el-radio>
                                <el-radio :label="0">目录</el-radio>
                                <el-radio :label="1">菜单</el-radio>
                                <el-radio :label="2">按钮</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="菜单名称" prop="menuName">
                            <el-input v-model="info.menuName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="菜单编码" prop="menuCode">
                            <el-input v-model="info.menuCode"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="菜单图标" prop="menuIcon">
                    <el-input v-model="info.menuIcon"></el-input>
                </el-form-item>
                <el-form-item label="菜单路由" prop="menuRouter">
                    <el-input v-model="info.menuRouter" placeholder="请输入页面路由路径（例如：/system/menu）"></el-input>
                </el-form-item>
                <el-form-item label="跳转路由" prop="redirectRouter" v-show="info.menuType!==2">
                    <el-input v-model="info.redirectRouter"></el-input>
                </el-form-item>
                <el-form-item label="元数据" prop="menuMeta" v-show="info.menuType!==2">
                    <el-input v-model="info.menuMeta"></el-input>
                </el-form-item>
                <el-form-item label="授权" prop="menuPerms">
                    <el-input v-model="info.menuPerms"></el-input>
                    多个用逗号分隔，如：user:list,user:create
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上级菜单" prop="parentId">
                            <SelectTree :tree-data="selectOptions" :selectId.sync="info.parentId"
                                        :treeProps="treeProps" :nodeKey="'menuId'"
                                        :onlySelectLeaf="false"></SelectTree>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="排序号" prop="orderNum">
                            <el-input v-model="info.orderNum"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    // import { parseTime } from '@/utils'
    import Item from '../../layout/components/Sidebar/Item'
    import Pagination from '@/components/Pagination' // secondary package based on el-pagination
    import SelectTree from '@/components/tree/SelectTree';
    import Breadcrumb from "../../components/Breadcrumb";
    export default {
        components: {
            Pagination,
            Item,
            SelectTree,
            Breadcrumb
        },
        filters: {
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'info',
                    2: 'danger'
                };
                return statusMap[status]
            },
            typeFilter(status) {
                const statusMap = {
                    9: '模块/子系统',
                    0: '目录',
                    1: '菜单',
                    2: '按钮'
                };
                return statusMap[status]
            }
        },
        data() {
            return {
                crumbData: [
                    {
                        path: '/main',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '菜单管理'
                    }
                ],
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    categoryId: '',
                    releaseFlag: '',
                    bannerStatus: '',
                    deleteStatus: '0',
                    sort: ''
                },
                tableKey: 0,
                tableData: [],
                tableLoading: false,
                sortOptions: [{
                    label: 'ID Ascending',
                    key: '+id'
                }, {
                    label: 'ID Descending',
                    key: '-id'
                }],
                statusOptions: ['published', 'draft', 'deleted'],
                info: {},
                ruleValidate: {
                    menuType: [
                        { required: true, type: "number", message: '请选择类型', trigger: 'change' }
                    ],
                    menuName: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                    menuCode: [
                        { required: true, message: '请输入编码', trigger: 'blur' }
                    ],
                    orderNum: [
                        { required: true, message: '请输入排序号', trigger: 'blur' }
                    ],
                },
                editDialog: false,
                selectOptions: [],
                treeProps: {
                    value: 'menuId',
                    label: 'menuName',
                    children: 'children'
                },
                moduleList: []
            }
        },
        mounted() {
            this.initTable();
            this.getMoudleList();
        },
        methods: {
            getMoudleList: function () {
                this.apis.get('system/menu/module/getList')
                    .then((res) => {
                        if (res.code === 1) {
                            this.moduleList = res.data
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('system/menu/getTree', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            sortChange(data) {
                const { prop, order } = data;
                console.log(prop, order)
                // if (prop === 'id') {
                //     this.sortByID(order)
                // }
            },
            getSortClass: function(key) {
                const sort = this.queryParams.sort;
                return sort === `+${key}` ? 'ascending' : 'descending'
            },
            getTreeList: function () {
                this.apis.postForm('system/menu/getTree', { isAll: 0 })
                    .then((res) => {
                        if (res.code === 1) {
                            this.selectOptions = res.data;
                            this.selectOptions.splice(0, 0, { menuId: 0, menuName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toAdd: function () {
                this.info = {
                    orderNum: 0
                };
                this.getTreeList();
                this.editDialog = true;
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "system/menu/save";

                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieTNAME();
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieTNAME();

                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editDialog = false;
                                    this.initTable();
                                    this.$message({
                                        message: '操作成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (keyId) {
                this.getTreeList();
                this.apis.get('system/menu/getById?menuId=' + keyId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDialog = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                this.$confirm('确定要删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        menuId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieTNAME()
                    };
                    this.apis.postForm('system/menu/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.initTable();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            toCopy: function (row) {
                this.$confirm('确定要复制此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.apis.postJson('system/menu/copy', row)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.initTable();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
