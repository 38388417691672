var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"page-design",staticClass:"page-design",attrs:{"id":"page-design"}},[_c('div',{staticClass:"out-page",style:({
    // width: dPage.width * dZoom / 100 + 120 + 'px',
    height: _vm.dPage.height * _vm.dZoom / 100 + 120 + 'px',
    opacity: 1 - (_vm.dZoom < 100 ? _vm.dPage.tag : 0)
  })},[_c('div',{staticClass:"design-canvas",style:({
      width: _vm.dPage.width + 'px',
      height: _vm.dPage.height + 'px',
      transform: 'scale(' + _vm.dZoom / 100 + ')',
      transformOrigin: (_vm.dZoom >= 100 ? 'center' : 'left') + ' top',
      backgroundColor: _vm.dPage.backgroundColor,
      backgroundImage: 'url(\'' + (_vm.dPage.backgroundImage ? _vm.dPage.backgroundImage : '') + '\')',
      opacity: _vm.dPage.opacity + (_vm.dZoom < 100 ? _vm.dPage.tag : 0)
    }),attrs:{"data-type":_vm.dPage.type,"data-uuid":_vm.dPage.uuid,"id":_vm.pageDesignCanvasId}},[_c('grid-size'),_vm._l((_vm.getlayers()),function(layer){return _c(layer.type,{key:layer.uuid,tag:"component",staticClass:"layer",class:{
        'layer-active': _vm.getIsActive(layer.uuid),
        'layer-hover': layer.uuid === _vm.dHoverUuid || _vm.dActiveElement.parent === layer.uuid
      },attrs:{"data-title":layer.type,"params":layer,"parent":_vm.dPage,"data-type":layer.type,"data-uuid":layer.uuid}},_vm._l((_vm.getChilds(layer.uuid)),function(widget){return (layer.isContainer)?_c(widget.type,{key:widget.uuid,tag:"component",staticClass:"layer",class:{
          'layer-active': _vm.getIsActive(widget.uuid),
          'layer-no-hover': _vm.dActiveElement.uuid !== widget.parent && _vm.dActiveElement.parent !== widget.parent,
          'layer-hover': widget.uuid === _vm.dHoverUuid
        },attrs:{"data-title":widget.type,"params":widget,"parent":layer,"data-type":widget.type,"data-uuid":widget.uuid}}):_vm._e()}),1)}),(_vm.dSelectWidgets.length === 0)?_c('ref-line'):_vm._e(),(_vm.dSelectWidgets.length === 0)?_c('size-control'):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }