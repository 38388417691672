<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="24">
                <el-row :gutter="8" class="query-container">
                    <el-col :span="24">
                        <el-form :inline="true">
                            <el-form-item label="">
                                <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                            </el-form-item>
                            <el-button-group style="float: right">
                                <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                            </el-button-group>
                        </el-form>
                    </el-col>
                </el-row>
                <el-row :gutter="8" class="table-container">
                    <el-col>
                        <el-table
                            :key="tableKey"
                            v-loading="tableLoading"
                            :data="tableData"
                            height="calc(100vh - 200px)"
                            fit
                            border
                            default-expand-all
                            highlight-current-row
                            style="width: 100%;"
                            ref="elementTable"
                            row-key="itemId"
                            :indent="28"
                            @sort-change="sortChange"
                        >
                            <el-table-column label="项目名称" prop="id" align="left" :show-overflow-tooltip="true">
<!--                                <i v-if="row.children.length===0" class="el-icon-document" style="font-size: 16px; margin-right: 3px"></i>-->

                                <template slot-scope="{row}">
                                    <i v-if="row.children.length > 0" class="el-icon-folder" style="color: #1890FF; font-size: 16px; margin: 0 3px"></i>
                                    <i v-else class="el-icon-document"  style="color: #1890FF;font-size: 16px; margin: 0 3px"></i>
                                    <span>{{ row.itemName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="排序号" width="80px" align="center">
                                <template slot-scope="{row}">
                                    {{ row.orderNum }}
                                </template>
                            </el-table-column>
                            <el-table-column label="操作时间" width="140px" align="center">
                                <template slot-scope="{row}">
                                    <span>{{ row.modifyTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="260" fixed="right" class-name="small-padding fixed-width">
                                <template slot-scope="{row}">
                                    <el-button type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.itemId)">修改</el-button>
                                    <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.itemId, 1)">删除</el-button>
                                    <el-button type="text" size="mini" icon="el-icon-plus" @click="toAddSame(row)">平级</el-button>
                                    <el-button type="text" size="mini" icon="el-icon-plus" @click="toAddSub(row)">下级</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-dialog
            title="项目编辑"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="150px" label-suffix=":">
                <el-form-item label="项目名称" prop="itemName">
                    <el-input v-model="info.itemName"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="所属上级" prop="parentId">
                            <SelectTree :tree-data="selectOptions"
                                        :nodeKey="'itemId'"
                                        :treeProps="treeProps"
                                        :selectId.sync="info.parentId" :onlySelectLeaf="false"></SelectTree>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="排序号" prop="orderNum">
                    <el-input v-model="info.orderNum"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
// import { parseTime } from '@/utils'
import SelectTree from "../../../components/tree/SelectTree";
import Breadcrumb from "../../../components/Breadcrumb";
export default {
    components: {
        SelectTree,
        Breadcrumb
    },
    filters: {
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'info',
                2: 'danger'
            };
            return statusMap[status]
        },
        levelFilter(status) {
            const statusMap = {
                0: '',
                1: '一级检索',
                2: '二级检索'
            };
            return statusMap[status]
        }
    },
    data() {
        return {
            crumbData: [
                {
                    path: '/dashboard',
                    name: '首页'
                },
                {
                    path: '',
                    name: '字典管理'
                },
                {
                    path: '',
                    name: '运动项目'
                }
            ],
            queryParams: {
                pageNumber: 1,
                pageSize: 10,
                totalRecord: 0,
                keyword: '',
                itemId: '',
                releaseFlag: '',
                bannerStatus: '',
                delState: '0',
                sort: ''
            },
            tableKey: 0,
            tableData: [],
            tableLoading: false,
            sortOptions: [{
                label: 'ID Ascending',
                key: '+id'
            }, {
                label: 'ID Descending',
                key: '-id'
            }],
            statusOptions: ['published', 'draft', 'deleted'],
            info: {},
            ruleValidate: {
                itemName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                // metaTypeId: [
                //     { required: true, message: '请选择元数据类型', trigger: 'change' }
                // ],
                orderNum: [
                    { required: true, message: '请输入排序号', trigger: 'blur' }
                ],
            },
            editDialog: false,
            selectOptions: [],
            treeProps: {
                value: 'itemId',
                label: 'itemName',
                children: 'children'
            },
            treeNormalizer(node) {
                return {
                    id: node.itemId,
                    label: node.itemName
                }
            },
            metaTypeList: [],
        }
    },
    mounted() {
        this.initTable();
    },
    methods: {
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('fitness/items/getTree', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 1;
            this.initTable();
        },
        sortChange(data) {
            const { prop, order } = data;
            console.log(prop, order)
            // if (prop === 'id') {
            //     this.sortByID(order)
            // }
        },
        getSortClass: function(key) {
            const sort = this.queryParams.sort;
            return sort === `+${key}` ? 'ascending' : 'descending'
        },
        getTreeList: function () {
            this.apis.postForm('fitness/items/getTree', { isAll: 0 })
                .then((res) => {
                    if (res.code === 1) {
                        this.selectOptions = res.data;
                        // this.selectOptions.splice(0, 0, { itemId: 0, itemName: '默认' });
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toAdd: function () {
            this.info = {
                orderNum: 0,
                parentId: 0,
                searchLevel: 0,
            };
            this.getTreeList();
            this.editDialog = true;
        },
        toAddSame: function (row) {
            this.info = {
                orderNum: 1,
                parentId: row.parentId
            };
            this.getTreeList();
            this.editDialog = true;
        },
        toAddSub: function (row) {
            this.info = {
                orderNum: row.children.length,
                parentId: row.itemId
            };
            this.getTreeList();
            this.editDialog = true;
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "fitness/items/save";
                    this.info.createUid = this.appCookies.getCookieUID();
                    this.info.createUname = this.appCookies.getCookieTNAME() ;
                    this.info.updateUid = this.appCookies.getCookieUID();
                    this.info.updateUname = this.appCookies.getCookieTNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (keyId) {
            this.getTreeList();
            this.apis.get('fitness/items/getById?itemId=' + keyId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        this.editDialog = true;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDelete: function (id, deleteStatus) {
            this.$confirm('确定要删除此条记录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    itemId: id,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieTNAME()
                };
                this.apis.postForm('fitness/items/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep {
    .el-table__row .cell {
        //.el-table__expand-icon--expanded {
        //    // 这是点击后的旋转角度
        //    transform: rotate(0deg);
        //}
        //.el-table__expand-icon {
        //    .el-icon-arrow-right:before {
        //        //这是收起图标
        //        content: "\e78a";
        //        font-size: 16px;
        //    }
        //}
        //.el-table__expand-icon--expanded {
        //    .el-icon-arrow-right:before {
        //        // 这是展开图标
        //        content: "\e784";
        //        font-size: 16px;
        //    }
        //}
    }
}
</style>
