var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('img',{ref:"widget",staticClass:"w-image",style:({
  position: 'absolute',
  left: (_vm.params.left - _vm.parent.left) + 'px',
  top: (_vm.params.top - _vm.parent.top) + 'px',
  width: _vm.params.width + 'px',
  height: _vm.params.height + 'px',
  opacity: _vm.params.opacity,
  borderTopLeftRadius: _vm.params.radiusTopLeft + 'px',
  borderTopRightRadius: _vm.params.radiusTopRight + 'px',
  borderBottomLeftRadius: _vm.params.radiusBottomLeft + 'px',
  borderBottomRightRadius: _vm.params.radiusBottomRight + 'px'
}),attrs:{"id":"w-image","src":_vm.params.imgUrl}})}
var staticRenderFns = []

export { render, staticRenderFns }