<template>
    <div class="uploadmain">
        <el-upload
            class="image-uploader"
            :http-request="uploadFile"
            :headers="{'Authorization': accessToken}"
            accept=".png,.jpg,.jpeg"
            action="tmp"
            :limit="limit"
            :show-file-list="true"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
            :on-progress="uploadProcess">
            <el-button size="small" type="primary">点击上传</el-button>
<!--            <img v-if="uploadFlag===false && currImageUrl" :src="currImageUrl" class="image" :style="{width: width+ 'px', height: height+ 'px'}">-->
<!--            <el-progress v-else-if="currImageUrl==='' && uploadFlag == true" type="circle" :percentage="uploadPercent"></el-progress>-->
<!--            <i v-else class="el-icon-plus image-uploader-icon" :style="{width: width+ 'px', height: height+ 'px', lineHeight: height+ 'px'}"></i>-->
        </el-upload>
        <div style="text-align: center">
            <p style="color: red; line-height: 16px">{{text}}</p>
            <p v-show="subtext" style="color: red; line-height: 16px">{{subtext}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            limit: {
                type: Number,
                default: 1
            },
            index: {
                type: Number,
                default: 0
            },
            height: {
                type: Number,
                default: 140
            },
            width: {
                type: Number,
                default: 140
            },
            imageUrl: {
                type: String,
                default: ''
            },
            text: {
                type: String,
                default: ''
            },
            subtext: {
                type: String,
                default: ''
            },
            appId: {
                type: String,
                default: ''
            },
            uploadPath: {
                type: String,
                default: 'wx/open/mini/mgr/uploadMiniMedia'
            },
        },
        data() {
            return {
                accessToken: 'Bearer ' + this.appCookies.getCookieAccessToken(),
                uploadUrl: process.env.VUE_APP_API_HOST + this.uploadPath,
                dialogVisible: false,
                uploadFlag: false,
                uploadPercent: 0,
                currImageUrl: ''
            }
        },
        watch: {
            imageUrl: {
                handler (val) {
                    this.currImageUrl = val
                },
                deep: true,
                immediate: true
            },
        },
        methods: {
            uploadFile: function (f) {
                let formData = new FormData();
                formData.append('appId', this.appId);
                formData.append('file', f.file);
                let url = this.uploadPath; // 'wx/open/mini/mgr/uploadMedia';
                // 保存数据
                this.apis.postFile(url, formData)
                    .then((res) => {
                        if (res.code === 1) {
                            console.log(res.data)
                            this.success(res.data);
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 200,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            uploadSuccess(res, file) {
                // console.log(res) savepath
                this.success(res.data);
            },
            success(data) {
                // console.log(res) savepath
                this.uploadFlag = false;
                this.uploadPercent = 0;
                this.$emit("callback", data.mediaId, data.type, this.index)
            },
            beforeUpload(file) {

                let types = ['image/jpeg', 'image/gif', 'image/bmp', 'image/png'];
                const isImage = types.includes(file.type);
                const isLt2M = true; // file.size / 1024 / 1024 < 2;
                if (!isImage) {
                    this.$message({
                        message: '只能上传图片格式!',
                        showClose: true,
                        offset: 150,
                        type: 'error'
                    });
                }
                // if (!isLt2M) {
                //     this.$message({
                //         message: '上传头像图片大小不能超过 2MB!',
                //         showClose: true,
                //         offset: 150,
                //         type: 'error'
                //     });
                // }
                return isImage && isLt2M;
            },
            uploadProcess(event, file, fileList){
                this.uploadFlag = true;
                this.uploadPercent = parseInt(file.percentage.toFixed(0), 0);
            },
            handlePictureCardPreview(file) {
                this.dialogVisible = true;
            }
        },
        mounted() {
            this.$nextTick(function () {
            });
        }
    }
</script>

<style lang="scss" scoped>
    .uploadmain {
        height: 100%;
        width: 100%;
    }
    ::v-deep .image-uploader {
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-upload:hover {
            border-color: #409EFF;
        }
        .image-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 140px;
            height: 140px;
            line-height: 140px;
            text-align: center;
        }
        .image {
            width: 140px;
            height: 140px;
            display: block;
        }
    }
</style>

