<template>
    <div class="uploadmain">

        <!--            :action="uploadUrl"-->
        <el-upload
            class="image-uploader"
            :headers="{'Authorization': accessToken}"
            accept=".png,.jpg,.jpeg"
            :file-list='imageList'
            :list-type="'picture-card'"
            action="tmp"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
            :on-remove="handleRemove"
            :http-request="uploadFile"
            :on-preview="handlePictureCardPreview">
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="imageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    import Compressor from "compressorjs";

    export default {
        props: {
            height: {
                type: Number,
                default: 140
            },
            width: {
                type: Number,
                default: 140
            },
            imageList: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        watch: {
            imageList(val) {
                this.c_imageList = val;
            },

            /**
             * 监听市code
             * @param val
             */
            c_imageList(val) {
                this.$emit('update:imageList', val)
            },
        },
        data() {
            return {
                accessToken: 'Bearer ' + this.appCookies.getCookieAccessToken(),
                uploadUrl: process.env.VUE_APP_API_HOST + 'system/file/uploadFile',
                dialogVisible: false,
                uploadFlag: false,
                uploadPercent: 0,
                imageUrl: '',
                c_imageList: []
            }
        },
        methods: {
            uploadFile: function (f) {
                let that = this;
                // eslint-disable-next-line no-new
                new Compressor(f.file, {
                    maxWidth: 1024,   // 压缩完成的宽度，高度会自适应
                    quality: 0.8, // 压缩的质量，不加上面的宽度话压缩效果很不理想，体积基本没有变化，一定要加个宽或者高度
                    success: res => {      // 箭头函数的形势去除success this指向内部函数的
                        // file = new File([res], res.name, {  // 返回的结果是个blob对象，js内置函数将其3转为file对象
                        //     type: res.type,
                        //     lastModified: Date.now()
                        // });
                        // console.log("图片压缩后res", res)
                        // console.log("图片压缩后file", file.size)
                        let reader = new FileReader();
                        reader.readAsDataURL(res);
                        reader.onload = function (e) {
                            // e.target.result
                            console.log("e.target.result", e.target.result)
                            let fdata = {
                                file: e.target.result
                            }
                            let url = "system/file/uploadFileBase64";
                            // 保存数据
                            that.apis.postForm(url, fdata)
                                .then((res) => {
                                    if (res.code === 1) {
                                        console.log(res.data)
                                        that.success(res.data);
                                    } else {
                                        this.$message({
                                            message: res.msg,
                                            showClose: true,
                                            offset: 200,
                                            type: 'error'
                                        });
                                    }
                                }).catch(err => {
                                console.log(err);
                            });
                        }
                    },
                    error (err) {
                        that.$message({ message: '上传失败', showClose: true, offset: 200, type: 'error' });
                        console.log(err)
                    }
                });
                // let formData = new FormData();
                // formData.append('file', f.file);
                // let url = "system/file/uploadFile";
                // // 保存数据
                // this.apis.postFile(url, formData)
                //     .then((res) => {
                //         if (res.code === 1) {
                //             console.log(res.data)
                //             this.success(res.data);
                //         } else {
                //             this.$message({
                //                 message: res.msg,
                //                 showClose: true,
                //                 offset: 200,
                //                 type: 'error'
                //             });
                //         }
                //     }).catch(err => {
                //     console.log(err);
                // });
            },
            getImageList: function () {
                return this.c_imageList;
            },
            uploadSuccess(res, file) {
                // console.log(this.c_imageList)
                this.success(res.data);
            },
            success(data) {
                // console.log(res) savepath
                this.uploadFlag = false;
                this.uploadPercent = 0;
                this.c_imageList.push({
                    url: data.webPath,
                    fileUrl: data.webPath,
                    filePath: data.savepath
                })
            },
            beforeUpload(file) {

                let types = ['image/jpeg', 'image/gif', 'image/bmp', 'image/png'];
                const isImage = types.includes(file.type);
                const isLt2M = true; // file.size / 1024 / 1024 < 2;
                if (!isImage) {
                    this.$message({
                        message: '只能上传图片格式!',
                        showClose: true,
                        offset: 150,
                        type: 'error'
                    });
                }
                // if (!isLt2M) {
                //     this.$message({
                //         message: '上传头像图片大小不能超过 2MB!',
                //         showClose: true,
                //         offset: 150,
                //         type: 'error'
                //     });
                // }
                return isImage && isLt2M;
            },
            uploadProcess(event, file, fileList){
                this.uploadFlag = true;
                this.uploadPercent = parseInt(file.percentage.toFixed(0), 0);
            },
            handlePictureCardPreview(file) {
                this.imageUrl = file.url;
                this.dialogVisible = true;
            },
            handleRemove(file, fileList) {
                let idx = -1;
                // eslint-disable-next-line guard-for-in
                for (let item in this.c_imageList) {
                    if (file.url === this.c_imageList[item].url) {
                        idx = item;
                    }
                }
                if (idx > -1) {
                    this.c_imageList.splice(idx, 1)
                }
            },
        },
        mounted() {
            this.$nextTick(function () {
            });
        }
    }
</script>

<style lang="scss" scoped>
    .uploadmain {
        height: 100%;
        width: 100%;
    }
    ::v-deep .image-uploader {
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-upload:hover {
            border-color: #409EFF;
        }
        .image-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 140px;
            height: 140px;
            line-height: 140px;
            text-align: center;
        }
        .image {
            width: 140px;
            height: 140px;
            display: block;
        }
    }
</style>

