<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>机构列表</span>
                    </div>
                    <el-menu
                        default-active="0"
                        class="el-menu-vertical-demo" style="border-right: none !important;">
                        <el-menu-item  @click="orgSelected('')" index="0">
                            <i class="el-icon-folder"></i>
                            <span slot="title">全部用户</span>
                        </el-menu-item>
                        <el-menu-item v-for="(item, index) in orgList" :index="item.orgId" :key="index" @click="orgSelected(item.orgId)">
                            <i class="el-icon-folder"></i>
                            <span slot="title">{{item.orgName}}</span>
                        </el-menu-item>
                    </el-menu>
                </el-card>
            </el-col>
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>用户列表</span>
                        <el-button-group style="float: right">
                            <el-button class="filter-item" type="success" size="mini" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                        </el-button-group>
                    </div>
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width: 150px"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态" style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                :key="tableKey"
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20">
                                <el-table-column label="用户名" prop="userName" min-width="120px" align="left"></el-table-column>
                                <el-table-column label="真实姓名" prop="trueName" min-width="100px" align="left"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="联系电话" prop="phone" width="120px" align="center"></el-table-column>
                                <el-table-column label="邮箱" prop="email" min-width="150px" align="left"></el-table-column>
                                <el-table-column label="角色" prop="roleNames" min-width="150px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="所属机构" prop="orgName" min-width="150" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="启用/禁用" width="100" align="center">
                                    <template slot-scope="{row}">
                                        <el-switch
                                            v-model="row.lockStatus"
                                            :active-value="0"
                                            :inactive-value="1"
                                            @change="toLock(row)">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" width="100" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | statusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作时间" width="120" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.modifyTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="180" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.userId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.userId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" @click="toResetPwd(row.userId)">重置密码</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.userId, 0)">恢复</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>

                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="新建用户"
            :visible.sync="createDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="createForm" label-width="120px" label-suffix=":">
                <el-form-item label="用户名" prop="userName">
                    <el-input v-model="info.userName"></el-input>
                    <div><i class="el-icon-question"></i>建议使用手机号作为用户名</div>
                </el-form-item>
                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="密码" prop="userPwd">
                            <el-input v-model="info.userPwd" show-password></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="确认密码" prop="userPwd2">
                            <el-input v-model="info.userPwd2" show-password></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="真实姓名" prop="trueName">
                            <el-input v-model="info.trueName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属机构" prop="orgId" required>
                            <el-select v-model="info.orgId" filterable placeholder="请选择所属机构">
                                <el-option
                                    v-for="item in orgList"
                                    :key="item.orgId"
                                    :label="item.orgName"
                                    :value="item.orgId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="联系电话" prop="phone">
                            <el-input v-model="info.phone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="info.email"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
<!--                <el-form-item label="角色" prop="roleId">-->
<!--                    <el-select v-model="info.roleId" placeholder="请选择角色">-->
<!--                        <el-option-->
<!--                            v-for="item in roleList"-->
<!--                            :key="item.roleId"-->
<!--                            :label="item.roleName"-->
<!--                            :value="item.roleId">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->

                <el-form-item label="用户角色" prop="roleIdList">
                    <el-checkbox-group v-model="info.roleIdList">
                        <el-checkbox v-for="(item, idx) in roleList" :label="item.roleId" :key="idx">{{item.roleName}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

<!--                <el-form-item label="用户角色" prop="roleIdList">-->
<!--                       <span slot="label">-->
<!--                        用户角色-->
<!--                        <el-tooltip effect="dark" content="操作级权限（可访问或操作哪些功能）" placement="top">-->
<!--                          <i class="el-icon-question"/>-->
<!--                        </el-tooltip>-->
<!--                        :-->
<!--                   </span>-->
<!--                    <el-checkbox-group v-model="info.roleIdList">-->
<!--                        <el-checkbox v-for="(item, idx) in roleList" :label="item.roleId" :key="idx">{{item.roleName}}</el-checkbox>-->
<!--                    </el-checkbox-group>-->
<!--                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="createDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('createForm')">保 存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="编辑用户"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-form-item label="用户名" prop="userName">
                    <el-input v-model="info.userName" readonly></el-input>
                </el-form-item>

                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="真实姓名" prop="trueName">
                            <el-input v-model="info.trueName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属机构" prop="orgId" required>
                            <el-select v-model="info.orgId" filterable placeholder="请选择所属机构">
                                <el-option
                                    v-for="item in orgList"
                                    :key="item.orgId"
                                    :label="item.orgName"
                                    :value="item.orgId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="联系电话" prop="phone">
                            <el-input v-model="info.phone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="info.email"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
<!--                <el-form-item label="用户角色" prop="roleId">-->
<!--                    <el-select v-model="info.roleId" placeholder="请选择角色">-->
<!--                        <el-option-->
<!--                            v-for="item in roleList"-->
<!--                            :key="item.roleId"-->
<!--                            :label="item.roleName"-->
<!--                            :value="item.roleId">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="用户角色" prop="roleIdList">
                    <el-checkbox-group v-model="info.roleIdList">
                        <el-checkbox v-for="(item, idx) in roleList" :label="item.roleId" :key="idx">{{item.roleName}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
<!--                <el-form-item label="用户角色" prop="roleIdList">-->
<!--                    <span slot="label">-->
<!--                        用户角色-->
<!--                        <el-tooltip effect="dark" content="操作级权限（可访问或操作哪些功能）" placement="top">-->
<!--                          <i class="el-icon-question"/>-->
<!--                        </el-tooltip>-->
<!--                        :-->
<!--                   </span>-->
<!--                    <el-checkbox-group v-model="info.roleIdList">-->
<!--                        <el-checkbox v-for="(item, idx) in roleList" :label="item.roleId" :key="idx">{{item.roleName}}</el-checkbox>-->
<!--                    </el-checkbox-group>-->
<!--                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="重置密码"
            :visible.sync="pwdDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="30%">
            <el-form :model="changeInfo" :rules="ruleValidate" ref="pwdForm" label-width="120px" label-suffix=":">
                <el-form-item label="新密码" prop="userPwd">
                    <el-input v-model="changeInfo.userPwd" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="userPwd2">
                    <el-input v-model="changeInfo.userPwd2" show-password></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="pwdDialog = false">关 闭</el-button>
                <el-button type="primary" @click="resetPwd('pwdForm')">保 存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="选择机构"
            :visible.sync="orgDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="70%">
            <org-select @callback="setOrg"></org-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="orgDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    // import { parseTime } from '@/utils'
    import Pagination from '@/components/Pagination'
    import Breadcrumb from "../../components/Breadcrumb";
    // import OrgSelect from "./components/OrgSelect";
    import { encryptDes, vlaidPassword } from '@js/des'
    const rangeOptions = [
        {
            code: 90,
            name: '全部数据'
        },
        {
            code: 80,
            name: '本机构数据'
        },
        {
            code: 60,
            name: '负责班级数据'
        },
    ];
    // arr to obj, such as { CN : "China", US : "USA" }
    const rangeKeyValue = rangeOptions.reduce((acc, cur) => {
        acc[cur.code] = cur.name
        return acc
    }, {})

    export default {
        components: {
            Pagination,
            Breadcrumb,
            // OrgSelect
        },
        filters: {
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger'
                };
                return statusMap[status]
            },
            statusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            rangeFilter(type) {
                return rangeKeyValue[type]
            }
        },
        data() {
            return {
                rangeList: rangeOptions,
                crumbData: [
                    {
                        path: '/main',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '用户管理'
                    }
                ],
                queryParams: {
                    userId: this.appCookies.getCookieUID(),
                    parentId: this.appCookies.getCookieOID(),
                    orgId: '',
                    deptId: '',
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    useState: '0',
                    deleteStatus: '0'
                },
                tableKey: 0,
                tableData: [],
                tableLoading: false,
                info: {
                    roleIdList: []
                },
                ruleValidate: {
                    userName: [
                        { required: true, message: '请输入用户名', trigger: 'blur' }
                    ],
                    trueName: [
                        { required: true, message: '请输入真实姓名', trigger: 'blur' }
                    ],
                    userPwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ],
                    userPwd2: [
                        { required: true, message: '请再次输入密码', trigger: 'blur' }
                    ],
                    orgId: [
                        { required: true, message: '请选择所属机构', trigger: 'change' }
                    ],
                    roleIdList: [
                        { required: true, message: '请选择角色', trigger: 'change' }
                    ]
                },
                createDialog: false,
                editDialog: false,
                roleList: [],
                deptList: [],
                orgDeptList: [],
                pwdDialog: false,
                changeInfo: {
                    userId: '',
                    userPwd: '',
                    userPwd2: '',
                },
                orgDialog: false,
                orgList: []
            }
        },
        mounted() {
            this.getOrgList();
            this.getRoleList();
            this.initTable();
        },
        methods: {
            getOrgList: function (val) {
                this.apis.get('system/org/getList?keyword=' + val + '&orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.orgList = res.data || []
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            setOrg: function (orgId, orgName) {
                this.info.orgId = orgId;
                this.info.orgName = orgName;
                this.getDeptList(this.info.orgId)
                this.orgDialog = false;
                if (this.createDialog) {
                    this.$refs['createForm'].validateField('orgName');
                }
                if (this.editDialog) {
                    this.$refs['editForm'].validateField('orgName');
                }
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('system/admin/queryCustom', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            getRoleList: function () {
                this.apis.get('system/role/getList?rangeCode=' + this.appCookies.getRangeCode())
                    .then((res) => {
                        if (res.code === 1) {
                            this.roleList = res.data
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toAdd: function () {
                this.info = {
                    orderNum: 0,
                    orgId: this.queryParams.orgId,
                    roleIdList: []
                };
                this.createDialog = true;
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        if (this.createDialog && !vlaidPassword(this.info.userPwd)) {
                            this.$message({
                                message: '您的密码强度太低（必须包含字母、数字和特称字符，至少8个字符，最多20个字符），请重新输入！', showClose: true, offset: 200, type: 'error'
                            });
                            this.info.userPwd = '';
                            return;
                        }
                        if (this.createDialog && this.info.userPwd !== this.info.userPwd2) {
                            this.$message({
                                message: '两次输入的密码不一致',
                                showClose: true, offset: 50, type: 'error'
                            });
                            return;
                        }
                        let url = "system/admin/save";
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME() ;
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME() ;

                        let saveData = JSON.parse(JSON.stringify(this.info));
                        if (this.createDialog) {
                            saveData.userPwd = encryptDes(this.info.userPwd);
                            // saveData.userPwd = this.info.userPwd;
                            saveData.userPwd2 = '';
                        } else {
                            saveData.userPwd = '';
                            saveData.userPwd2 = '';
                        }

                        // 保存数据
                        this.apis.postJson(url, saveData)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editDialog = false;
                                    this.createDialog = false;
                                    this.initTable();
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (keyId) {
                this.apis.get('system/admin/getById?userId=' + keyId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDialog = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                this.$confirm('确定要删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        userId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieTNAME()
                    };
                    this.apis.postForm('system/admin/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.initTable();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            toLock: function (row) {
                // console.log(row)
                let data = {
                    userId: row.userId,
                    lockStatus: row.lockStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieTNAME()
                };
                this.apis.postForm('system/admin/lock', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toResetPwd: function (keyId) {
                this.changeInfo.userId = keyId;
                this.changeInfo.userPwd = '';
                this.changeInfo.userPwd2 = '';
                this.pwdDialog = true;
            },
            resetPwd: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {

                        if (!vlaidPassword(this.changeInfo.userPwd)) {
                            this.$message({
                                message: '您的密码强度太低（必须包含字母、数字和特称字符，至少8个字符，最多20个字符），请重新输入！', showClose: true, offset: 200, type: 'error'
                            });
                            this.changeInfo.userPwd = '';
                            return;
                        }
                        if (this.changeInfo.userPwd !== this.changeInfo.userPwd2) {
                            this.$message({
                                message: '两次输入的密码不一致',
                                showClose: true, offset: 50, type: 'error'
                            });
                            return;
                        }
                        // if (!this.vlaidPassword(this.changeInfo.userPwd)) {
                        //     this.$message({
                        //         message: '您的密码强度太低（至少8位且必须包含大写字母、小写字符和数字），请重新输入！', showClose: true, offset: 200, type: 'error'
                        //     });
                        //     this.changeInfo.userPwd = '';
                        //     return;
                        // }

                        let url = "system/admin/resetPwd";
                        let formData = {
                            userPwd: encryptDes(this.changeInfo.userPwd),
                            // userPwd: this.changeInfo.userPwd,
                            userId: this.changeInfo.userId,
                            modifyBy: this.appCookies.getCookieUID(),
                            modifyByname: this.appCookies.getCookieUNAME()
                        };
                        // 保存数据
                        this.apis.postForm(url, formData)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '操作成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                                this.pwdDialog = false;
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                });
            },
            orgSelected(orgId) {
                this.queryParams.orgId = orgId;
                this.refreshTable()
            },
        }
    }
</script>

<style lang="scss" scoped>
::v-deep .el-menu-item {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
}
</style>
