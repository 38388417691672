<template>
    <el-drawer
        title="编辑处方信息"
        :visible.sync="editDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        @closed="toclose"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        size="80%"
    >
        <div class="demo-drawer__content">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row :gutter="32">
                    <el-col :span="12">
                        <el-form-item label="处方名" prop="recipeName">
                            <el-input v-model="info.recipeName" placeholder="请输入处方名"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="12">
                        <el-form-item label="处方分类" prop="recipeCatIds">
                            <el-select v-model="info.recipeCatIds" multiple placeholder="请选择" style="width: 100%">
                                <el-option
                                    v-for="item in catList"
                                    :key="item.categoryId"
                                    :label="item.categoryName"
                                    :value="item.categoryId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="适宜人群" prop="recipeCrowds">
                            <el-select v-model="info.recipeCrowds" multiple placeholder="请选择" style="width: 100%">
                                <el-option
                                    v-for="item in crowdList"
                                    :key="item.dictId"
                                    :label="item.dictName"
                                    :value="item.dictId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-form-item label="运动目的" prop="recipeTarget">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 3, maxRows: 3}"
                                placeholder="请输入运动目的"
                                v-model="info.recipeTarget">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-form-item label="运动项目" prop="recipeItems">
                            <el-input
                                placeholder="请输入运动项目"
                                v-model="info.recipeItems">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-form-item label="运动方式" prop="recipeMethod">
                            <editor ref="editor" :height="300"></editor>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="12">
                        <el-form-item label="运动时间" prop="recipeTime">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 3, maxRows: 3}"
                                placeholder="请输入运动时间"
                                v-model="info.recipeTime">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运动强度" prop="recipeStrength">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 3, maxRows: 3}"
                                placeholder="请输入运动强度"
                                v-model="info.recipeStrength">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="12">
                        <el-form-item label="运动频次" prop="recipeFreq">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 3, maxRows: 3}"
                                placeholder="请输入运动频次"
                                v-model="info.recipeFreq">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="运动目标" prop="recipeTarget">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 3, maxRows: 3}"
                                placeholder="请输入运动频次"
                                v-model="info.recipeTarget">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-form-item label="注意事项" prop="recipeNotice">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 5, maxRows: 5}"
                                placeholder="请输入注意事项"
                                v-model="info.recipeNotice">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="toclose">关闭</el-button>
            <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
        </div>
    </el-drawer>
</template>

<script>
import editor from '../../../../components/editor/editor';
export default {
    components: {
        editor
    },
    data () {
        return {
            //编辑、新增表单
            editDrawer: false,
            recipeId: '',
            info: {
            },
            //表单验证规则
            ruleValidate: {
                // 基本信息
                recipeName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                recipeCatIds: [
                    { required: true, message: '请选择分类', trigger: 'blur' }
                ],
            },
            crowdList: [],
            catList: [],
        };
    },
    methods: {
        show: function (recipeId) {
            this.editDrawer = true;
            this.recipeId = recipeId;
            this.getById();
            this.$nextTick(function () {
            });
            this.getDictList()
        },
        toclose: function () {
            this.editDrawer = false
        },
        getById: async function () {
            if (this.recipeId) {
                this.apis.get('/fitness/recipe/getById?recipeId=' + this.recipeId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "/fitness/recipe/save";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$emit("callback")
                                this.editDrawer = false;
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        getDictList: function () {
            this.apis.get('system/dict/getList?typeCode=0012')
                .then((res) => {
                    if (res.code === 1) {
                        this.crowdList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            this.apis.postForm('fitness/recipe/cat/getTree')
                .then((res) => {
                    if (res.code === 1) {
                        this.catList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toAddDocs: function () {
            this.$nextTick(() => {
                this.$refs.uploadDialog.open({});
            })
        },
        addDocsFinish: function (fileList) {
            if (!this.info.annexList) {
                this.info.annexList = []
            }

            fileList.forEach(item => {
                this.info.annexList.push({
                    annexName: item.fileName,
                    annexPath: item.filePath,
                    annexPathUrl: item.filePathUrl,
                    fileSize: item.fileSize,
                    fileType: item.fileType
                })
            })
            this.$forceUpdate()
            // this.info.annexList = this.info.annexList.concat(fileList)
        },
        deleteAnnexFile: function (idx, annexId) {
            this.$confirm('确定要删除此条数据？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.info.annexList.splice(idx, 1)
                // if (fileId) {
                //     let data = {
                //         annexId: annexId,
                //         deleteStatus: 1,
                //         operUserId: this.appCookies.getCookieUID(),
                //         operUserName: this.appCookies.getCookieUNAME()
                //     };
                //     this.apis.postForm('course/annex/file/delete', data)
                //         .then((res) => {
                //             if (res.code === 1) {
                //                 this.info.annexList.splice(idx, 1)
                //                 this.$message({
                //                     message: '删除成功',
                //                     showClose: true, offset: 200, type: 'success'
                //                 });
                //             } else {
                //                 this.$message({
                //                     message: res.msg,
                //                     showClose: true, offset: 50, type: 'error'
                //                 });
                //             }
                //         }).catch(err => {
                //         console.log(err);
                //     });
                // } else {
                //     this.info.annexList.splice(idx, 1)
                // }
            }).catch(() => {
            });
        },
        toAddRow: function () {
            if (!this.info.prizeList) {
                this.info.prizeList = []
            }
            this.info.prizeList.push({
                programName: '',
                prizeTypeName: '',
                prizeLevelName: '',
                prizeRank: '',
                prizeRole: '',
                prizeYear: '',
                deleteStatus: 0
            })
        },
        toDeleteRow: function (idx, prizeId) {
            this.$confirm('确定要删除此条数据？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.info.prizeList.splice(idx, 1)
            }).catch(() => {
            });
        },
        filterTreeData(data){
            for (let i=0;i<data.length;i++) {
                if (data[i].children) {
                    if (data[i].children.length<1) {
                        // children若为空数组，则将children设为undefined
                        data[i].children=undefined;
                    } else {
                        // children若不为空数组，则继续 递归调用 本方法

                        this.filterTreeData(data[i].children);
                    }
                }
            }
            return data;
        }
    }
}
</script>

<style scoped>

</style>
