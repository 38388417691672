<template>
    <el-drawer
        title="编辑专家信息"
        :visible.sync="editDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        @closed="toclose"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        size="80%"
    >
        <div class="demo-drawer__content">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row :gutter="32">
                    <el-col :span="14">
                        <el-form-item label="姓名" prop="expertName">
                            <el-input v-model="info.expertName" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="性别" prop="sex">
                            <el-radio-group v-model="info.expertSex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="出生日期" prop="birthday">
                            <el-date-picker
                                v-model="info.birthday"
                                type="date"
                                placeholder="选择日期"
                                @change="info.birthday=$event"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input v-model="info.phone" placeholder="请输入联系电话" />
                        </el-form-item>
                        <el-form-item label="邮箱">
                            <el-input v-model="info.email" placeholder="请输入邮箱" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" style="text-align: center">
                        <SingleUploadImg @callback="uploadHeadImg" :imageUrl="info.headImgUrl"
                                         :width="114" :height="145" :text="'专家照片'"></SingleUploadImg>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="8">
                        <el-form-item label="民族" filterable prop="nationId">
                            <el-select v-model="info.nationId" placeholder="请选择">
                                <el-option
                                    v-for="item in nationList"
                                    :key="item.dictId"
                                    :label="item.dictName"
                                    :value="item.dictId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="政治面貌" filterable prop="politicOutlook">
                            <el-select v-model="info.politicOutlook" placeholder="请选择">
                                <el-option
                                    v-for="item in zzmmList"
                                    :key="item.dictId"
                                    :label="item.dictName"
                                    :value="item.dictId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="籍贯" filterable prop="nativePlaceId">
                            <el-select v-model="info.nativePlaceId" placeholder="请选择">
                                <el-option key="" label="请选择" value=""></el-option>
                                <el-option
                                    v-for="item in provinceList"
                                    :key="item.regionId"
                                    :label="item.regionName"
                                    :value="item.regionId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="8">
                        <el-form-item label="毕业学校" prop="schoolName">
                            <el-input v-model="info.schoolName" placeholder="请输入毕业学校"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="毕业时间" prop="graduationTime">
                            <el-input v-model="info.graduationTime" placeholder="请输入毕业时间"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所学专业" prop="majorStudy">
                            <el-input v-model="info.majorStudy" placeholder="请输入所学专业"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="8">
                        <el-form-item label="学历" prop="eduId">
                            <el-select v-model="info.eduId" placeholder="请选择">
                                <el-option
                                    v-for="item in eduList"
                                    :key="item.dictId"
                                    :label="item.dictName"
                                    :value="item.dictId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="职称" prop="titleId">
                            <el-select v-model="info.titleId" placeholder="请选择">
                                <el-option
                                    v-for="item in titleList"
                                    :key="item.dictId"
                                    :label="item.dictName"
                                    :value="item.dictId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="8">
                        <el-form-item label="工作单位" prop="unitName">
                            <el-input v-model="info.unitName" placeholder="请输入工作单位"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="职务" prop="positionId">
                            <el-select v-model="info.positionId" placeholder="请选择">
                                <el-option
                                    v-for="item in positionList"
                                    :key="item.dictId"
                                    :label="item.dictName"
                                    :value="item.dictId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="16">
                        <el-form-item label="擅长项目" prop="majorItemsIds">
                            <el-cascader v-model="info.majorItemsIds"
                                         :options="filterTreeData(itemTreeList)"
                                         :props="{ expandTrigger: 'hover', multiple: true, value: 'itemId', label: 'itemName' }"
                                         clearable style="width: 100%">
                                <template slot-scope="{ node, data }">
                                    <span>{{ data.itemName }}</span>
                                    <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                </template>
                            </el-cascader>
<!--                            <el-select v-model="info.majorItems" placeholder="请选择">-->
<!--                                <el-option-->
<!--                                    v-for="item in nationList"-->
<!--                                    :key="item.dictId"-->
<!--                                    :label="item.dictName"-->
<!--                                    :value="item.dictId">-->
<!--                                </el-option>-->
<!--                            </el-select>-->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-form-item label="所在地" prop="trainAddress">
                            <AreaSelect
                                :provinceId.sync="info.provinceId"
                                :cityId.sync="info.cityId"
                                :districtId.sync="info.districtId">
                            </AreaSelect>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-form-item label="">
                        <el-tabs value="m1"  type="border-card">
                            <el-tab-pane label="主要专业工作经历" name="m1">
                                <el-row :gutter="32">
                                    <el-col :span="24">
                                        <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 20, maxRows: 20}"
                                            placeholder="请输入主要专业工作经历"
                                            v-model="info.otherInfo.workHistory">
                                        </el-input>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="代表论文、著作" name="m2">
                                <el-row :gutter="32">
                                    <el-col :span="24">
                                        <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 20, maxRows: 20}"
                                            placeholder="请输入代表论文、著作"
                                            v-model="info.otherInfo.papersBooks">
                                        </el-input>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="最新成果" name="m3">
                                <el-row :gutter="32">
                                    <el-col :span="24">
                                        <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 20, maxRows: 20}"
                                            placeholder="请输入最新成果"
                                            v-model="info.otherInfo.latestResult">
                                        </el-input>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="主要突出贡献、突出技能" name="m4">
                                <el-row :gutter="32">
                                    <el-col :span="24">
                                        <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 20, maxRows: 20}"
                                            placeholder="请输入主要突出贡献、突出技能"
                                            v-model="info.otherInfo.majorDevote">
                                        </el-input>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="获奖情况" name="m5">
                                <el-row :gutter="32">
                                    <el-col :span="24">
                                        <el-table
                                            :data="info.prizeList"
                                            fit
                                            stripe
                                            highlight-current-row
                                            style="width: 100%;"
                                            ref="queryTable"
                                            :indent="20"
                                        >
                                            <el-table-column label="获奖项目名称" prop="programName" min-width="160px" align="left">
                                                <template slot-scope="{row}">
                                                    <el-input v-model="row.programName" placeholder="请输入获奖项目名称"></el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="获奖类别" prop="prizeTypeName" min-width="100px" align="left">
                                                <template slot-scope="{row}">
                                                    <el-input v-model="row.prizeTypeName" placeholder="请输入获奖类别"></el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="等级" prop="prizeLevelName" min-width="100px" align="left">
                                                <template slot-scope="{row}">
                                                    <el-input v-model="row.prizeLevelName" placeholder="请输入获奖等级"></el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="排名" prop="prizeRank" min-width="100px" align="left">
                                                <template slot-scope="{row}">
                                                    <el-input v-model="row.prizeRank" placeholder="请输入排名"></el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="角色" prop="prizeRole" min-width="100px" align="left">
                                                <template slot-scope="{row}">
                                                    <el-input v-model="row.prizeRole" placeholder="请输入角色"></el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="年度" prop="prizeYear" min-width="100px" align="left">
                                                <template slot-scope="{row}">
                                                    <el-input v-model="row.prizeYear" placeholder="请输入年度"></el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="操作" align="center" width="120" fixed="right" class-name="small-padding fixed-width">
                                                <template slot="header">
                                                    <el-button type="primary" round size="mini"  icon="el-icon-plus" @click="toAddRow">添加</el-button>
                                                </template>
                                                <template slot-scope="scope">
                                                    <el-button type="text" round size="mini" @click="toDeleteRow(scope.$index, scope.row.prizeId)">删除</el-button>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                        </el-tabs>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="20">
                        <el-form-item label="相关资料">
                            <div style="margin-bottom: 4px">
                                <el-button  type="primary" plain @click="toAddDocs()">添加资料</el-button>
                                <span style="margin-left: 15px; color: #666666">最多上传5个文件</span>
                            </div>
                            <el-row class="table-container" v-show="info.annexList && info.annexList.length > 0">
                                <el-col :span="20">
                                    <el-table
                                        :data="info.annexList"
                                        highlight-current-row
                                        style="width: 100%;"
                                        :indent="20">
                                        <el-table-column label="文件名" prop="annexName" align="left" :show-overflow-tooltip="true">
                                            <template slot-scope="{row}">
                                                {{row.annexName}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="类型" prop="fileType" width="150px" align="center"></el-table-column>
                                        <el-table-column label="操作" width="60px" align="center">
                                            <template slot-scope="scope">
                                                <el-button type="text" @click="deleteAnnexFile(scope.$index, scope.row.annexId)">删除</el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-col>
                            </el-row>

                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <UploadDialog ref="uploadDialog"
                          :title="'资料上传'" :accept="'.pdf,.ppt,.pptx,.doc,.docx'"
                          :desc="'资料上传,支持格式（.pdf,.ppt,.pptx,.doc,.docx）'"
                          :limit="5"
                          @callback="addDocsFinish"></UploadDialog>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="toclose">关闭</el-button>
            <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
        </div>
    </el-drawer>
</template>

<script>
import AreaSelect from '@/views/components/AreaSelect'
import SingleUploadImg from "@c/upload/SingleUploadImg";
import UploadDialog from "@c/upload/UploadDialog";
export default {
    components: {
        AreaSelect,
        SingleUploadImg,
        UploadDialog
    },
    data () {
        return {
            //编辑、新增表单
            editDrawer: false,
            expertId: '',
            info: {
                annexList: [],
                otherInfo: {
                    workHistory: '',
                    papersBooks: '',
                    latestResult: '',
                    majorDevote: '',
                },
                prizeList: [
                    {
                        programName: '',
                        prizeTypeName: '',
                        prizeLevelName: '',
                        prizeRank: '',
                        prizeRole: '',
                        prizeYear: '',
                        deleteStatus: 0
                    }
                ]
            },
            //表单验证规则
            ruleValidate: {
                // 基本信息
                expertName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
            },
            nationList: [],
            zzmmList: [],
            provinceList: [],
            eduList: [],
            titleList: [],
            positionList: [],
            itemTreeList: [],
        };
    },
    methods: {
        show: function (expertId) {
            this.editDrawer = true;
            this.expertId = expertId;
            this.getById();
            this.$nextTick(function () {
            });
            this.getDictList()
        },
        toclose: function () {
            this.editDrawer = false
        },
        getById: async function () {
            if (this.expertId) {
                this.apis.get('/fitness/expert/getById?expertId=' + this.expertId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }
        },
        //上传蓝底照片SingleUploadImg组件的callback方法
        uploadHeadImg: function (webPath, savePath) {
            this.info.headImg = savePath;
            this.info.headImgUrl = webPath;
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "/fitness/expert/save";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$emit("callback")
                                this.editDrawer = false;
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        getDictList: function () {
            this.apis.get('system/dict/getList?typeCode=0010')
                .then((res) => {
                    if (res.code === 1) {
                        this.nationList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            this.apis.get('system/dict/getList?typeCode=0011')
                .then((res) => {
                    if (res.code === 1) {
                        this.zzmmList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            // provinceList
            this.apis.get('system/region/getList?parentId=0')
                .then((res) => {
                    if (res.code === 1) {
                        this.provinceList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            this.apis.get('system/dict/getList?typeCode=0003')
                .then((res) => {
                    if (res.code === 1) {
                        this.eduList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            this.apis.get('system/dict/getList?typeCode=0008')
                .then((res) => {
                    if (res.code === 1) {
                        this.titleList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            this.apis.get('system/dict/getList?typeCode=0009')
                .then((res) => {
                    if (res.code === 1) {
                        this.positionList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
            // itemTreeList
            this.apis.postForm('fitness/items/getTree')
                .then((res) => {
                    if (res.code === 1) {
                        this.itemTreeList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toAddDocs: function () {
            this.$nextTick(() => {
                this.$refs.uploadDialog.open({});
            })
        },
        addDocsFinish: function (fileList) {
            if (!this.info.annexList) {
                this.info.annexList = []
            }

            fileList.forEach(item => {
                this.info.annexList.push({
                    annexName: item.fileName,
                    annexPath: item.filePath,
                    annexPathUrl: item.filePathUrl,
                    fileSize: item.fileSize,
                    fileType: item.fileType
                })
            })
            this.$forceUpdate()
            // this.info.annexList = this.info.annexList.concat(fileList)
        },
        deleteAnnexFile: function (idx, annexId) {
            this.$confirm('确定要删除此条数据？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.info.annexList.splice(idx, 1)
                // if (fileId) {
                //     let data = {
                //         annexId: annexId,
                //         deleteStatus: 1,
                //         operUserId: this.appCookies.getCookieUID(),
                //         operUserName: this.appCookies.getCookieUNAME()
                //     };
                //     this.apis.postForm('course/annex/file/delete', data)
                //         .then((res) => {
                //             if (res.code === 1) {
                //                 this.info.annexList.splice(idx, 1)
                //                 this.$message({
                //                     message: '删除成功',
                //                     showClose: true, offset: 200, type: 'success'
                //                 });
                //             } else {
                //                 this.$message({
                //                     message: res.msg,
                //                     showClose: true, offset: 50, type: 'error'
                //                 });
                //             }
                //         }).catch(err => {
                //         console.log(err);
                //     });
                // } else {
                //     this.info.annexList.splice(idx, 1)
                // }
            }).catch(() => {
            });
        },
        toAddRow: function () {
            if (!this.info.prizeList) {
                this.info.prizeList = []
            }
            this.info.prizeList.push({
                programName: '',
                prizeTypeName: '',
                prizeLevelName: '',
                prizeRank: '',
                prizeRole: '',
                prizeYear: '',
                deleteStatus: 0
            })
        },
        toDeleteRow: function (idx, prizeId) {
            this.$confirm('确定要删除此条数据？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.info.prizeList.splice(idx, 1)
            }).catch(() => {
            });
        },
        filterTreeData(data){
            for (let i=0;i<data.length;i++) {
                if (data[i].children) {
                    if (data[i].children.length<1) {
                        // children若为空数组，则将children设为undefined
                        data[i].children=undefined;
                    } else {
                        // children若不为空数组，则继续 递归调用 本方法

                        this.filterTreeData(data[i].children);
                    }
                }
            }
            return data;
        }
    }
}
</script>

<style scoped>

</style>
