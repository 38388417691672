var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"widget",staticClass:"w-text",class:[{'edit-text': _vm.editable}, _vm.params.uuid, _vm.params.fontClass.value],style:({
  position: 'absolute',
  left: (_vm.params.left - _vm.parent.left) + 'px',
  top: (_vm.params.top - _vm.parent.top) + 'px',
  width: Math.max(_vm.params.fontSize, _vm.params.width) + 'px',
  minWidth: _vm.params.fontSize + 'px',
  minHeight: _vm.params.fontSize * _vm.params.lineHeight + 'px',
  lineHeight: _vm.params.fontSize * _vm.params.lineHeight + 'px',
  letterSpacing: _vm.params.fontSize * _vm.params.letterSpacing / 100 + 'px',
  fontSize: _vm.params.fontSize + 'px',
  color: _vm.params.textColor,
  textAlign: _vm.params.textAlign,
  fontWeight: _vm.params.fontWeight,
  fontStyle: _vm.params.fontStyle,
  textDecoration: _vm.params.textDecoration,
  opacity: _vm.params.opacity,
  backgroundColor: _vm.params.backgroundColor
}),attrs:{"id":"w-text","contenteditable":_vm.editable ? 'plaintext-only' : false},domProps:{"innerHTML":_vm._s(_vm.params.text)},on:{"dblclick":function (e) { return _vm.dblclickText(e); },"blur":function (e) { return _vm.updateText(e); }}})}
var staticRenderFns = []

export { render, staticRenderFns }