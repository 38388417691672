<template>
    <div style="height: 100%">
        <mavon-editor
            placeholder="请输入内容"
            :style="{zIndex:0}"
            :toolbars="toolbars"
            v-model="article.markdownContent"
            :subfield="true"
            :codeStyle="codeStyle"
            :ishljs="true"
            :externalLink="externalLink"
            ref=md
            @save="updateArticle"
            @imgAdd="handleImgAdd"
            @imgDel="handleImgDel"/>
    </div>
</template>

<script>
export default {
    data() {
        return {
            codeStyle: '',
            isShowTagSelectModel: false,
            containerHeight: '500px',
            types: [],
            showTags: [],
            tags: [],
            selectedTags: [],
            defaultTypeLabel: '',
            article: {
                id: null,
                typeId: '',
                title: '',
                markdownContent: '',
                tags: []
            },
            externalLink: {
                markdown_css: function () {
                    // 这是你的markdown css文件路径
                    return '/mavon-editor/markdown/github-markdown.min.css';
                },
                hljs_js: function () {
                    // 这是你的hljs文件路径
                    return '/mavon-editor/highlightjs/highlight.min.js';
                },
                hljs_css: function (css) {
                    // 这是你的代码高亮配色文件路径
                    return '/mavon-editor/highlightjs/styles/' + css + '.min.css';
                },
                hljs_lang: function (lang) {
                    // 这是你的代码高亮语言解析路径
                    return '/mavon-editor/highlightjs/languages/' + lang + '.min.js';
                },
                katex_css: function () {
                    // 这是你的katex配色方案路径路径
                    return '/mavon-editor/katex/katex.min.css';
                },
                katex_js: function () {
                    // 这是你的katex.js路径
                    return '/mavon-editor/katex/katex.min.js';
                },
            },
            toolbars: {
                bold: true, // 粗体
                italic: true, // 斜体
                header: true, // 标题
                underline: true, // 下划线
                strikethrough: true, // 中划线
                mark: true, // 标记
                superscript: true, // 上角标
                subscript: true, // 下角标
                quote: true, // 引用
                ol: true, // 有序列表
                ul: true, // 无序列表
                link: true, // 链接
                imagelink: true, // 图片链接
                code: true, // code
                table: true, // 表格
                fullscreen: true, // 全屏编辑
                readmodel: true, // 沉浸式阅读
                // htmlcode: true, // 展示html源码
                help: true, // 帮助
                /* 1.3.5 */
                undo: true, // 上一步
                redo: true, // 下一步
                // trash: true, // 清空
                save: true, // 保存（触发events中的save事件）
                /* 1.4.2 */
                navigation: true, // 导航目录
                /* 2.1.8 */
                alignleft: true, // 左对齐
                aligncenter: true, // 居中
                alignright: true, // 右对齐
                /* 2.2.1 */
                subfield: true, // 单双栏模式
                preview: true // 预览
            }
        }
    },
    mounted() {
        let that = this;
        that.codeStyle = "solarized-dark";
    },
    methods: {
        updateArticle() {
            console.log(this.article.markdownContent)
            console.log(this.$refs.md.d_render)
        },
        handleImgAdd(pos, $file) {
            let formData = new FormData();
            formData.append('file', $file);
            let url = "system/file/uploadFile";
            // 保存数据
            this.apis.postFile(url, formData)
                .then((res) => {
                    if (res.code === 1) {
                        console.log(res.data)
                        this.$refs.md.$img2Url(pos, res.data.webPath)
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });

            // instance.post('/api/upload/fileds', formdata).then(res => {
            //     if (res.data.code === 200) {
            //         this.$Message.success('上传成功')
            //         let url = res.data.data
            //         let name = $file.name
            //         if (name.includes('-')) {
            //             name = name.replace(/-/g, '')
            //         }
            //         let content = this.form.content
            //         // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)  这里是必须要有的
            //         if (content.includes(name)) {
            //             let oStr = `(${pos})`
            //             let nStr = `(${url})`
            //             let index = content.indexOf(oStr)
            //             let str = content.replace(oStr, '')
            //             let insertStr = (soure, start, newStr) => {
            //                 return soure.slice(0, start) + newStr + soure.slice(start)
            //             }
            //             this.form.content = insertStr(str, index, nStr)
            //         }
            //     } else {
            //         this.$Message.error(res.data.msg)
            //     }
            // })
        },
        handleImgDel(pos) {
            delete this.imgFile[pos]
        },
    }
}
</script>

<style lang="scss" scoped>
.article-box-content {
    width: 100%;
    height: calc(100vh - 140px);
}

::v-deep .markdown-body {
    ul {
        list-style: disc !important;
    }

    ol {
        list-style: decimal !important;
    }
}
</style>
