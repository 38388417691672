<template>
        <el-row :gutter="8" class="form-row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="200px" label-suffix=":">
                    <el-row>
                        <el-col :span="24" class="item_header"><span class="title">小程序设置</span></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="小程序的AppId" prop="orgCode">
                                <el-input v-model="info.appId"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="小程序的AppSecret" prop="orgName">
                                <el-input v-model="info.secret"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="EncodingAESKey" prop="orgCode">
                                <el-input v-model="info.aesKey"></el-input>
                                接口配置里的EncodingAESKey值
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="接口配置里的Token值" prop="orgName">
                                <el-input v-model="info.token"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="消息格式" prop="msgDataFormat">
                                <el-input disabled v-model="info.msgDataFormat"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="24" style="text-align: center">
                            <el-button size="medium" type="primary" @click="saveData('editForm')">保  存</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
</template>

<script>
export default {
    components: {
    },
    props: {
        orgId: {
            type: [String, Number],
            default: ''
        },
    },
    watch: {
        orgId: {
            deep: true,
            immediate: true,
            handler(val) {
                this.currOrgId = val
                this.getByOrgId();
            }
        },
    },
    data () {
        return {
            currOrgId: '',
            info: {},
            //表单验证规则
            ruleValidate: {
            },
        };
    },
    mounted () {
    },
    computed: {
    },
    methods: {
        getByOrgId: function () {
            this.apis.get('/wx/open/mamp/conf/getByOrgId?appType=3&orgId=' + this.currOrgId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        this.$nextTick(function() {
                        });
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "/wx/open/mamp/conf/save";
                    this.info.orgId = this.currOrgId
                    this.info.appType = 3
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.getByOrgId()
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
