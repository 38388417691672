<template>
    <el-drawer
        title="消息模板设置"
        :visible.sync="editDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        size="80%"
    >
        <div class="demo-drawer__content">
            <el-row>
                <el-col :span="24" class="item_header"><span class="title">消息模板</span></el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="padding: 0 40px 40px 40px">
                    <el-tabs v-model="activeName" @tab-click="changeTab">
                        <el-tab-pane label="已绑定模板" name="tab01">
                            <el-table
                                :data="dataList"
                                stripe
                                style="width: 100%">
                                <el-table-column
                                    prop="businessName"
                                    label="业务类型">
                                </el-table-column>
                                <el-table-column
                                    prop="templateTitle"
                                    label="标题" width="150">
                                </el-table-column>
                                <el-table-column
                                    prop="type"
                                    label="模版类型"
                                    width="100">
                                    <template slot-scope="{row}">
                                        {{row.templateType === 2 ? '一次性订阅' : row.templateType === 3 ? '长期订阅' : ''}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="templateId"
                                    label="模板ID"
                                    width="300">
                                </el-table-column>
                                <el-table-column
                                    prop="templateContent"
                                    label="关键词">
                                    <template slot-scope="{row}">
                                        <div style="white-space: pre-line;" v-html="row.templateContent"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="templateExample"
                                    label="示例">
                                    <template slot-scope="{row}">
                                        <div style="white-space: pre-line;" v-html="row.templateExample"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="fieldMapper"
                                    label="字段映射">
                                    <template slot-scope="{row}">
                                        <div v-for="(item, idx) in row.fieldMapperList" :key="idx">
                                            {{item.name}}-{{item.mapperField}}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="140" fixed="right"
                                                 class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.msgTmplId && row.templateId" type="text" size="mini" icon="el-icon-view" @click="toDelete(row.msgTmplId)">删除</el-button>
                                        <el-button v-if="row.msgTmplId && row.templateId" type="text" size="mini" icon="el-icon-view" @click="toTest(row.msgTmplId)">测试</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="我的模板" name="tab02">
                            <el-button type="warning" round size="mini" icon="el-icon-view" @click="toAdd('')">添加模板</el-button>
                            <el-table
                                :data="myTmplList"
                                stripe
                                style="width: 100%">
                                <el-table-column
                                    prop="title"
                                    label="标题" width="150">
                                </el-table-column>
                                <el-table-column
                                    prop="type"
                                    label="模版类型"
                                    width="150">
                                    <template slot-scope="{row}">
                                        {{row.type === 2 ? '一次性订阅' : '长期订阅'}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="priTmplId"
                                    label="模板ID"
                                    width="300">
                                </el-table-column>
                                <el-table-column
                                    prop="content"
                                    label="关键词">
                                    <template slot-scope="{row}">
                                        <div style="white-space: pre-line;" v-html="row.content"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="example"
                                    label="示例">
                                    <template slot-scope="{row}">
                                        <div style="white-space: pre-line;" v-html="row.example"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="160" fixed="right"
                                                 class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button type="text" size="mini" icon="el-icon-view" @click="toBind(row)">绑定业务类型</el-button>
                                        <el-button type="text" size="mini" icon="el-icon-view" @click="toDelTmpl(row)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="editDrawer=false">关闭</el-button>
        </div>
        <TmplateQueryDialog ref="tmplateQueryDialog"></TmplateQueryDialog>
        <SubTmplBindDialog ref="subTmplBindDialog"></SubTmplBindDialog>
        <SubTmplTestDialog ref="subTmplTestDialog"></SubTmplTestDialog>
    </el-drawer>
</template>

<script>
import TmplateQueryDialog from './TmplateQueryDialog'
import SubTmplBindDialog from './SubTmplBindDialog'
import SubTmplTestDialog from './SubTmplTestDialog'
export default {
    components: {
        TmplateQueryDialog,
        SubTmplBindDialog,
        SubTmplTestDialog
    },
    filters: {},
    data() {
        return {
            appId: '',
            editDrawer: false,
            info: {},
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                appType: 3,
                keyword: '',
                deleteStatus: '0',
                certTmplType: '',
                orgId: ''
            },
            dataList: [],
            catIdList: [],
            myTmplList: [],
            activeName: 'tab01'
        };
    },
    mounted() {
    },
    computed: {},
    methods: {
        open: function (appId, orgId) {
            this.editDrawer = true;
            this.appId = appId;
            this.queryParams.orgId = orgId
            this.catIdList = []
            this.dataList = []
            this.myTmplList = []
            this.activeName = 'tab01';
            this.queryData()
            this.getCategory()
        },
        changeTab: function (e) {
            if (e.name === 'tab01') {
                this.queryData();
            } else if (e.name === 'tab02') {
                this.queryMyTmpl();
            }
        },
        queryData: function () {
            this.apis.postForm('/wx/open/tmpl/conf/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.dataList = res.data;
                    } else {
                        this.dataList = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        queryMyTmpl: function () {
            this.apis.postForm('/wx/open/mini/template/getTemplateList?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        this.myTmplList = res.data;
                    } else {
                        this.myTmplList = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getCategory: function () {
            this.apis.postForm('/wx/open/mini/template/getCategory?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        let list = res.data || [];
                        this.catIdList = []
                        list.forEach(item => {
                            this.catIdList.push(item.id)
                        })
                    } else {
                        this.dataList = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toAdd: function () {
            this.$refs.tmplateQueryDialog.open(this.appId, this.catIdList)
        },
        toBind: function (item) {
            this.$refs.subTmplBindDialog.open(item, this.queryParams.orgId)
        },
        toDelete: function (msgTmplId) {
            this.$confirm('确定要设置的模版？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let url = "/wx/open/tmpl/conf/save";
                this.info.msgTmplId = msgTmplId;
                this.info.templateId = '';
                this.info.templateTitle = '';
                this.info.templateContent = '';
                this.info.templateExample = '';
                this.info.templateType = '';
                this.info.fieldMapperList = []
                this.info.createBy = this.appCookies.getCookieUID();
                this.info.createByname = this.appCookies.getCookieTNAME();
                this.info.modifyBy = this.appCookies.getCookieUID();
                this.info.modifyByname = this.appCookies.getCookieTNAME();
                this.info.orgId = this.appCookies.getCookieTOID();
                // 保存数据
                this.apis.postJson(url, this.info)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '删除成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.queryData()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });

        },
        toDelTmpl: function (item) {
            this.$confirm('确定要删除模板？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let url = "/wx/open/mini/template/delTemplate";
                let data = {
                    appId: this.appId,
                    tid: item.priTmplId
                }
                // 保存数据
                this.apis.postJson(url, data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '删除成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.queryMyTmpl()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        toTest: function (msgTmplId) {
            this.$refs.subTmplTestDialog.open(msgTmplId)
        },
    }
}
</script>

<style scoped>

</style>
