<template>
    <div class="comp-container">
        <el-row :gutter="32" class="table-container">
            <el-col>
                <el-table
                    v-loading="tableLoading"
                    :data="tableData"
                    fit
                    stripe
                    highlight-current-row
                    style="width: 100%;"
                    ref="selectTable"
                    :indent="20"
                >
                    <el-table-column label="试卷" prop="paperName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="日期" width="140px" align="center">
                        <template slot-scope="{row}">
                            <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="得分" prop="resultScore" min-width="110px" align="center"></el-table-column>
                    <el-table-column label="状态" min-width="100" align="center">
                        <template slot-scope="{row}">
                            <span>{{ row.dataStatus | dataStatusFilter }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                            :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                            @pagination="initTable"/>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination';
export default {
    components: {
        Pagination,
    },
    filters: {
        dataStatusFilter(status) {
            const statusMap = {
                0: '未完成',
                1: '已完成'
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'danger',
                1: 'success'
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                typeCode: '',
                keyword: '',
                gnvqCatId: '',
                gnvqId: '',
                levelId: '',
                fromRefId: '',
            },
            tableData: [],
            tableLoading: false
        };
    },
    mounted () {
    },
    methods: {
        initParam: function (userId, fromRefId) {
            this.queryParams.pageNumber = 0;
            this.queryParams.userId = userId;
            this.queryParams.fromRefId = fromRefId;
            this.initTable();
        },
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('user/highfreq/paper/mst/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
