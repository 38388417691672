<template>
    <el-dialog
        :title="'操作手册：[' + bookInfo.ebookName + ']'"
        :visible.sync="bookEditDialog"
        :destroy-on-close="false"
        :append-to-body="true"
        :modal-append-to-body="true"
        :fullscreen="true"
        :close-on-click-modal="false">
        <el-row :gutter="8" class="row-container">
            <el-col :span="24">
                <div class="left-container">
                    <div class="left-box">
                        <el-card class="box-card" shadow="never">
                            <div slot="header" class="clearfix">
                                <i class="el-icon-s-operation"></i>
                                <span>章节</span>
                                <el-button-group style="float: right">
                                </el-button-group>
                                <el-button type="text" icon="el-icon-circle-plus-outline" size="mini" circle @click="() => toAddDir()" style="float: right">添加</el-button>
                            </div>
                            <el-row>
                                <el-col :span="24">
                                    <EbookChapterTree ref="ebookChapterTree" :ebookId="ebookId" :canEdit="true" @callback="callbackChapter"></EbookChapterTree>
                                </el-col>
                            </el-row>
                        </el-card>
                    </div>
                    <div class="right-box">
                        <el-tabs v-model="rightTabActive" type="border-card">
                            <el-tab-pane label="内容编辑" name="right01">
                                <el-row :gutter="8" class="row-container">
                                    <el-col :span="24">
                                        <el-form :model="chapterInfo">
                                            <el-form-item label="" prop="chapterName">
                                                <el-input v-model="chapterInfo.chapterName" ></el-input>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                </el-row>
                                <CusMavonEditor ref="cusMavonEditor"
                                                @saveData="saveData"
                                                :mdContent.sync="chapterContent.markdownContent"
                                                :htmlContent.sync="chapterContent.htmlContent"></CusMavonEditor>
                            </el-tab-pane>
                        </el-tabs>
                        <div class="el-tabs-more" v-if="rightTabActive==='right01'">
                            <el-button type="primary" size="mini" @click="saveData()">保 存</el-button>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
                <el-button @click="bookEditDialog = false">关 闭</el-button>
            </span>
    </el-dialog>
</template>

<script>
import EbookChapterTree from './EbookChapterTree'
import CusMavonEditor from '@/components/editor/CusMavonEditor'
export default {
    components: {
        EbookChapterTree,
        CusMavonEditor
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在加载中，请稍等',
            bookEditDialog: false,
            ebookId: '',
            bookInfo: {},
            chapterInfo: {},
            chapterContent: {
                markdownContent: '',
                htmlContent: '',
            },
            rightTabActive: 'right01',
            metaItemIndex: 0,

        }
    },
    mounted() {
    },
    methods: {
        open: function (ebookId) {
            this.ebookId = ebookId;
            this.bookEditDialog = true;
            this.bookInfo = {}
            this.chapterInfo = {}
            this.chapterContent = {
                markdownContent: '',
                htmlContent: '',
            }
            this.$nextTick(() => {
                this.getEbookInfo(ebookId);
                // this.getChapterContent(ebookId);
                this.rightTabActive = 'right01';
            })
        },
        getEbookInfo: function (ebookId) {
            this.apis.get('system/ebook/info/getById?ebookId=' + ebookId)
                .then((res) => {
                    if (res.code === 1) {
                        this.bookInfo = res.data;
                    } else {
                        this.bookInfo = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        callbackChapter: function (chapterInfo) {
            this.chapterInfo = chapterInfo;
            this.getChapterContent(this.chapterInfo.chapterId);
        },
        getChapterContent: function (chapterId) {
            this.apis.get('system/ebook/chapter/content/getByChapterId?chapterId=' + chapterId)
                .then((res) => {
                    if (res.code === 1) {
                        this.chapterContent = res.data || { contentTxt: '' };
                    } else {
                        this.chapterContent = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        saveData: function () {
            if (!this.chapterInfo.chapterName) {
                this.$message({
                    message: '请输入标题',
                    showClose: true, offset: 200, type: 'error'
                });
                return
            }
            let url = "system/ebook/chapter/content/save";
            // 保存数据
            this.chapterContent.ebookId = this.ebookId;
            this.chapterContent.chapterId = this.chapterInfo.chapterId;
            this.chapterContent.chapterName = this.chapterInfo.chapterName;
            // 保存数据
            this.apis.postJson(url, this.chapterContent)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '保存成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.$refs.ebookChapterTree.getTreeData();
                        this.chapterInfo = {}
                        this.chapterContent = {
                            markdownContent: '',
                            htmlContent: '',
                        }
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toAddDir: function () {
            this.$refs.ebookChapterTree.toAddRoot();
        },
    }
}
</script>

<style scoped lang="scss">
    .left-container {
        display: flex;
        flex-direction: row;
        .left-box {
            width: 350px;
        }
        .right-box {
            flex: 1;
            margin-left: 8px;
            position: relative;
        }
    }
    .el-tabs-more {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        text-align: right;
        color: #666666;
        display: block;
        position: absolute;
        top: 0px;
        right: 20px;
        display: flex;
        align-items: center;
    }
    ::v-deep {
        .el-dialog__body {
            padding: 10px 10px;
            .row-container {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
        .el-card__header {
            padding: 10px 20px !important;
        }
        .el-card__body {
            height: calc(100vh - 200px);;
            padding: 10px;
            overflow: auto;
        }
        .el-tabs__content {
            height: calc(100vh - 190px);
            padding: 10px;
            overflow: auto;
            .el-tab-pane {
                //height: 100%;
                //overflow: auto;
            }
        }
    }
</style>
