<template>
    <el-drawer
        title="小程序信息"
        :visible.sync="editDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        :ref="'editDrawerInfo' + appId"
        size="80%"
    >
        <div class="demo-drawer__content">
            <el-form :model="info" ref="editForm" label-width="200px" label-suffix=":">
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">基本信息</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="APPID">
                            {{info.appid}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="小程序名称">
                            {{info.nickname}}
                            <span style="color: red; margin: 0 10px">
                                {{nameStatus.errmsg}}
                            </span>
                            <el-button type="text" size="mini" @click="toModiNickName">修改</el-button>
                            <el-button type="text" size="mini" @click="getModiStatus">审核状态</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="名称修改次数/总额度">
                            本年{{info.nickname_info.modify_used_count}}/{{info.nickname_info.modify_quota}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="主体名称">
                            {{info.principal_name}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="实名验证状态">
                            {{info.realname_status === 1 ? "实名验证成功" : info.account_type === 2 ? "实名验证中" : "实名验证失败"}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="帐号类型">
                            {{info.account_type === 1 ? "订阅号" : info.account_type === 2 ? "服务号" : "小程序"}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="主体类型">
                            {{info.principal_type | principalTypeFilter}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="企业类型">
                            {{info.customer_type | customerTypeFilter}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="企业代码">
                            {{info.credential}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header">
                        <span class="title">头像信息</span>
                        <div class="right">
                            <el-button type="text" size="mini" @click="getApiQuota('/cgi-bin/account/modifyheadimage')">调用次数</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="头像">
                            <img v-if="info.head_image_info" :src="info.head_image_info.head_image_url" class="image" style="width: 140px;height: 140px">
                            <el-button type="text" size="mini" @click="toModiHeadImg">修改</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="头像已使用修改次数" v-if="info.head_image_info">
                                    {{info.head_image_info.modify_used_count}}次（本年）
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="头像修改次数总额度" v-if="info.head_image_info">
                                    {{info.head_image_info.modify_quota}}次（本年）
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">微信认证信息</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否资质认证">
                            {{info.wx_verify_info.qualification_verify === true ? '是' : '否'}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否名称认证">
                            {{info.wx_verify_info.naming_verify === true ? '是' : '否'}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否需要年审">
                            {{info.wx_verify_info.annual_review === true ? '是' : '否'}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="info.wx_verify_info.annual_review">
                    <el-col :span="12">
                        <el-form-item label="年审开始时间">
                            {{info.wx_verify_info.annual_review_begin_time}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="年审截止时间">
                            {{info.wx_verify_info.annual_review_end_time}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">功能介绍信息</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="功能介绍">
                            {{info.signature_info.signature}}
                            <el-button type="text" size="mini" @click="toModiSignature">修改</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="功能介绍已使用修改次数">
                            {{info.signature_info.modify_used_count}}次（本月）
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="功能介绍修改次数总额度">
                            {{info.signature_info.modify_quota}}次（本月）
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">类目管理</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" style="padding: 0 40px 40px 40px">
                        <el-table
                            :data="settingCategories.categories"
                            stripe
                            style="width: 100%">
                            <el-table-column prop="firstName" label="类目">
                                <template slot-scope="{row}">
                                    {{row.firstName}} -> {{row.secondName}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditStatus" align="center" label="状态">
                                <template slot-scope="{row}">
                                    <span v-if="row.auditStatus==1">审核中</span>
                                    <span v-if="row.auditStatus==2">审核不通过</span>
                                    <span v-if="row.auditStatus==3">审核通过</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditReason" label="状态说明"></el-table-column>
                            <el-table-column prop="auditStatus" align="center" label="状态">
                                <template slot-scope="{row}">
                                    <el-button type="text" size="mini" @click="delCategories(row.first, row.second)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="12">
                        <el-button type="primary" size="mini" round @click="toaddCategories">添加类目</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">备案信息</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备案说明">
                            <el-link href="/ebook/detail?ebookId=7&chapterId=26" type="primary" :underline="false" target="_blank" size="mini" >查看说明</el-link>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <div style="font-size: 14px; margin-left: 150px">
                            <p>申请小程序备案之前，请确保已经完成：</p>
                            <p>1、小程序名称、图标、描述等小程序基本信息已填写完成。</p>
                            <p>2、小程序至少存在一个审核通过的类目。</p>
                            <p>3、非个人类型小程序必须完成微信认证。</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">地理位置接口权限</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="padding: 0 40px 40px 40px">
                        <el-table
                            :data="privacy.interfaceList"
                            stripe
                            style="width: 100%">
                            <el-table-column
                                prop="groupName"
                                label="分组">
                            </el-table-column>
                            <el-table-column
                                prop="apiName"
                                label="API名称"
                                width="180">
                                <template slot-scope="{row}">
                                    <el-link :href="row.apiLink" type="primary" :underline="false" target="_blank">{{row.apiName}}</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="apiChName"
                                label="API中文名称">
                            </el-table-column>
                            <el-table-column
                                prop="apiDesc"
                                label="功能描述">
                            </el-table-column>
                            <el-table-column
                                prop="status"
                                align="center"
                                label="状态">
                                <template slot-scope="{row}">
                                    <el-tag v-if="row.status==1" type="info">待申请开通</el-tag>
                                    <el-tag v-if="row.status==2" type="warning">无权限</el-tag>
                                    <el-tag v-if="row.status==3">申请中</el-tag>
                                    <el-tag v-if="row.status==4" type="danger">申请失败</el-tag>
                                    <el-tag v-if="row.status==5" type="success">已开通</el-tag>
                                    <el-button v-if="row.status==1 || row.status==4" type="text" size="mini" @click="toapplyPrivacy(appId, row)">开通</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="failReason"
                                label="状态说明">
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">用户隐私保护</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" style="padding: 0 40px 40px 40px">
                        <el-table
                            :data="privacySetting.settingList"
                            stripe
                            style="width: 100%">
                            <el-table-column prop="privacyKey" label="隐私保护项目">
                                <template slot="header">
                                    隐私保护项目<el-button type="text" size="mini" round @click="toaddPrivacyKey">添加</el-button>
                                </template>
                                <template slot-scope="{row}">
                                    <el-select filterable v-model="row.privacyKey" placeholder="请选择">
                                        <el-option v-for="item in privacySetting.privacyDesc.privacyDescList" :key="item.privacyKey"
                                                   :label="item.privacyDesc" :value="item.privacyKey"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column prop="privacyText" align="center" label="信息用途">
                                <template slot-scope="{row}">
                                    <el-input v-model="row.privacyText" placeholder="请填写收集该信息的用途"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditStatus" align="center" label="操作">
                                <template slot-scope="scope">
                                    <el-button type="text" size="mini" @click="delPrivacyKey(scope.$index)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="信息收集方手机号">
                            <el-input v-model="privacySetting.ownerSetting.contactPhone"></el-input>
                        </el-form-item>
                        <el-form-item label="信息收集方邮箱地址">
                            <el-input v-model="privacySetting.ownerSetting.contactEmail"></el-input>
                        </el-form-item>
                        <el-form-item label="通知方式">
                            <el-input v-model="privacySetting.ownerSetting.noticeMethod" placeholder="短信"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" size="small" round @click="setPrivacySetting">保存设置</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">体验者</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" style="padding: 0 40px 40px 40px">
                        <el-table
                            :data="testerList"
                            stripe
                            style="width: 100%">
                            <el-table-column prop="privacyKey" label="体验者微信号">
                                <template slot="header">
                                    体验者微信号<el-button type="text" size="mini" round @click="toaddTester">添加</el-button>
                                </template>
                                <template slot-scope="{row}">
                                    <el-input v-model="row.userstr" placeholder="体验者微信号"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditStatus" align="center" label="操作">
                                <template slot-scope="scope">
                                    <el-button type="text" size="mini" @click="bindTester(scope.row)">保存</el-button>
                                    <el-button type="text" size="mini" @click="unbindTester(scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="editDrawer=false">关闭</el-button>
        </div>
        <CategoryDialog ref="categoryDialog" @callback="getSettingCategories"></CategoryDialog>
        <NicknameDialog ref="nicknameDialog" @callback="getBasicInfo"></NicknameDialog>
        <HeadImgDialog ref="headImgDialog" @callback="getBasicInfo"></HeadImgDialog>
        <SignatureDialog ref="signatureDialog" @callback="getBasicInfo"></SignatureDialog>
        <ApplyPrivacyInterfaceDialog ref="applyPrivacyInterfaceDialog" @callback="getPrivacyInterface"></ApplyPrivacyInterfaceDialog>
    </el-drawer>
</template>

<script>
import CategoryDialog from './CategoryDialog'
import NicknameDialog from './NicknameDialog'
import HeadImgDialog from './HeadImgDialog'
import SignatureDialog from './SignatureDialog'
import ApplyPrivacyInterfaceDialog from './ApplyPrivacyInterfaceDialog'

export default {
    components: {
        CategoryDialog,
        NicknameDialog,
        HeadImgDialog,
        SignatureDialog,
        ApplyPrivacyInterfaceDialog
    },
    filters: {
        principalTypeFilter(status) {
            const statusMap = {
                0: '个人',
                1: '企业',
                2: '媒体',
                3: '政府',
                4: '其他组织',
            };
            return statusMap[status]
        },
        customerTypeFilter(status) {
            const statusMap = {
                0: '未知',
                1: '企业',
                2: '企业媒体',
                3: '政府',
                4: '非盈利组织',
                5: '民营非企业',
                6: '盈利组织',
                8: '社会团体',
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            appId: '',
            editDrawer: false,
            info: {
                orderNum: 0,
                head_image_info: {
                    head_image_url: '',
                    modify_used_count: 0,
                    modify_quota: 0
                },
                wx_verify_info: {
                    qualification_verify: ''
                },
                signature_info: {
                    signature: ''
                },
                nickname_info: {
                    modify_used_count: '',
                    modify_quota: ''
                }
            },
            privacy: {
                interfaceList: [
                    {
                        apiName: '',
                        apiChName: '',
                        apiDesc: '',
                        status: '',
                        failReason: '',
                        groupName: '',
                    }
                ]
            },
            nameStatus: {
                errcode: '',
                errmsg: ''
            },
            settingCategories: {},
            allCategories: {},
            privacySetting: {
                settingList: [
                    {
                        privacyKey: '',
                        privacyText: '',
                    }
                ],
                ownerSetting: {
                    contactPhone: '',
                    contactEmail: '',
                    storeExpireTimestamp: '',
                    noticeMethod: '',
                },
                privacyDesc: {
                    privacyDescList: [
                        {
                            privacyKey: 'UserInfo',
                            privacyDesc: '用户信息（微信昵称、头像）',
                        }
                    ]
                }
            },
            testerList: [{
                userstr: '',
                type: ''
            }]
        };
    },
    mounted () {
    },
    computed: {
    },
    methods: {
        open: function (appId) {
            this.editDrawer = true;
            this.appId = appId;
            this.getBasicInfo()
        },
        getBasicInfo: function () {
            this.apis.get('wx/open/mini/mgr/getBasicInfo?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        this.getOtherInfo()
                        if (this.info.realname_status !== 1) {
                            this.getNickNameStatus(this.appId)
                        }
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getNickNameStatus: function () {
            this.apis.get('wx/open/mini/mgr/getNickNameStatus?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        this.nameStatus = res.data;
                    }
                }).catch(err => {
                console.log(err);
            });

        },
        getOtherInfo() {
            this.getPrivacyInterface();
            this.getSettingCategories();
            this.getPrivacySetting();
            // this.getTester();
            this.getIcpEntranceInfo()
            // this.apis.get('wx/open/mini/mgr/getAllCategories?appId=' + appid)
            //     .then((res) => {
            //         console.log(res);
            //         if (res.code === 1) {
            //             this.allCategories = res.data;
            //         } else {
            //             this.info = {}
            //         }
            //     }).catch(err => {
            //     console.log(err);
            // });
        },
        getPrivacyInterface() {
            this.apis.get('wx/open/mini/mgr/getPrivacyInterface?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        this.privacy = res.data;
                    } else {
                        this.privacy = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getSettingCategories() {
            this.apis.get('wx/open/mini/mgr/getSettingCategories?appId=' + this.appId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.settingCategories = res.data;
                    } else {
                        this.settingCategories = {}
                    }
                }).catch(err => {
                console.log(err);
            });
            this.getPrivacyInterface()
        },
        getTester() {
            this.apis.get('wx/open/mini/mgr/getTester?appId=' + this.appId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.testerList = res.data.membersList || [];
                    } else {
                        this.testerList = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toapplyPrivacy: function (appId, interfaceInfo) {
            this.$refs.applyPrivacyInterfaceDialog.open(appId, interfaceInfo)
        },
        toaddCategories: function () {
            this.$refs.categoryDialog.open(this.appId)
        },
        delCategories: function (first, second) {
            let data = {
                appId: this.appId,
                first: first,
                second: second
            };
            this.apis.postForm('wx/open/mini/mgr/deleteCategory', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.getSettingCategories(this.appId);
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toModiNickName: function () {
            this.$refs.nicknameDialog.open(this.appId)
        },
        toModiHeadImg: function () {
            this.$refs.headImgDialog.open(this.appId)
        },
        toModiSignature: function () {
            this.$refs.signatureDialog.open(this.appId)
        },
        toaddPrivacyKey: function () {
            this.privacySetting.settingList.push({
                privacyKey: '',
                privacyText: '',
            })
        },
        toaddTester: function () {
            this.testerList.push({
                userstr: '',
                type: 'new',
            })
        },
        bindTester(row) {
            this.apis.postJson('wx/open/mini/mgr/bindTester?appId=' + this.appId + '&wechatid=' + row.userstr)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '添加成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        // this.getTester()
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        unbindTester(row) {
            this.apis.postJson('wx/open/mini/mgr/unbindTester?appId=' + this.appId + '&wechatid=' + row.userstr)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '删除成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        // this.getTester()
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        delPrivacyKey: function (index) {
            this.privacySetting.settingList.splice(index, 1);
        },
        getModiStatus() {
            this.apis.get('wx/open/mini/mgr/getNickNameStatus?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: res.data,
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getPrivacySetting() {
            this.apis.get('wx/open/mini/mgr/getPrivacySetting?appId=' + this.appId + '&privacy_ver=2')
                .then((res) => {
                    if (res.code === 1) {
                        this.privacySetting = res.data;
                    } else {
                        this.privacySetting = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        setPrivacySetting() {
            this.apis.postJson('wx/open/mini/mgr/setPrivacySetting/' + this.appId, this.privacySetting)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getApiQuota(cgiPath) {
            this.apis.get('wx/open/mini/mgr/getApiQuota?appId=' + this.appId + '&cgiPath=' + cgiPath)
                .then((res) => {
                    if (res.code === 1) {
                        console.log(res.data);
                    } else {
                        // this.privacySetting = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getIcpEntranceInfo() {
            this.apis.get('wx/open/mini/icp/getIcpEntranceInfo?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        console.log(res.data);
                    } else {
                        // this.privacySetting = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped>

</style>
