<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>视频分类</span>
                    </div>
                    <GnvqStoreTree ref="gnvqTree" @callback="toQuery"></GnvqStoreTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>视频列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" clearable style="width: 140px"></el-input>
                                </el-form-item>
                                <el-form-item style="width: 100px">
                                    <el-select v-model="queryParams.useStatus" placeholder="请选择可用状态">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="可用" value="1"></el-option>
                                        <el-option label="禁用" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="width: 100px">
                                    <el-select v-model="queryParams.uploadStatus" placeholder="请选择上传状态">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="未上传" value="0"></el-option>
                                        <el-option label="已上传" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="width: 100px">
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择储存状态">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-show="queryParams.levelId || queryParams.baseCatId" style="width: 100px">
                                    <GroupEditorSelect ref="groupSelect"
                                        apiPrefix="/course/video/group"
                                        :editable="false"
                                        :groupId.sync="queryParams.groupId"
                                        :baseCatId="queryParams.baseCatId"
                                        :gnvqId="queryParams.gnvqId"
                                        :levelId="queryParams.levelId">
                                    </GroupEditorSelect>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button class="filter-item"
                                    v-show="queryParams.levelId || queryParams.baseCatId"
                                    type="success"
                                    round
                                    icon="el-icon-edit"
                                    style="float: right"
                                    @click="toMoveGroup">转移分组
                                </el-button>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                                @selection-change="handleSelectionChange"
                            >
                                <el-table-column
                                    type="selection"
                                    width="55">
                                </el-table-column>
                                <el-table-column label="视频名称" prop="videoName" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="视频时长" prop="videoTime" min-width="100px" align="center"></el-table-column>
                                <el-table-column label="视频类型" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.videoType | colorFilter">
                                            {{ row.videoType  | videoTypeFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="基础课类别" prop="baseCatName" min-width="110px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种" prop="gnvqName" min-width="150px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="分组" prop="groupName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="排序号" prop="orderNum" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="可用状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-switch
                                            v-model="row.useStatus"
                                            :active-value="1"
                                            :inactive-value="0"
                                            active-color="#2d8cf0"
                                            inactive-color="#efefef"
                                            @change="toLock(row)">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" min-width="100px" align="left">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作人" prop="modifyByname" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="操作时间" prop="modifyTime" min-width="180px" align="left"></el-table-column>
                                <el-table-column label="操作" align="center" width="170" fixed="right" class-name="small-padding">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.videoId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.videoId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.videoId, 0)">恢复</el-button>
                                        <el-button v-if="row.uploadStatus===1" type="text" size="mini" @click="toPlayAliyun(row)">预览</el-button>
                                        <el-button v-if="row.uploadStatus===1 && row.videoTime===0" type="text" size="mini" @click="updVideoTime(row.videoId)">更新时长</el-button>
                                        <el-button v-if="row.uploadStatus===0 && row.playSource!==3" type="text" size="mini" @click="toUpload(row.videoId,row.videoName)">上传视频</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="视频管理"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-row :gutter="32">
                        <el-col :span="12">
                            <el-form-item label="视频名称" prop="videoName">
                                <el-input v-model="info.videoName" placeholder="请输入视频名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="视频类型" prop="videoType" >
                                <el-radio-group v-model="info.videoType" @change="changeVideoType">
                                    <el-radio :label="1">基础课</el-radio>
                                    <el-radio :label="2">专业课</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-if="info.videoType===1">
                        <el-col :span="8">
                            <el-form-item label="基础课类别" prop="baseCatId">
                                <el-select v-model="info.baseCatId" @change="onBaseCatIdChange">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in baseCatList" :value="item.dictId" :label="item.dictName"  :key="item.dictId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-if="info.videoType===2">
                        <el-col :span="16">
                            <el-form-item label="工种等级" prop="categoryId">
                                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                                            :levelId.sync="info.levelId"  @callback="levelChange"></GnvqSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-show="info.levelId || info.baseCatId">
                        <el-col :span="16">
                            <el-form-item label="分组">
                                <GroupEditorSelect
                                    ref="groupEditor"
                                    apiPrefix="/course/video/group"
                                    :editable="true"
                                    :groupId.sync="info.groupId"
                                    :baseCatId="info.baseCatId"
                                    :gnvqId="info.gnvqId"
                                    :levelId="info.levelId">
                                </GroupEditorSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="14">
                            <el-form-item label="视频时长" prop="videoTime">
                                <el-input v-model="info.videoTime" placeholder="请输入视频时长">
                                    <template slot="append">秒</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="视频源" prop="playSource" >
                                <el-radio-group v-model="info.playSource">
                                    <el-radio :label="2">上传视频</el-radio>
                                    <el-radio :label="3">外部链接</el-radio>
                                </el-radio-group>
                                <div v-show="info.playSource===2"><i class="el-icon-question" style="color: #999999"></i>保存后再上传</div>
                            </el-form-item>
                            <el-form-item label="视频地址" prop="videoPath" v-show="info.playSource===3">
                                <el-input v-model="info.videoPath" placeholder="请输入视频地址">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="视频图片">
                                <SingleUploadImg @callback="uploadVideoImgFront" :imageUrl="info.videoImgUrl"
                                                 text="建议尺寸750*560或比例4:3，小于2M的JPG、PNG格式图片"
                                                 :width="160" :height="120"></SingleUploadImg>
                                <el-button type="success" size="mini" @click="toSelectClassImage">选择图片</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="24">
                            <el-form-item label="相关资料" prop="videoTime">
                                <div style="margin-bottom: 4px">
                                    <el-button  type="primary" plain @click="toAddDocs()">添加资料</el-button>
                                    <span style="margin-left: 15px; color: #666666">最多添加5个文件，学员学习中可进行查看</span>
                                </div>
                                <el-row class="table-container" v-show="info.annexFileList && info.annexFileList.length > 0">
                                    <el-col :span="20">
                                        <el-table
                                            :data="info.annexFileList"
                                            highlight-current-row
                                            style="width: 100%;"
                                            :indent="20">
                                            <el-table-column label="文件名" prop="fileName" align="left" :show-overflow-tooltip="true">
                                                <template slot-scope="{row}">
                                                    <el-link type="primary" @click="toPreviewFile(row)">{{row.fileName}}</el-link>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="类型" prop="fileType" width="150px" align="center"></el-table-column>
                                            <el-table-column label="大小" prop="size" width="100px" align="center">
                                                <template slot-scope="{row}">
                                                    {{ (row.fileSize / 1024).toFixed(2)}} KB
                                                </template>
                                            </el-table-column>

                                            <el-table-column label="状态" prop="size" width="100px" align="center">
                                                <template slot-scope="{row}">
                                                    {{ row.transImgStattus | transStatus}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="信息" prop="size" width="100px" align="center">
                                                <template slot-scope="{row}">
                                                    {{ row.transImgMsg}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="操作" width="140px" align="center">
                                                <template slot-scope="scope">
                                                    <el-button type="text" @click="deleteAnnexFile(scope.$index, scope.row.fileId)">删除</el-button>
                                                    <el-button type="text" v-if="scope.row.transImgStattus!==0 && scope.row.transImgStattus!==1" @click="trans2Img(scope.row.fileId)">转图片</el-button>
                                                    <el-button type="text" @click="toPreviewImg(scope.row)">预览</el-button>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-col>
                                </el-row>

                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="知识点" prop="pointId">
                                <SelectTree :tree-data="pointList" :selectId.sync="info.pointId"
                                            :treeProps="treeProps" :nodeKey="'pointId'"
                                            :onlySelectLeaf="false"></SelectTree>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="授课老师" prop="teacherId">
                                <el-input v-model="info.teacherName" :readonly="true">
                                    <el-button @click="toTeacherSelect" slot="append" icon="el-icon-search"></el-button>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="序号" prop="orderNum">
                                <span slot="label">
                                    序号：
                                    <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                        <i class="el-icon-question" style="color:red"></i>
                                    </el-tooltip>
                                </span>
                                <el-input v-model="info.orderNum"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="24">
                            <el-form-item label="视频简介" prop="videoDesc">
                                <el-input type="textarea" :rows="6" v-model="info.videoDesc"
                                          placeholder="请输入视频简介" maxlength="300"
                                          show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">取消</el-button>
                <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
        <el-dialog
            title="转移分组"
            :visible.sync="moveGroupDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="moveGroupInfo" ref="moveGroupForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20" :offset="2">
                        <el-form-item label="分组" prop="moveGroupInfo.groupId">
                            <GroupEditorSelect
                                ref="moveGroupEditor"
                                apiPrefix="/course/video/group"
                                :editable="false"
                                :groupId.sync="moveGroupInfo.groupId"
                                :baseCatId="queryParams.baseCatId"
                                :gnvqId="queryParams.gnvqId"
                                :levelId="queryParams.levelId">
                            </GroupEditorSelect>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="moveGroupDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveGroup()">保存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="视频预览"
            :visible.sync="playVideoDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="800px"
            >
            <el-row>
                <el-col>
                    <video-player  class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :options="playerOptions"
                        :playsinline="playsinline"
                    >
                    </video-player>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="playVideoDialog = false">关 闭</el-button>
                <el-button type="primary" @click="reUpload">重新上传</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="pdf文件预览"
            :visible.sync="previewPdfDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="100%"
            >
            <el-row>
                <el-col>
                    <el-row align="middle">
                        <el-col :span="24" style="text-align: center;">
                            <el-button-group>
                                <el-button type="primary" icon="el-icon-d-arrow-left" size="mini" @click="firstPage">首页</el-button>
                                <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prePage">上一页</el-button>
                                <el-button type="primary" size="mini" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                                <el-button type="primary" size="mini" @click="lastPage">尾页<i class="el-icon-d-arrow-right"></i></el-button>
                            </el-button-group>
                        <div class="pageNum" style="float:right;padding-top:4px">
                                <input v-model.number="previewInfo.pageNum"
                                    type="number"
                                    class="inputNumber"
                                    @input="inputEvent()"> / {{previewInfo.pageTotalNum}}
                        </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col style="text-align: center;">
                            <pdf
                                ref="pdf"
                                :src="previewInfo.filePathUrl"
                                :page="previewInfo.pageNum"
                                @progress="previewInfo.loadedRatio=$event"
                                @num-pages="previewInfo.pageTotalNum=$event"
                            >
                            </pdf>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="previewPdfDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="docx文件预览"
            :visible.sync="previewDocxDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="100%">
            <el-row>
                <el-col :span="20" :offset="2">
                    <div ref="docxFile"></div>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="previewDocxDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :class="[imgInfo.showAll?'limit-vertical-height':'limit-height']"
            title="浏览图片"
            :visible.sync="previewImgDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :fullscreen="true">
                <ImageViewer
                :imgList="imgList"
                :maxRecord="imgInfo.maxRecord"
                :showAll.sync='imgInfo.showAll'
                @updateMaxRecord="updateMaxRecord">
                </ImageViewer>
            <span slot="footer" class="dialog-footer">
                <el-button @click="previewImgDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
<!--        <el-dialog-->
<!--            title="视频上传"-->
<!--            :visible.sync="uploadVideoDialog"-->
<!--            :close-on-click-modal="false"-->
<!--            width="900px"-->
<!--            >-->
<!--            <div>-->
<!--                <AliyunUploadVideo ref="aliyunUploadVideo" @callback="uploadFinish"></AliyunUploadVideo>-->
<!--            </div>-->
<!--            <span slot="footer" class="dialog-footer">-->
<!--                <el-button @click="uploadVideoDialog = false">取消</el-button>-->
<!--                <el-button type="primary" @click="uploadVideoDialog = false">完成</el-button>-->
<!--            </span>-->
<!--        </el-dialog>-->
        <AliyunSingleUpload ref="aliyunSingleUpload"
                           :title="'视频上传'" :accept="'.mp4'"
                           :desc="'支持mp4格式;文件最大不超过500M;最大支持720高清转码'"  authUrl='course/video/getUploadAuth' @callback="uploadFinish"></AliyunSingleUpload>
        <UploadDialog ref="uploadDialog"
                            :title="'资料上传'" :accept="'.pdf,.ppt,.pptx'"
                            :desc="'资料上传,支持格式（.pdf,.ppt,.pptx）'"
                      :limit="5"
                      @callback="addDocsFinish"></UploadDialog>
        <TeacherSelect ref="teacherSelect" @callback="setTeacherInfo"></TeacherSelect>
        <ImageSelectDialog ref="imageSelectDialog" @callback="imageCallback"></ImageSelectDialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqStoreTree from "@v/components/GnvqStoreTree";
    import GroupEditorSelect from "@v/components/GroupEditorSelect";
    import GnvqSelect from "@v/components/GnvqSelect";
    import SingleUploadImg from "@c/upload/SingleUploadImg";
    import AliyunSingleUpload from "./upload/AliyunSingleUpload";
    import SelectTree from '@/components/tree/SelectTree';
    import UploadDialog from "@c/upload/UploadDialog";
    import TeacherSelect from "./components/TeacherSelect.vue";
    import pdf from 'vue-pdf';
    import ImageViewer from './components/ImageViewer'
    import ImageSelectDialog from "../components/ImageSelectDialog"
    let docx = require("docx-preview");
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqStoreTree,
            GnvqSelect,
            SingleUploadImg,
            AliyunSingleUpload,
            SelectTree,
            GroupEditorSelect,
            UploadDialog,
            pdf,
            TeacherSelect,
            ImageViewer,
            ImageSelectDialog
            // SingleUploadImg,
            // MutiUploadImg,
            // AreaSelect
        },
        filters: {
            dataStatusFilter(status) {
                const statusMap = {
                    1: '已提交',
                    0: '暂存',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    1: '已删除',
                    0: '正常',
                };
                return statusMap[status]
            },
            confirmStatusFilter(status) {
                const statusMap = {
                    1: '已审核',
                    0: '未审核',
                };
                return statusMap[status]
            },
            videoTypeFilter(status) {
                const statusMap = {
                    1: '基础课',
                    2: '专业课',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
            transStatus(status) {
                const statusMap = {
                    0: '转换中',
                    1: '已转换',
                    3: '未转换',
                    9: '转换失败'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '课程视频库'
                    }
                ],
                //视频列表数据
                tableData: [],
                tableLoading: false,
                //视频列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    uploadStatus: '',
                    useStatus: '',
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    videoType: '',
                    baseCatId: '',
                    playSource: '',
                    transType: '',
                    groupId: '',
                    orgId: this.appCookies.getCookieOID()
                },
                selectdata: [],
                moveGroupDialog: false,
                moveGroupInfo: {
                    groupId: 0
                },
                //编辑、新增表单
                info: {
                    orderNum: 0,
                    annexFileList: []
                },
                //基础课类别菜单
                baseCatList: [],
                addGroupDialog: false,
                newGroupName: "",
                //知识点菜单
                pointList: [
                    // {
                    //     pointId: '0',
                    //     pointName: '请选择'
                    // }
                ],
                //文件预览
                previewPdfDialog: false,
                previewDocxDialog: false,
                previewInfo: {
                    // url: '/testPDF.pdf',
                    pageNum: 1,
                    pageTotalNum: 1,
                    loadedRatio: 0,
                    scale: 100,
                },
                //图片浏览
                previewImgDialog: false,
                imgInfo: {
                    maxRecord: 0,
                    showAll: false,
                },
                imgList: [],
                //知识点下拉菜单
                treeProps: {
                    value: 'pointId',
                    label: 'pointName',
                    children: 'children'
                },
                //表单验证规则
                ruleValidate: {
                    // 视频基本信息
                    videoName: [
                        { required: true, message: '请输入视频名称', trigger: 'blur' }
                    ],
                    videoType: [
                        { required: true, message: '请选则视频类型', trigger: 'blur' }
                    ],
                    //工种类别、工种、工种等级
                    // gnvqCatId: [
                    //     { required: true, type: 'number', message: '请选择工种类别', trigger: 'change' }
                    // ],
                },
                //控制组件显示
                playVideoDialog: false,
                editDrawer: false,
                //视频预览
                playerOptions: {
                    // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    aspectRatio: '16:9',
                    // width: document.documentElement.clientWidth, // 默认视频全屏时的最大宽度
                    autoplay: false, // 是否自动播放
                    muted: false, // 是否默认静音
                    language: 'zh-CN',
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    loop: false, // 导致视频一结束就重新开始。
                    playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4",
                        // mp4
                        src: "",
                        //src: "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4"
                        // webm
                        // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                    }], // 视频地址
                    poster: "", // 封面
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true  // 全屏按钮
                    }
                },
                //视频上传
                uploadInfo: {},
            };
        },
        mounted () {
            this.initTable();
            this.getBaseCatList();
            this.$nextTick(function() {
                window.removeEventListener('message', this.message, false);
                window.addEventListener('message', this.message, false);
            });
        },
        computed: {
            //视频预览相关
            player() {
                return this.$refs.videoPlayer.player
            },
            playsinline () {
                let ua = navigator.userAgent.toLocaleLowerCase()
                // x5内核
                if (ua.match(/tencenttraveler/) !== null || ua.match(/qqbrowse/) !== null) {
                    return false
                } else {
                    // ios端
                    return true
                }
            }
        },
        methods: {
            handleSelectionChange(val) {
                this.selectdata = val;
            },
            toMoveGroup: function () {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                this.moveGroupDialog=true;
                this.moveGroupInfo.groupId = this.queryParams.groupId;
            },
            saveGroup: function () {
                let videoIds = [];
                this.selectdata.forEach(function (item) {
                    videoIds.push(item.videoId)
                });
                let data = {
                    "toGroupId": this.moveGroupInfo.groupId,
                    "videoIds": videoIds
                }
                // 保存数据
                this.apis.postJson('/course/video/batchUpdGroup', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '保存成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.moveGroupDialog = false;
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            onBaseCatIdChange: function () {
                // this.info.groupId = 0;
                this.$set(this.info, 'groupId', 0)
                this.getPointList();
            },
            toAddDocs: function () {
                this.$nextTick(() => {
                    this.$refs.uploadDialog.open({});
                })
            },
            addDocsFinish: function (fileList) {
                if (!this.info.annexFileList) {
                    this.info.annexFileList = []
                }
                this.info.annexFileList = this.info.annexFileList.concat(fileList)
            },
            toPreviewFile: function (row) {
                if (row.fileType === 'pdf') {
                    this.previewInfo.filePathUrl = row.filePathUrl
                    this.previewInfo.pageNum = 1;
                    this.previewInfo.scale = 100;
                    this.previewPdfDialog = true;
                } else if (row.fileType === 'docx') {
                    this.goPreview(row.filePathUrl);
                }
            },
            toPreviewImg: function (row) {
                console.log(row);
                this.imgInfo.maxRecord = 0
                if (row.fileType === 'pdf') {
                    this.imgInfo.showAll = true;
                } else {
                    this.imgInfo.showAll = false;
                }
                this.apis.get('course/annex/file/getImgaes?fileId=' + row.fileId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.imgList = res.data;
                            this.previewImgDialog = true;
                        } else {
                            this.imgList = []
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            //首页
            firstPage() {
                this.previewInfo.pageNum = 1
            },
            //上一页
            prePage() {
                let page = this.previewInfo.pageNum
                page = page > 1 ? page - 1 : this.previewInfo.pageTotalNum
                this.previewInfo.pageNum = page
            },
            // 下一页
            nextPage() {
                let page = this.previewInfo.pageNum
                page = page < this.previewInfo.pageTotalNum ? page + 1 : 1
                this.previewInfo.pageNum = page
            },
            // 最后一页
            lastPage() {
                this.previewInfo.pageNum = this.previewInfo.pageTotalNum
            },
            // 输入页码时校验
            inputEvent () {
                if (this.previewInfo.pageNum > this.previewInfo.pageTotalNum) {
                    // 1. 大于max
                    this.previewInfo.pageNum = this.previewInfo.pageTotalNum
                } else if (this.previewInfo.pageNum < 1) {
                    // 2. 小于min
                    this.previewInfo.pageNum = 1
                }
            },
            goPreview(filePathUrl) {
                this.apis.axios()({
                    method: 'get',
                    url: filePathUrl,
                    'responseType': 'blob'
                }).then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '未查询到数据',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        return false
                    }
                    this.previewDocxDialog = true;
                    docx.renderAsync(res, this.$refs.docxFile); // 渲染到页面
                }).catch(err => {
                    console.log(err);
                });
            },
            deleteAnnexFile: function (idx, fileId) {
                this.$confirm('确定要删除此条数据？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (fileId) {
                        let data = {
                            fileId: fileId,
                            deleteStatus: 1,
                            operUserId: this.appCookies.getCookieUID(),
                            operUserName: this.appCookies.getCookieUNAME()
                        };
                        this.apis.postForm('course/annex/file/delete', data)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.info.annexFileList.splice(idx, 1)
                                    this.$message({
                                        message: '删除成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    } else {
                        this.info.annexFileList.splice(idx, 1)
                    }
                }).catch(() => {
                });
            },
            trans2Img: function (fileId) {
                this.apis.postForm('course/annex/file/trans2Img', { fileId: fileId })
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '文件转换中，请稍后预览查看',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toTeacherSelect: function () {
                this.$refs.teacherSelect.open();
            },
            //视频上传
            toUpload: function (videoId, videoName) {
                this.uploadInfo.videoId = videoId;
                this.uploadInfo.videoName = videoName;
                this.$nextTick(() => {
                    this.$refs.aliyunSingleUpload.open(this.uploadInfo);
                })
            },
            reUpload: function () {
                this.playVideoDialog = false;
                this.$nextTick(() => {
                    this.$refs.aliyunSingleUpload.open(this.uploadInfo);
                })
            },
            uploadFinish: function (uploadInfo) {
                this.apis.postForm('course/video/uploadStatus', uploadInfo)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                        } else {
                            self.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'success'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            //获取视频列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/video/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            setTeacherInfo: function (id, name) {
                this.info.teacherId = id;
                this.info.teacherName = name;
            },
            //教师头像显示失败
            errorHandler() {
                return true
            },
            //获取基础课选择菜单
            getBaseCatList: function () {
                this.apis.get('system/dict/getList?typeCode=0004')
                    .then((res) => {
                        if (res.code === 1) {
                            this.baseCatList = res.data || [];
                            this.baseCatTreeData = [
                                {
                                    dictId: '',
                                    dictName: '基础课视频',
                                    children: this.baseCatList
                                }
                            ]
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //获取知识点下拉菜单选项
            getPointList: function () {
                let data = {
                    levelId: this.info.levelId,
                    baseCatId: this.info.baseCatId,
                };
                this.apis.get('gnvq/point/getList', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.pointList = res.data || []
                            this.pointList.splice(0, 0, { pointId: '0', pointName: '请选择' });
                            console.log(this.pointList);
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            clearQuery: function () {
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                this.queryParams.videoType = '';
                this.queryParams.baseCatId = '';
                this.queryParams.groupId = '';
            },
            //gnvqTree组件（视频分类）callback方法
            toQuery: function (videoType, data) {
                // console.log(data)
                this.clearQuery();
                this.queryParams.videoType = videoType;
                if (videoType === 1) {
                    this.queryParams.baseCatId = data.dictId;
                } else {
                    if (data.type === 'CAT') {
                        this.queryParams.gnvqCatId = data.id;
                    } else if (data.type === 'INFO') {
                        this.queryParams.gnvqCatId = data.categoryId;
                        this.queryParams.gnvqId = data.id;
                    } else {
                        this.queryParams.gnvqCatId = data.categoryId;
                        this.queryParams.gnvqId = data.parentId;
                        this.queryParams.levelId = data.id;
                    }
                }
                this.refreshTable();
            },
            //gnvqSelect组件（工种等级分类）callback方法
            levelChange: function (levelInfo) {
                // this.info.groupId = 0;
                this.$set(this.info, 'groupId', 0)
                this.getPointList();
            },
            changeVideoType: function(val){
                this.info.videoType = val ;
                this.info.gnvqCatId = '';
                this.info.gnvqId = '';
                this.info.levelId = '';
                this.info.baseCatId = '';
                this.info.pointId = 0;
            },
            updVideoTime: function (videoId) {
                let data = {
                    videoId: videoId
                };
                this.apis.postForm('course/video/updVideoTime', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            //改变视频可用状态
            toLock: function (row) {
                //console.log(row)
                let data = {
                    videoId: row.videoId,
                    useStatus: row.useStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/video/updUseStatus', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            //上传视频图片组件的callback
            uploadVideoImgFront: function (webPath, savePath,) {
                // alert(savePath)
                this.$set(this.info, 'videoImgUrl', webPath);
                this.$set(this.info, 'videoImg', savePath);
                // this.info.raceImgUrl = webPath;
                // this.info.raceImg = savePath;
                // console.log(isReadCard)
            },
            //视频预览
            toPlayAliyun: function (row) {
                if (row.playSource===3) {
                    this.showVideoAli(row.videoPath);
                } else {
                    this.uploadInfo.videoId = row.videoId;
                    this.uploadInfo.videoName = row.videoName;
                    this.apis.get('course/video/getById?videoId=' + row.videoId)
                        .then((res) => {
                            if (res.code === 1) {
                                this.showVideoAli(res.data.aliyunUrl);
                                this.videoId = res.data.videoId;
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            },
            showVideoAli: function (videoPathUrl) {
                //this.playerOptions['sources'][0].src = "https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4"
                //this.playerOptions['sources'][0].src = this.info.videoPathUrl;
                this.playerOptions['sources'][0].src = videoPathUrl;
                this.playVideoDialog = true;
            },
            // showVideo: function (row) {
            //     this.videoId = row.videoId;
            //     // this.playerOptions['sources'][0].src = this.info.videoPathUrl;
            //     this.playerOptions['sources'][0].src = row.videoPathUrl;
            //     this.playVideoDialog = true;
            // },
            toAdd: function () {
                this.info = {
                    orderNum: 0,
                    gnvqCatId: this.queryParams.gnvqCatId,
                    gnvqId: this.queryParams.gnvqId,
                    levelId: this.queryParams.levelId,
                    videoType: this.queryParams.videoType,
                    baseCatId: this.queryParams.baseCatId,
                    groupId: 0,
                    //pointId: '',
                    playSource: 2,
                    videoImgUrl: '',
                    annexFileList: []
                };
                // console.log(this.info);
                this.$nextTick(function() {
                    this.$refs.addableSelect.updateGroupSelect(this.info.baseCatId, this.info.gnvqId, this.info.levelId)
                    // this.$refs.gnvqSelect.initData(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
                });
                this.editDrawer = true;
                // this.$refs.editForm.resetFields();
            },
            saveData: async function (formRef) {
                if (this.info.videoType === 1 && this.info.baseCatId==='') {
                        this.$message({
                            message: '请选择基础课类别',
                            showClose: true, offset: 50, type: 'error'
                        });
                        return
                    } else if (this.info.videoType === 2 && this.info.gnvqCatId==='') {
                        this.$message({
                            message: '请选择工种类别',
                            showClose: true, offset: 50, type: 'error'
                        });
                        return
                    } else if (this.info.videoType === 2 && this.info.gnvqId==='') {
                        this.$message({
                            message: '请选择工种',
                            showClose: true, offset: 50, type: 'error'
                        });
                        return
                    // } else if (this.info.videoType === 2 && this.info.levelId==='') {
                    //     this.$message({
                    //         message: '请选择工种等级',
                    //         showClose: true, offset: 50, type: 'error'
                    //     });
                    //     return
                    } else {
                            this.$refs[formRef].validate((valid) => {
                            console.log(this.$refs)
                            if (valid) {
                                let url = "course/video/save";
                                this.info.orgId = this.appCookies.getCookieOID();
                                this.info.createBy = this.appCookies.getCookieUID();
                                this.info.createByname = this.appCookies.getCookieTNAME();
                                this.info.modifyBy = this.appCookies.getCookieUID();
                                this.info.modifyByname = this.appCookies.getCookieTNAME();
                                // 保存数据
                                this.apis.postJson(url, this.info)
                                    .then((res) => {
                                        if (res.code === 1) {
                                            this.editDrawer = false;
                                            this.initTable();
                                            this.$message({
                                                message: '保存成功',
                                                showClose: true, offset: 200, type: 'success'
                                            });
                                        } else {
                                            this.$message({
                                                message: res.msg,
                                                showClose: true, offset: 50, type: 'error'
                                            });
                                        }
                                    }).catch(err => {
                                    console.log(err);
                                });
                            }
                        })
                    }

            },
            toEdit: function (keyId) {
                this.apis.get('course/video/getById?videoId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                            this.$nextTick(function() {
                                // this.$refs.gnvqSelect.initData(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
                                // let lv = {
                                //     levelId: this.info.levelId
                                // }
                                this.getPointList();
                                // this.$refs.editForm.resetFields();
                            });
                            console.log(this.info)
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        videoId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/video/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            //图片浏览组件ImageViewer回调方法
            updateMaxRecord(maxRecord) {
                this.imgInfo.maxRecord = maxRecord;
                console.log("maxRecord: " + this.imgInfo.maxRecord);
            },
            toSelectClassImage: function () {
                this.$refs.imageSelectDialog.open();
            },
            imageCallback: function (webPath, savePath) {
                this.$set(this.info, 'videoImgUrl', webPath);
                this.$set(this.info, 'videoImg', savePath);
            },
        }
    }
</script>

<style lang="scss" scoped>
.inputNumber{
  width: 40px;
}
.limit-vertical-height{
    ::v-deep .el-dialog__body{
        height: 90vh;
        // max-height: 90vh;
    }
}
.limit-height{
    ::v-deep .el-dialog__body{
        height: 100vh;
        max-height: 100vh;
    }
}
</style>
