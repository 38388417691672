<template>
    <el-row :gutter="8" class="row-container">
        <el-col :span="5">
            <el-card class="fix-height-card" shadow="never">
                <div slot="header" class="clearfix">
                    <i class="el-icon-s-operation"></i>
                    <span>部门</span>
                </div>
                <DeptListTree ref="deptListTree" :orgId="this.queryParams.orgId" @callback="changeDeptId"></DeptListTree>
            </el-card>
        </el-col>
        <!-- 列表主体 -->
        <el-col :span="19">
            <el-card class="box-card" shadow="never">
                <div slot="header" class="clearfix">
                    <i class="el-icon-s-operation"></i>
                    <span>员工</span>
                    <div style="float: right; padding: 0px 0">
                        <!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
                        <!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                    </div>
                </div>
                <!-- 搜索查询 -->
                <el-row class="query-container">
                    <el-col>
                        <el-form :inline="true">
                            <el-form-item label="">
                                <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:140px"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
                <!-- 列表内容 -->
                <el-row class="table-container">
                    <el-col>
                        <el-table
                            v-loading="tableLoading"
                            :data="tableData"
                            fit
                            stripe
                            highlight-current-row
                            style="width: 100%;"
                            ref="queryTable"
                            :indent="20"
                            @selection-change="handleSelectionChange"
                        >
                            <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column>
                            <el-table-column label="姓名" prop="trueName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="性别" prop="sex" min-width="80px" align="left"></el-table-column>
                            <el-table-column label="联系电话" prop="phone" min-width="100px" align="left"></el-table-column>
                            <el-table-column label="民族" prop="nationName" min-width="100px" align="left"></el-table-column>
                            <el-table-column label="身份证号" prop="idCard" min-width="100px" align="left"></el-table-column>
                            <el-table-column label="出生日期" prop="birthday" min-width="100px" align="left" :show-overflow-tooltip="true">
                                <template slot-scope="{row}">
                                    <span>{{ row.birthday | parseTime('{y}-{m}-{d}') }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="入职日期" prop="entryDate" min-width="100px" align="left">
                                <template slot-scope="{row}">
                                    <span>{{ row.entryDate | parseTime('{y}-{m}-{d}') }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="所属部门" prop="deptName" min-width="100px" align="left"></el-table-column>
                            <el-table-column label="在职/离职" min-width="100px" align="center">
                                <template slot-scope="{row}">
                                    <el-tag :type="row.onJobStatus | colorFilter">
                                        {{ row.onJobStatus  | onJobStatusStatusFilter}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 分页组件 -->
                        <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                    :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                    @pagination="initTable"/>
                    </el-col>
                </el-row>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
    import Pagination from '@/components/Pagination';
    import DeptListTree from "./DeptListTree";
    export default {
        components: {
            Pagination,
            DeptListTree,
        },
        filters: {
            onJobStatusStatusFilter(status) {
                const statusMap = {
                    0: '在职',
                    1: '离职',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    deleteStatus: 0,
                    onJobStatus: 0,
                    deptId: '',
                    orgId: this.appCookies.getCookieOID(),
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //机构下拉菜单
                orgList: [],
                //所属部门下拉菜单
                deptList: [],
                treeProps: {
                    value: 'deptId',
                    label: 'deptName',
                    children: 'children'
                },
                //编辑、新增表单
                editDrawer: false,
                info: {},
                selectdata: [],
            };
        },
        mounted () {
            this.getOrgList();
            this.initTable();
            this.getDeptList();
            this.$nextTick(function() {
            });
        },
        computed: {
        },
        methods: {
            handleSelectionChange(val) {
                this.selectdata = val;
            },
            getSelected: function () {
                // if (this.selectdata.length === 0) {
                //     this.$message({
                //         message: '您未选择任何记录，请进行选择',
                //         showClose: true, offset: 200, type: 'success'
                //     });
                //     return [];
                // }
                let staffIds = [];
                this.selectdata.forEach(function (item) {
                    staffIds.push(item.staffId)
                });
                return staffIds;
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('/system/staff/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            //切换机构时重置查询参数，刷新列表
            changeOrgId: function () {
                this.getDeptList();
                this.queryParams.deptId = ''
                this.queryParams.keyword = ''
                this.queryParams.deleteStatus = 0
                this.refreshTable();
            },
            changeDeptId: function (data) {
                this.queryParams.deptId = data.deptId;
                this.refreshTable();
            },
            //获取机构列表下拉菜单
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getDeptList: function () {
                console.log(this.queryParams.orgId);
                this.apis.get('system/dept/getList?orgId='+this.queryParams.orgId)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.deptList = res.data || [];
                            // this.supCatTreeDataList = [
                            //     { deptId: 'deptId1', deptName: 'deptName1', children: [{ deptId: 'deptId1-1', deptName: 'deptName1-1', children: [], }], }
                            // ];//测试用
                            // this.supCatTreeDataList = [];//测试用
                            this.deptList.splice(0, 0, { deptId: '0', deptName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>
