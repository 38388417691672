<template>
    <div class="app-container"
         v-loading="mainLoading"
         :element-loading-text="mainLoadingText">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never"  style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>学习管理</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-button-group style="float: right">-->
<!--                                <el-button class="filter-item" type="success" round @click="toStudyRemain">学习提醒</el-button>-->
<!--                                <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExport">学习情况</el-button>-->
<!--                                <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExportScore">模拟考试成绩</el-button>-->
<!--                                <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExportScorePdf">模拟考试试卷</el-button>-->
<!--                                <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExportOnlineScore">在线考试成绩</el-button>-->
<!--                                <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExportOnlinePdf">在线考试试卷</el-button>-->
<!--                            </el-button-group>-->
                            <el-button-group>
                                <el-button class="filter-item" type="success" size="mini" round @click="toStudyRemain">学习提醒</el-button>
                                <el-dropdown @command="dropdownOperate">
                                    <el-button icon="el-icon-download" type="success" round size="mini">
                                        导出<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="dropdownValue('toExport', '')" icon="el-icon-download">学习情况</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('toExportScore', '')" icon="el-icon-download">模拟考试成绩</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('toExportScorePdf', '')" icon="el-icon-download">模拟考试试卷</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('toExportOnlineScore', '')" icon="el-icon-download">在线考试成绩</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('toExportOnlinePdf', '')" icon="el-icon-download">在线考试试卷</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('toExportHighScore', '')" icon="el-icon-download">专项练习成绩</el-dropdown-item>
                                        <el-dropdown-item :command="dropdownValue('toExportHighPdf', '')" icon="el-icon-download">专项练习试卷</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-button-group>
                        </div>
                    </div>
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:100px"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="queryParams.deptName" placeholder="所属部门" style="width:100px"></el-input>
                                </el-form-item>

                                <el-form-item label=""  v-if="this.privRange.havePriv()">
                                    <el-select filterable v-model="queryParams.companyId"  placeholder="请选择报名机构" @change="getClassList" style="width:150px">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"  :key="item.orgId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select filterable v-model="queryParams.classId" placeholder="请选择班级" style="width:100px">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="item in classList" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.classType" placeholder="请选择类型" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="继续教育" value="LEARN"></el-option>
                                        <el-option label="考试报名" value="ENROLL"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="queryParams.courseCount" placeholder="学习课程数" style="width:100px"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="queryParams.exerciseCount" placeholder="刷题次数" style="width:100px"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="queryParams.examCount" placeholder="模拟考试次数" style="width:100px"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
<!--                    <el-row :gutter="32" class="table-container">-->
<!--                        <el-col :span="24">-->
<!--                            <el-form :inline="true">-->
<!--                                <el-button-group style="float: right">-->
<!--                                    <el-button class="filter-item" type="success" round @click="toStudyRemain">学习提醒</el-button>-->
<!--                                    <el-button class="filter-item" type="success" round icon="el-icon-upload2" @click="toExportScore">模拟考试成绩</el-button>-->
<!--                                    <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExportScorePdf">模拟考试试卷</el-button>-->
<!--                                    <el-button class="filter-item" type="success" round icon="el-icon-upload2" @click="toExportOnlineScore">在线考试成绩</el-button>-->
<!--                                    <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExportOnlinePdf">在线考试试卷</el-button>-->
<!--                                </el-button-group>-->
<!--                            </el-form>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="selectTable"
                                @selection-change="handleSelectionChange"
                                :indent="20"
                            >
                                <el-table-column
                                    type="selection"
                                    width="55">
                                </el-table-column>
                                <el-table-column label="姓名" prop="trueName" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="性别" min-width="50" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.sex | sexFilter }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="身份证号" prop="idCard" min-width="170px" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="联系电话" prop="phone" min-width="110px" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种" prop="gnvqName"  align="left"  min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName"  align="left" min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="班级" prop="className"  align="left" min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="类型" min-width="100" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.classType | classTypeFilter }}</span>
                                    </template>
                                </el-table-column>
<!--                                <el-table-column label="报名机构" prop="orgName"  min-width="120" align="left" :show-overflow-tooltip="true"></el-table-column>-->
                                <el-table-column label="报名时间" width="140px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="320" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button type="text" size="mini" @click="showVideo(row.userId, row.gnvqId, row.levelId, row.orderId)">
                                            视频学习({{(row.studyTime / row.courseTime * 100).toFixed(0) }}%)
                                        </el-button>
                                        <el-button type="text" size="mini" @click="showExercise(row.userId, row.gnvqId, row.levelId, row.orderId)">
                                            刷题({{row.exerciseCount}})
                                        </el-button>
                                        <el-button type="text" size="mini" @click="showHighExam(row.userId, row.orderId)">
                                            专项({{row.highExamCount}})
                                        </el-button>
                                        <el-button type="text" size="mini" @click="showExam(row.userId, row.gnvqId, row.levelId, row.orderId)">
                                            模考({{row.examCount}})
                                        </el-button>
                                        <el-button type="text" size="mini" @click="showOnline(row.userId, row.gnvqId, row.levelId, row.orderId)">
                                            考试({{row.onlineExamCount}})
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="学习提醒"
            :visible.sync="studyRemindModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="studyRemindInfo" :rules="ruleStudyValidate" ref="remindForm" label-width="120px" label-suffix=":">
                <el-form-item label="提醒原因" prop="resean">
                    <el-input v-model="studyRemindInfo.resean" type="textarea" :autosize="{ minRows: 4}" maxlength="50"
                              show-word-limit placeholder="请输入提醒原因（最多50个汉字）"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="studyRemindModal = false">关 闭</el-button>
                <el-button type="primary" @click="studyRemainSave('remindForm')">发 送</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="学习记录"
            :visible.sync="showVideoModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <UserVideoRecord ref="userVideoRecord"></UserVideoRecord>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showVideoModal = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="模拟考试记录"
            :visible.sync="showExamModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <UserExam ref="userExam"></UserExam>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showExamModal = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="在线考试记录"
            :visible.sync="showOnlineModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <UserOnlineExam ref="userOnlineExam"></UserOnlineExam>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showOnlineModal = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="专项练习记录"
            :visible.sync="showHighExamModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <UserHighExam ref="userHighExam"></UserHighExam>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showHighExamModal = false">关 闭</el-button>
            </span>
        </el-dialog>


        <el-dialog
            title="刷题记录"
            :visible.sync="showExerciseModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <UserExercise ref="userExercise"></UserExercise>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showExerciseModal = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    import UserExam from "@v/edumgr/components/UserExam";
    import UserHighExam from "@v/edumgr/components/UserHighExam";
    import UserExercise from "@v/edumgr/components/UserExercise";
    import UserOnlineExam from "@v/edumgr/components/UserOnlineExam";
    import UserVideoRecord from "@v/edumgr/components/UserVideoRecord";

    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqTree,
            UserExam,
            UserExercise,
            UserVideoRecord,
            UserOnlineExam,
            UserHighExam
        },
        filters: {
            sexFilter(status) {
                const statusMap = {
                    1: '男',
                    2: '女'
                };
                return statusMap[status]
            },
            classTypeFilter(status) {
                const statusMap = {
                    'LEARN': '继续教育',
                    'ENROLL': '考试报名'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                mainLoading: false,
                mainLoadingText: '正在导出数据，请稍等',
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '学习统计'
                    }
                ],
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    dataStatus: '',
                    confirmStatus: '1',
                    payWay: '',
                    payStatus: '',
                    orgId: this.appCookies.getCookieTOID(),
                    companyId: this.appCookies.getCookieOID(),
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    identifyStatus: '',
                    classType: '',
                    orderIds: [],
                    deptName: '',
                    userId: this.appCookies.getCookieUID()
                },
                gnvqTreeData: [],
                tableData: [],
                info: {
                    certList: [{
                        certName: '',
                        certFiles: []
                    }],
                },
                tableLoading: false,
                editDrawer: false,
                orgList: [],
                classList: [],
                classList2: [],
                eduList: [],
                multipleSelection: [],
                studyRemindModal: false,
                studyRemindInfo: {},
                ruleStudyValidate: {
                    resean: [
                        { required: true, message: '请输入提醒原因', trigger: 'blur' }
                    ],
                },
                showVideoModal: false,
                showExerciseModal: false,
                showExamModal: false,
                showHighExamModal: false,
                showOnlineModal: false,
            };
        },
        mounted () {
            this.initTable();
            this.getOrgList();
        },
        methods: {
            toQuery: function (data) {
                // console.log(data)
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.getClassList();
                this.refreshTable();
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('edumgr/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getList?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getClassList: function () {
                if (!this.queryParams.companyId || !this.queryParams.levelId || !this.queryParams.gnvqId){
                    this.classList = [];
                    return;
                }
                let data = {
                    companyId: this.queryParams.companyId,
                    levelId: this.queryParams.levelId,
                    gnvqId: this.queryParams.gnvqId,
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            toStudyRemain: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                if (this.multipleSelection.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 50, type: 'error'
                    });
                    return;
                }
                let orderIds = [];
                this.multipleSelection.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.studyRemindInfo = {};
                this.studyRemindInfo.orderIds = orderIds;
                this.studyRemindModal = true;
            },
            studyRemainSave: function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let title = '确定发送学习提醒信息？';
                        this.$confirm(title, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.apis.postJson('user/enrollOrder/studyRemind', this.studyRemindInfo)
                                .then((res) => {
                                    if (res.code === 1) {
                                        this.$message({
                                            message: '发送成功',
                                            showClose: true, offset: 200, type: 'success'
                                        });
                                        this.studyRemindModal = false;
                                    } else {
                                        this.$message({ message: res.msg, showClose: true, offset: 50, type: 'error' });
                                    }
                                }).catch(err => {
                                console.log(err);
                            });
                        }).catch(() => {
                        });
                    }
                });
            },
            showHighExam: function (userId, fromRefId) {
                this.showHighExamModal = true;
                this.$nextTick(function() {
                    this.$refs.userHighExam.initParam(userId, fromRefId);
                });
            },
            showExam: function (userId, gnvqId, levelId, fromRefId) {
                this.showExamModal = true;
                this.$nextTick(function() {
                    this.$refs.userExam.initParam(userId, gnvqId, levelId, fromRefId);
                });
            },
            showExercise: function (userId, gnvqId, levelId, fromRefId) {
                this.showExerciseModal = true;
                this.$nextTick(function() {
                    this.$refs.userExercise.initParam(userId, gnvqId, levelId, fromRefId);
                });
            },
            showOnline: function (userId, gnvqId, levelId, fromRefId) {
                this.showOnlineModal = true;
                this.$nextTick(function() {
                    this.$refs.userOnlineExam.initParam(userId, gnvqId, levelId, fromRefId);
                });
            },

            showVideo: function (userId, gnvqId, levelId, fromRefId) {
                this.showVideoModal = true;
                this.$nextTick(function() {
                    this.$refs.userVideoRecord.initParam(userId, gnvqId, levelId, fromRefId);
                });
            },
            toExport: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.queryParams.orderIds = [];
                let orderIds = [];
                this.multipleSelection.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.queryParams.orderIds = orderIds;

                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'edumgr/export',
                    data: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '学习情况统计.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            toExportScore: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.queryParams.orderIds = [];
                let orderIds = [];
                this.multipleSelection.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.queryParams.orderIds = orderIds;

                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'edumgr/exportScore',
                    data: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '考试成绩.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            toExportOnlineScore: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.queryParams.orderIds = [];
                let orderIds = [];
                this.multipleSelection.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.queryParams.orderIds = orderIds;

                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'edumgr/exportOnlineScore',
                    data: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '考试成绩.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            toExportOnlinePdf: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.queryParams.orderIds = [];
                let orderIds = [];
                this.multipleSelection.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.queryParams.orderIds = orderIds;

                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'edumgr/exportOnlineScorePdf',
                    data: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '考试试卷.zip')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            toExportScorePdf: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.queryParams.orderIds = [];
                let orderIds = [];
                this.multipleSelection.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.queryParams.orderIds = orderIds;
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'edumgr/exportScorePdf',
                    data: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '考试试卷.zip')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            toExportHighPdf: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.queryParams.orderIds = [];
                let orderIds = [];
                this.multipleSelection.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.queryParams.orderIds = orderIds;
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'edumgr/exportHighPdf',
                    data: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '考试试卷.zip')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            toExportHighScore: function () {
                if (!this.queryParams.classId) {
                    this.$message({
                        message: '只能根据班级进行导出，请选择班级',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.queryParams.orderIds = [];
                let orderIds = [];
                this.multipleSelection.forEach(function (item) {
                    orderIds.push(item.orderId)
                });
                this.queryParams.orderIds = orderIds;

                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'edumgr/exportHighScore',
                    data: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '考试成绩.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            /**
             * 文件流下载
             */
            downFile(content, fileName) {
                let blob = new Blob([content], {
                    type: 'application/vnd.ms-excel'
                });
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = objectUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            },
            dropdownValue: function (type, row) {
                return {
                    'type': type, //key 是你自己想要的字段，值自己定义
                    'data': row
                };
            },
            dropdownOperate: function (command) {
                console.log(command)
                if (command.type === 'toExport') {
                    this.toExport();
                } else if (command.type === 'toExportScore') {
                    this.toExportScore();
                } else if (command.type === 'toExportScorePdf') {
                    this.toExportScorePdf();
                } else if (command.type === 'toExportOnlineScore') {
                    this.toExportOnlineScore();
                } else if (command.type === 'toExportOnlinePdf') {
                    this.toExportOnlinePdf();
                } else if (command.type === 'toExportHighScore') {
                    this.toExportHighScore();
                } else if (command.type === 'toExportHighPdf') {
                    this.toExportHighPdf();
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
