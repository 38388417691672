<template>
    <el-dialog
        title="修改头像"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" >
            <el-col :span="24">
                <el-form :model="info" ref="editForm" label-width="140px" label-suffix=":">
                    <el-form-item  label="小程序介绍" >
                        <el-input type="textarea" :rows="4" v-model="info.signature" maxlength="120" show-word-limit placeholder="请输入"></el-input>
                        <div>1、长度4~120。 2、至少4个字的中文描述。</div>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>
            <el-button type="primary"  @click="modifysignature">确 定</el-button>
<!--            v-if="info.canApply"-->
        </span>
    </el-dialog>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            editDialog: false,
            appId: '',
            info: {
                appId: '',
                signature: '',
            }
        }
    },
    mounted() {
    },
    methods: {
        open: function (appId) {
            this.editDialog = true;
            this.info.appId = appId;
        },
        modifysignature: function () {
            this.apis.postForm('wx/open/mini/mgr/modifysignature', this.info)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.$emit('callback')
                        this.editDialog = false;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
