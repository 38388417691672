<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>部门列表</span>
                        <el-button style="float: right;" size="mini" type="success" @click="toAdd">新增</el-button>
                    </div>
                    <div v-if="this.privRange.havePriv()" style="margin-bottom: 4px">
                        <!-- 测试用 -->
                        <el-select v-model="queryParams.orgId" placeholder="机构" @change="changeOrgId"  style="width: 100%">
                            <el-option v-for="item in orgList" :label="item.orgName" :value="item.orgId"
                                       :key="item.orgId"></el-option>
                        </el-select>
                    </div>
                    <DeptListTree
                    ref="deptListTree"
                    :orgId="this.queryParams.orgId"
                    :showAll="false"
                    @callback="getInfo"></DeptListTree>
                </el-card>
            </el-col>
            <!-- 右侧主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never" style="height:calc(100vh - 146px)">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>部门信息编辑</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                        <el-row>
                            <el-col :span="20" :offset="2">
                                <el-form-item label="部门名称" prop="deptName" style="margin:20px auto">
                                    <el-input v-model="info.deptName" placeholder="请输入部门名称"></el-input>
                                </el-form-item>
                                <el-form-item label="部门编码" prop="deptCode" style="margin:20px auto">
                                    <el-input v-model="info.deptCode" placeholder="请输入部门编码"></el-input>
                                </el-form-item>
                                <el-form-item label="所属上级" prop="parentId" style="margin:20px auto">
                                    <SelectTree :tree-data="supCatTreeDataList" :selectId.sync="info.parentId"
                                            :treeProps="treeProps" :nodeKey="'deptId'"
                                            :onlySelectLeaf="false"></SelectTree>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex" justify="center">
                            <el-col :span="4" v-if="info.deptId"><el-button type="danger" @click="toDelete">删除</el-button></el-col>
                            <el-col :span="4" ><el-button type="primary" @click="saveData('editForm')">保存</el-button></el-col>
                        </el-row>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import DeptListTree from "./components/DeptListTree";
    import Breadcrumb from '@/components/Breadcrumb';
    import SelectTree from '@/components/tree/SelectTree';
    export default {
        components: {
            DeptListTree,
            Breadcrumb,
            SelectTree,
        },
        filters: {
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '部门管理'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    deleteStatus: '0',
                    orgId: this.appCookies.getCookieOID(),
                },
                orgId: this.appCookies.getCookieOID(),
                //列表数据
                tableData: [],
                tableLoading: false,
                //机构下拉菜单
                orgList: [],
                //所属上级下拉菜单
                supCatTreeDataList: [],
                treeProps: {
                    value: 'deptId',
                    label: 'deptName',
                    children: 'children'
                },
                //表单
                info: {},
                //表单验证规则
                ruleValidate: {
                    // 基本信息
                    deptName: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                },
            };
        },
        mounted () {
            this.getOrgList();
            this.getSupCatTreeData();
            this.$nextTick(function() {
            });
        },
        computed: {
        },
        methods: {
            getInfo: function (data) {
                let deptId = data.deptId
                this.apis.get('/system/dept/getById?deptId=' + deptId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            //获取机构列表下拉菜单
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            changeOrgId: function () {
                this.info = {
                    deptId: '',
                    deptName: '',
                    parentId: 0,
                };
                this.getSupCatTreeData();
            },
            getSupCatTreeData: function () {
                this.apis.get('system/dept/getList?orgId='+this.queryParams.orgId)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.supCatTreeDataList = res.data || [];
                            // this.supCatTreeDataList = [
                            //     { deptId: 'deptId1', deptName: 'deptName1', children: [{ deptId: 'deptId1-1', deptName: 'deptName1-1', children: [], }], }
                            // ];//测试用
                            // this.supCatTreeDataList = [];//测试用
                            this.supCatTreeDataList.splice(0, 0, { deptId: '0', deptName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toAdd: function () {
                this.info = {
                    deptId: '',
                    deptName: '',
                };
                console.log(this.info);
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "/system/dept/save";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    this.info.orgId = this.queryParams.orgId;
                    // this.info.orgId = this.orgId;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$refs.deptListTree.getDeptTreeData();
                                this.getSupCatTreeData();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            toDelete: function () {
                let title = '确定要删除此条数据？';
                // if (deleteStatus === 0) {
                //     title = '确定要恢复此条数据？';
                // }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        deptId: this.info.deptId,
                        deleteStatus: 1,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('/system/dept/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$refs.deptListTree.getDeptTreeData();
                                this.info = {};
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                }).catch(() => {
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
