var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-list-wrap",attrs:{"id":"text-list-wrap"}},[_c('div',{staticClass:"title"},[_vm._v(" 通用字段 ")]),_c('ul',{staticClass:"basic-text-wrap"},_vm._l((_vm.basicTextList),function(item,index){return _c('li',{key:index,staticClass:"basic-text-item",style:({
                fontSize: item.fontSize / 2 + 'px',
                fontWeight: item.fontWeight
            }),on:{"click":function($event){return _vm.selectBasicText(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"title"},[_vm._v(" 证书模板字段 ")]),_c('ul',{staticClass:"basic-text-wrap"},_vm._l((_vm.certTextList),function(item,index){return _c('li',{key:index,staticClass:"basic-text-item",style:({
                fontSize: item.fontSize / 2 + 'px',
                fontWeight: item.fontWeight
            }),on:{"click":function($event){return _vm.selectBasicText(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"title"},[_vm._v(" 分享模板字段 ")]),_c('ul',{staticClass:"basic-text-wrap"},_vm._l((_vm.shareTextList),function(item,index){return _c('li',{key:index,staticClass:"basic-text-item",style:({
                fontSize: item.fontSize / 2 + 'px',
                fontWeight: item.fontWeight
            }),on:{"click":function($event){return _vm.selectBasicText(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"other-text-wrap"})])}
var staticRenderFns = []

export { render, staticRenderFns }