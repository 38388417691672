<template>
    <el-dialog
        title="绑定消息模版"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" >
            <el-col :span="24">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="180px" label-suffix=":">
                    <el-form-item label="消息业务类型" prop="businessId">
                        <el-select v-model="info.businessId" filterable placeholder="请选择消息业务类型" @change="getMsgField">
                            <el-option value="">请选择</el-option>
                            <el-option v-for="item in businessList" :value="item.dictId" :label="item.dictName" :key="item.dictId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="info.fieldMapperList" label="业务字段映射" prop="fieldMapping">
                        <el-table
                            :data="info.fieldMapperList"
                            stripe
                            style="width: 80%">
                            <el-table-column prop="firstName" label="业务消息内容">
                                <template slot-scope="{row}">
                                    {{row.name}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditStatus" align="center" label="消息字段">
                                <template slot-scope="{row}">
                                    <el-select v-model="row.mapperField" filterable placeholder="请选择消息字段">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="item in contentList" :value="item.code" :label="item.name" :key="item.code"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>
            <el-button type="primary"  @click="saveBind('editForm')">确 定</el-button>
<!--            v-if="info.canApply"-->
        </span>
    </el-dialog>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            editDialog: false,
            appId: '',
            info: {
                businessId: ''
            },
            //表单验证规则
            ruleValidate: {
                businessId: [
                    { required: true, message: '请选择消息业务类型', trigger: 'change' }
                ],
            },
            businessList: [],
            contentList: [],
        }
    },
    mounted() {
    },
    methods: {
        open: function (item, orgId) {
            console.log(item)
            this.editDialog = true;
            this.info.orgId = orgId;
            this.info.businessId = '';
            this.info.templateId = item.priTmplId;
            this.info.templateTitle = item.title;
            this.info.templateContent = item.content;
            this.info.templateExample = item.example;
            this.info.templateType = item.type;
            this.info.fieldMapperList = [];
            this.info.appType = 3;
            this.splitContent(item.content)
            this.getBusinessList()
        },
        getBusinessList: function () {
            this.apis.get('system/dict/getList?typeCode=0010&systemStandard=1')
                .then((res) => {
                    if (res.code === 1) {
                        this.businessList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        splitContent: function (content) {
            let data = {
                content: content
            }
            this.apis.postForm('/wx/open/mini/template/splitContent', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.contentList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        getMsgField: function (val) {
            let busi = this.businessList.filter((p) => {
                return p.dictId === val;
            });
            if (busi && busi.length >0) {
                let data = {
                    code: busi[0].dictCode
                }
                this.apis.postForm('/wx/open/mini/template/getMsgField', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info.fieldMapperList = res.data || []
                            this.$forceUpdate()
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            }
        },
        saveBind: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let ok = true
                    this.info.fieldMapperList.forEach(item => {
                        if (!item.mapperField) {
                            this.$message({
                                message: item.name + '未选择映射字段',
                                showClose: true, offset: 50, type: 'error'
                            });
                            ok = false
                            return;
                        }
                    })
                    if (!ok) {
                        return;
                    }
                    let url = "/wx/open/tmpl/conf/bind";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.editDialog = false;
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
