<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>报名列表</span>
                        <div style="float: right; padding: 0px 0">
                            <el-button-group style="float: right">
                                <el-button size="mini" type="success" round icon="el-icon-edit" @click="toAdd">学员报名</el-button>
                            </el-button-group>
                        </div>
                    </div>
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width: 120px"></el-input>
                                </el-form-item>
                                <el-form-item label="" style="width: 150px"  v-if="this.privRange.havePriv()">
                                    <el-select filterable v-model="queryParams.companyId" placeholder="请选择报名机构" @change="getClassList">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"  :key="item.orgId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="width: 120px">
                                    <el-select filterable v-model="queryParams.classId" placeholder="请选择班级">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="item in classList" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="width: 100px">
                                    <el-select v-model="queryParams.dataStatus" placeholder="请选择状态">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="暂存" value="0"></el-option>
                                        <el-option label="已提交" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="width: 100px">
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>

                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="姓名" prop="trueName" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="性别" min-width="50" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.sex | sexFilter }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="身份证号" prop="idCard" min-width="170px" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="联系电话" prop="phone" min-width="110px" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="报名金额" prop="priceValue" min-width="80px" align="center"></el-table-column>
                                <el-table-column label="支付方式" min-width="100" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.payWay | payWayFilter }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="支付状态" min-width="80" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.payStatus | colorFilter">
                                            {{ row.payStatus  | payStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="审核状态" min-width="80" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.confirmStatus | colorFilter">
                                            {{ row.confirmStatus  | confirmStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="工种" prop="gnvqName"  align="left"  min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName"  align="left" min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="班级" prop="className"  align="center" min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="报名机构" prop="orgName"  min-width="120" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="提交状态" min-width="80" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.dataStatus | colorFilter">
                                            {{ row.dataStatus  | dataStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="删除状态" min-width="80" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="报名时间" width="140px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作人" prop="createByname" min-width="80" align="center"></el-table-column>
                                <el-table-column label="操作" align="center" width="140" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.confirmStatus===0 && row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.orderId)">修改</el-button>
                                        <el-button v-if="row.confirmStatus===0 && row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.orderId, 1)">删除</el-button>
                                        <el-button v-if="row.confirmStatus===0 && row.deleteStatus===1" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.orderId, 0)">恢复</el-button>
                                        <el-button v-if="row.deleteStatus===1 || row.confirmStatus===1" type="text" size="mini"  @click="toEdit(row.orderId)">查看</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="编辑"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content"
                 v-loading="mainLoading"
                 :element-loading-text="mainLoadingText">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-divider content-position="left">工种信息</el-divider>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="工种等级" prop="categoryId">
                                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.categoryId" :gnvqId.sync="info.gnvqId"
                                            :levelId.sync="info.levelId"  @callback="levelChange"></GnvqSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="6">
                            <el-form-item label="报名机构" prop="companyId">
                                <el-select filterable v-model="info.companyId" @change="getClassList2">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"  :key="item.orgId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="班级" prop="classId">
                                <el-select filterable v-model="info.classId" @change="classChange">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in classList2" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="报名金额" prop="priceValue">
                                {{info.priceValue}}元
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left">基本信息</el-divider>
                    <el-row :gutter="32" v-if="info.classConfig.idcardImg.needInput===1">
                        <el-col :span="12">
                            <el-form-item>
                                <el-button type="success" plain round @click="readIdCard()">读取身份证信息</el-button>
                                请将身份证放到读卡器后点击
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-if="info.classConfig.idcardImg.needInput===1">
                        <el-col :span="12" style="text-align: center">
                            <el-form-item required>
                                <SingleUploadImg @callback="uploadIdCardFront" :imageUrl="info.idCardFrontUrl"
                                                 :width="243" :height="153" :text="'上传身份证头像面照片'"></SingleUploadImg>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="text-align: center">
                            <el-form-item required>
                                <SingleUploadImg @callback="uploadIdCardBack" :imageUrl="info.idCardBackUrl"
                                                 :width="243" :height="153" :text="'上传身份证国徽面照片'"></SingleUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="trueName">
                                <el-input v-model="info.trueName" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="性别" prop="sex">
                                <el-radio-group v-model="info.sex">
                                    <el-radio :label="1">男</el-radio>
                                    <el-radio :label="2">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="民族" prop="nationName" v-if="info.classConfig.nationName.needInput===1">
                                <el-input v-model="info.nationName" placeholder="请输入民族"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号" prop="idCard" v-if="info.classConfig.idCard.needInput===1">
                                <el-input v-model="info.idCard" placeholder="请输入身份证号"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="info.phone" placeholder="请输入学员联系电话"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" style="text-align: center"  v-if="info.classConfig.certHeadImg.needInput===1">
                            <el-form-item required>
                                <SingleUploadImg @callback="uploadCertHeadImg" :imageUrl="info.certHeadImgUrl"
                                                 :width="114" :height="145" :text="'蓝底照片'"
                                                 :subtext="'（大小94×132px、20kb）'"></SingleUploadImg>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="家庭住址" prop="homeAddr" v-if="info.classConfig.homeAddr.needInput===1">
                                <el-input v-model="info.homeAddr" placeholder="请输入家庭住址"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32"  v-if="info.classConfig.education.needInput===1">
                        <el-col :span="6">
                            <el-form-item label="学历" prop="educationId">
                                <el-select filterable v-model="info.educationId">
                                    <el-option value="">请选择学历</el-option>
                                    <el-option v-for="item in eduList" :value="item.dictId" :label="item.dictName"  :key="item.dictId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18">
                            <el-form-item label="毕业证书">
                                <MutiUploadImg ref="mutiUploadImg" :imageList.sync="info.eduImgList"></MutiUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32"  v-if="info.classConfig.workYears.needInput===1">
                        <el-col :span="6">
                            <el-form-item label="工龄" prop="workYears">
                                <el-input v-model="info.workYears" placeholder="请输入工龄">
                                    <template slot="append">年</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <a href="javascript:void(0)" @click="downloadPdf">证明模板下载</a>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18">
                            <el-form-item label="工龄证明">
                                <MutiUploadImg ref="mutiUploadImg2" :imageList.sync="info.workYearsImgList"></MutiUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32"   v-if="info.classConfig.certName.needInput===1">
                        <el-col :span="24">
                            <el-form-item label="证书">
                                <div v-for="(item, index) in info.certList" :key="index" style="display: flex; flex-direction: row; align-items: flex-start; margin-bottom: 5px">
                                    <el-form-item>
                                        <el-select v-model="item.certName" placeholder="请选择证书类型">
                                            <el-option value="">请选择学历</el-option>
                                            <el-option v-for="item in certNameList" :value="item.dictName" :label="item.dictName"  :key="item.dictName"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-button type="success" plain round @click="handleRemove(index)">删除</el-button>
                                    <el-form-item label="证书照片">
                                        <MutiUploadImg ref="mutiUploadImg3" :imageList.sync="item.certFiles"></MutiUploadImg>
                                    </el-form-item>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="success" plain round @click="handleAdd">继续添加证书信息</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <template v-if="info.defFieldList.length >0 ">
                        <el-divider content-position="left">其他信息</el-divider>
                        <el-row :gutter="16">
                            <el-col :span="12" v-for="(item, idx) in info.defFieldList" :key="'defF' + idx">
                                <template v-if="item.controlType==='INPUT'">
                                    <el-form-item :label="item.fieldName">
                                        <el-input v-model="item.fieldValue" placeholder="请输入" />
                                    </el-form-item>
                                </template>
                                <template v-if="item.controlType==='CHECKBOX'">
                                    <el-form-item :label="item.fieldName">
                                        <el-checkbox-group v-model="item.fieldValues">
                                            <el-checkbox v-for="option in item.controlOptions" :key="option.optionValue"
                                                         :label="option.optionValue">{{option.optionLable}}</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </template>
                                <template v-if="item.controlType==='RADIO'">
                                    <el-form-item :label="item.fieldName">
                                        <el-radio-group v-model="item.fieldValue">
                                            <el-radio v-for="option in item.controlOptions" :key="option.optionValue"
                                                      :label="option.optionValue">{{option.optionLable}}</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </template>
                                <template v-if="item.controlType==='SELECT'">
                                    <el-form-item :label="item.fieldName">
                                        <el-select v-model="item.fieldValue" placeholder="请选择">
                                            <el-option value="">请选择</el-option>
                                            <el-option v-for="option in item.controlOptions"
                                                       :value="option.optionValue"
                                                       :label="option.optionLable"  :key="option.optionValue"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                                <template v-if="item.controlType==='TEXTAREA'">
                                    <el-form-item :label="item.fieldName">
                                        <el-input v-model="item.fieldValue" placeholder="请输入" />
                                    </el-form-item>
                                </template>
                            </el-col>
                        </el-row>
                    </template>
                    <el-divider content-position="left" v-if="info.classConfig.certPost.needInput===1">证书邮寄地址</el-divider>
                    <el-row :gutter="32" v-if="info.classConfig.certPost.needInput===1">
                        <el-col :span="6">
                            <el-form-item label="收件人姓名" prop="addrUname">
                                <el-input v-model="info.addrUname" placeholder="请输入收件人姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系电话" prop="addrPhone">
                                <el-input v-model="info.addrPhone" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-if="info.classConfig.certPost.needInput===1">
                        <el-col :span="14">
                            <el-form-item label="邮寄地址" prop="addrProvince">
                                <AreaSelect ref="areaSelect" :provinceId.sync="info.addrProvince" :cityId.sync="info.addrCity" :districtId.sync="info.addrDistrict"></AreaSelect>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item label="详细地址" prop="addrDesc">
                                <el-input v-model="info.addrDesc" placeholder="请输入详细地址">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left" v-if="info.classConfig.bookPost.needInput===1">教材邮寄信息</el-divider>
                        <el-row :gutter="32" v-if="info.classConfig.bookPost.needInput===1">
                            <el-col :span="6">
                                <el-form-item label="收件人姓名" prop="addrUname">
                                    <el-input v-model="info.book.linkName" placeholder="请输入收件人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="联系电话" prop="addrPhone">
                                    <el-input v-model="info.book.linkMobile" placeholder="请输入联系电话"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="32" v-if="info.classConfig.bookPost.needInput===1">
                            <el-col :span="14">
                                <el-form-item label="邮寄地址" prop="addrProvince">
                                    <AreaSelect :provinceId.sync="info.book.provinceId" :cityId.sync="info.book.cityId" :districtId.sync="info.book.districtId"></AreaSelect>
                                </el-form-item>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item label="详细地址" prop="addrDesc">
                                    <el-input v-model="info.book.addressDesc" placeholder="请输入详细地址">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">关 闭</el-button>
                <el-button v-show="this.info.confirmStatus===0" type="success" @click="saveTmp('editForm')">暂 存</el-button>
                <el-button v-show="this.info.confirmStatus===0" type="primary" @click="saveData('editForm')">提交保存</el-button>
            </div>
        </el-drawer>
        <iframe ref="readIdcardIframe" name="readIdcardIframe" id="readIdcardIframe" src="/idcard.html" width="100%" height="10"></iframe>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    import GnvqSelect from "@v/components/GnvqSelect";
    import SingleUploadImg from "@c/upload/SingleUploadImg";
    import MutiUploadImg from "@c/upload/MutiUploadImg";
    import AreaSelect from "@v/components/AreaSelect";
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqTree,
            GnvqSelect,
            SingleUploadImg,
            MutiUploadImg,
            AreaSelect
        },
        filters: {
            dataStatusFilter(status) {
                const statusMap = {
                    1: '已提交',
                    0: '暂存',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    1: '已删除',
                    0: '正常',
                };
                return statusMap[status]
            },
            confirmStatusFilter(status) {
                const statusMap = {
                    1: '已审核',
                    0: '未审核',
                };
                return statusMap[status]
            },
            payStatusFilter(status) {
                const statusMap = {
                    1: '已支付',
                    0: '未支付',
                };
                return statusMap[status]
            },
            payWayFilter(status) {
                const statusMap = {
                    1: '微信支付',
                    2: '支付宝',
                    3: '线下支付'
                };
                return statusMap[status]
            },
            sexFilter(status) {
                const statusMap = {
                    1: '男',
                    2: '女'
                };
                return statusMap[status]
            },
            taskTypeFilter(status) {
                const statusMap = {
                    '1000': '开放任务',
                    '2000': '内部任务'
                };
                return statusMap[status]
            },
            consumTypeFilter(status) {
                const statusMap = {
                    1: '单人领取',
                    2: '多人领取'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            const validatePhone = (rule, value, callback) => {

                let phone_reg = new RegExp(/^\d{3}-?\d{7,8}|\d{4}-?\d{7,8}$/);
                if (value === undefined || value === null || value === '') {
                    callback(new Error('请输入联系电话'));
                } else if (!phone_reg.test(value) && !(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                    callback(new Error('联系电话格式不对'));
                } else {
                    callback();
                }
            };
            const validateIdcard = (rule, value, callback) => {
                if (value === undefined || value === null || value === '') {
                    callback(new Error('请输入身份证号'));
                } else if (value.length !== 18) {
                    callback(new Error('身份证号格式不对'));
                } else {
                    callback();
                }
            };
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '考试报名'
                    }
                ],
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    dataStatus: '',
                    confirmStatus: '',
                    payWay: '',
                    payStatus: '',
                    orgId: this.appCookies.getCookieTOID(),
                    companyId: this.appCookies.getCookieOID(),
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    identifyStatus: '',
                    classType: 'ENROLL',
                },
                gnvqTreeData: [],
                tableData: [],
                info: {
                    certList: [{
                        certName: '',
                        certFiles: []
                    }],
                    defFieldList: [],
                    classId: '',
                    classConfig: {
                        "workYears": {
                            "needInput": 1
                        },
                        "nationName": {
                            "needInput": 1
                        },
                        "education": {
                            "needInput": 1
                        },
                        "idCard": {
                            "needInput": 1
                        },
                        "sex": {
                            "needInput": 1
                        },
                        "homeAddr": {
                            "needInput": 1
                        },
                        "trueName": {
                            "needInput": 1
                        },
                        "bookPost": {
                            "needInput": 0
                        },
                        "certHeadImg": {
                            "needInput": 1
                        },
                        "idcardImg": {
                            "needInput": 1
                        },
                        "phone": {
                            "needInput": 1
                        },
                        "certName": {
                            "needInput": 0
                        },
                        "bankNo": {
                            "needInput": 0
                        },
                        "eduImg": {
                            "needInput": 0
                        },
                        "signImg": {
                            "needInput": 0
                        },
                        "certPost": {
                            "needInput": 0
                        }
                    }
                },
                tableLoading: false,
                editDrawer: false,
                ruleValidate: {
                    categoryId: [
                        { required: true, type: 'number', message: '请选择工种类别', trigger: 'change' }
                    ],
                    gnvqId: [
                        { required: true, type: 'number', message: '请选择工种', trigger: 'change' }
                    ],
                    // levelId: [
                    //     { required: true, type: 'number', message: '请选择工种等级', trigger: 'change' }
                    // ],
                    orgId: [
                        { required: true, type: 'number', message: '请选择报名机构', trigger: 'change' }
                    ],
                    classId: [
                        { required: true, message: '请选择班级', trigger: 'change' }
                    ],
                    // 报名信息
                    // 报名信息
                    trueName: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    sex: [
                        { required: true, type: 'number', message: '请选择性别', trigger: 'change' }
                    ],
                    idCard: [
                        { required: true, validator: validateIdcard, trigger: 'blur' }
                    ],
                    homeAddr: [
                        { required: true, message: '请输入家庭地址', trigger: 'blur' }
                    ],
                    phone: [
                        { required: true, validator: validatePhone, trigger: 'blur' }
                    ],
                    addrUname: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    addrPhone: [
                        { required: true, validator: validatePhone, trigger: 'blur' }
                    ],
                    addrProvince: [
                        { required: true, type: 'number', message: '请选择省份', trigger: 'change' }
                    ],
                    addrCity: [
                        { required: true, type: 'number', message: '请选择城市', trigger: 'change' }
                    ],
                    addrDistrict: [
                        { required: true, type: 'number', message: '请选择区/县', trigger: 'change' }
                    ],
                    addrDesc: [
                        { required: true, message: '请输入邮寄地址', trigger: 'blur' }
                    ],
                    payWay: [
                        { required: true, type: 'number', message: '请选择支付方式', trigger: 'change' }
                    ],
                },
                orgList: [],
                classList: [],
                classList2: [],
                eduList: [],
                certNameList: [],
                mainLoading: false,
                mainLoadingText: '正在导出数据，请稍等',
                isReadCard: false
            };
        },
        mounted () {
            // this.initActiveXObject();
            this.initTable();
            this.getOrgList();
            this.getEduList();
            this.getCertNameList();
            this.$nextTick(function() {
                window.removeEventListener('message', this.message, false);
                window.addEventListener('message', this.message, false);
            });
        },
        methods: {
            message: function(res) {
                console.log('postMessage=', res)
                console.log('data=', res.data)
                // console.log('================source================', res.source)
                // //console.log('================source name================', res.source.name)
                // alert("message="+this.dataIndex);
                // //alert(res.source.name);
                // console.log('================end message dataIndex : { '+this.dataIndex+' } ================')

                try {
                    let data = JSON.parse(res.data);
                    if (data.code === 0) {
                        this.$message({ message: data.msg, showClose: true, offset: 50, type: 'error' });
                    } else if (data.code === 1) {
                        this.$set(this.info, 'trueName', data.data.trueName);
                        this.$set(this.info, 'nationName', data.data.nationName);
                        // if (data.data.sex === '男') {
                        //     this.$set(this.info, 'sex', 1);
                        // } else if (data.data.sex === '女') {
                        //     this.$set(this.info, 'sex', 2);
                        // }
                        this.$set(this.info, 'sex', data.data.sex);
                        this.$set(this.info, 'idCard', data.data.idCard);
                        this.$set(this.info, 'homeAddr', data.data.homeAddr);
                        let fdata = {
                            file: data.data.base64Img
                        }
                        // 保存数据
                        this.apis.postForm('system/file/uploadFileBase64', fdata)
                            .then((res) => {
                                if (res.code === 1) {
                                    console.log(res.data)
                                    this.uploadCertHeadImg(res.data.webPath, res.data.savepath);
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true,
                                        offset: 200,
                                        type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                        let cardImgdata = {
                            file: data.data.cardImg
                        }
                        // 保存数据
                        this.apis.postForm('system/file/uploadIdcardBase64', cardImgdata)
                            .then((res) => {
                                if (res.code === 1) {
                                    console.log(res.data)
                                    this.uploadIdCardFront(res.data.frontWebPath, res.data.frontSavePath, true);
                                    this.uploadIdCardBack(res.data.backWebPath, res.data.backSavePath);
                                    // this.uploadCertHeadImg(res.data.webPath, res.data.savepath);
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true,
                                        offset: 200,
                                        type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                } catch (e) {
                    // alert("error:"+JSON.stringify(e));
                    console.log(e);
                }
            },
            readIdCard: function () {
                let readIdcardIframe = window.frames["readIdcardIframe"];
                readIdcardIframe.Idcard('1');
            },
            toQuery: function (data) {
                // console.log(data)
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.getClassList();
                this.refreshTable();
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('user/enrollOrder/queryByComp', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toAdd: function () {
                this.info = {
                    categoryId: this.queryParams.gnvqCatId,
                    gnvqId: this.queryParams.gnvqId,
                    levelId: this.queryParams.levelId,
                    classId: '',
                    certList: [{
                        certName: '',
                        certFiles: []
                    }],
                    eduImgList: [],
                    workYears: '',
                    workYearsImgList: [],
                    payWay: 3,
                    orgId: this.appCookies.getCookieTOID(),
                    companyId: this.appCookies.getCookieOID(),
                    enrollType: 'COMPANY',
                    idCardFront: '',
                    idCardBack: '',
                    certHeadImg: '',
                    idCardFrontUrl: '',
                    idCardBackUrl: '',
                    certHeadImgUrl: '',
                    confirmStatus: 0,
                    deptName: '',
                    trueName: '',
                    addrUname: '',
                    addrPhone: '',
                    sex: '',
                    idCard: '',
                    homeAddr: '',
                    birthDay: '',
                    nationName: '',
                    book: {
                        linkName: '',
                        linkMobile: '',
                        addressDesc: ''
                    },
                    defFieldList: [],
                    classConfig: {
                        "workYears": {
                            "needInput": 1
                        },
                        "nationName": {
                            "needInput": 1
                        },
                        "education": {
                            "needInput": 1
                        },
                        "idCard": {
                            "needInput": 1
                        },
                        "sex": {
                            "needInput": 1
                        },
                        "homeAddr": {
                            "needInput": 1
                        },
                        "trueName": {
                            "needInput": 1
                        },
                        "bookPost": {
                            "needInput": 0
                        },
                        "certHeadImg": {
                            "needInput": 1
                        },
                        "idcardImg": {
                            "needInput": 1
                        },
                        "phone": {
                            "needInput": 1
                        },
                        "certName": {
                            "needInput": 0
                        },
                        "bankNo": {
                            "needInput": 0
                        },
                        "eduImg": {
                            "needInput": 0
                        },
                        "signImg": {
                            "needInput": 0
                        },
                        "certPost": {
                            "needInput": 0
                        }
                    }
                };
                // this.getClassConfig(0);
                this.$nextTick(function() {
                    this.editDrawer = true;
                    // this.$refs.gnvqSelect.initData(this.info.categoryId, this.info.gnvqId, this.info.levelId);
                });
                // this.$refs.editForm.resetFields();
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {

                        // 检查文件是否都已经上传
                        if (this.info.classConfig.idcardImg.needInput===1) {
                            if (this.info.idCardFrontUrl === undefined || this.info.idCardFrontUrl === '' || this.info.idCardFrontUrl === null) {
                                this.$message({ message: '上传身份证头像面照片', showClose: true, offset: 50, type: 'error' });
                                return;
                            }
                            if (this.info.idCardBackUrl === undefined || this.info.idCardBackUrl === '' || this.info.idCardBackUrl === null) {
                                this.$message({ message: '上传身份证国徽面照片', showClose: true, offset: 50, type: 'error' });
                                return;
                            }
                        }

                        if (this.info.classConfig.certHeadImg.needInput===1) {
                            if (this.info.certHeadImgUrl === undefined || this.info.certHeadImgUrl === '' || this.info.certHeadImgUrl === null) {
                                this.$message({ message: '请上传蓝底照片', showClose: true, offset: 50, type: 'error' });
                                return;
                            }
                        }

                        if (this.info.classConfig.education.needInput===1) {
                            if (this.info.educationId === undefined || this.info.educationId === '' || this.info.educationId === null) {
                                this.$message({ message: '请选择学历', showClose: true, offset: 50, type: 'error' });
                                return;
                            }
                            if (this.info.eduImgList === undefined || this.info.eduImgList === '' || this.info.eduImgList === null || this.info.eduImgList.length === 0) {
                                this.$message({ message: '请上传学历照片', showClose: true, offset: 50, type: 'error' });
                                return;
                            }
                        }
                        if (this.info.classConfig.workYears.needInput===1) {
                            if (this.info.workYears === undefined || this.info.workYears === '' || this.info.workYears === null) {
                                this.$message({ message: '请输入工龄', showClose: true, offset: 50, type: 'error' });
                                return;
                            }
                            if (this.info.workYearsImgList === undefined || this.info.workYearsImgList === '' || this.info.workYearsImgList === null || this.info.workYearsImgList.length === 0) {
                                this.$message({ message: '请上传工龄证明', showClose: true, offset: 50, type: 'error' });
                                return;
                            }
                        }

                        let url = "user/enrollOrder/save";
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieTNAME();
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieTNAME();
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editDrawer = false;
                                    this.initTable();
                                    this.$message({
                                        message: '保存成功,请等待机构审核',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            saveTmp: async function (formRef) {
                if (!this.info.classId) {
                    this.$message({
                        message: '请选择报名班级后暂存',
                        showClose: true, offset: 50, type: 'error'
                    });
                    return
                }
                let url = "user/enrollOrder/savetmp";
                this.info.companyId = this.appCookies.getCookieOID();
                this.info.createBy = this.appCookies.getCookieUID();
                this.info.createByname = this.appCookies.getCookieTNAME();
                this.info.modifyBy = this.appCookies.getCookieUID();
                this.info.modifyByname = this.appCookies.getCookieTNAME();
                // 保存数据
                this.apis.postJson(url, this.info)
                    .then((res) => {
                        if (res.code === 1) {
                            this.editDrawer = false;
                            this.initTable();
                            this.$message({
                                message: '已暂存成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toEdit: function (keyId) {
                this.apis.get('user/enrollOrder/getById?orderId=' + keyId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                            this.$nextTick(function() {
                                // this.$refs.gnvqSelect.initData(this.info.categoryId, this.info.gnvqId, this.info.levelId);
                                // let lv = {
                                //     levelId: this.info.levelId
                                // }
                                this.getClassListinfo();
                                // this.$refs.editForm.resetFields();
                            });
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        orderId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('user/enrollOrder/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            getOrgList: function () {
                // if (!this.privRange.havePriv()){
                //     return
                // }
                this.apis.get('system/org/getList?orgType=SCHOOL&orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getEduList: function () {
                this.apis.get('system/dict/getList?typeCode=0003')
                    .then((res) => {
                        if (res.code === 1) {
                            this.eduList = res.data || []
                            // this.eduList.splice(0, 0, { dictId: '', dictName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getCertNameList: function () {
                this.apis.get('system/dict/getList?typeCode=0005')
                    .then((res) => {
                        if (res.code === 1) {
                            this.certNameList = res.data || []
                            // this.certNameList.splice(0, 0, { dictId: '', dictName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getClassList: function () {
                if (!this.queryParams.companyId || !this.queryParams.levelId || !this.queryParams.gnvqId){
                    this.classList = [];
                    return;
                }
                let data = {
                    companyId: this.queryParams.companyId,
                    levelId: this.queryParams.levelId,
                    gnvqId: this.queryParams.gnvqId,
                    classType: 'ENROLL',
                    operUserId: this.appCookies.getCookieUID()
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getClassList2: function () {
                // alert(this.info.levelId)
                this.info.classId = ''
                this.info.priceValue = ''
                if (!this.info.companyId || !this.info.levelId || !this.info.gnvqId){
                    this.classList2 = [];
                    return;
                }
                this.classList2 = [];
                // if (lv && lv.classId) {
                //     this.info.classId = lv.classId
                // }
                this.getClassListinfo()
            },
            getClassListinfo: function () {
                let data = {
                    companyId: this.info.companyId,
                    levelId: this.info.levelId,
                    gnvqId: this.info.gnvqId,
                    classType: 'ENROLL',
                    // operUserId: this.appCookies.getCookieUID()
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        // console.log(res)
                        if (res.code === 1) {
                            this.classList2 = res.data || [];

                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            levelChange: function (levelInfo) {
                // console.log(levelInfo)
                this.info.conditionDesc = levelInfo.levelDesc;
                this.getClassList2()
            },
            classChange: function (classId) {
                // this.info.classId = classId
                for (let item of this.classList2) {
                    if (item.classId === classId) {
                        this.info.priceValue = item.amount;
                    }
                }
                this.getClassConfig(classId)
            },
            getClassConfig: function (classId) {
                this.apis.get('course/class/getClassConfig?classId=' + classId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info.classConfig = res.data.configMap || {};
                            this.info.defFieldList = res.data.defFieldList || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            uploadIdCardFront: function (webPath, savePath, isReadCard) {
                // alert(savePath)
                this.$set(this.info, 'idCardFrontUrl', webPath);
                this.$set(this.info, 'idCardFront', savePath);
                // this.info.raceImgUrl = webPath;
                // this.info.raceImg = savePath;
                // console.log(isReadCard)
                if (!isReadCard) {
                    this.idcardInfo(webPath);
                }
            },
            uploadIdCardBack: function (webPath, savePath) {
                this.$set(this.info, 'idCardBackUrl', webPath);
                this.$set(this.info, 'idCardBack', savePath);
                // this.info.raceImgUrl = webPath;
                // this.info.raceImg = savePath;
            },
            uploadCertHeadImg: function (webPath, savePath) {
                this.$set(this.info, 'certHeadImgUrl', webPath);
                this.$set(this.info, 'certHeadImg', savePath);
            },
            idcardInfo: function (webPath) {
                let data = {
                    fileUrl: webPath,
                    side: 'front'
                }
                this.mainLoading = true;
                this.mainLoadingText = '正在进行身份证识别，请稍等';
                this.apis.postForm('third/ai/ocr/idcardUrl', data)
                    .then((res) => {
                        if (res.code === 1) {
                            let card = res.data;
                            this.info.trueName = card.trueName;
                            this.info.sex = card.sex;
                            this.info.idCard = card.idCard;
                            this.info.homeAddr = card.homeAddr;
                            this.info.birthDay = card.birthDay;
                            this.info.nationName = card.nationName;
                            this.$forceUpdate();
                            // this.$refs.editForm.resetFields();
                            this.checkHasEnroll();
                            console.log(res.data);
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                        this.mainLoading = false;
                    }).catch((err) => {
                    this.mainLoading = false;
                    console.log(err)
                });
            },
            checkHasEnroll: function () {
                let data = {
                    gnvqId: this.info.gnvqId,
                    levelId: this.info.levelId,
                    idCard: this.info.idCard,
                    orderId: this.info.orderId,
                }
                this.apis.get('user/enrollOrder/checkHasEnrollByIdCard', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '该身份证号已经报名过此工种等级,不能重复报名！',
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            handleAdd: function () {
                this.info.certList.push({
                    certName: '',
                    certFiles: []
                });
            },
            handleRemove: function (index) {
                this.info.certList.splice(index, 1);
            },
            downloadPdf: function () {
                // let data = {
                //     trueName: this.info.trueName,
                //     idCard: this.info.idCard,
                //     workName: '',
                //     workYear: this.info.workYears,
                // }
                this.apis.axios()({
                    method: 'post',
                    url: 'user/enrollOrder/downloadGLZM',
                    data: this.$http.adornParams(this.info),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '未查询到数据',
                            showClose: true, offset: 50, type: 'error'
                        });
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '.pdf')
                }).catch(err => {
                    console.log(err);
                });
            },
            /**
             * 文件流下载
             */
            downFile(content, fileName) {
                let blob = new Blob([content], {
                    type: 'application/pdf'
                });
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = objectUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
