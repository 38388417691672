<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>运动处方</span>
                        <div style="float: right; padding: 0px 0">
                            <!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
                            <!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:150px"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态" style="width:150px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="处方名" prop="recipeName" min-width="160px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="处方分类" prop="recipeCatName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="适宜人群" prop="recipeCrowdName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="运动项目" prop="recipeItems" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="160" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.recipeId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.recipeId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.recipeId, 0)">恢复</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <RecipeEditor ref="recipeEditor" @callback="initTable()"></RecipeEditor>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import RecipeEditor from './components/RecipeEditor'
export default {
    components: {
        Pagination,
        Breadcrumb,
        RecipeEditor,
    },
    filters: {
        deleteStatusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger',
            };
            return statusMap[status]
        },
        dataStatusFilter(status) {
            const statusMap = {
                0: '进行中',
                1: '已结束',
            };
            return statusMap[status]
        },
        trainWayFilter(status) {
            const statusMap = {
                1: '线上',
                2: '线下',
                3: '线上+线下',
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            crumbData: [
                {
                    path: '',
                    name: '首页'
                },
                {
                    path: '',
                    name: '运动处方'
                }
            ],
            //列表查询数据
            queryParams: {
                pageNumber: 1,
                pageSize: 10,
                totalRecord: 0,
                keyword: '',
                deleteStatus: '0',
                orgId: this.appCookies.getCookieOID(),
            },
            orgId: this.appCookies.getCookieOID(),
            //列表数据
            tableData: [],
            tableLoading: false,
            //机构下拉菜单
            orgList: [],
            //编辑、新增表单
            editDrawer: false,
            info: {},
            //表单验证规则
            ruleValidate: {
                // 基本信息
                trainName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
            },
        };
    },
    mounted () {
        this.initTable();
        this.getOrgList();
        this.$nextTick(function() {
        });
    },
    computed: {
    },
    methods: {
        //获取列表
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('/fitness/recipe/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 1;
            this.initTable();
        },
        //获取机构列表下拉菜单
        getOrgList: function () {
            if (!this.privRange.havePriv()){
                return
            }
            this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.orgList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toAdd: function () {
            this.$refs.recipeEditor.show('');
            // this.$refs.editForm.resetFields();
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "/fitness/recipe/save";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    this.info.orgId = this.queryParams.orgId;
                    console.log(this.info)
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDrawer = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (recipeId) {
            this.$refs.recipeEditor.show(recipeId);
        },
        toDelete: function (recipeId, deleteStatus) {
            let title = '确定要删除此条数据？';
            if (deleteStatus === 0) {
                title = '确定要恢复此条数据？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    recipeId: recipeId,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('/fitness/recipe/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
