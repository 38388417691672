<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>部门列表</span>
                    </div>
                    <div v-if="this.privRange.havePriv()" style="margin-bottom: 4px">
                        <el-select v-model="queryParams.orgId" placeholder="机构" style="width: 100%" @change="changeOrgId">
                            <el-option v-for="item in orgList" :label="item.orgName" :value="item.orgId"
                                       :key="item.orgId"></el-option>
                        </el-select>
                    </div>
                    <DeptListTree ref="deptListTree" :orgId="this.queryParams.orgId" @callback="changeDeptId"></DeptListTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>员工列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:140px"></el-input>
                                </el-form-item>
                                <el-form-item label="在职状态">
                                    <el-select v-model="queryParams.onJobStatus" placeholder="请选择" style="width:120px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="在职" value="0"></el-option>
                                        <el-option label="离职" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="状态">
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择" style="width:120px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" :value="0"></el-option>
                                        <el-option label="已删除" :value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-upload2" @click="toImport">导入</el-button>
                                    <el-button class="filter-item" type="success" round icon="el-icon-plus" @click="toAdd">新增</el-button>
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toMoveDept">转移部门</el-button>
                                </el-button-group>
                                <el-link href="/model/StaffImportModel.xlsx" icon="el-icon-download" :underline="false" target="_blank" style="float: right; margin-right: 10px">下载模板</el-link>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                                @selection-change="handleSelectionChange"
                            >
                                <el-table-column
                                    type="selection"
                                    width="55">
                                </el-table-column>
                                <el-table-column label="姓名" prop="trueName" min-width="120px" align="left" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <span>{{ row.trueName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="关联状态" prop="userId" width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag v-if="row.userId">已关联</el-tag>
                                        <el-tag v-else type="danger">未关联</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="性别" prop="sex" min-width="80px" align="left"></el-table-column>
                                <el-table-column label="联系电话" prop="phone" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="民族" prop="nationName" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="身份证号" prop="idCard" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="出生日期" prop="birthday" min-width="100px" align="left" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <span>{{ row.birthday | parseTime('{y}-{m}-{d}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="入职日期" prop="entryDate" min-width="100px" align="left">
                                    <template slot-scope="{row}">
                                        <span>{{ row.entryDate | parseTime('{y}-{m}-{d}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="所属部门" prop="deptName" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="在职/离职" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.onJobStatus | colorFilter">
                                            {{ row.onJobStatus  | onJobStatusStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="离职日期" prop="leaveDate" min-width="100px" align="left">
                                    <template slot-scope="{row}">
                                        <span>{{ row.leaveDate | parseTime('{y}-{m}-{d}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="200" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.staffId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.staffId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.staffId, 0)">恢复</el-button>
                                        <el-button v-if="row.deleteStatus===0 && row.onJobStatus===0" type="text" size="mini" @click="toLeave(row.staffId)">离职</el-button>
                                        <el-button v-if="row.deleteStatus===0 && row.onJobStatus===1" type="text" size="mini" @click="concleLeave(row.staffId)">取消离职</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="编辑员工信息"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="50%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
                    <el-row :gutter="32">
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="trueName">
                                <el-input v-model="info.trueName" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="12">
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="info.phone" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="身份证号" prop="idCard">
                                <el-input v-model="info.idCard" placeholder="请输入身份证号"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="12">
                            <el-form-item label="性别" prop="sex">
                                <el-radio-group v-model="info.sex">
                                    <el-radio :label="1">男</el-radio>
                                    <el-radio :label="2">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="出生日期" prop="birthday">
                                <el-date-picker
                                    v-model="info.birthday"
                                    type="date"
                                    placeholder="选择日期"
                                    @change="info.birthday=$event"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="民族" prop="nationName">
                                <el-input v-model="info.nationName" placeholder="请输入民族"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="学历" prop="educationId">
                                <el-select v-model="info.educationId" filterable placeholder="请选择学历">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in eduList" :value="item.dictId" :label="item.dictName" :key="item.dictId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="入职日期" prop="entryDate">
                                <el-date-picker
                                    v-model="info.entryDate"
                                    type="date"
                                    placeholder="选择日期"
                                    @change="info.entryDate=$event"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属部门" prop="deptId">
                                <SelectTree :tree-data="deptList" :selectId.sync="info.deptId"
                                        :treeProps="treeProps" :nodeKey="'deptId'"
                                        :onlySelectLeaf="false"></SelectTree>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">取消</el-button>
                <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
        <el-dialog
            title="离职信息"
            :visible.sync="leaveDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="leaveInfo" :rules="leaveRuleValidate" ref="leaveForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="离职日期" prop="leaveDate">
                            <el-date-picker
                                v-model="leaveInfo.leaveDate"
                                type="date"
                                placeholder="选择日期"
                                @change="info.endDate=$event"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="leaveDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveLeave('leaveForm')">保存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="导入员工信息"
            :visible.sync="importDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="70%">
            <el-form :model="importInfo" :rules="ruleValidate" ref="importForm" label-width="120px" label-suffix=":">
                <el-row :gutter="16">
                    <el-col :span="20" :offset="2">
                        <el-form-item label="所属部门" prop="deptId">
                            <SelectTree :tree-data="deptList" :selectId.sync="importInfo.deptId"
                                        :treeProps="treeProps" :nodeKey="'deptId'"
                                        :onlySelectLeaf="false"></SelectTree>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20" :offset="2">
                        <el-form-item label="导入数据" prop="certCode">
                            <el-upload
                                class="upload-demo"
                                accept=".xls,.xlsx"
                                ref="upload"
                                action="#"
                                :limit="1"
                                :before-upload="beforeUpload"
                                :on-change="fileChange"
                                :file-list="fileList"
                                :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx文件</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="16" v-show="errorList && errorList.length > 0">
                    <el-col :span="24">
                        <el-table
                            :data="errorList"
                            fit
                            stripe
                            highlight-current-row
                            style="width: 100%;"
                            :indent="20"
                        >
                            <el-table-column label="姓名" prop="trueName" align="left"></el-table-column>
                            <el-table-column label="身份证号" prop="idCard" align="left"></el-table-column>
                            <el-table-column label="联系电话" prop="phone"  align="left"></el-table-column>
                            <el-table-column label="错误原因" prop="importMsg" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importDialog = false">关 闭</el-button>
                <el-button type="primary" :loading="btnLoading" @click="importData('importForm')">导 入</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="转移部门"
            :visible.sync="moveDeptDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="moveDeptInfo" ref="moveDeptForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20" :offset="2">
                        <el-form-item label="转移到部门" prop="deptId">
                            <SelectTree :tree-data="deptList" :selectId.sync="moveDeptInfo.deptId"
                                        :treeProps="treeProps" :nodeKey="'deptId'"
                                        :onlySelectLeaf="false"></SelectTree>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="moveDeptDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveDept('moveDeptForm')">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import DeptListTree from "./components/DeptListTree";
    import SelectTree from '@/components/tree/SelectTree';
    export default {
        components: {
            Pagination,
            Breadcrumb,
            DeptListTree,
            SelectTree,
        },
        filters: {
            onJobStatusStatusFilter(status) {
                const statusMap = {
                    0: '在职',
                    1: '离职',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
        },
        data () {
            const validatePhone = (rule, value, callback) => {
                if (value === undefined || value === null || value === '') {
                    callback(new Error('请输入手机号'));
                } else if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                    callback(new Error('手机号格式不对'));
                } else {
                    callback();
                }
            };
            const validateIdcard = (rule, value, callback) => {
                if (value === undefined || value === null || value === '') {
                    callback(new Error('请输入身份证号'));
                } else if (value.length !== 18) {
                    callback(new Error('身份证号格式不对'));
                } else {
                    callback();
                }
            };
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '员工管理'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    deleteStatus: 0,
                    onJobStatus: '',
                    deptId: '',
                    orgId: this.appCookies.getCookieOID(),
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //机构下拉菜单
                orgList: [],
                //所属部门下拉菜单
                deptList: [],
                treeProps: {
                    value: 'deptId',
                    label: 'deptName',
                    children: 'children'
                },
                //编辑、新增表单
                editDrawer: false,
                info: {},
                eduList: [], //学历下拉菜单
                //表单验证规则
                ruleValidate: {
                    // 基本信息
                    trueName: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                    phone: [
                        { required: true, validator: validatePhone, trigger: 'blur' }
                    ],
                    idCard: [
                        { required: true, validator: validateIdcard, trigger: 'blur' }
                    ],
                    deptId: [
                        { required: true, message: '请选择所属部门', trigger: 'change' }
                    ],
                },
                leaveDialog: false,
                leaveInfo: {},
                //表单验证规则
                leaveRuleValidate: {
                    // 基本信息
                    leaveDate: [
                        { required: true, message: '请选择离职日期', trigger: 'blur' }
                    ],
                },
                importDialog: false,
                importFile: null,
                fileList: [],
                importInfo: {},
                btnLoading: false,
                errorList: [],
                selectdata: [],
                moveDeptDialog: false,
                moveDeptInfo: {}
            };
        },
        mounted () {
            this.getOrgList();
            this.initTable();
            this.getEduList();
            this.getDeptList();
            this.$nextTick(function() {
            });
        },
        computed: {
        },
        methods: {
            handleSelectionChange(val) {
                this.selectdata = val;
            },
            toMoveDept: function () {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                this.moveDeptDialog=true;
                this.moveDeptInfo.deptId = this.queryParams.deptId;
            },
            saveDept: function () {
                let staffIds = [];
                this.selectdata.forEach(function (item) {
                    staffIds.push(item.staffId)
                });
                let data = {
                    "deptId": this.moveDeptInfo.deptId,
                    "staffIds": staffIds
                }
                // 保存数据
                this.apis.postJson('/system/staff/batchUpdDept', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '保存成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.moveDeptDialog = false;
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('/system/staff/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            //切换机构时重置查询参数，刷新列表
            changeOrgId: function () {
                this.getDeptList();
                this.queryParams.deptId = ''
                this.queryParams.keyword = ''
                this.queryParams.deleteStatus = 0
                this.refreshTable();
            },
            changeDeptId: function (data) {
                this.queryParams.deptId = data.deptId;
                this.refreshTable();
            },
            //获取机构列表下拉菜单
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toAdd: function () {
                this.info = {
                    staffId: '',
                    trueName: '',
                    deptId: this.queryParams.deptId || 0,
                };
                console.log(this.info);
                this.editDrawer = true;
                this.getDeptList();
                // this.$refs.editForm.resetFields();
            },
            toImport: function () {
                this.importInfo = {
                    deptId: this.queryParams.deptId,
                    orgId: this.appCookies.getCookieOID()
                };
                this.importFile = null;
                this.importDialog = true;
            },
            importData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        if (this.importFile === null) {
                            this.$message({
                                message: '请选择上传的文件',
                                showClose: true,
                                offset: 200,
                                type: 'error'
                            });
                            return ;
                        }
                        this.btnLoading = true;
                        let formData = new FormData();
                        formData.append('file', this.importFile);
                        let url = "system/staff/import";
                        this.importInfo.createBy = this.appCookies.getCookieUID();
                        this.importInfo.createByname = this.appCookies.getCookieUNAME() ;
                        this.importInfo.modifyBy = this.appCookies.getCookieUID();
                        this.importInfo.modifyByname = this.appCookies.getCookieUNAME() ;
                        formData.append("info", new Blob([JSON.stringify(this.importInfo)], { type: "application/json" }));
                        // 保存数据
                        this.mainLoading = true;
                        this.mainLoadingText = '正在导入数据，请稍等';
                        this.apis.postFile(url, formData)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '导入成功',
                                        showClose: true,
                                        offset: 200,
                                        type: 'success'
                                    });
                                    this.initTable();
                                    if (res.data) {
                                        this.errorList = res.data
                                    } else {
                                        this.importDialog = false;
                                    }
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true,
                                        offset: 200,
                                        type: 'error'
                                    });
                                }
                                this.mainLoading = false;
                                this.btnLoading = false;
                            }).catch(err => {
                            this.mainLoading = false;
                            this.btnLoading = false;
                            console.log(err);
                        });
                    }
                })
            },
            //获取学历列表
            getEduList: function () {
                this.apis.get('system/dict/getList?typeCode=0003')
                    .then((res) => {
                        if (res.code === 1) {
                            this.eduList = res.data || []
                            // this.eduList.splice(0, 0, { dictId: '', dictName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getDeptList: function () {
                console.log(this.queryParams.orgId);
                this.apis.get('system/dept/getList?orgId='+this.queryParams.orgId)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.deptList = res.data || [];
                            // this.supCatTreeDataList = [
                            //     { deptId: 'deptId1', deptName: 'deptName1', children: [{ deptId: 'deptId1-1', deptName: 'deptName1-1', children: [], }], }
                            // ];//测试用
                            // this.supCatTreeDataList = [];//测试用
                            this.deptList.splice(0, 0, { deptId: '0', deptName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "/system/staff/save";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    this.info.orgId = this.queryParams.orgId;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDrawer = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            toEdit: function (staffId) {
                this.apis.get('/system/staff/getById?staffId=' + staffId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (staffId, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        staffId: staffId,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('/system/staff/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                }).catch(() => {
                });
            },
            toLeave: function (staffId) {
                this.leaveInfo.staffId = staffId;
                this.leaveDialog = true;
            },
            saveLeave: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "/system/staff/leave";
                    this.leaveInfo.onJobStatus = 1;
                    this.leaveInfo.operUserId = this.appCookies.getCookieUID();
                    this.leaveInfo.operUserName = this.appCookies.getCookieUNAME();
                    console.log(this.leaveInfo);
                    // 保存数据
                    this.apis.postJson(url, this.leaveInfo)
                        .then((res) => {
                            if (res.code === 1) {
                                this.leaveDialog = false;
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            concleLeave: function (staffId) {
                let title = '确定要取消离职？';
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        staffId: staffId,
                        onJobStatus: 0,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postJson('/system/staff/leave', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                }).catch(() => {
                });
            },
            beforeUpload(file) {
                //文件类型
                const isType = file.type === 'application/vnd.ms-excel'
                const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                const fileType = isType || isTypeComputer
                if (!fileType) {
                    this.$message.error('上传文件只能是xls/xlsx格式！')
                }
                this.importFile = file;

                return fileType;
            },
            fileChange(file, fileList) {
                console.log(file)
                this.importFile = file.raw
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>
