<template>
    <div class="app-container"
         v-loading="mainLoading"
         :element-loading-text="mainLoadingText">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never"  style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <el-col :span="19" class="main-tab">
                <el-tabs v-model="activeName" type="border-card"  @tab-click="handleClick">
                    <el-tab-pane label="考试日历" name="calendar">
                        <el-row class="query-container">
                            <el-col :span="24">
                                <el-form :inline="true">
                                    <el-form-item label="">
                                        <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:100px"></el-input>
                                    </el-form-item>
                                    <el-form-item label="">
                                        <el-input v-model="queryParams.deptName" placeholder="所属部门" style="width:100px"></el-input>
                                    </el-form-item>

                                    <el-form-item label=""  v-if="this.privRange.havePriv()">
                                        <el-select v-model="queryParams.companyId"  placeholder="请选择报名机构" @change="getClassList" style="width:150px">
                                            <el-option value="">请选择</el-option>
                                            <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"  :key="item.orgId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-select v-model="queryParams.classId" placeholder="请选择班级" style="width:100px">
                                            <el-option value="">请选择</el-option>
                                            <el-option v-for="item in classList" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-select v-model="queryParams.classType" placeholder="请选择类型" style="width:100px">
                                            <el-option label="全部" value=""></el-option>
                                            <el-option label="继续教育" value="LEARN"></el-option>
                                            <el-option label="考试报名" value="ENROLL"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" icon="el-icon-search"  @click="queryCalendar">查询</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                        <el-row :gutter="8" class="row-container">
                            <el-col :span="currExamList.length > 0 ? 16 : 24">
                                <el-calendar v-model="queryParams.date">
                                    <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                                    <template slot="dateCell" slot-scope="{date, data}">
                                        <div>
                                            <div class="calendar-day">{{ data.day.split('-').slice(2).join('-') }}</div>
                                            <div v-for="(values, key) in calendarData" :key="key">
                                                <div v-if="key===data.day">
<!--                                                    <el-tooltip placement="top" v-for="(item, idx) in values" :key="idx">-->
<!--                                                        <div slot="content">{{item.orgName}}<br/>{{item.gnvqName}}{{item.levelName}}<br/>{{item.yearNum}}第{{item.classNum}}期</div>-->
<!--                                                        <el-button type="success" size="mini" round>{{item.startTime}}~{{item.endTime}}</el-button>-->
<!--                                                    </el-tooltip>-->
                                                    <el-button type="success" size="mini" round @click="showList(values)">{{values.length}}场</el-button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </el-calendar>
                            </el-col>
                            <el-col :span="8" v-if="currExamList.length > 0">
                                <div v-for="(item, idx) in currExamList" :key="idx">
                                    <el-alert :title="item.companyName"
                                              type="success"
                                              show-icon :closable="false">
                                        <div slot="">
                                            <div>
                                                <p>{{item.gnvqName}}-{{item.levelName}}</p>
                                                <p>{{item.yearNum}}年第{{item.classNum}}期</p>
                                            </div>
                                            <div style="text-align: right">
                                                <el-button type="text" size="mini" @click="showClassDetail(item.classId, item.classExamId)">
                                                    查看详情
                                                </el-button>
                                            </div>
                                        </div>
                                    </el-alert>
                                    <div style="height: 15px"></div>
                                </div>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="考试明细" name="datalist">
                        <el-row class="query-container">
                            <el-col :span="24">
                                <el-form :inline="true">
                                    <el-form-item label="">
                                        <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:100px"></el-input>
                                    </el-form-item>
                                    <el-form-item label="">
                                        <el-input v-model="queryParams.deptName" placeholder="所属部门" style="width:100px"></el-input>
                                    </el-form-item>

                                    <el-form-item label=""  v-if="this.privRange.havePriv()">
                                        <el-select v-model="queryParams.companyId"  placeholder="请选择报名机构" @change="getClassList" style="width:150px">
                                            <el-option value="">请选择</el-option>
                                            <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"  :key="item.orgId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-select v-model="queryParams.classId" placeholder="请选择班级" style="width:100px">
                                            <el-option value="">请选择</el-option>
                                            <el-option v-for="item in classList" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-select v-model="queryParams.classType" placeholder="请选择类型" style="width:100px">
                                            <el-option label="全部" value=""></el-option>
                                            <el-option label="继续教育" value="LEARN"></el-option>
                                            <el-option label="考试报名" value="ENROLL"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                        <el-row :gutter="32" class="table-container">
                            <el-col>
                                <el-table
                                    v-loading="tableLoading"
                                    :data="tableData"
                                    fit
                                    highlight-current-row
                                    style="width: 100%;"
                                    height="calc(100vh - 320px)"
                                    ref="selectTable"
                                    :indent="20"
                                >
                                    <el-table-column
                                        type="selection"
                                        width="55">
                                    </el-table-column>
                                    <el-table-column label="机构" prop="companyName" min-width="140px" align="left"></el-table-column>
                                    <el-table-column label="工种" prop="gnvqName"  align="left"  min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column label="等级" prop="levelName"  align="left" min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                    <el-table-column label="班级" prop="className"  align="left" min-width="150" :show-overflow-tooltip="true">
                                        <template slot-scope="{row}">
                                            <span>{{ row.yearNum}}年第{{ row.classNum}}期</span>
                                        </template>
                                    </el-table-column>
<!--                                    <el-table-column label="考试时间" width="250" align="center">-->
<!--                                        <template slot-scope="{row}">-->
<!--                                            <span>{{ row.startDate}} {{ row.startTime}} ~ {{ row.endDate}} {{ row.endTime}}</span>-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
                                    <el-table-column label="类型" prop="examType" width="100px" align="center">
                                        <template slot-scope="{row}">
                                            {{row.examType | examTypeFilter}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="方式" prop="examWay" width="100px" align="center">
                                        <template slot-scope="{row}">
                                            {{row.examWay | examWayFilter}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="详情" align="center" width="140" fixed="right" class-name="small-padding fixed-width">
                                        <template slot-scope="{row}">
                                            <el-button type="text" size="mini" @click="showClassDetail(row.classId, row.classExamId)">
                                                查看详情
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                            :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                            @pagination="initTable"/>
                            </el-col>
                        </el-row>

                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
        <ClassExamDetail ref="classExamDetail"></ClassExamDetail>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    import ClassExamDetail from "@v/course/components/ClassExamDetail";
    import { getDate } from "../../utils/utils";
    import { parseTime } from "@/utils";
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqTree,
            ClassExamDetail
        },
        filters: {
            examTypeFilter(status) {
                const statusMap = {
                    1: '理论考试',
                    2: '实操考试',
                };
                return statusMap[status]
            },
            examWayFilter(status) {
                const statusMap = {
                    1: '线上',
                    2: '线下',
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                mainLoading: false,
                mainLoadingText: '正在导出数据，请稍等',
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '学习统计'
                    }
                ],
                activeName: 'calendar',
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    dataStatus: '',
                    confirmStatus: '1',
                    payWay: '',
                    payStatus: '',
                    orgId: this.appCookies.getCookieTOID(),
                    companyId: this.appCookies.getCookieOID(),
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    identifyStatus: '',
                    classType: '',
                    orderIds: [],
                    deptName: '',
                    userId: this.appCookies.getCookieUID(),
                    date: getDate()
                },
                gnvqTreeData: [],
                tableData: [],
                calendarData: [],
                info: {
                    certList: [{
                        certName: '',
                        certFiles: []
                    }],
                },
                tableLoading: false,
                editDrawer: false,
                orgList: [],
                classList: [],
                classList2: [],
                eduList: [],
                currExamList: [],
            };
        },
        mounted () {
            this.queryCalendar();
            this.getOrgList();
            this.clikeBtn()
        },
        methods: {
            handleClick() {
                if (this.activeName === 'calendar') {
                    this.queryCalendar();
                } else {
                    this.refreshTable();
                }
            },
            showList: function (vals) {
                this.currExamList = vals;
            },
            clikeBtn: function () {
                this.$nextTick(() => {
                    // 点击前一个月
                    let prevBtn = document.querySelector(
                        ".el-calendar__button-group .el-button-group>button:nth-child(1)"
                    );
                    prevBtn.addEventListener("click", e => {
                        this.queryParams.date = parseTime(this.queryParams.date, '{y}-{m}-{d}')
                        console.log(this.queryParams.date);
                        this.queryCalendar()
                    });

                    //点击下一个月
                    let nextBtn = document.querySelector(
                        ".el-calendar__button-group .el-button-group>button:nth-child(3)"
                    );
                    nextBtn.addEventListener("click", () => {
                        this.queryParams.date = parseTime(this.queryParams.date, '{y}-{m}-{d}')
                        console.log(this.queryParams.date);
                        this.queryCalendar()
                    });

                    //点击今天
                    let todayBtn = document.querySelector(
                        ".el-calendar__button-group .el-button-group>button:nth-child(2)"
                    );
                    todayBtn.addEventListener("click", () => {
                        this.queryParams.date = parseTime(this.queryParams.date, '{y}-{m}-{d}')
                        console.log(this.queryParams.date);
                        this.queryCalendar()
                    });
                });
            },
            queryCalendar: function () {
                this.apis.postForm('course/class/exam/mst/queryCalendar', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.calendarData = res.data || [];
                            this.currExamList = []
                            console.log(res);
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toQuery: function (data) {
                // console.log(data)
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.getClassList();
                this.handleClick();
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/class/exam/mst/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getList?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getClassList: function () {
                if (!this.queryParams.companyId || !this.queryParams.levelId || !this.queryParams.gnvqId){
                    this.classList = [];
                    return;
                }
                let data = {
                    companyId: this.queryParams.companyId,
                    levelId: this.queryParams.levelId,
                    gnvqId: this.queryParams.gnvqId,
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            showClassDetail: function (classId, classExamId) {
                this.$nextTick(function() {
                    this.$refs.classExamDetail.init(classId, classExamId);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
::v-deep .main-tab .el-tabs__content {
    height: calc(100vh - 200px);
    overflow: auto;
}
::v-deep .el-alert__content {
    width: 100%;
}
</style>
