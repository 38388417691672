<template>
    <el-dialog
        title="添加类目"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" >
            <el-col :span="24">
                <el-form :model="info" ref="editForm" label-width="160px" label-suffix=":">
                    <el-form-item label="选择类目">
                        <el-cascader
                            v-model="value"
                            :options="allCategories"
                            :props="{value: 'id', label: 'name'}"
                            @change="handleChange"></el-cascader>
                    </el-form-item>
                    <el-form-item label="适用范围" v-if="info.scope">
                        {{info.scope}}
                    </el-form-item>
                    <div v-for="(item, idx) in info.certicates" :key="idx">
                        <el-form-item  label="资质文件" >
                            <div>{{item.key}}</div>
                            <div>上传原件或复印件，复印件务必加盖公司公章，文件格式为jpg、jpeg、bmp、gif或png，文件大小不超过10M，可拼图上传。</div>
                            <MiniUploadImg @callback="imgCallback" :imageUrl="item.url" :appId="appId" :index="idx"
                                             :width="150" :height="150"></MiniUploadImg>
                            <div>资质有效期需大于30天。</div>
<!--                            <el-radio-group v-model="item.isPermanent">-->
<!--                                <el-radio :label="0">选择有效期</el-radio>-->
<!--                                <el-radio :label="1">长期有效</el-radio>-->
<!--                            </el-radio-group>-->
<!--                            <div v-if="item.isPermanent===0">-->
<!--                                <el-date-picker-->
<!--                                    v-model="item.expireTime"-->
<!--                                    type="date"-->
<!--                                    value-format="yyyy-MM-dd"-->
<!--                                    format="yyyy-MM-dd"-->
<!--                                    placeholder="选择有效期">-->
<!--                                </el-date-picker>-->
<!--                            </div>-->
                        </el-form-item>
                    </div>
                    <el-form-item label="备注说明" v-if="info.remark">
                        {{info.remark}}
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>
            <el-button type="primary"  @click="addCategories">确 定</el-button>
<!--            v-if="info.canApply"-->
        </span>
    </el-dialog>
</template>

<script>
import MiniUploadImg from "@c/upload/MiniUploadImg";
export default {
    components: {
        MiniUploadImg
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            editDialog: false,
            appId: '',
            allCategories: [],
            value: '',
            info: {
                first: '',
                second: '',
                certicates: [],
                scope: '',
                sensitiveType: '',
                canApply: false,
                remark: ''
            }
        }
    },
    mounted() {
    },
    methods: {
        handleChange(value) {
            let curr = this.allCategories.filter((p) => {
                return p.id === value[0];
            });
            let children = curr[0].children.filter((p) => {
                return p.id === value[1];
            });
            console.log(children[0])
            this.info.scope = children[0].scope
            this.info.sensitiveType = children[0].sensitive_type
            this.info.canApply = children[0].can_apply
            let qualify = children[0].biz_qualify
            let certicates = []
            qualify.exter_list.forEach(item => {
                item.inner_list.forEach(sub => {
                    certicates.push({
                        key: sub.name,
                        value: '',
                        expireTime: '',
                        isPermanent: ''
                    })
                })
            })
            this.info.first = children[0].father
            this.info.second = children[0].id
            this.info.certicates = certicates
            this.info.remark = qualify.remark

        },
        open: function (appId) {
            this.editDialog = true;
            this.appId = appId;
            this.info = {
                first: '',
                    second: '',
                    certicates: [],
                    scope: '',
                    sensitiveType: '',
                    canApply: false,
                    remark: ''
            }
            this.getAllCategories()
        },
        getAllCategories(appid) {
            this.apis.get('wx/open/mini/mgr/getAllCategories?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        let categories = res.data.categories_list.categories;
                        let parents = categories.filter((p) => {
                            return p.level === 1;
                        });
                        parents.forEach(item => {
                            let childrenIds = item.children
                            let children = categories.filter((p) => {
                                return childrenIds.indexOf(p.id) > -1;
                            });
                            children.forEach(item => {
                                item.children = ''
                            })
                            item.children = children
                        })
                        this.allCategories = parents
                    } else {
                        this.allCategories = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        imgCallback: function (mediaId, type, index) {
            this.info.certicates[index].value = mediaId;
            this.info.certicates[index].type = type;
        },
        addCategories: function () {

            let categories = {
                appId: this.appId,
                categories: []
            }
            categories.categories[0] = this.info
            this.apis.postJson('wx/open/mini/mgr/addCategory', categories)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.$emit('callback')
                        this.editDialog = false;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
