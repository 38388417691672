<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>发票申请管理</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="20">
                            <el-form :inline="true" label-width="100px">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item label="开票状态">
                                    <el-select v-model="queryParams.invoiceStatus" filterable style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="未开票" value="0"></el-option>
                                        <el-option label="已开票" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="邮寄状态">
                                    <el-select v-model="queryParams.postStatus" filterable style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="未邮寄" value="0"></el-option>
                                        <el-option label="已邮寄" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="公司名称" prop="invoiceHead" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="税务登记号" prop="invoiceCode" min-width="130px"></el-table-column>
                                <el-table-column label="开户银行" prop="bankName" min-width="160px"></el-table-column>
                                <el-table-column label="银行帐号" prop="bankAccount" min-width="130px"></el-table-column>
                                <el-table-column label="注册地址" prop="invoiceAddr" min-width="200px"></el-table-column>
                                <el-table-column label="金额" prop="payValue" min-width="80px" ></el-table-column>
                                <el-table-column label="电子邮箱" prop="email" min-width="100px" ></el-table-column>
                                <el-table-column label="开票状态" prop="invoiceStatus" min-width="100px">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.invoiceStatus | colorFilter">
                                            {{ row.invoiceStatus  | invoiceStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="邮寄状态" prop="postStatus" min-width="100px">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.postStatus | colorFilter">
                                            {{ row.postStatus  | postStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="联系人" prop="linkName" min-width="100px"></el-table-column>
                                <el-table-column label="电话" prop="linkMobile" min-width="120px"></el-table-column>
                                <el-table-column label="邮寄地址" prop="provinceName" min-width="200px">
                                    <template slot-scope="{row}">
                                        <span>{{row.provinceName + row.cityName + row.districtName + row.addressDesc}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="快递名称" prop="expressName" min-width="100px"></el-table-column>
                                <el-table-column label="快递单号" prop="expressNo" min-width="100px"></el-table-column>
                                <el-table-column label="学员确认" prop="receiveStatus" min-width="100px">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.receiveStatus | colorFilter">
                                            {{ row.receiveStatus  | receiveStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="80" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.invoiceStatus === 0" type="text" size="mini"  @click="updInvoiceStatus(row.invoiceId)">确认开票</el-button>
                                        <el-button v-if="row.invoiceStatus === 1" type="text" size="mini"   @click="toPost(row.invoiceId, row.expressName, row.expressNo)">确认邮寄</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="信息"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="快递名称" prop="expressName">
                            <el-input v-model="info.expressName" placeholder="请输入快递名称"></el-input>
                        </el-form-item>
                        <el-form-item label="快递单号" prop="expressNo">
                            <el-input v-model="info.expressNo" placeholder="请输入快递单号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="savePost('editForm')">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    export default {
        name: "InvoiceQuery",
        components: {
            Pagination,
            Breadcrumb,
        },
        filters: {
            receiveStatusFilter(status) {
                const statusMap = {
                    0: '未确认',
                    1: '已确认',
                };
                return statusMap[status]
            },
            invoiceStatusFilter(status) {
                const statusMap = {
                    0: '未开票',
                    1: '已开票',
                };
                return statusMap[status]
            },
            postStatusFilter(status) {
                const statusMap = {
                    0: '未邮寄',
                    1: '已邮寄',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '财务管理'
                    },
                    {
                        path: '',
                        name: '发票管理'
                    }
                ],
                //视频列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    invoiceStatus: '',
                    postStatus: '',
                    orgId: this.appCookies.getCookieOID()
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //编辑、新增表单
                editDialog: false,
                info: {
                    invoiceId: '',
                    expressName: '',
                    expressNo: '',
                },
                //表单验证规则
                ruleValidate: {
                    expressName: [
                        { required: true, message: '请输入快递名称', trigger: 'blur' }
                    ],
                    expressNo: [
                        { required: true, message: '请输入快递单号', trigger: 'blur' }
                    ],
                },
            };
        },
        mounted () {
            this.initTable();
        },
        computed: {
        },
        methods: {
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('user/invoice/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            //确认开票
            updInvoiceStatus: function (invoiceId) {
                let title = '确定已开具发票？';
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        invoiceId: invoiceId,
                        invoiceStatus: 1,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('user/invoice/updInvoiceStatus', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            //确认邮寄
            toPost: async function (invoiceId, expressName, expressNo) {
                this.info = {
                    expressName: '',
                    expressNo: ''
                };
                this.info.invoiceId = invoiceId;
                if (expressName) {
                    this.info.expressName = expressName;
                    this.info.expressNo = expressNo;
                }
                this.editDialog = true;
            },
            savePost: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "user/invoice/updPostStatus";
                    this.info.postStatus = 1;
                    this.info.operUserId = this.appCookies.getCookieUID();
                    this.info.operUserName = this.appCookies.getCookieUNAME();
                    // 保存数据
                    this.apis.postForm(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            toEdit: function (keyId) {
                this.apis.get('xxx/port' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (keyId, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        keyId: keyId,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/video/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
