import { render, staticRenderFns } from "./CategoryQuery.vue?vue&type=template&id=62b90c92&scoped=true&"
import script from "./CategoryQuery.vue?vue&type=script&lang=js&"
export * from "./CategoryQuery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62b90c92",
  null
  
)

export default component.exports