<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="6">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 150px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>字典类型</span>
                        <el-button-group style="float: right">
                            <el-button class="filter-item" size="mini" round icon="el-icon-plus" @click="toAddType">新增</el-button>
                        </el-button-group>
                    </div>
                    <el-table
                        fit
                        highlight-current-row
                        :show-header="false"
                        :data="dictTypeList"
                        @row-click="cellClick"
                        style="width: 100%">
                        <el-table-column
                            prop="typeName"
                            label="名称">
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="120" fixed="right" class-name="small-padding fixed-width">
                            <template slot-scope="{row}">
                                <el-button type="text" size="mini" @click="toEditType(row.typeId)">修改</el-button>
                                <el-button v-if="row.delState===0" type="text" size="mini"  @click="toDeleteType(row.typeId, 1)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
            <el-col :span="18">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>数据字典</span>
                    </div>
                    <!-- 列表查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-plus" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row class="table-container">
                        <el-col :span="24">
                            <el-table
                                :key="tableKey"
                                v-loading="tableLoading"
                                :data="tableData"
                                height="calc(100vh - 310px)"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                ref="elementTable"
                                row-key="dictId"
                                :indent="20"
                                @sort-change="sortChange">
                                <!--                        <el-table-column label="编号" min-width="60px" align="center">-->
                                <!--                            <template slot-scope="{row}">-->
                                <!--                                <span>{{ row.dictCode}}</span>-->
                                <!--                            </template>-->
                                <!--                        </el-table-column>-->
                                <el-table-column label="名称" align="left" width="280" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <span>{{ row.dictName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="类型" min-width="110px" align="center">
                                    <template slot-scope="{row}">
                                        {{ row.dictTypeName}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="排序号" width="80px" align="center">
                                    <template slot-scope="{row}">
                                        {{ row.orderNum }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" min-width="100" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.delState | colorFilter">
                                            {{ row.delState  | statusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作时间" width="140px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.updateTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.dictId)">修改</el-button>
                                        <el-button v-if="row.delState===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.dictId, 1)">删除</el-button>

                                        <el-button v-if="queryParams.treeType===1" type="text" size="mini" icon="el-icon-plus" @click="toAddChild(row.parentId)">平级</el-button>
                                        <el-button v-if="queryParams.treeType===1" type="text" size="mini"  icon="el-icon-plus" @click="toAddChild(row.dictId)">下级</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        :first-zero="0"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>

                </el-card>
            </el-col>
        </el-row>

        <el-dialog
            title="字典编辑"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
<!--                <el-form-item label="字典编码" prop="dictCode">-->
<!--                    <el-input v-model="info.dictCode"></el-input>-->
<!--                </el-form-item>-->
                <el-form-item label="字典名称" prop="dictName">
                    <el-input v-model="info.dictName"></el-input>
                </el-form-item>
                <el-form-item label="字典类型" prop="dictTypeId">
                    <el-select v-model="info.dictTypeId" placeholder="请选择">
                        <el-option
                            v-for="item in dictTypeList"
                            :key="item.typeId"
                            :label="item.typeName"
                            :value="item.typeId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-show="queryParams.treeType===1" label="所属上级" prop="parentId">
                    <SelectTree :tree-data="selectOptions" :selectId.sync="info.parentId"
                                :treeProps="treeProps" :nodeKey="'dictId'" :onlySelectLeaf="false"></SelectTree>
                </el-form-item>
                <el-form-item label="排序号" prop="orderNum">
                    <el-input v-model="info.orderNum"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="字典类型编辑"
            :visible.sync="editTypeDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="typeInfo" :rules="ruleValidate" ref="editTypeForm" label-width="120px" label-suffix=":">
                <el-form-item label="类型名称" prop="typeName">
                    <el-input v-model="typeInfo.typeName"></el-input>
                </el-form-item>
                <el-form-item label="是否树状级联" prop="treeType">
                    <el-radio-group v-model="typeInfo.treeType">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否可分类筛选" prop="canFilter">
                    <el-radio-group v-model="typeInfo.canFilter">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="排序号" prop="orderNum">
                    <el-input v-model="typeInfo.orderNum"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editTypeDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveTypeData('editTypeForm')">保 存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
// import { parseTime } from '@/utils'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination
import Breadcrumb from "../../../components/Breadcrumb";
import SelectTree from "../../../components/tree/SelectTree";
export default {
    components: {
        SelectTree,
        Pagination,
        Breadcrumb
    },
    filters: {
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger'
            };
            return statusMap[status]
        },
        statusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        }
    },
    data() {
        return {
            crumbData: [
                {
                    path: '/dashboard',
                    name: '首页'
                },
                {
                    path: '',
                    name: '知识库'
                },
                {
                    path: '',
                    name: '字典管理'
                }
            ],
            queryParams: {
                pageNumber: 1,
                pageSize: 10,
                totalRecord: 0,
                keyword: '',
                typeId: '',
                treeType: 0,
                delState: '0',
                sort: ''
            },
            tableKey: 0,
            tableData: [],
            tableLoading: false,
            sortOptions: [{
                label: 'ID Ascending',
                key: '+id'
            }, {
                label: 'ID Descending',
                key: '-id'
            }],
            statusOptions: ['published', 'draft', 'deleted'],
            info: {},
            ruleValidate: {
                dictCode: [
                    { required: true, message: '请输入编码', trigger: 'blur' }
                ],
                dictName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                dictTypeId: [
                    { required: true, message: '请选择类型', trigger: 'change' }
                ],
                treeType: [
                    { required: true, message: '请选择是否树状级联', trigger: 'change' }
                ],
                orderNum: [
                    { required: true, message: '请输入排序号', trigger: 'blur' }
                ],
                typeName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
            },
            editDialog: false,
            dictTypeList: [],
            typeInfo: {},
            editTypeDialog: false,
            selectOptions: [],
            treeProps: {
                value: 'dictId',
                label: 'dictName',
                children: 'children'
            }
        }
    },
    mounted() {
        this.getDictTypeList();
    },
    methods: {
        getDictTypeList: function () {
            this.apis.get('fitness/dicttype/getList')
                .then((res) => {
                    if (res.code === 1) {
                        this.dictTypeList = res.data

                        if (this.dictTypeList && this.dictTypeList.length >0) {
                            this.queryParams.typeId = this.dictTypeList[0].typeId;
                            this.queryParams.treeType = this.dictTypeList[0].treeType;
                            this.initTable();
                        }
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        cellClick: function(row) {
            this.queryParams.typeId = row.typeId;
            this.queryParams.treeType = row.treeType;
            this.refreshTable();
        },
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('fitness/dict/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 1;
            this.initTable();
        },
        sortChange(data) {
            const { prop, order } = data;
            console.log(prop, order)
            // if (prop === 'id') {
            //     this.sortByID(order)
            // }
        },
        getSortClass: function(key) {
            const sort = this.queryParams.sort;
            return sort === `+${key}` ? 'ascending' : 'descending'
        },
        toAddType: function () {
            this.typeInfo = {
                orderNum: 0,
                treeType: 0,
                canFilter: 0
            };
            this.editTypeDialog = true;
        },
        saveTypeData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "fitness/dicttype/save";
                    this.typeInfo.createUid = this.appCookies.getCookieUID();
                    this.typeInfo.createUname = this.appCookies.getCookieTNAME() ;
                    this.typeInfo.updateUid = this.appCookies.getCookieUID();
                    this.typeInfo.updateUname = this.appCookies.getCookieTNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.typeInfo)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editTypeDialog = false;
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.getDictTypeList();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEditType: function (keyId) {
            this.apis.get('fitness/dicttype/getById?typeId=' + keyId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.typeInfo = res.data;
                        this.editTypeDialog = true;
                    } else {
                        this.typeInfo = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDeleteType: function (id, delState) {

            this.$confirm('确定要删除此条记录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    typeId: id,
                    delState: delState,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieTNAME()
                };
                this.apis.postForm('fitness/dicttype/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.getDictTypeList();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        toAddChild: function (parentId) {
            this.info = {
                dictTypeId: this.queryParams.typeId,
                orderNum: 0,
                parentId: parentId
            };
            if (this.queryParams.treeType===1) {
                this.getTreeList()
            }
            this.editDialog = true;
        },
        toAdd: function () {
            this.info = {
                dictTypeId: this.queryParams.typeId,
                orderNum: 0,
                parentId: 0
            };
            if (this.queryParams.treeType===1) {
                this.getTreeList()
            }
            this.editDialog = true;
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "fitness/dict/save";
                    this.info.createUid = this.appCookies.getCookieUID();
                    this.info.createUname = this.appCookies.getCookieTNAME() ;
                    this.info.updateUid = this.appCookies.getCookieUID();
                    this.info.updateUname = this.appCookies.getCookieTNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.initTable();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (keyId) {
            this.apis.get('fitness/dict/getById?dictId=' + keyId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.info = res.data;
                        if (this.queryParams.treeType===1) {
                            this.getTreeList()
                        }
                        this.editDialog = true;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getTreeList: function () {
            this.apis.get('fitness/dict/getTreeList?typeId=' + this.queryParams.typeId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.selectOptions = res.data || [];
                    } else {
                        this.selectOptions = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDelete: function (id, delState) {

            this.$confirm('确定要删除此条记录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    dictId: id,
                    delState: delState,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieTNAME()
                };
                this.apis.postForm('fitness/dict/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
