import { render, staticRenderFns } from "./OrgCustomQuery.vue?vue&type=template&id=a32adc52&scoped=true&"
import script from "./OrgCustomQuery.vue?vue&type=script&lang=js&"
export * from "./OrgCustomQuery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a32adc52",
  null
  
)

export default component.exports