<template>
    <el-dialog
        :title="title"
        :visible.sync="uploadDialog"
        :append-to-body="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="900px"
    >
        <el-form ref="createForm" :model="uploadInfo" label-width="0" label-suffix="">
            <el-form-item label="">
                {{ uploadInfo.name }}
            </el-form-item>
            <el-row style="margin-bottom: 10px">
                <el-col :span="24">
                    <el-upload
                        class="upload-demo"
                        drag
                        :show-file-list="false"
                        :auto-upload="false"
                        :accept="accept"
                        :multiple="multiple"
                        :limit="limit"
                        action="11"
                        :on-change="fileChange"
                        :on-exceed="handleExceed"
                        :before-upload="beforeUpload">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__text">{{desc}}</div>
                    </el-upload>
                </el-col>
            </el-row>
            <el-row class="table-container" v-show="fileList.length > 0">
                <el-col>
                    <el-table
                        :data="fileList"
                        fit
                        highlight-current-row
                        style="width: 100%;"
                        ref="queryTable"
                        :indent="20"
                    >
                        <el-table-column label="文件名" prop="name" align="left" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="类型" prop="raw.type" width="150px" align="center"></el-table-column>
                        <el-table-column label="大小" prop="size" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{ (row.size / 1024).toFixed(2)}} KB
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{ row.status  | statusFilter}}
                            </template>
                        </el-table-column>
                        <el-table-column label="上传进度" width="100px" align="center">
                            <template slot-scope="{row}">
                                {{row.percentage}}%
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100px" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" @click="deleteFile(scope.$index)" :disabled="loading">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button @click="uploadDialog = false">关闭</el-button>
                <el-button type="primary" :disabled="uploadDisabled" @click="toUpload()" :loading="loading">
                    {{ loading ? '上传中' : '开始上传（' + fileList.length + '）'}}</el-button>
            </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '视频上传'
        },
        desc: {
            type: String,
            default: ''
        },
        accept: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: true
        },
        limit: {
            type: Number,
            default: 1
        },
    },
    filters: {
        statusFilter(status) {
            const statusMap = {
                0: '未上传',
                1: '上传中..',
                2: '上传完',
            };
            return statusMap[status]
        },
    },
    data() {
        return {
            loading: false,
            uploadInfo: {},
            uploadDisabled: true,
            uploadDialog: false,
            fileList: [],
            uploadProgress: 0,
            fileInfoList: [],
        };
    },
    methods: {
        open: function (uploadInfo) {
            this.uploadInfo = uploadInfo;
            this.fileList = [];
            this.fileInfoList = [];
            this.uploadDisabled = true
            this.uploadDialog = true;
            this.loading = false;
        },
        beforeUpload(file) {
            if (!file) {
                this.$message({
                    message: '请先选择需要上传的文件',
                    showClose: true, offset: 50, type: 'success'
                });
                return
            }
            // file.status = 0;
            // this.fileList.push(file)
            this.uploadDisabled = false;
            return true;
        },
        deleteFile: function (idx) {
            this.fileList.splice(idx, 1)
        },
        handleExceed(files, fileList) {
            this.$message.warning(`最多只能上传 ${this.limit} 个文件`);
        },
        fileChange: function (files, fileList) {
            console.log(fileList)
            this.fileList = fileList
            if (this.fileList.length > 0) {
                this.uploadDisabled = false;
            } else {
                this.uploadDisabled = true;
            }
        },
        toUpload: async function () {
            let that = this;
            this.fileInfoList = []
            if (this.fileList.length === 0) {
                this.$message({
                    message: '请先选择需要上传的文件',
                    showClose: true, offset: 50, type: 'success'
                });
                return
            }
            this.loading = true;
            for (let idx = 0; idx < that.fileList.length; idx++) {
                let file = that.fileList[idx]
                file.percentage = 0
                let formData = new FormData();
                formData.append('file', file.raw);
                let url = "system/file/uploadFile";
                let extConfig = {
                    onUploadProgress: (e) => {
                        // progressEvent.loaded:已上传文件大小
                        // progressEvent.total:被上传文件的总大小
                        console.log("进度：");
                        console.log(e);
                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                        //如果lengthComputable为false，就获取不到e.total和e.loaded
                        if (e.lengthComputable) {
                            let progress = Number(
                                ((e.loaded / e.total) * 90).toFixed(2)
                            );
                            // file.progress = progress;
                            // that.$forceUpdate();
                            that.$set(that.fileList[idx], 'percentage', progress)
                        }
                    },
                };
                // 保存数据
                await this.apis.postFile(url, formData, false, extConfig)
                    .then((res) => {
                        if (res.code === 1) {
                            // file.progress = 100;
                            that.$set(that.fileList[idx], 'percentage', 100)
                            // that.$forceUpdate();
                            file.savepath = res.data.savepath
                            file.webPath = res.data.webPath
                            file.fileName = res.data.fileName
                            this.fileInfoList.push({
                                fileName: res.data.fileName,
                                fileExtName: res.data.fileExtName,
                                filePath: res.data.filePath,
                                filePathUrl: res.data.filePathUrl,
                                fileSize: res.data.fileSize,
                                fileType: res.data.fileType
                            })
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 200,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }
            that.success();
        },
        success() {
            // console.log(this.fileList)
            this.$message({
                message: '上传完成',
                showClose: true, offset: 50, type: 'success'
            });
            this.$emit("callback", this.fileInfoList)
            this.uploadDialog = false;
            // this.$emit("callback", data.webPath, data.savepath)
        },
    }
}
</script>

<style scoped lang="scss">
.upload-demo {
    width: 100%;
}
::v-deep .el-upload {
    width: 100%;
}
::v-deep .el-upload-dragger{
    width:100% !important;
}
</style>
