<template>
    <el-dialog
        title="公共模板库"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        :close-on-click-modal="false">
        <el-row>
            <el-col :span="24" class="item_header"><span class="title">消息模板库</span></el-col>
            <el-col :span="24">
                <el-button v-for="(item, idx) in tidList" :key="idx" size="mini" @click="toView(item.tid)">{{item.name}}</el-button>
            </el-col>
        </el-row>
        <el-row
            v-loading="mainLoading"
            :element-loading-text="mainLoadingText">
            <el-col :span="24" style="padding: 0 40px 40px 40px">
                <el-table
                    :data="tableData"
                    stripe
                    style="width: 100%">
                    <el-table-column
                        prop="tid"
                        label="模版标题 id"
                        width="100">
                    </el-table-column>
                    <el-table-column
                        prop="title"
                        label="模版标题">
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="模版类型"
                        width="150">
                        <template slot-scope="{row}">
                            {{row.type === 2 ? '一次性订阅' : '长期订阅'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" align="center" fixed="right" class-name="small-padding fixed-width">
                        <template slot-scope="{row}">
                            <el-button type="text" size="mini" icon="el-icon-view" @click="toView(row.tid)">选用</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                            :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                            @pagination="initTable"/>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>
        </span>
        <TmplateAddDialog ref="tmplateAddDialog"></TmplateAddDialog>
    </el-dialog>
</template>

<script>
import Pagination from '@/components/Pagination';
import TmplateAddDialog from './TmplateAddDialog'
export default {
    components: {
        Pagination,
        TmplateAddDialog
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在查询中，请稍等',
            editDialog: false,
            appId: '',
            info: {
            },
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                appId: '',
                keyword: '',
                catIdList: []
            },
            tableData: [],
            tableLoading: false,
            checkList: [],
            tidList: [
                {
                    name: '报名通知',
                    tid: 181,
                    remarks: '报名审核确认结果通知'
                },
                {
                    name: '上课提醒',
                    tid: 186,
                    remarks: '课程需学习通知提醒'
                },
                {
                    name: '考前提醒',
                    tid: 17611,
                    remarks: '考前考试地点通知'
                },
                {
                    name: '考试通知',
                    tid: 16645,
                    remarks: '考试时间地点信息通知'
                }
            ]
        }
    },
    mounted() {
    },
    methods: {
        open: function (appId, catIdList) {
            this.editDialog = true;
            this.appId = appId;
            this.queryParams.appId = appId;
            this.queryParams.catIdList = catIdList;
            this.info = {}
            this.refreshTable()
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        //获取列表
        initTable: function () {
            this.mainLoading = true
            this.tableLoading = true;
            this.apis.postJson('/wx/open/mini/template/getPubTemplateTitleList', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data.data;
                        this.queryParams.totalRecord = res.data.count;
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                    this.mainLoading = false
                }).catch((err) => {
                this.tableLoading = false;
                this.mainLoading = false
                console.log(err)
            });
        },
        toView(tid) {

            let tids = this.tidList.filter(item => {
                return item.tid === tid
            })
            let remarks = ''
            if (tids) {
                remarks = tids[0].remarks
            }
            this.$refs.tmplateAddDialog.open(this.appId, tid, remarks);
            // let data = {
            //     tid: tid,
            //     appId: this.appId
            // }
            // this.apis.postForm('/wx/open/mini/template/getPubTemplateKeyWordsById', data)
            //     .then((res) => {
            //         if (res.code === 1) {
            //             this.info = res.data || {};
            //         } else {
            //             console.log('发生错误:' + res.msg);
            //         }
            //     }).catch((err) => {
            //     console.log(err)
            // });
        },
        handleCheckChange(kid) {
        }
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
