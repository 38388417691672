var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"imgWaterFall",staticClass:"img-water-fall",attrs:{"id":"img-water-fall"}},[_c('div',{staticClass:"content-box",style:({
    top: _vm.contentTop + 'px'
  })},_vm._l((_vm.innerListData),function(item,index){return _c('div',{key:index,staticClass:"img-box",style:({
      width: _vm.boxWidth + 'px',
      height: _vm.boxWidth / item.ratio + 'px',
      top: item.top + 'px',
      left: item.left + 'px'
    }),on:{"click":function($event){$event.stopPropagation();return _vm.selectImg(item, index)}}},[_c('transition',{attrs:{"name":"fade"}},[_c('img',{staticClass:"img",style:({
          width: _vm.boxWidth - 10 + 'px',
          height: _vm.boxWidth / item.ratio - 10 + 'px'
        }),attrs:{"src":item.value.url,"title":item.value.name}})]),(item.value.canDel)?_c('div',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteImg(item, index)}}},[_c('div',{staticClass:"bg"}),_c('i',{staticClass:"delete-icon iconfont icon-delete-x"})]):_vm._e()],1)}),0),_c('div',{ref:"scrollBox",staticClass:"scroll-box",attrs:{"id":"scroll-box"}},[_c('div',{ref:"scrollBar",staticClass:"scroll-bar",style:({
    height: _vm.scrollBarStyle.height + 'px',
    top: _vm.scrollBarStyle.top + 'px',
    display: _vm.scrollBarStyle.display
  }),attrs:{"id":"scroll-bar"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }