<template>
    <el-dialog
        title="修改头像"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" >
            <el-col :span="24">
                <el-form :model="info" ref="editForm" label-width="140px" label-suffix=":">
                    <el-form-item  label="上传头像" >
                        <div>新头像不允许涉及政治敏感与色情;</div>
                        <div>图片格式必须为：png,bmp,jpeg,jpg,gif；不可大于2M；建议使用png格式图片，以保持最佳效果；建议图片尺寸为144px*144px</div>
                        <MiniUploadImg @callback="imgCallback" :appId="info.appId"
                                       :uploadPath="'wx/open/mini/mgr/uploadMiniMedia'"
                                       :width="150" :height="150"></MiniUploadImg>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>
            <el-button type="primary"  @click="setHeadImage">确 定</el-button>
<!--            v-if="info.canApply"-->
        </span>
    </el-dialog>
</template>

<script>
import MiniUploadImg from "@c/upload/MiniUploadImg";
export default {
    components: {
        MiniUploadImg
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            editDialog: false,
            appId: '',
            info: {
                appId: '',
                headImgMediaId: '',
                x1: 0,
                y1: 0,
                x2: 1,
                y2: 1,
            }
        }
    },
    mounted() {
    },
    methods: {
        open: function (appId) {
            this.editDialog = true;
            this.info.appId = appId;
        },
        imgCallback: function (mediaId, type, index) {
            this.info.headImgMediaId = mediaId;
        },
        setHeadImage: function () {
            this.apis.postForm('wx/open/mini/mgr/setHeadImage', this.info)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.$emit('callback')
                        this.editDialog = false;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
