<template>
    <el-dialog
        title="小程序名称修改"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" >
            <el-col :span="24">
                <el-form :model="info" ref="editForm" label-width="140px" label-suffix=":">
                    <el-form-item label="小程序名称">
                        <el-input v-model="info.nickname" placeholder="小程序名称" @blur="checkNickName"></el-input>
                        <div style="color: red">1、帐号名称长度为4-30个字符，一个中文字等于2个字符。</div>
                        <div style="color: red">2、小程序发布前，可修改2次名称。当前还能修改2次。发布后，必须通过微信认证流程改名。</div>
                        <div style="color: red">3、只有API创建的小程序可以修改名称</div>
                    </el-form-item>
                    <el-form-item label="">
                        {{checkResult.msg}}
                    </el-form-item>
                    <el-form-item label="身份证照片">
                        <div>上传原件或复印件，复印件务必加盖公司公章，文件格式为jpg、jpeg、bmp、gif或png，文件大小不超过10M，可拼图上传。</div>
                        <MiniUploadImg @callback="idCardCallback" :appId="info.appId"
                                       :width="150" :height="150"></MiniUploadImg>
                    </el-form-item>
                    <el-form-item label="营业执照">
                        <div>上传原件或复印件，复印件务必加盖公司公章，文件格式为jpg、jpeg、bmp、gif或png，文件大小不超过10M，可拼图上传。</div>
                        <MiniUploadImg @callback="licenseCallback" :appId="info.appId"
                                       :width="150" :height="150"></MiniUploadImg>
                    </el-form-item>
                    <el-form-item label="办学许可证">
                        <div>上传办学许可证，文件格式为jpg、jpeg、bmp、gif或png，文件大小不超过10M，可拼图上传。</div>
                        <MiniUploadImg @callback="banxxkCallback" :appId="info.appId"
                                       :width="150" :height="150"></MiniUploadImg>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>
            <el-button type="primary" v-if="checkResult.data.errcode==0"  @click="setnickname">确 定</el-button>
<!--            v-if="info.canApply"-->
        </span>
    </el-dialog>
</template>

<script>
import MiniUploadImg from "@c/upload/MiniUploadImg";
export default {
    components: {
        MiniUploadImg
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            editDialog: false,
            appId: '',
            allCategories: [],
            value: '',
            info: {
                nickname: '',
                appId: '',
                idCard: '',
                license: '',
                namingOtherStuff1: ''
            },
            checkResult: {
                code: 0,
                msg: '',
                data: {
                    errcode: '-1',
                    errmsg: '',
                    hitCondition: '',
                    success: '',
                    wording: ''
                }
            }
        }
    },
    mounted() {
    },
    methods: {
        open: function (appId) {
            this.editDialog = true;
            this.appId = appId;
            this.info = {
                appId: appId,
                nickname: ''
            }
        },
        checkNickName() {
            this.apis.postForm('wx/open/mini/mgr/checkNickName', this.info)
                .then((res) => {
                        if (res.code === 1) {
                            this.checkResult = res
                        } else {
                            this.checkResult = res
                            this.checkResult.data = {
                                errcode: '-1',
                                errmsg: '',
                                hitCondition: '',
                                success: '',
                                wording: ''
                            }
                        }

                }).catch(err => {
                console.log(err);
            });
        },
        idCardCallback: function (mediaId, type, index) {
            this.info.idCard = mediaId;
        },
        licenseCallback: function (mediaId, type, index) {
            this.info.license = mediaId;
        },
        banxxkCallback: function (mediaId, type, index) {
            this.info.namingOtherStuff1 = mediaId;
        },
        setnickname: function () {
            this.apis.postForm('wx/open/mini/mgr/setnickname', this.info)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.$emit('callback')
                        this.editDialog = false;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
