<template>
    <el-drawer
        title="考试详情"
        :visible.sync="classExamDialog"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        size="80%"
    >
        <div class="demo-drawer__content"
             v-loading="mainLoading"
             :element-loading-text="mainLoadingText">
            <el-row>
                <el-col :span="24">
                    <el-descriptions title="考试信息">
                        <el-descriptions-item label="机构">{{examInfo.companyName}}</el-descriptions-item>
                        <el-descriptions-item label="工种">{{examInfo.gnvqName}}</el-descriptions-item>
                        <el-descriptions-item label="等级">{{examInfo.levelName}}</el-descriptions-item>
                        <el-descriptions-item label="班级">
                            {{examInfo.yearNum}}年第{{examInfo.classNum}}期
                        </el-descriptions-item>
                        <el-descriptions-item label="类型">
                            {{examInfo.examType | examTypeFilter}}
                        </el-descriptions-item>
                        <el-descriptions-item label="方式">
                            {{examInfo.examWay | examWayFilter}}
                        </el-descriptions-item>
<!--                        <el-descriptions-item label="考试时间">-->
<!--                            {{ examInfo.startDate}} {{ examInfo.startTime}} ~ {{ examInfo.endDate}} {{ examInfo.endTime}}-->
<!--                        </el-descriptions-item>-->
                    </el-descriptions>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-tabs>
                        <el-tab-pane label="考试明细">
                            <el-row class="query-container">
                                <el-col :span="24">
                                    <el-tabs v-model="schedTabName" type="card" @tab-click="handleClickSchedTab">
                                        <el-tab-pane label="全部" name="0"></el-tab-pane>
                                        <el-tab-pane v-for="(item, idx) in examInfo.schedList" :key="idx"
                                            :label="'场次' + (idx+1)" :name="item.schedId+''">
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                                <el-col :span="24">
                                    <el-form :inline="true" label-suffix=":">
                                        <el-form-item label="关键字">
                                            <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:150px"></el-input>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                        </el-form-item>
                                        <el-form-item v-show="currentSched.startDate" label="考试时间">
                                            {{currentSched.startDate}} {{currentSched.startTime}} ~ {{currentSched.endTime}}
                                        </el-form-item>
                                        <el-form-item v-show="currentSched.examAddress" label="考试地点">
                                            {{currentSched.examAddress}}
                                        </el-form-item>
<!--                                        <el-popover v-if="queryParams.schedId"-->
<!--                                            placement="bottom"-->
<!--                                            width="400"-->
<!--                                            trigger="click" @show="getSignInQrUrl">-->
<!--                                            <div style="width: 100%; height: 300px; padding: 30px 18px; display: flex; flex-direction: column; justify-content: space-between; align-items: center">-->
<!--                                                <img :src="signInQrUrl" alt="" style="width: 180px; height: 180px;">-->
<!--                                                <p style="font-size: 13px; color: #666666">微信扫码，进行考试签到</p>-->
<!--                                                <el-button type="text" @click="downloadSignPdf">下载签到码</el-button>-->
<!--                                            </div>-->
<!--                                            <el-button type="primary" plain icon="el-icon-share" round slot="reference">签到码</el-button>-->
<!--                                        </el-popover>-->
                                        <el-button-group style="float: right">
                                            <el-button v-if="queryParams.schedId" class="filter-item" type="success" round @click="toSendExamNotic('REMAIN')">考试提醒</el-button>
                                            <el-button v-else class="filter-item" type="success" round @click="toSendExamNotic('NOTICE')">考试预约通知</el-button>
                                            <el-dropdown v-if="hasPerm('enroll:export')"  @command="dropdownOperate2">
                                                <el-button icon="el-icon-download" type="success" round>
                                                    信息导出<i class="el-icon-arrow-down el-icon--right"></i>
                                                </el-button>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item :command="dropdownValue2('toExportOnlineScore', '')" icon="el-icon-download">在线考试成绩</el-dropdown-item>
                                                    <el-dropdown-item :command="dropdownValue2('toExportOnlinePdf', '')" icon="el-icon-download">在线考试试卷</el-dropdown-item>

                                                    <el-dropdown-item divided :command="dropdownValue2('export', '')" icon="el-icon-download">导出报名信息(Excel)</el-dropdown-item>
                                                    <el-dropdown-item :command="dropdownValue2('exportpdf', '')" icon="el-icon-download">导出报名资料(PDF)</el-dropdown-item>
                                                    <el-dropdown-item :command="dropdownValue2('exportTmpl', '')" icon="el-icon-download">导出报名资料(模板)</el-dropdown-item>
                                                    <el-dropdown-item :command="dropdownValue2('exportCertImg', '')" icon="el-icon-download">导出证件照片</el-dropdown-item>
                                                    <el-dropdown-item :command="dropdownValue2('exportEnrollImg', '')" icon="el-icon-download">导出报名照片(高级)</el-dropdown-item>
                                                    <el-dropdown-item :command="dropdownValue2('exportIdcard', '')" icon="el-icon-download">导出身份证</el-dropdown-item>
                                                    <el-dropdown-item :command="dropdownValue2('exportBankno', '')" icon="el-icon-download">导出银行卡</el-dropdown-item>
                                                    <el-dropdown-item :command="dropdownValue2('exportJieyeImg', '')" icon="el-icon-download">导出结业证书</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>

                                        </el-button-group>
                                    </el-form>
                                </el-col>
                                <el-col>
                                    <el-form :inline="true" label-suffix=":">
                                        <el-form-item label="预约状态">
                                            <el-select v-model="queryParams.hasSubs" placeholder="预约状态" style="width:100px">
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="已预约" :value="1"></el-option>
                                                <el-option label="未预约" :value="0"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="签到状态">
                                            <el-select v-model="queryParams.hasSignin" placeholder="签到状态" style="width:100px">
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="已签到" :value="1"></el-option>
                                                <el-option label="未签到" :value="0"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="及格状态">
                                            <el-select v-model="queryParams.hasPass" placeholder="及格状态" style="width:100px">
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="及格" :value="1"></el-option>
                                                <el-option label="不及格" :value="0"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="是否有效">
                                            <el-select v-model="queryParams.scoreStatus" placeholder="是否有效" style="width:100px">
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="未确认" :value="0"></el-option>
                                                <el-option label="有效" :value="1"></el-option>
                                                <el-option label="无效" :value="2"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="认证状态">
                                            <el-select v-model="queryParams.faceChecked" placeholder="认证状态" style="width:100px">
                                                <el-option label="全部" value=""></el-option>
                                                <el-option label="已认证" :value="1"></el-option>
                                                <el-option label="未认证" :value="0"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32" class="table-container">
                                <el-col>
                                    <el-table
                                        v-loading="tableLoading"
                                        :data="tableData"
                                        fit
                                        highlight-current-row
                                        style="width: 100%;"
                                        ref="selectTable"
                                        @selection-change="handleSelectionChange"
                                        :indent="20"
                                    >
                                        <el-table-column
                                            type="selection"
                                            width="55">
                                        </el-table-column>
                                        <el-table-column label="姓名" prop="trueName" align="left"></el-table-column>
                                        <el-table-column label="身份证号" prop="idCard" width="150px" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                        <el-table-column label="联系电话" prop="phone" width="110px" align="center"></el-table-column>
                                        <el-table-column label="预约状态" prop="faceChecked" width="80px" align="center">
                                            <template slot-scope="{ row}">
                                                <template v-if="examInfo.subscribe===1">
                                                    <el-tag size="small" v-if="row.userSchedId">已预约</el-tag>
                                                    <el-button v-else size="mini" type="text" @click="toSubs(row.classExamId, row.userId)">未预约</el-button>
                                                </template>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="签到状态" prop="signinId" width="80px" align="center">
                                            <template slot-scope="{ row}">
                                                <el-tag size="small" v-if="row.signinId">已签到</el-tag>
                                                <span v-else>未签到</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="认证状态" prop="faceChecked" width="140px" align="center">
                                            <template slot-scope="{ row}">
                                                <template v-if="row.mstId">
                                                    <el-tag size="small" v-if="row.faceChecked===1">已认证</el-tag>
                                                    <el-tag size="small" v-else type="danger">未认证</el-tag>
                                                    <br>
                                                    <span v-if="row.faceChecked===1">{{row.faceTime}}</span>
                                                </template>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="退出次数" prop="exitTimes" align="center">
                                            <template slot-scope="{ row}">
                                                <template v-if="row.mstId">
                                                    <span>{{row.exitTimes}}</span>
                                                </template>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="用时(分钟)" align="center"  width="120">
                                            <template slot-scope="{row}">
                                                <span v-if="row.usedTime">
                                                    {{(row.usedTime / 60).toFixed(0) }}
                                                </span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="得分" align="center" width="80px">
                                            <template slot-scope="{row}">
                                                <span v-if="row.resultScore">
                                                    {{row.resultScore }}
                                                </span>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="是否及格" prop="hasPass" width="80px" align="center">
                                            <template slot-scope="{ row}">
                                                <el-tag size="small" v-if="row.hasPass===1">是</el-tag>
                                                <el-tag size="small" v-else-if="row.hasPass===2" type="danger">否</el-tag>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="是否有效" prop="faceChecked" width="80px" align="center">
                                            <template slot-scope="{ row}">
                                                <el-tag size="small" v-if="row.scoreStatus===1">有效</el-tag>
                                                <el-tag size="small" v-else-if="row.scoreStatus===2" type="danger">无效</el-tag>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="提交状态" prop="dataStatus" width="80px" align="center">
                                            <template slot-scope="{ row}">
                                                <el-tag size="small" v-if="row.dataStatus===1">已提交</el-tag>
                                                <el-tag size="small" v-else-if="row.dataStatus===0" type="danger">未提交</el-tag>
                                                <span v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                                            <template slot-scope="{row}">
                                                <el-button v-if="row.mstId" type="text" size="mini" @click="showMatchStatus(row.mstId)" style="margin-right: 6px">
                                                    监控对比
                                                </el-button>
                                                <el-popover
                                                    v-if="row.mstId && row.dataStatus===1 && row.scoreStatus===0"
                                                    placement="top"
                                                    width="200">
                                                    <p>请确认成绩是否有效？</p>
                                                    <div style="text-align: right; margin: 0">
                                                        <el-button size="mini" type="text" @click="updScoreStatus(row.mstId, 2)">无效</el-button>
                                                        <el-button type="primary" size="mini" @click="updScoreStatus(row.mstId, 1)">有效</el-button>
                                                    </div>
                                                    <el-button slot="reference" type="text" size="mini" @click="popoverVisible=true">
                                                        成绩有效
                                                    </el-button>
                                                </el-popover>
                                                <el-dropdown size="mini" @command="dropdownOperate">
                                                                                    <span class="el-dropdown-link">
                                                                                        更多<i
                                                                                        class="el-icon-arrow-down el-icon--right"></i>
                                                                                      </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item v-if="!row.signinId"
                                                                          :command="dropdownValue('signin', row)">手工签到
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-if="row.faceChecked===0"
                                                                          :command="dropdownValue('faceChecked', row)">手工认证
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-if="row.mstId"
                                                                          :command="dropdownValue('cancelSubmit', row)">重置提交状态
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-if="row.exitTimes>0"
                                                                          :command="dropdownValue('exitTimes', row)">重置退出次数
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-if="row.userSchedId && !row.signinId" :command="dropdownValue('cancelSubs', row)">取消预约
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-if="!row.mstId" :command="dropdownValue('genPaper', row)">生成空白试卷
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-if="row.mstId" :command="dropdownValue('downloadPaperPdf', row)">下载试卷
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                                :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                                @pagination="initTable"/>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="预约码" v-if="examInfo.subscribe===1">
                            <el-row>
                                <el-col :span="24">
                                    <div class="qr">
                                        <img :src="subscribeQrUrl">
                                        <p style="font-size: 13px; color: #666666">微信扫码，进行考试预约</p>
                                        <el-button type="text" @click="downloadSubsPdf">下载预约码</el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
<!--                        <el-tab-pane label="签到码">-->
<!--                            <el-row>-->
<!--                                <el-col :span="24">-->
<!--                                    <div class="qr">-->
<!--                                        <img :src="signInQrUrl">-->
<!--                                        <p style="font-size: 13px; color: #666666">微信扫码，进行考试签到</p>-->
<!--                                    </div>-->
<!--                                </el-col>-->
<!--                            </el-row>-->
<!--                        </el-tab-pane>-->
                    </el-tabs>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="classExamDialog = false">关 闭</el-button>
        </div>

        <el-dialog
            title="监控照片对比"
            :visible.sync="matchModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="80%">
            <el-table
                :data="examPhotoList"
                fit
                highlight-current-row
                style="width: 100%;"
                ref="selectTable2"
                :indent="20"
            >
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column label="时间" align="center">
                    <template slot-scope="{row}">
                        <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="认证照片" align="center">
                    <template slot-scope="{ row }">
                        <el-avatar v-if="row.faceImgUrl" shape="square" :size="200" :src="row.faceImgUrl"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column label="监控照片" align="center">
                    <template slot-scope="{ row }">
                        <el-avatar v-if="row.photoImgUrl" shape="square" :size="200" :src="row.photoImgUrl"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column label="匹配结果" align="center" width="160" fixed="right" class-name="small-padding fixed-width">
                    <template slot-scope="{row}">
                        <el-tag :type="row.matchStatus | colorFilter">
                            {{ row.matchStatus  | matchStatusFilter}}
                        </el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="matchModal = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="导出报名信息"
            :visible.sync="exportDialog"
            :destroy-on-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
            width="600px">
            <el-form ref="importEditForm" :model="exportTmplInfo" label-width="150px" label-suffix=":">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="报名信息">

                            <el-checkbox
                                v-for="(item, idx) in exportFieldList.normalFieldList" :key="idx"
                                v-model="item.selected">{{item.fieldName}}</el-checkbox>

                        </el-form-item>
                        <el-form-item label="扩展信息">
                            <el-checkbox
                                v-for="(item, idx) in exportFieldList.defFieldList" :key="idx"
                                v-model="item.selected">{{item.fieldName}}</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportDialog = false">取消</el-button>
                <el-button type="primary" @click="submitExport()">确认导出</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="按模板导出报名资料"
            :visible.sync="exportTmplDialog"
            :destroy-on-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
            width="600px">
            <el-form ref="importEditForm" :model="exportTmplInfo" label-width="150px"  label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="模板" prop="tmplId">
                            <el-select v-model="exportTmplInfo.tmplId" filterable placeholder="请选择模板" required>
                                <el-option value="">请选择</el-option>
                                <el-option v-for="item in exportTmplList" :value="item.tmplId" :label="item.tmplName" :key="item.tmplId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="导出方式" prop="exportType" required>
                            <el-radio-group v-model="exportTmplInfo.exportType">
                                <el-radio :label="1">合并导出（多人合并到一个文件中）</el-radio>
                                <el-radio :label="2">独立导出（每人一个文件）</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportTmplDialog = false">取消</el-button>
                <el-button type="primary" @click="exportTmpl('importEditForm')">确认导出</el-button>
            </span>
        </el-dialog>
        <!-- 【导出证件照片】对话框-->
        <el-dialog
            title="导出证件照片"
            :visible.sync="exportImgModal"
            :destroy-on-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="exportImgInfo" label-width="150px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="命名方式" prop="sex">
                            <el-radio-group v-model="exportImgInfo.nameType">
                                <el-radio :label="0">身份证号</el-radio>
                                <el-radio :label="1">身份证号姓名</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="证件照图片名称" prop="sex">
                            <el-radio-group v-model="exportImgInfo.appendA">
                                <el-radio :label="1">带A</el-radio>
                                <el-radio :label="0">不带A</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportImgModal = false">取消</el-button>
                <el-button type="primary" @click="toExportImg()">确认导出</el-button>
            </span>
        </el-dialog>
        <!-- 【导出证件照片】对话框-->
        <el-dialog
            title="导出报名资料照片"
            :visible.sync="exportEnrollImgModal"
            :destroy-on-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="exportImgInfo" label-width="150px" label-suffix=":">
                <el-form-item label="图片类型" prop="sex">
                    <el-radio-group v-model="exportImgInfo.exportImgType" @change="getCertNameList">
                        <el-radio label="certHeadImg">蓝底照片</el-radio>
                        <el-radio label="idcardImgFront">身份证正面</el-radio>
                        <el-radio label="idcardImgBack">身份证反面</el-radio>
                        <el-radio label="workImg">工龄证明</el-radio>
                        <el-radio label="eduImg">学历证明</el-radio>
                        <el-radio label="certImg">证书照片</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="证书类型" v-show="this.exportImgInfo.exportImgType==='certImg'">
                    <el-select v-model="exportImgInfo.exportCertName" placeholder="请选择证书类型">
                        <el-option value="">请选择学历</el-option>
                        <el-option v-for="item in certNameList" :value="item.dictName" :label="item.dictName"  :key="item.dictName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="后缀字符">
                    <el-input v-model="exportImgInfo.exportImgSuffix" placeholder="请输入后缀"></el-input>
                    <p>例如：文件名为 <span>372123138312120123</span><span style="color: red">-1</span>.jpg， 则输入<span style="color: red">-1</span> </p>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportEnrollImgModal = false">取消</el-button>
                <el-button type="primary" @click="toExportEnrollImg()">确认导出</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="考试通知/提醒"
            :visible.sync="examRemindModal"
            :destroy-on-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="examRemindInfo" ref="remindForm" label-width="120px" label-suffix=":">
                <el-form-item label="提醒内容" prop="resean">
                    <el-input v-model="examRemindInfo.content" type="textarea" :autosize="{ minRows: 4}" maxlength="50"
                              show-word-limit placeholder="请输入提醒内容（最多50个汉字）"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="examRemindModal = false">关 闭</el-button>
                <el-button type="primary" @click="sendExamNoticeRemain">发 送</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="选择预约场次"
            :visible.sync="subsDialog"
            :destroy-on-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
            width="800px">
            <div class="sched-box">
                <div v-for="(item, idx) in schedData.schedList" :key="idx" @click="toSelect(item.schedId, item.openStatus)"
                     :class="item.schedId === userSubs.schedId ? 'sched selected' : 'sched'">
                    <div :class="item.schedId === userSubs.schedId ? 'address colors' : 'address'">{{item.examAddress}}</div>
                    <div :class="item.schedId === userSubs.schedId ? 'date colors' : 'date'">{{item.startDate}} {{item.startTime}} ~ </div>
                    <!-- <view class="date">至</view> -->
                    <div :class="item.schedId === userSubs.schedId ? 'date colors' : 'date'">{{item.endDate}}  {{item.endTime}}</div>
                    <div v-if="item.openStatus===0" class="num">可预约人数：{{item.maxSubNum - item.hasSubNum}}  <span>（{{item.hasSubNum}}/{{item.maxSubNum}}）</span></div>
                    <div v-else class="num">已结束</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="subsDialog = false">取消</el-button>
                <el-button type="primary" @click="saveSubs">确 定</el-button>
            </span>
        </el-dialog>
    </el-drawer>
</template>

<script>
import Pagination from '@/components/Pagination';
import { getDate } from "@/utils/utils";
export default {
    components: {
        Pagination
    },
    filters: {
        examTypeFilter(status) {
            const statusMap = {
                1: '理论考试',
                2: '实操考试',
            };
            return statusMap[status]
        },
        examWayFilter(status) {
            const statusMap = {
                1: '线上',
                2: '线下',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                2: 'danger',
                1: 'success'
            };
            return statusMap[status]
        },
        matchStatusFilter(status) {
            const statusMap = {
                0: '未比对',
                1: '比对成功',
                2: '比对失败',
            };
            return statusMap[status]
        },
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在导出数据，请稍等',
            classExamDialog: false,
            examInfo: {},
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                typeCode: '',
                keyword: '',
                deleteStatus: '0',
                dataStatus: '',
                confirmStatus: '1',
                payWay: '',
                payStatus: '',
                orgId: this.appCookies.getCookieTOID(),
                companyId: this.appCookies.getCookieOID(),
                gnvqCatId: '',
                gnvqId: '',
                levelId: '',
                identifyStatus: '',
                classType: '',
                orderIds: [],
                deptName: '',
                userId: this.appCookies.getCookieUID(),
                date: getDate(),
                classId: '',
                classExamId: '',
                schedId: '',
                hasPass: '',
                scoreStatus: '',
                faceChecked: '',
                hasSubs: '',
                hasSignin: '',
            },
            tableData: [],
            tableLoading: false,
            multipleSelection: [],
            matchModal: false,
            examPhotoList: [],
            signInQrUrl: '',
            subscribeQrUrl: '',
            schedTabName: '0',
            currentSched: {},
            popoverVisible: false,
            exportDialog: false,
            exportTmplInfo: {
                tmplId: '',
                exportType: 1
            },
            exportFieldList: {
                normalFieldList: [],
                defFieldList: []
            },
            exportTmplDialog: false,
            exportTmplList: [],
            exportImgModal: false,
            exportImgInfo: {
                appendA: 1,
                nameType: 0,
                exportImgType: '',
                exportCertName: '',
                exportImgSuffix: '',
            },
            exportEnrollImgModal: false,
            certNameList: [],
            examRemindModal: false,
            examRemindInfo: {
                type: '',
                content: ''
            },
            subsDialog: false,
            schedData: {},
            userSubs: {
                classExamId: '',
                schedId: ''
            }
        }
    },
    methods: {
        handleClickSchedTab(tab, event) {
            console.log(tab, event);
            if (tab.name!=="0") {
                this.getSchedInfo(tab.name)
                this.queryParams.schedId = tab.name
            } else {
                this.currentSched = {}
                this.queryParams.schedId = ''
            }
            this.refreshTable()
        },
        init: function (classId, classExamId) {
            this.queryParams.schedId = '';
            this.schedTabName = '0';
            this.currentSched = {};
            this.classExamDialog = true;
            this.queryParams.classId = classId;
            this.queryParams.classExamId = classExamId;
            this.getDetail(classExamId);
            this.refreshTable()
            // this.getSignInQrUrl()
            this.subscribeQrUrl = null;
            this.signInQrUrl = null;
            this.getSubscribeQrUrl()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        getDetail: function () {
            this.apis.get('course/class/exam/mst/getDetail?classExamId=' + this.queryParams.classExamId)
                .then((res) => {
                    if (res.code === 1) {
                        this.examInfo = res.data || {};
                    } else {
                        this.examInfo = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getSchedInfo: function (schedId) {
            this.apis.get('course/class/exam/mst/getSchedById?schedId=' + schedId)
                .then((res) => {
                    if (res.code === 1) {
                        this.currentSched = res.data || {};
                    } else {
                        this.currentSched = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('user/online/exam/mst/queryByClass', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        showMatchStatus: function (mstId) {
            this.apis.get('user/online/photo/getListByMstId?mstId=' + mstId)
                .then((res) => {
                    if (res.code === 1) {
                        this.examPhotoList = res.data || [];
                    } else {
                        this.examPhotoList = []
                    }
                    this.matchModal = true;
                }).catch(err => {
                console.log(err);
            });
        },
        toExportOnlineScore: function () {
            this.queryParams.orderIds = [];
            let orderIds = [];
            this.multipleSelection.forEach(function (item) {
                orderIds.push(item.orderId)
            });
            this.queryParams.orderIds = orderIds;

            this.mainLoading = true;
            this.mainLoadingText = '正在导出数据，请稍等';
            this.apis.axios()({
                method: 'post',
                url: 'edumgr/exportOnlineScore',
                data: this.$http.adornParams(this.queryParams),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '导出数据失败',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    this.mainLoading = false;
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '考试成绩.xlsx')
                this.mainLoading = false;
            }).catch(err => {
                this.mainLoading = false;
                console.log(err);
            });
        },
        toExportOnlinePdf: function () {
            this.queryParams.orderIds = [];
            let orderIds = [];
            this.multipleSelection.forEach(function (item) {
                orderIds.push(item.orderId)
            });
            this.queryParams.orderIds = orderIds;
            this.mainLoading = true;
            this.mainLoadingText = '正在创建导出任务，请稍等';

            this.apis.postJson('edumgr/exportOnlineScorePdf', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                console.log(err);
            });

            // this.apis.axios()({
            //     method: 'post',
            //     url: 'edumgr/exportOnlineScorePdf',
            //     data: this.$http.adornParams(this.queryParams),
            //     'responseType': 'blob'
            // }, false, 'json').then((res) => {
            //     if (res.type === 'application/json') {
            //         this.$message({
            //             message: '导出数据失败',
            //             showClose: true,
            //             offset: 200,
            //             type: 'error'
            //         });
            //         this.mainLoading = false;
            //         return false
            //     }
            //     let date = new Date();
            //     let year = date.getFullYear();
            //     let timestamp = date.valueOf();
            //     this.downFile(res, year.toString() + timestamp.toString() + '考试试卷.zip')
            //     this.mainLoading = false;
            // }).catch(err => {
            //     this.mainLoading = false;
            //     console.log(err);
            // });
        },
        toGenPage: function (userId, classExamId, classId) {
            this.mainLoading = true;
            this.mainLoadingText = '正在处理中，请稍等';
            let data = {
                userId: userId,
                classExamId: classExamId,
                classId: classId
            }
            this.apis.postForm('user/online/exam/genExamPaper', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.initTable();
                        this.$message({
                            message: '操作成功',
                            showClose: true,
                            offset: 200,
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 50,
                            type: 'error'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                this.mainLoading = false;
                console.log(err);
            });
        },
        exportOnlineSinglePdf: function (mstId, userId, classExamId, classId, orderId) {
            let data = {
                mstId: mstId,
                userId: userId,
                classExamId: classExamId,
                classId: classId,
                orderId: orderId
            }
            this.mainLoading = true;
            this.mainLoadingText = '正在导出数据，请稍等';
            this.apis.axios()({
                method: 'post',
                url: 'edumgr/exportOnlineSinglePdf',
                data: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '导出数据失败',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    this.mainLoading = false;
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '考试试卷.zip')
                this.mainLoading = false;
            }).catch(err => {
                this.mainLoading = false;
                console.log(err);
            });
        },
        getSubscribeQrUrl: function () {
            if (!this.subscribeQrUrl) {
                this.subscribeQrUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=YUYUE&bizId=' + this.queryParams.classExamId + "&tenantId=" + this.appCookies.getTenantId();
            }
        },
        getSignInQrUrl: function () {
            if (this.queryParams.schedId) {
                this.signInQrUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=QIANDAO&bizId=' + this.queryParams.classExamId + '&schedId=' + this.queryParams.schedId + "&tenantId=" + this.appCookies.getTenantId();
            } else {
                this.signInQrUrl = ''
            }
        },
        toSignin: function (userId, classExamId) {
            let schedId = ''
            if (this.examInfo.subscribe===1) {
                schedId = this.queryParams.schedId
            } else {
                schedId = this.examInfo.schedList[0].schedId
            }
            if (!schedId) {
                this.$message({
                    message: '请选择考试场次',
                    showClose: true,
                    offset: 50,
                    type: 'error'
                });
                return
            }

            let title = '确定要人工签到？';
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    userId: userId,
                    classExamId: classExamId,
                    schedId: schedId,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postJson('user/online/exam/signin/save', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        updFaceChecked: function (mstId) {
            let title = '确定要人工认证通过？';
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    mstId: mstId,
                    faceChecked: 1,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('user/online/exam/updFaceChecked', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        updScoreStatus: function (mstId, scoreStatus) {
            let title = '确定成绩有效？';
            if (scoreStatus===2) {
                title = '确定成绩无效？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    mstId: mstId,
                    scoreStatus: scoreStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('user/online/exam/mst/updScoreStatus', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.popoverVisible = false;
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        updDataStatus: function (mstId, dataStatus) {
            let title = '确定取消提交状态？';
            if (dataStatus === 0) {
                title = '确定设置为已提交？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    mstId: mstId,
                    dataStatus: dataStatus === 1 ? 0 : 1,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('user/online/exam/mst/updDataStatus', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        resetExitTimes: function (mstId, exitTimes) {
            let title = '确定重置退出次数？';
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    mstId: mstId,
                    exitTimes: exitTimes,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('user/online/exam/mst/resetExitTimes', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        toSubs: function(classExamId, userId) {
            this.userSubs.classExamId = classExamId;
            this.userSubs.userId = userId
            let params = {
                classExamId: classExamId,
                userId: userId
            }
            this.apis.get('course/class/exam/mst/getDetail', params, false, true)
                .then((res) => {
                    if (res.code === 1) {
                        this.schedData = res.data || {}
                        this.subsDialog = true
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toSelect: function(schedId, openStatus) {
            if (openStatus===0) {
                this.userSubs.schedId = schedId
            }
        },
        saveSubs: function() {
            if (this.userSubs.userSchedId) {
                return
            }
            if (!this.userSubs.schedId) {
                this.$message({ message: '请选择预约的场次', showClose: true, offset: 50, type: 'error' });
                return
            }
            let data = {
                schedId: this.userSubs.schedId,
                classExamId: this.schedData.classExamId
            }
            this.apis.postJson('user/online/exam/sched/saveSubs', data, false, true)
                .then((res) => {
                    if (res.code === 1) {
                        // 立即支付
                        this.$message({
                            message: '预约成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.refreshTable()
                        this.userSubs = {
                            classExamId: '',
                            schedId: '',
                            userId: '',
                        }
                        this.subsDialog = false
                    } else {
                        this.$message({ message: res.msg, showClose: true, offset: 50, type: 'error' });
                        this.userSubs = {
                            classExamId: '',
                            schedId: ''
                        }
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        deleteUserSched: function (userSchedId, deleteStatus) {
            let title = '确定取消预约？';
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    userSchedId: userSchedId,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('user/online/exam/sched/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        dropdownValue: function (type, row) {
            return {
                'type': type, //key 是你自己想要的字段，值自己定义
                'data': row
            };
        },
        dropdownOperate: function (command) {
            console.log(command)
            if (command.type === 'faceChecked') {
                this.updFaceChecked(command.data.mstId)
            } else if (command.type === 'cancelSubmit') {
                this.updDataStatus(command.data.mstId, command.data.dataStatus);
            } else if (command.type === 'exitTimes') {
                this.resetExitTimes(command.data.mstId, 0);
            } else if (command.type === 'cancelSubs') {
                this.deleteUserSched(command.data.userSchedId, 1);
            } else if (command.type === 'genPaper') {
                this.toGenPage(command.data.userId, this.examInfo.classExamId, this.examInfo.classId);
            } else if (command.type === 'downloadPaperPdf') {
                this.exportOnlineSinglePdf(command.data.mstId, command.data.userId, this.examInfo.classExamId, this.examInfo.classId, command.data.orderId);
            } else if (command.type === 'signin') {
                this.toSignin(command.data.userId, this.examInfo.classExamId);
            }

        },
        downloadSignPdf: function () {
            let url = 'course/class/exam/sched/downloadSigninQr'
            let data = {
                classExamId: this.queryParams.classExamId,
                schedId: this.queryParams.schedId
            }
            this.downloadPdf(url, data)
        },
        downloadSubsPdf: function () {
            let url = 'course/class/exam/sched/downloadSubsQr'
            let data = {
                classExamId: this.queryParams.classExamId
            }
            this.downloadPdf(url, data)
        },
        downloadPdf: function (url, data) {
            // let data = {
            //     trueName: this.info.trueName,
            //     idCard: this.info.idCard,
            //     workName: '',
            //     workYear: this.info.workYears,
            // }
            this.apis.axios()({
                method: 'post',
                url: url,
                data: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '未查询到数据',
                        showClose: true, offset: 50, type: 'error'
                    });
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '.pdf')
            }).catch(err => {
                console.log(err);
            });
        },
        /**
         * 文件流下载
         */
        downFile(content, fileName) {
            let blob = new Blob([content], {
                type: 'application/pdf'
            });
            let objectUrl = URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = objectUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        },
        toSendExamNotic: function (type) {
            if (this.multipleSelection.length === 0) {
                this.$message({
                    message: '您未选择任何记录，请进行选择',
                    showClose: true, offset: 50, type: 'error'
                });
                return;
            }
            this.examRemindModal = true;
            this.examRemindInfo.type = type
            this.examRemindInfo.content = ''
        },
        sendExamNoticeRemain: function () {
            if (this.examRemindInfo.type === 'NOTICE') {
                this.sendExamNotice()
            } else {
                this.sendExamRemain()
            }
        },
        sendExamNotice: function () {
            if (this.multipleSelection.length === 0) {
                this.$message({
                    message: '您未选择任何记录，请进行选择',
                    showClose: true, offset: 50, type: 'error'
                });
                return;
            }
            let orderIds = [];
            this.multipleSelection.forEach(function (item) {
                orderIds.push(item.orderId)
            });
            let data = {
                classExamId: this.queryParams.classExamId,
                remark: this.examRemindInfo.content,
                orderIds: orderIds
            }

            let title = '确定发送考试通知信息？';
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.apis.postJson('user/online/exam/mst/sendExamNotice', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '发送成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.studyRemindModal = false;
                        } else {
                            this.$message({ message: res.msg, showClose: true, offset: 50, type: 'error' });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        sendExamRemain: function () {
            if (this.multipleSelection.length === 0) {
                this.$message({
                    message: '您未选择任何记录，请进行选择',
                    showClose: true, offset: 50, type: 'error'
                });
                return;
            }
            let orderIds = [];
            this.multipleSelection.forEach(function (item) {
                orderIds.push(item.orderId)
            });
            let data = {
                classExamId: this.queryParams.classExamId,
                schedId: this.queryParams.schedId,
                remark: this.examRemindInfo.content,
                orderIds: orderIds
            }
            let title = '确定发送考试提醒？';
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.apis.postJson('user/online/exam/mst/sendExamRemain', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '发送成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.studyRemindModal = false;
                        } else {
                            this.$message({ message: res.msg, showClose: true, offset: 50, type: 'error' });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        dropdownValue2: function (type, row) {
            return {
                'type': type, //key 是你自己想要的字段，值自己定义
                'data': row
            };
        },
        dropdownOperate2: function (command) {
            console.log(command)
            if (command.type === 'export') {
                this.toExport();
            } else if (command.type === 'exportpdf') {
                this.toExportPdf();
            } else if (command.type === 'exportTmpl') {
                this.toExportTmpl();
            } else if (command.type === 'exportCertImg') {
                this.exportImgModal = true;
            } else if (command.type === 'exportIdcard') {
                this.toExportIdcard();
            } else if (command.type === 'exportBankno') {
                this.toExportBankno();
            } else if (command.type === 'exportJieyeImg') {
                this.toImportJieyeCert();
            } else if (command.type === 'exportEnrollImg') {
                this.exportEnrollImgModal = true;
            } else if (command.type === 'toExportOnlineScore') {
                this.toExportOnlineScore();
            } else if (command.type === 'toExportOnlinePdf') {
                this.toExportOnlinePdf();
            }
        },
        toExport: function () {
            if (!this.queryParams.classId) {
                this.$message({
                    message: '只能根据班级进行导出，请选择班级',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            let data = {
                classId: this.queryParams.classId
            }
            this.apis.get('user/enrollOrder/getExportField', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.exportFieldList = res.data || {};
                        this.exportDialog = true;
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        //导出报名资料
        toExportPdf: function () {
            this.mainLoading = true;
            this.mainLoadingText = '正在导出，请稍等';
            let data = this.queryParams;
            if (!this.queryParams.classId) {
                this.mainLoading = false;
                this.$message({
                    message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            this.apis.postJson('user/enrollOrder/downloadPdf', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                console.log(err);
            });

            // this.apis.axios()({
            //     method: 'post',
            //     url: 'user/enrollOrder/downloadPdf',
            //     data: this.$http.adornParams(data),
            //     'responseType': 'blob'
            // }, false, 'json').then((res) => {
            //     if (res.type === 'application/json') {
            //         this.$message({
            //             message: '未查询到数据',
            //             showClose: true,
            //             offset: 200,
            //             type: 'error'
            //         });
            //         this.mainLoading = false;
            //         return false
            //     }
            //     let date = new Date();
            //     let year = date.getFullYear();
            //     let timestamp = date.valueOf();
            //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
            //     this.mainLoading = false;
            // }).catch(err => {
            //     console.log(err);
            //     this.mainLoading = false;
            // });
        },
        toExportTmpl: function () {
            if (!this.queryParams.companyId) {
                this.$message({
                    message: '请选择报名机构',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            this.exportTmplInfo.tmplId = ''
            this.exportTmplInfo.exportType = 1
            this.apis.get('course/enroll/export/tmpl/getList?orgId=' + this.queryParams.companyId)
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.exportTmplList = res.data || [];
                        this.exportTmplDialog = true;
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        //导出身份证
        toExportIdcard: function () {
            this.mainLoading = true;
            this.mainLoadingText = '正在导出数据，请稍等';
            let data = this.queryParams;

            if (!this.queryParams.classId) {
                this.mainLoading = false;
                this.$message({
                    message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            this.apis.postJson('user/enrollOrder/exportIdcard', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                console.log(err);
            });
            // this.apis.axios()({
            //     method: 'post',
            //     url: 'user/enrollOrder/exportIdcard',
            //     data: this.$http.adornParams(data),
            //     'responseType': 'blob'
            // }, false, 'json').then((res) => {
            //     if (res.type === 'application/json') {
            //         this.$message({
            //             message: '未查询到数据',
            //             showClose: true,
            //             offset: 200,
            //             type: 'error'
            //         });
            //         this.mainLoading = false;
            //         return false
            //     }
            //     let date = new Date();
            //     let year = date.getFullYear();
            //     let timestamp = date.valueOf();
            //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
            //     this.mainLoading = false;
            // }).catch(err => {
            //     console.log(err);
            //     this.mainLoading = false;
            // });
        },
        toImportJieyeCert: function () {
            this.mainLoading = true;
            this.mainLoadingText = '正在导出数据，请稍等';
            let data = this.queryParams;

            if (!this.queryParams.classId) {
                this.mainLoading = false;
                this.$message({
                    message: '只能根据班级或选择的记录进行导出，请选择记录',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            this.apis.axios()({
                method: 'post',
                url: 'user/enrollOrder/downloadCertImg',
                data: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '未查询到数据',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    this.mainLoading = false;
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '.zip')
                this.mainLoading = false;
            }).catch(err => {
                console.log(err);
                this.mainLoading = false;
            });
        },
        toExportBankno: function () {
            let data = this.queryParams;
            if (!data.classId) {
                this.$message({
                    message: '请选择班级',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return
            }
            this.mainLoading = true;
            this.mainLoadingText = '正在导出，请稍等';
            this.apis.axios()({
                method: 'post',
                url: 'user/enrollOrder/exportBankno',
                data: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '未查询到数据',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    this.mainLoading = false;
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '.xlsx')
                this.mainLoading = false;
            }).catch(err => {
                console.log(err);
                this.mainLoading = false;
            });
        },
        submitExport: function () {
            this.exportDialog = false;
            this.mainLoading = true;
            this.mainLoadingText = '正在导出数据，请稍等';
            let data = this.queryParams;
            data.exportFieldList = this.exportFieldList;
            if (!this.queryParams.classId && data.orderIds.length === 0) {
                this.mainLoading = false;
                this.$message({
                    message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            this.apis.axios()({
                method: 'post',
                url: 'user/enrollOrder/exportV2',
                data: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '未查询到数据',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    this.mainLoading = false;
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '.xlsx')
                this.mainLoading = false;
            }).catch(err => {
                console.log(err);
                this.mainLoading = false;
            });
        },
        exportTmpl: function () {
            if (!this.exportTmplInfo.tmplId) {
                this.$message({
                    message: '请选择模板',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            this.mainLoading = true;
            this.mainLoadingText = '正在创建导出任务，请稍等';
            let data = this.queryParams;
            if (!this.queryParams.classId) {
                this.mainLoading = false;
                this.$message({
                    message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            data.tmplId = this.exportTmplInfo.tmplId;
            data.exportType = this.exportTmplInfo.exportType;


            this.apis.postJson('user/enrollOrder/exportByTmpl', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                console.log(err);
            });

            // this.apis.axios()({
            //     method: 'post',
            //     url: 'user/enrollOrder/exportByTmpl',
            //     data: this.$http.adornParams(data),
            //     'responseType': 'blob'
            // }, false, 'json').then((res) => {
            //     if (res.type === 'application/json') {
            //         this.$message({
            //             message: '未查询到数据',
            //             showClose: true,
            //             offset: 200,
            //             type: 'error'
            //         });
            //         this.mainLoading = false;
            //         return false
            //     }
            //     let date = new Date();
            //     let year = date.getFullYear();
            //     let timestamp = date.valueOf();
            //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
            //     this.mainLoading = false;
            //     this.exportTmplDialog = false
            // }).catch(err => {
            //     console.log(err);
            //     this.mainLoading = false;
            // });
            this.exportTmplDialog = false;
        },
        //获取证书类型下拉菜单列表
        getCertNameList: function () {
            if (this.exportImgInfo.exportImgType === 'certImg') {
                this.apis.get('system/dict/getList?typeCode=0005')
                    .then((res) => {
                        if (res.code === 1) {
                            this.certNameList = res.data || []
                            // this.certNameList.splice(0, 0, { dictId: '', dictName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            }
        },
        //导出证件照片对话框>确认导出
        toExportImg: function () {
            this.mainLoading = true;
            this.mainLoadingText = '正在导出，请稍等';
            let data = this.queryParams;

            if (!this.queryParams.classId) {
                this.mainLoading = false;
                this.$message({
                    message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            data.appendA = this.exportImgInfo.appendA;
            data.nameType = this.exportImgInfo.nameType;
            this.exportImgModal = false;
            this.apis.postJson('user/enrollOrder/downloadImg', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                console.log(err);
            });
            // this.apis.axios()({
            //     method: 'post',
            //     url: 'user/enrollOrder/downloadImg',
            //     data: this.$http.adornParams(data),
            //     'responseType': 'blob'
            // }, false, 'json').then((res) => {
            //     if (res.type === 'application/json') {
            //         this.$message({
            //             message: '未查询到数据',
            //             showClose: true,
            //             offset: 200,
            //             type: 'error'
            //         });
            //         this.mainLoading = false;
            //         return false
            //     }
            //     let date = new Date();
            //     let year = date.getFullYear();
            //     let timestamp = date.valueOf();
            //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
            //     this.mainLoading = false;
            // }).catch(err => {
            //     console.log(err);
            //     this.mainLoading = false;
            // });
        },
        toExportEnrollImg: function () {
            this.mainLoading = true;
            this.mainLoadingText = '正在导出，请稍等';
            let data = this.queryParams;

            if (!this.queryParams.classId) {
                this.mainLoading = false;
                this.$message({
                    message: '只能根据班级或选择的记录进行导出，请选择班级或记录',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            if (!this.exportImgInfo.exportImgType) {
                this.mainLoading = false;
                this.$message({
                    message: '请选择导出的图片类型',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }
            if (this.exportImgInfo.exportImgType === '') {
                if (!this.exportImgInfo.exportCertName) {
                    this.mainLoading = false;
                    this.$message({
                        message: '请选择证书类型',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
            }
            if (!this.exportImgInfo.exportImgSuffix) {
                this.mainLoading = false;
                this.$message({
                    message: '请输入后缀字符',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return;
            }

            data.exportImgType = this.exportImgInfo.exportImgType;
            data.exportCertName = this.exportImgInfo.exportCertName;
            data.exportImgSuffix = this.exportImgInfo.exportImgSuffix;
            this.exportEnrollImgModal = false;
            this.apis.postJson('user/enrollOrder/downloadEnrollImg', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                console.log(err);
            });
            // this.apis.axios()({
            //     method: 'post',
            //     url: 'user/enrollOrder/downloadEnrollImg',
            //     data: this.$http.adornParams(data),
            //     'responseType': 'blob'
            // }, false, 'json').then((res) => {
            //     if (res.type === 'application/json') {
            //         this.$message({
            //             message: '未查询到数据',
            //             showClose: true,
            //             offset: 200,
            //             type: 'error'
            //         });
            //         this.mainLoading = false;
            //         return false
            //     }
            //     let date = new Date();
            //     let year = date.getFullYear();
            //     let timestamp = date.valueOf();
            //     this.downFile(res, year.toString() + timestamp.toString() + '.zip')
            //
            //     this.mainLoading = false;
            // }).catch(err => {
            //     console.log(err);
            //     this.mainLoading = false;
            // });
        },
    }
}
</script>

<style lang="scss" scoped>
.qr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img {
        width: 200px;
        height: 200px;
    }
    p {
        font-size: 16px;
        font-weight: bold;
    }
}
.sched-box {
    width: 100%;
    padding: 0px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.sched-box .sched {
    width: 350px;
    /* height: 100px; */
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0,52,227,0.1000);
    opacity: 1;
    border-radius: 6px;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    margin-top: 20px;
    cursor: pointer;
}
.sched-box .sched .address {
    color: #387EE5;
    line-height: 2;
}
.sched-box .sched .date {
    color: #666666;
    line-height: 1.5;
}
.sched-box .sched .num {
    color: red;
    line-height: 2;
}
.sched-box .selected {
    background-color: #2E50CC;
    color: #FFFFFF !important;
}
.sched-box .not {
    background-color: #E0E0E0;
    color: #FFFFFF !important;
}
.colors {
    color: #FFFFFF !important;
}
</style>
