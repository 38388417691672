<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="form-row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <MiniappEntry v-if="!miniInfo.fromType || miniInfo.authorized===0" @callback="toNext"></MiniappEntry>
                <MiniappFastReg v-else-if="miniInfo.fromType === 'BETAREG' && miniInfo.authorized===1" :openAppId="miniInfo.openAppId"></MiniappFastReg>
                <MiniappAuthPage v-else-if="miniInfo.fromType === 'AUTHORIZED' && miniInfo.authorized===1" :authUrl="authUrl" :openAppId="miniInfo.openAppId"></MiniappAuthPage>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import MiniappEntry from "./components/MiniappEntry";
import MiniappAuthPage from "./components/MiniappAuthPage";
import MiniappFastReg from "./components/MiniappFastReg";
import { Message } from "element-ui";
export default {
    components: {
        Breadcrumb,
        MiniappEntry,
        MiniappAuthPage,
        MiniappFastReg
    },
    filters: {
    },
    data () {
        return {
            crumbData: [
                {
                    path: '',
                    name: '首页'
                },
                {
                    path: '',
                    name: '小程序管理'
                }
            ],
            miniInfo: {
                fromType: ''
            },
            authUrl: '',
            authorizationInfo: {},
            authInfo: {},
        };
    },
    mounted () {
        if (this.$route.query.auth_code) {
            this.getPreAuthCode(this.$route.query.auth_code);
        } else {
            this.getByOrgId();
        }
    },
    computed: {
    },
    methods: {
        getPreAuthCode: function (auth_code) {
            this.apis.get('/wx/open/auth/getPreAuthCode?auth_code=' + auth_code)
                .then((res) => {
                    if (res.code === 1) {
                        this.authorizationInfo = res.data;
                        this.getAuthorizerInfo(this.authorizationInfo.authorizationInfo.authorizerAppid);
                    } else {
                        Message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        })
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getAuthorizerInfo: function (appId) {
            let data = {
                appId: appId,
                userId: this.appCookies.getCookieUID(),
                orgId: this.appCookies.getCookieOID(),
            }
            this.apis.get('/wx/open/auth/getAuthorizerInfo', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.getByOrgId();
                    } else {
                        Message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        })
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getByOrgId: function () {
            this.apis.get('/wx/open/app/getByOrgId?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    if (res.code === 1) {
                        this.miniInfo = res.data || { fromType: '' };
                    } else {
                        this.miniInfo = {
                            fromType: ''
                        }
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toNext: function (val) {
            if (val === 'AUTHORIZED') {
                this.getPreAuthUrl(val)
            } else {
                this.miniInfo.fromType = val
            }
            this.miniInfo.authorized = 1
        },
        getPreAuthUrl: function (val) {
            this.apis.get('/wx/open/auth/getPreAuthUrl')
                .then((res) => {
                    if (res.code === 1) {
                        this.authUrl = res.data;
                        this.miniInfo.fromType = val
                    } else {
                        Message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        })
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped>

</style>
