<template>
    <el-dialog
        title="小程序体验版二维码"
        :visible.sync="tiyanQrModal"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :append-to-body="true"
        close="clearTimer"
        width="400px">
        <div class="qr-container">
            <div class="qr-img">
                <img :src="tiyanQrUrl" alt="">
            </div>
            <div class="qr-title">
                打开微信扫一扫
            </div>
            <div class="qr-desc">
                首先完成体验版的发布才能体验
            </div>
        </div>
        <div slot='footer'>
            <el-button type="text" @click='tiyanQrModal=false'>取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            tiyanQrModal: false,
            tiyanQrUrl: '',
            appId: ''
        }
    },
    mounted() {
    },
    methods: {
        open: function (appId) {
            this.tiyanQrModal = true;
            this.appId = appId;
            this.getTiyanQrcode(appId)
        },
        getTiyanQrcode: function (appId) {
            let data = {
                appId: appId
            }
            this.apis.axios()({
                method: 'get',
                url: '/wx/open/mini/code/getQrcode',
                params: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                this.tiyanQrUrl = URL.createObjectURL(res)
                this.tiyanQrModal = true;
                if (res.type === 'application/json') {
                    this.$message({
                        message: '发生错误;'+ res.msg,
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                }
            }).catch(err => {
                console.log(err);
                this.mainLoading = false;
            });
        },
    }
}
</script>

<style scoped lang="scss">
.qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .qr-img {
        img {
            width: 150px;
            height: 150px;
        }
    }
    .qr-title {
        font-size: 16px;
        font-weight: bold;
        padding: 5px;
    }
    .qr-desc {
        padding: 5px;
        font-size: 14px;
    }
}
</style>
