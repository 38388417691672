<template>
    <div class="mainselect">
        <el-row>
            <el-col :span="8" style="padding-right: 4px">
                <el-form-item prop="provinceId">
                    <el-select v-model="c_provinceId" placeholder="请选择省份" filterable @visible-change="handleChangeFlag" style="width: 100%">
                        <el-option key="" label="请选择" value=""></el-option>
                        <el-option
                            v-for="item in provinceList"
                            :key="item.regionId"
                            :label="item.regionName"
                            :value="item.regionId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8" style="padding-right: 4px">
                <el-form-item prop="cityId">
                    <el-select v-model="c_cityId" placeholder="请选择市" filterable @visible-change="handleChangeFlag" style="width: 100%;">
                        <el-option key="" label="请选择" value=""></el-option>
                        <el-option
                            v-for="item in cityList"
                            :key="item.regionId"
                            :label="item.regionName"
                            :value="item.regionId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item prop="districtId">
                    <el-select v-model="c_districtId" placeholder="请选择区/县" filterable @visible-change="handleChangeFlag" style="width: 100%;">
                        <el-option key="" label="请选择" value=""></el-option>
                        <el-option
                            v-for="item in districtList"
                            :key="item.regionId"
                            :label="item.regionName"
                            :value="item.regionId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        props: ['provinceId', 'cityId', 'districtId'],
        // props: {
        //     provinceId: {
        //         type: Number
        //     },
        //     cityId: {
        //         type: Number
        //     },
        //     districtId: {
        //         type: Number
        //     },
        // },
        data() {
            return {
                c_provinceId: '',
                c_cityId: '',
                c_districtId: '',
                provinceList: [],
                cityList: [],
                districtList: [],
                changeFlag: false
            }
        },
        watch: {
            provinceId: {
                handler (val) {
                    this.c_provinceId = val
                    if (val) {
                        this.getCityList(val)
                    }
                },
                deep: true,
                immediate: true
            },
            cityId: {
                handler (val) {
                    this.c_cityId = val
                    if (val) {
                        this.getDistrictList(val)
                    }
                },
                deep: true,
                immediate: true
            },
            districtId: {
                handler (val) {
                    this.c_districtId = val
                },
                deep: true,
                immediate: true
            },
            /**
             * 监听省code
             * @param val
             */
            c_provinceId(val) {
                if (this.changeFlag){
                    this.c_cityId = null;
                    this.$emit('update:provinceId', val)
                }

            },
            /**
             * 监听市code
             * @param val
             */
            c_cityId(val) {
                if (this.changeFlag){
                    this.c_districtId = null;
                    this.$emit('update:cityId', val)
                }
            },
            /**
             * 监听区code
             * @param val
             */
            c_districtId(val) {
                if (this.changeFlag) {
                    this.$emit('update:districtId', val)
                }
            },
        },
        mounted() {
            this.getProvinceList();
            this.$nextTick(function () {

            });
        },
        methods: {
            initData: function (provinceId, cityId, districtId) {
                this.c_cityId = cityId;
                this.c_districtId = districtId;
                if (this.provinceId) {
                    this.apis.get('system/region/getList?parentId=' + this.provinceId)
                        .then((res) => {
                            if (res.code === 1) {
                                this.cityList = res.data || [];
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }
                if (this.c_cityId) {
                    this.apis.get('system/region/getList?parentId=' + this.c_cityId)
                        .then((res) => {
                            if (res.code === 1) {
                                this.districtList = res.data || [];
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }
            },
            getProvinceList: function () {
                this.apis.get('system/region/getList?parentId=0')
                    .then((res) => {
                        if (res.code === 1) {
                            this.provinceList = res.data || [];
                            // this.provinceList.splice(0, 0, { regionId: '', regionName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getCityList: function (provinceId) {
                this.c_cityId = '';
                // this.cityList = [];
                this.c_districtId = '';
                // this.districtList = [];
                this.apis.get('system/region/getList?parentId=' + provinceId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.cityList = res.data || [];
                            // this.cityList.splice(0, 0, { regionId: '', regionName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getDistrictList: function (cityId) {
                this.c_districtId = '';
                // this.districtList = [];
                this.apis.get('system/region/getList?parentId=' + cityId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.districtList = res.data || [];
                            // this.districtList.splice(0, 0, { regionId: '', regionName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            setData: function (districtId) {
                this.c_districtId = districtId;
            },
            handleChangeFlag(type) {
                // select回调，判断当前下拉框是否展示
                // console.log('handleChangeFlag=' + type)
                this.changeFlag = type;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .mainselect {
        height: 100%;
        width: 100%;
    }
</style>

