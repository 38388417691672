<template>
    <div>
        <el-input v-model="filterValue"  size='small' placeholder="请输入关键字" clearable class="input-with-select">
            <div slot="append">
                <el-button @click="filterText"  icon="el-icon-search"></el-button>
            </div>
        </el-input>
        <el-tree ref="deptListTree"
                 :data="treeDataList"
                 highlight-current
                 :props="{label: 'deptName', children: 'children'}"
                 :default-expanded-keys="expandedKeys"
                 :default-expand-all="true"
                 :expand-on-click-node="false"
                 :filter-node-method="filterNodeTree"
                 @node-click="treeSelectChange">
        <span class="custom-tree-node" slot-scope="{ node, data }">
            <i v-if="data.deptId===''" class="el-icon-wallet"></i>
            <i v-else-if="!data.children" class="el-icon-tickets" style="color: #1890FF"></i>
            <i v-else-if="node.expanded" class="el-icon-folder-opened"  style="color: #1890FF"></i>
            <i v-else class="el-icon-folder"  style="color: #1890FF"></i>
            <span :title='node.label || "-"' style="font-size: 15px; line-height: 30px; margin-left: 3px;">{{node.label}}</span>
        </span>
        </el-tree>
    </div>
</template>
<script>
export default {
    props: {
        expandedKeys: {
            type: Array,
            default() {
                return []
            }
        },
        orgId: {
            type: [String, Number],
            default() {
                return ''
            }
        },
        showAll: {
            type: Boolean,
            default() {
                return true;
            }
        },
    },
    watch: {
        orgId: {
            handler(newValue, oldValue) {
                this.getDeptTreeData()
            },
            deep: true
        },
    },
    data () {
        return {
            queryParams: {
            },
            treeDataList: [],
            filterValue: '',
        }
    },
    mounted () {
        this.getDeptTreeData();
    },
    methods: {
        filterText() {
            this.$refs.deptListTree.filter(this.filterValue);
        },
        // 调用tree过滤方法 中文英过滤
        filterNodeTree (value, data, node) {
            if (!value) { //如果数据为空，则返回true,显示所有的数据项
                node.expanded = false
                return true
            }
            // 查询列表是否有匹配数据，将值小写，匹配英文数据
            let val = value.toLowerCase()
            return this.chooseNode(val, data, node) // 调用过滤二层方法
        },
        // 过滤父节点 / 子节点 (如果输入的参数是父节点且能匹配，则返回该节点以及其下的所有子节点；如果参数是子节点，则返回该节点的父节点。name是中文字符，enName是英文字符.
        chooseNode (value, data, node) {
            if (data.deptName.indexOf(value) !== -1) {
                return true
            }
            const level = node.level
            // 如果传入的节点本身就是一级节点就不用校验了
            if (level === 1) {
                return false
            }
            // 先取当前节点的父节点
            let parentData = node.parent
            // 遍历当前节点的父节点
            let index = 0
            while (index < level - 1) {
                // 如果匹配到直接返回，此处name值是中文字符，enName是英文字符。判断匹配中英文过滤
                if (parentData.data.deptName.indexOf(value) !== -1) {
                    return true
                }
                // 否则的话再往上一层做匹配
                parentData = parentData.parent
                index++
            }
            // 没匹配到返回false
            return false
        },
        treeSelectChange: function (data) {
            // { deptId: '', deptName: '全部分类' }
            this.$emit('callback', data)
        },
        getDeptTreeData: function () {
            this.apis.get('system/dept/getList?orgId='+this.orgId)
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.treeDataList = res.data || [];
                        // this.treeDataList = [];
                        if (this.showAll) {
                            this.treeDataList.splice(0, 0, { deptId: '', deptName: '全部' });
                        }
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-tree-node__content {
    height: 32px !important;
    overflow: hidden;
    .el-tree-node__label {
        color: #666666;
    }
}
</style>
