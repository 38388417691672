<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>专家列表</span>
                        <div style="float: right; padding: 0px 0">
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:150px"></el-input>
                                </el-form-item>
<!--                                <el-form-item label="">-->
<!--                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态" style="width:150px">-->
<!--                                        <el-option label="全部" value=""></el-option>-->
<!--                                        <el-option label="正常" value="0"></el-option>-->
<!--                                        <el-option label="已删除" value="1"></el-option>-->
<!--                                    </el-select>-->
<!--                                </el-form-item>-->
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="姓名" prop="expertName" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="照片" prop="headImgUrl" width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <img v-show="row.headImgUrl" :src="row.headImgUrl" alt="" style="height: 80px; width: 60px">
                                    </template>
                                </el-table-column>
                                <el-table-column label="性别" prop="expertSex" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        {{ row.expertSex  | sexFilter}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="擅长项目" prop="majorItemsName" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="职称" prop="titleName" min-width="100px" align="center"></el-table-column>
                                <el-table-column label="工作单位" prop="unitName" align="left"></el-table-column>
                                <el-table-column label="职务" prop="positionName" align="center"></el-table-column>
                                <el-table-column label="所在地" min-width="100px" align="left">
                                    <template slot-scope="{row}">
                                        <span>{{ row.provinceName }}{{ row.cityName }}{{ row.districtName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="审核状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.confirmStatus | colorFilter">
                                            {{ row.confirmStatus  | confirmStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="160" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.expertId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.expertId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.expertId, 0)">恢复</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <ExpertEditor ref="expertEditor" @callback="initTable()"></ExpertEditor>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import ExpertEditor from './components/ExpertEditor'
export default {
    components: {
        Pagination,
        Breadcrumb,
        ExpertEditor
    },
    filters: {
        deleteStatusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        sexFilter(status) {
            const statusMap = {
                1: '男',
                2: '女',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger',
            };
            return statusMap[status]
        },
        confirmStatusFilter(status) {
            const statusMap = {
                0: '审核中',
                1: '审核通过',
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            crumbData: [
                {
                    path: '',
                    name: '首页'
                },
                {
                    path: '',
                    name: '专家库'
                }
            ],
            //列表查询数据
            queryParams: {
                pageNumber: 1,
                pageSize: 10,
                totalRecord: 0,
                keyword: '',
                deleteStatus: 0,
            },
            //列表数据
            tableData: [],
            tableLoading: false,
        };
    },
    mounted () {
        this.initTable();
        this.$nextTick(function() {
        });
    },
    computed: {
    },
    methods: {
        //获取列表
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('/fitness/expert/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 1;
            this.initTable();
        },
        //获取机构列表下拉菜单
        getOrgList: function () {
            if (!this.privRange.havePriv()){
                return
            }
            this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.orgList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toAdd: function () {
            this.$refs.expertEditor.show('');
            // this.$refs.editForm.resetFields();
        },
        toEdit: function (expertId) {
            this.$refs.expertEditor.show(expertId);
        },
        toDelete: function (expertId, deleteStatus) {
            let title = '确定要删除此条数据？';
            if (deleteStatus === 0) {
                title = '确定要恢复此条数据？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    expertId: expertId,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('/fitness/expert/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
