<template>
    <el-dialog
        title="添加消息模板"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="800px"
        style="height: 80vh"
        :close-on-click-modal="false">
        <el-row>
            <el-col :span="24" class="item_header"><span class="title">选择关键词</span></el-col>
        </el-row>
        <el-row :gutter="16"
                v-loading="mainLoading"
                :element-loading-text="mainLoadingText">
            <el-col :span="24">
                <el-form :model="info" ref="editForm" :rules="ruleValidate" label-width="140px" label-suffix=":">
                    <el-row>
                        <el-col :span="8">
                            <div class="sample-box">
                                <div class="title">已选择</div>
                                <div v-for="(item, idx) in info.itemList" :key="idx" class="item">
                                    <span>{{item.name}}</span>
                                    <span>{{item.example}}</span>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="16">
                            <el-form-item label="关键词" prop="kidList">
                                <el-checkbox-group v-model="info.kidList" @change="handleCheckChange">
                                    <el-checkbox v-for="(item, index) in keyWordList"
                                                :key="index" :label="item.kid" style="display:block;">{{item.name}}-{{item.rule}}{{item.kid}}</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="场景说明" prop="sceneDesc">
                                <el-input v-model="info.sceneDesc" maxlength="15" show-word-limit placeholder="说明订阅消息服务场景"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>
            <el-button type="primary"  @click="saveData('editForm')">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在查询，请稍等',
            editDialog: false,
            appId: '',
            info: {
                tid: '',
                kidList: [],
                itemList: [],
                sceneDesc: ''
            },
            keyWordList: [],
            ruleValidate: {
                kidList: [
                    { required: true, message: '请选择关键词', trigger: 'change' }
                ],
                sceneDesc: [
                    { required: true, message: '请输入场景说明', trigger: 'blur' }
                ],
            },
        }
    },
    mounted() {
    },
    methods: {
        open: function (appId, tid, remarks) {
            this.editDialog = true;
            this.appId = appId;
            this.info = {
                appId: appId,
                tid: tid,
                kidList: [],
                itemList: [],
                sceneDesc: remarks
            }
            this.toView(tid)
        },
        toView(tid) {
            this.mainLoading = true
            let data = {
                tid: tid,
                appId: this.appId
            }
            this.apis.postForm('/wx/open/mini/template/getPubTemplateKeyWordsById', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.keyWordList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                    this.mainLoading = false
                }).catch((err) => {
                this.mainLoading = false
                console.log(err)
            });
        },
        handleCheckChange(val) {
            // console.log(val)
            // console.log(this.info.itemList)
            this.info.itemList = []
            this.keyWordList.forEach(item => {
                if (val.indexOf(item.kid) > -1) {
                    this.info.itemList.push(item)
                }
            })
        },
        saveData: function (formRef) {
            if (this.info.kidList.length <2) {
                this.$message({
                    message: '至少选择两个关键词',
                    showClose: true, offset: 50, type: 'error'
                });
                return
            }
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "/wx/open/mini/template/addTemplate";
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // this.info.orgId = this.orgId;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.editDialog = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
::v-deep .el-checkbox-group {
    height: 180px;
    overflow: auto;
}
.sample-box {
    display: flex;
    flex-direction: column;
    .title {
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        font-weight: bold;
    }
    .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        color: #606266;
        line-height: 30px;
    }
}
</style>
