<template>
    <el-dialog
        :title="title"
        :visible.sync="uploadDialog"
        :close-on-click-modal="false"
        width="900px"
    >
    <el-form ref="createForm" :model="uploadInfo" label-width="0" label-suffix="">
        <el-form-item label="">
            {{ uploadInfo.name }}
        </el-form-item>
            <el-row style="margin-bottom: 10px">
                <el-col :span="24">
                    <el-upload
                        class="upload-demo"
                        drag
                        :show-file-list="true"
                        :accept="accept"
                        action="11"
                        :before-upload="beforeUpload">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__text">{{desc}}</div>
                    </el-upload>
                </el-col>
            </el-row>
        <el-row class="table-container" v-show="fileList.length > 0">
            <el-col>
                <el-table
                    :data="fileList"
                    fit
                    highlight-current-row
                    style="width: 100%;"
                    ref="queryTable"
                    :indent="20"
                >
                    <el-table-column label="文件名" prop="name" align="left" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="类型" prop="type" width="100px" align="center"></el-table-column>
                    <el-table-column label="大小" prop="size" width="100px" align="center">
                        <template slot-scope="{row}">
                            {{ (row.size / 1024).toFixed(2)}} KB
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" width="100px" align="center">
                        <template slot-scope="{row}">
                            {{ row.status  | statusFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="上传进度" width="100px" align="center">
                        <template>
                            {{authProgress}} %
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="操作" align="center" width="100" fixed="right" class-name="small-padding">-->
<!--                        <template>-->
<!--                            <el-button type="text" size="mini">取消上传</el-button>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                </el-table>
            </el-col>
        </el-row>
<!--        <el-form-item label="">-->
<!--            <div style="text-align: center">-->
<!--                <label class="status">上传状态: <span>{{statusText}}</span></label>-->
<!--                <span class="progress">上传进度: <i id="auth-progress">{{authProgress}}</i> %</span>-->
<!--            </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="">-->
<!--            <div style="text-align: center">-->
<!--                <el-Button @click="authUpload()" :disabled="uploadDisabled" style="margin-right: 10px">开始上传</el-Button>-->
<!--                <el-Button @click="pauseUpload()" :disabled="pauseDisabled" style="margin-right: 10px">暂停上传（{{authProgress}}%）</el-Button>-->
<!--                <el-Button @click="resumeUpload()" :disabled="resumeDisabled">恢复上传（{{authProgress}}%）</el-Button>-->
<!--            </div>-->
<!--        </el-form-item>-->
    </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button @click="uploadDialog = false">关闭</el-button>
                <el-button type="primary" :disabled="uploadDisabled" @click="authUpload()" :loading="loading">
                    {{ loading ? '上传中' : '开始上传（' + fileList.length + '）'}}</el-button>
                <el-button v-show="!pauseDisabled" :disabled="pauseDisabled" @click="pauseUpload()">暂停上传（{{authProgress}}%）</el-button>
                <el-button v-show="!resumeDisabled" type="primary" :disabled="resumeDisabled" @click="resumeUpload()">恢复上传（{{authProgress}}%）</el-button>
            </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '视频上传'
        },
        desc: {
            type: String,
            default: ''
        },
        accept: {
            type: String,
            default: ''
        },
        authUrl: {
            type: String,
            default: ''
        },
    },
    filters: {
        statusFilter(status) {
            const statusMap = {
                0: '未上传',
                1: '上传中..',
                2: '上传完',
            };
            return statusMap[status]
        },
    },
    data() {
        return {
            loading: false,
            uploadInfo: {},
            uploader: null,
            timeout: '',
            partSize: '',
            parallel: '',
            retryCount: '',
            retryDuration: '',
            region: 'cn-shenzhen',
            userId: '1410327544657015',
            authProgress: 0,
            uploadDisabled: true,
            resumeDisabled: true,
            pauseDisabled: true,
            statusText: '',
            uploadDialog: false,
            fileList: []
        };
    },
    methods: {
        open: function (uploadInfo) {
            this.uploadInfo = uploadInfo;
            this.fileList = [];
            this.uploadDisabled = true
            this.pauseDisabled = true
            this.resumeDisabled = true
            this.uploader = null;
            this.uploadDialog = true;
            this.loading = false;
        },
        beforeUpload(file) {
            if (!file) {
                this.$message({
                    message: '请先选择需要上传的文件',
                    showClose: true, offset: 50, type: 'success'
                });
                return
            }
            this.fileList = []
            file.status = 0;
            this.fileList.push(file)
            this.uploadDisabled = false;
            return false;
        },
        authUpload () {
            this.uploadInfo.fileName = this.fileList[0].name
            let userData = '{"Vod":{}}'
            if (this.uploader) {
                this.uploader.stopUpload()
                this.authProgress = 0
                this.statusText = ""
            }
            this.uploader = this.createUploader()
            this.uploader.addFile(this.fileList[0], null, null, null, userData)
            // 然后调用 startUpload 方法, 开始上传
            if (this.uploader !== null) {
                this.uploader.startUpload()
                this.fileList[0].status = 1;
                this.uploadDisabled = true
                this.pauseDisabled = false
                this.resumeDisabled = true
                this.loading = true;
            }
        },
        // 暂停上传
        pauseUpload () {
            if (this.uploader !== null) {
                this.uploader.stopUpload()
                this.resumeDisabled = false
                this.pauseDisabled = true
            }
        },
        // 恢复上传
        resumeUpload () {
            if (this.uploader !== null) {
                this.uploader.startUpload()
                this.resumeDisabled = true
                this.pauseDisabled = false
            }
        },
        createUploader(type) {
            let self = this
            let uploader = new AliyunUpload.Vod({
                timeout: self.timeout || 60000,
                partSize: self.partSize || 1048576,
                parallel: self.parallel || 5,
                retryCount: self.retryCount || 3,
                retryDuration: self.retryDuration || 2,
                region: self.region,
                userId: self.userId,
                // 添加文件成功
                addFileSuccess: function (uploadInfo) {
                    self.uploadDisabled = false
                    self.resumeDisabled = false
                    self.statusText = '添加文件成功, 等待上传...'
                    console.log("addFileSuccess: " + uploadInfo.file.name)
                },
                // 开始上传
                onUploadstarted: function (uploadInfo) {
                    // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
                    // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
                    // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
                    // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
                    // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
                    // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)

                    // let createUrl =  process.env.VUE_APP_API_HOST + 'system/file/uploadFile';  authUrl
                    // self.apis.postForm('course/video/getUploadAuth', self.uploadInfo)
                    self.apis.postForm(self.authUrl, self.uploadInfo)
                        .then((res) => {
                            if (res.code === 1) {
                                let uploadAuth = res.data.uploadAuth
                                let uploadAddress = res.data.uploadAddress
                                let videoId = res.data.videoId
                                self.uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId)
                            } else {
                                self.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'success'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                    self.statusText = '文件开始上传...'
                    // console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)

                },
                // 文件上传成功
                onUploadSucceed: function (uploadInfo) {
                    // console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                    self.statusText = '文件上传成功!'
                    this.uploadDisabled = true;
                    this.pauseDisabled = true;
                    this.resumeDisabled = true;
                },
                // 文件上传失败
                onUploadFailed: function (uploadInfo, code, message) {
                    console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
                    self.statusText = '文件上传失败!'
                },
                // 取消文件上传
                onUploadCanceled: function (uploadInfo, code, message) {
                    console.log("Canceled file: " + uploadInfo.file.name + ", code: " + code + ", message:" + message)
                    self.statusText = '文件已暂停上传'
                },
                // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
                onUploadProgress: function (uploadInfo, totalSize, progress) {
                    console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
                    let progressPercent = Math.ceil(progress * 100)
                    self.authProgress = progressPercent
                    self.statusText = '文件上传中...'
                },
                // 上传凭证超时
                onUploadTokenExpired: function (uploadInfo) {
                    // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                    // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
                    // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
                    self.apis.postForm('course/video/getUploadAuth', self.uploadInfo)
                        .then((res) => {
                            if (res.code === 1) {
                                let uploadAuth = res.data.uploadAuth
                                self.uploader.resumeUploadWithAuth(uploadAuth)
                                console.log('upload expired and resume upload with uploadauth ' + uploadAuth)

                            } else {
                                self.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'success'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                    self.statusText = '文件超时...'
                },
                // 全部文件上传结束
                onUploadEnd: function (uploadInfo) {
                    self.$message({
                        message: '上传完成',
                        showClose: true, offset: 50, type: 'success'
                    });
                    self.$emit("callback", self.uploadInfo)
                    self.uploadDialog = false;
                    // console.log("onUploadEnd: uploaded all the files")
                    // self.statusText = '文件上传完毕'
                    // self.apis.postForm('course/video/uploadStatus', self.uploadInfo)
                    //     .then((res) => {
                    //         if (res.code === 1) {
                    //             self.$emit("callback", self.uploadInfo)
                    //         } else {
                    //             self.$message({
                    //                 message: res.msg,
                    //                 showClose: true, offset: 50, type: 'success'
                    //             });
                    //         }
                    //     }).catch(err => {
                    //     console.log(err);
                    // });
                }
            })
            return uploader
        }
    }
}
</script>

<style scoped lang="scss">
    .upload-demo {
        width: 100%;
    }
    ::v-deep .el-upload {
        width: 100%;
    }
    ::v-deep .el-upload-dragger{
        width:100% !important;
    }
</style>
