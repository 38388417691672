<template>
    <el-row :gutter="8" class="form-row-container">
        <!-- 列表主体 -->
        <el-col :span="24">
            <el-form ref="editForm" label-width="200px" label-suffix=":">
                <div v-for="(item, idx) in dataList" :key="idx">
                    <el-row>
                        <el-col :span="24" class="item_header"><span class="title">{{item.appTypeName}}</span></el-col>
                    </el-row>
                    <el-row v-if="item.appType!==3">
                        <el-col :span="12">
                            <el-form-item label="AppId" prop="appId">
                                <el-input v-model="item.appId"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="商户号" prop="mchId">
                                <el-input v-model="item.mchId"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="微信商户密钥" prop="mchKey">
                                <el-input v-model="item.mchKey"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="回调地址" prop="notifyUrl">
                                <el-input v-model="item.notifyUrl"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-else>
                        <el-col :span="12">
                            <el-form-item label="AppId" prop="appId">
                                <el-input v-model="item.appId"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="支付宝私钥" prop="mchKey">
                                <el-input type="textarea" v-model="item.privateKey"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="支付宝公钥" prop="publicKey">
                                <el-input type="textarea" v-model="item.publicKey"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="回调地址" prop="notifyUrl">
                                <el-input v-model="item.notifyUrl"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <el-row :gutter="32">
                    <el-col :span="24" style="text-align: center">
                        <el-button size="medium" type="primary" @click="saveData('editForm')">保  存</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-col>
    </el-row>
</template>

<script>
export default {
    components: {
    },
    props: {
        orgId: {
            type: [String, Number],
            default: ''
        },
    },
    watch: {
        orgId: {
            deep: true,
            immediate: true,
            handler(val) {
                this.currOrgId = val
                this.getListByOrgId();
            }
        },
    },
    data () {
        return {
            currOrgId: '',
            dataList: [],
            //表单验证规则
            ruleValidate: {
            },
        };
    },
    mounted () {
    },
    computed: {
    },
    methods: {
        getListByOrgId: function () {
            this.apis.postForm('/wx/open/pay/conf/getList?orgId=' + this.currOrgId)
                .then((res) => {
                    if (res.code === 1) {
                        this.dataList = res.data;
                        this.$nextTick(function() {
                        });
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        saveData: async function (formRef) {
            let url = "/wx/open/pay/conf/saveBtach";
            // this.dataList.orgId = this.currOrgId
            // this.dataList.appType = 2
            // this.info.createBy = this.appCookies.getCookieUID();
            // this.info.createByname = this.appCookies.getCookieTNAME();
            // this.info.modifyBy = this.appCookies.getCookieUID();
            // this.info.modifyByname = this.appCookies.getCookieTNAME();
            this.dataList.forEach(item => {
                item.orgId = this.currOrgId
                item.createBy = this.appCookies.getCookieUID();
                item.createByname = this.appCookies.getCookieTNAME();
                item.modifyBy = this.appCookies.getCookieUID();
                item.modifyByname = this.appCookies.getCookieTNAME();
            })
            let data = {
                orgId: this.currOrgId,
                confList: this.dataList
            }
            // 保存数据
            this.apis.postJson(url, data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '保存成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.getListByOrgId()
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped>

</style>
