<template>
    <div class="widget-panel" id="widget-panel">
        <div class="widget-classify">
            <ul class="classify-wrap">
                <li
                    class="classify-item"
                    :class="{ 'active-classify-item' : activeWidgetClassify === index}"
                    v-for="(item, index) in widgetClassifyList"
                    :key="index"
                    @click="clickClassify(index)">
                    <i :class="item.icon" class="iconfont"></i>
                    <p>{{ item.name }}</p>
                </li>
            </ul>
        </div>
        <div class="widget-wrap">
            <img-list-wrap :certTmplId="v_certTmplId" v-if="widgetClassifyList[0].show" :style="getStyle(0)"/>
            <text-list-wrap v-if="widgetClassifyList[1].show" :style="getStyle(1)"/>
            <!--      <shape-list-wrap v-if="widgetClassifyList[1].show" :style="getStyle(1)" />-->
        </div>
    </div>
</template>

<script>
// 组件面板
import textListWrap from "./textListWrap";
import imgListWrap from "./imgListWrap";

const NAME = 'widget-panel'
export default {
    name: NAME,
    components: {
        textListWrap,
        imgListWrap
    },
    props: {
        certTmplId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            widgetClassifyList: [
                {
                    name: '图片',
                    icon: 'icon-pic',
                    show: true
                },
                {
                    name: '文本',
                    icon: 'icon-text',
                    show: false
                },
                // {
                //   name: '形状',
                //   icon: 'icon-shape',
                //   show: false
                // },
                // {
                //   name: '素材',
                //   icon: 'icon-material',
                //   show: false
                // }
            ],
            activeWidgetClassify: -1,
            v_certTmplId: 0
        }
    },
    watch: {
        activeWidgetClassify(index) {
            if (index >= 0 && index < this.widgetClassifyList.length) {
                this.widgetClassifyList[index].show = true;
            }
        },
        certTmplId: {
            handler (val) {
                this.v_certTmplId = val
                this.$forceUpdate();
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.activeWidgetClassify = 0
    },
    methods: {
        clickClassify(index) {
            this.activeWidgetClassify = index
        },
        getStyle(index) {
            return {
                display: (this.activeWidgetClassify === index ? '' : 'none')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '~STYLUS/page-design.styl'
.widget-panel {
    position: relative;
    width: 360px;
    height: 100%;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    color: #ffffff;

    .widget-classify {
        width: 60px;
        height: 100%;
        text-align: center;
        background-color: #262c33;

        .classify-wrap {
            width: 100%;

            .classify-item {
                width: 100%;
                height: 60px;
                font-size: 12px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                p {
                    margin-top: 5px;
                }

                &:hover {
                    color: #3B74F1;
                }
            }

            .active-classify-item {
                background-color: #3e4651;
                color: #3B74F1;
            }
        }
    }

    .widget-wrap {
        flex: 1;
        height: 100%;
        background-color: #3e4651;
    }
}
</style>
