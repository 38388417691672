<template>
    <div v-loading="mainLoading"
         :element-loading-text="mainLoadingText" class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="12">
                <el-card class="box-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>报名资料导出模板列表</span>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" clearable style="width:120px"></el-input>
                                </el-form-item>
<!--                                <el-form-item  v-if="this.privRange.havePriv()">-->
<!--                                    <el-select v-model="queryParams.orgId" placeholder="请选择培训机构" style="width:150px">-->
<!--                                        <el-option value="">全部</el-option>-->
<!--                                        <el-option v-for="item in orgList" :key="item.orgId" :value="item.orgId" :label="item.orgName"></el-option>-->
<!--                                    </el-select>-->
<!--                                </el-form-item>-->
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态" style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-plus" @click="toAdd">新建</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                height="calc(100vh - 300px)"
                                style="width: 100%;"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="模板名称" prop="tmplName" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="状态" prop="deleteStatus" align="center" width="100px">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | statusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="160" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.tmplId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.tmplId, 1)">删除</el-button>
                                        <el-button v-if="row.tmplPath" type="text" @click="goPreview(row.tmplPathUrl)">预览</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>模板字段说明</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryFieldParams.keyword" placeholder="查询关键字" clearable style="width:120px"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="initFieldTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                            <el-alert
                                title="模板中替换字段，可参考下表，格式说明如下："
                                type="success"
                                :closable="false"
                                show-icon>
                                <span>普通字段格式：{字段编码} ；例如：姓名，模板中为: {trueName}</span><br>
                                <span>图片字段格式：{字段编码#宽度x高度x是否偏移x左偏移量} ；是否偏移： T=是，F=否，可以不写；左偏移量可以不写默认为0；例如：照片，模板中为: {certHeadImg#100x200}或{certHeadImg#100x200xTx250}</span><br>
                                <span>自定义字段格式：{字段名称} ；例如：工作单位，模板中为: {工作单位}</span>
                            </el-alert>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="fieldTableLoading"
                                :data="fieldTableData"
                                fit
                                stripe
                                highlight-current-row
                                height="calc(100vh - 346px)"
                                style="width: 100%;"
                                ref="fieldQueryTable"
                                :indent="20"
                            >
                                <el-table-column label="字段编码" prop="fieldCode" width="120px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="字段名称" prop="fieldName" width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="说明" prop="remarks" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <span>
                                            模板中为：<span>{</span>{{row.fieldCode}}<span style="margin-right: 10px">}</span>{{ row.remarks}}
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="邮寄信息"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="模板名称" prop="tmplName">
                            <el-input v-model="info.tmplName" placeholder="请输入模板名称"></el-input>
                        </el-form-item>
                        <el-form-item label="模板文件" prop="tmplPath">
                            <div style="margin-bottom: 4px">
                                <el-button  type="primary" plain @click="toAddDocs()">上传模板</el-button>
                                <span style="margin-left: 15px; color: #666666">最多添加1个文件</span>
                            </div>
                            <div v-show="info.tmplFileName">
                                {{info.tmplFileName}}
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保存</el-button>
            </span>
        </el-dialog>
        <UploadDialog ref="uploadDialog"
                      :title="'模板上传'" :accept="'.docx'"
                      :desc="'模板上传,支持格式（.docx）'"
                      :limit="1"
                      @callback="addDocsFinish"></UploadDialog>
        <el-dialog
            title="docx文件预览"
            :visible.sync="previewDocxDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="100%">
            <el-row>
                <el-col :span="20" :offset="2">
                    <div ref="docxFile"></div>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="previewDocxDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@c/Breadcrumb';
    import Pagination from '@c/Pagination';
    import UploadDialog from "@c/upload/UploadDialog";
    let docx = require("docx-preview");
    export default {
        components: {
            Pagination,
            Breadcrumb,
            UploadDialog
        },
        filters: {
            statusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '报名资料导出模板'
                    }
                ],
                mainLoading: false,
                mainLoadingText: '正在导出数据，请稍等',
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    deleteStatus: '0',
                    orgId: this.appCookies.getCookieOID(),
                    userId: this.appCookies.getCookieUID()
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //动态下拉菜单
                orgList: [],
                //编辑、新增表单
                editDialog: false,
                info: {},
                //表单验证规则
                ruleValidate: {
                    // 基本信息
                    tmplName: [
                        { required: true, message: '请输入模板名称', trigger: 'blur' }
                    ],
                },
                queryFieldParams: {
                    keyword: '',
                },
                fieldTableLoading: false,
                fieldTableData: [],
                previewDocxDialog: false,
            };
        },
        mounted () {
            this.initTable();
            this.getOrgList();
            this.initFieldTable();
        },
        computed: {
        },
        methods: {
            initFieldTable: function () {
                this.fieldTableLoading = true;
                this.apis.get('course/enroll/export/tmpl/getFieldList', this.queryFieldParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.fieldTableData = res.data;
                            this.fieldTableLoading = false;
                        } else {
                            this.fieldTableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.fieldTableLoading = false;
                    console.log(err)
                });
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/enroll/export/tmpl/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toAdd: function () {
                this.info = {
                    tmplName: '',
                    tmplFileName: '',
                    tmplPath: ''
                };
                this.editDialog = true;
            },
            toEdit: function (tmplId) {
                this.apis.get('course/enroll/export/tmpl/getById?tmplId=' + tmplId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDialog = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "course/enroll/export/tmpl/save";
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.operUserId = this.appCookies.getCookieUID();
                        this.info.operUserName = this.appCookies.getCookieUNAME();
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.initTable();
                                    this.editDialog = false;
                                } else {
                                    this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toDelete: function (tmplId, deleteStatus) {
                this.$confirm('确定要删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        tmplId: tmplId,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieTNAME()
                    };
                    this.apis.postForm('course/enroll/export/tmpl/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.initTable();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            toAddDocs: function () {
                this.$nextTick(() => {
                    this.$refs.uploadDialog.open({});
                })
            },
            addDocsFinish: function (fileList) {
                // console.log(fileList)
                this.info.tmplPath = fileList[0].filePath;
                this.info.tmplFileName = fileList[0].fileName;
            },
            goPreview(filePathUrl) {
                this.apis.axios()({
                    method: 'get',
                    url: filePathUrl,
                    'responseType': 'blob'
                }).then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '未查询到数据',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        return false
                    }
                    this.previewDocxDialog = true;
                    docx.renderAsync(res, this.$refs.docxFile); // 渲染到页面
                }).catch(err => {
                    console.log(err);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
