var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dActiveElement.record && _vm.dActiveElement.uuid !== '-1')?_c('div',{staticClass:"size-control",attrs:{"id":"size-control"}},[(_vm.dActiveElement.record.dir === 'all')?_c('div',{staticClass:"square",style:({
    left: _vm.left + 'px',
    top: _vm.top + 'px',
    cursor: 'nw-resize',
  }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'left-top')}}}):_vm._e(),(_vm.dActiveElement.record.dir === 'vertical' || _vm.dActiveElement.record.dir === 'all')?_c('div',{staticClass:"square",style:({
    left: _vm.left + _vm.width / 2 + 'px',
    top: _vm.top + 'px',
    cursor: 'n-resize'
  }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'top')}}}):_vm._e(),(_vm.dActiveElement.record.dir === 'all')?_c('div',{staticClass:"square",style:({
    left: _vm.left + _vm.width + 'px',
    top: _vm.top + 'px',
    cursor: 'ne-resize'
  }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'right-top')}}}):_vm._e(),(_vm.dActiveElement.record.dir === 'horizontal' || _vm.dActiveElement.record.dir === 'all')?_c('div',{staticClass:"square",style:({
    left: _vm.left + 'px',
    top: _vm.top + _vm.height / 2 + 'px',
    cursor: 'w-resize'
  }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'left')}}}):_vm._e(),(_vm.dActiveElement.record.dir === 'horizontal' || _vm.dActiveElement.record.dir === 'all')?_c('div',{staticClass:"square",style:({
    left: _vm.left + _vm.width + 'px',
    top: _vm.top + _vm.height / 2 + 'px',
    cursor: 'e-resize'
  }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'right')}}}):_vm._e(),(_vm.dActiveElement.record.dir === 'all')?_c('div',{staticClass:"square",style:({
    left: _vm.left + 'px',
    top: _vm.top + _vm.height + 'px',
    cursor: 'sw-resize'
  }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'left-bottom')}}}):_vm._e(),(_vm.dActiveElement.record.dir === 'vertical' || _vm.dActiveElement.record.dir === 'all')?_c('div',{staticClass:"square",style:({
    left: _vm.left + _vm.width / 2 + 'px',
    top: _vm.top + _vm.height + 'px',
    cursor: 's-resize'
  }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'bottom')}}}):_vm._e(),(_vm.dActiveElement.record.dir === 'all')?_c('div',{staticClass:"square",style:({
    left: _vm.left + _vm.width + 'px',
    top: _vm.top + _vm.height + 'px',
    cursor: 'se-resize'
  }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'right-bottom')}}}):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }