<template>
    <div id="img-list-wrap" class="img-list-wrap">
        <div class="style-tab">
            <span class="tab" :class="{'active-tab' : activeTab === 0}" @click="activeTab = 0">模板图片</span>
            <span class="tab" :class="{'active-tab' : activeTab === 1}" @click="activeTab = 1">其他图片</span>
        </div>
        <div class="tab-content" v-if="hadShowMyImg" :style="getStyle(0)">
            <img-water-fall
                class="img-list"
                :listData="certImgList"
                sortBy="id"
                k="id"
                @delete-img="deleteImg"
                @select-img="selectImg2"/>
<!--            <div class="upload-btn-wrap">-->
<!--                <div class="upload-btn" @click="uploadImg">-->
<!--                    <i class="iconfont icon-upload"></i>-->
<!--                    <span>上传图片</span>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="tab-content" v-if="hadShowRecommendImg" :style="getStyle(1)">
            <img-water-fall
                class="img-list"
                :listData="sealImgList"
                sortBy="id"
                k="id"
                @delete-img="deleteImg"
                @select-img="selectImg"/>
        </div>
    </div>
</template>

<script>
// 图片列表
import wImage from "../widgets/wImage/wImage";
import { mapActions } from 'vuex'

const NAME = 'img-list-wrap'
export default {
    name: NAME,
    props: {
        certTmplId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            activeTab: -1,
            certImgList: [],
            sealImgList: [
                {
                    id: 1,
                    code: 'CERT_IMG',
                    name: '证书照片',
                    //url: 'http://files.zpbks.com/group1/M00/00/D9/rBEAAWFvvFqAAsXTAAQtBElhPnU240.png',
                    utl: 'https://files.zpbks.com/430fa56e7d2b402bb1bd8494570e606f.png',
                    ratio: 1
                },
                {
                    id: 2,
                    code: 'SEAL_IMG',
                    name: '印章',
                    // url: 'http://files.zpbks.com/group1/M00/00/D9/rBEAAWFvvIWACtcLAAAM22d8XFk198.png',
                    url: 'https://files.zpbks.com/699548fcf7e54ce8af947f324043bd0b.png',
                    ratio: 1
                },
                {
                    id: 3,
                    code: 'QR_IMG',
                    name: '二维码',
                    // url: 'http://files.zpbks.com/group1/M00/00/D9/rBEAAWFvvP-AEKScAAAEMR_L51Q958.png',
                    url: 'https://files.zpbks.com/3c91a94a196a4355816bd51c963d367a.png',
                    ratio: 1
                },
                {
                    id: 4,
                    code: 'CLASS_COURSE_IMG',
                    name: '课程/班级图片',
                    // url: 'http://files.zpbks.com/group1/M00/02/9A/rBqcumH1QdOAIw8EAACNeCwXw6U213.jpg',
                    url: 'https://files.zpbks.com/929324afa17745f4829645763caa9540.jpg',
                    ratio: 1
                },
                {
                    id: 5,
                    code: 'HEAD_IMG',
                    name: '微信头像',
                    // url: 'http://files.zpbks.com/group1/M00/02/9D/rBqcumIjEbGAPfLWAAFjaVHPtEc77.jpeg',
                    url: 'https://files.zpbks.com/dc00652c8cbd41d5a0ab7a70d87e7ea9.jpeg',
                    ratio: 1
                },
                {
                    id: 6,
                    code: 'BLANK_IMG',
                    name: '白色背景图',
                    // url: 'http://files.zpbks.com/group1/M00/02/9E/rBqcumIvFXuAH5VNAAACTgkC76M519.png',
                    url: 'https://files.zpbks.com/120ac3f4ad634e478e897aa1a6d523f8.png',
                    ratio: 1
                }
            ],
            hadShowMyImg: false,
            hadShowRecommendImg: false,
            pos: 0
        }
    },
    mounted() {
        this.activeTab = 0
    },
    watch: {
        certTmplId: {
            handler (val) {
                this.getCertImgList();
            },
            deep: true,
            immediate: true
        },
        activeTab(value) {
            if (value === 0) {
                this.hadShowMyImg = true
            } else if (value === 1) {
                this.hadShowRecommendImg = true
                // this.getSealImgList()
            }
        }
    },
    methods: {
        ...mapActions([
            'addWidget',
            'updateDPage'
        ]),
        getStyle(index) {
            return {
                display: (this.activeTab === index ? '' : 'none')
            }
        },
        selectImg2(item) {
            this.updateDPage({
                image: item.value.url,
                width: item.value.width,
                height: item.value.height
            })
        },
        selectImg(item) {
            let setting = JSON.parse(JSON.stringify(wImage.setting))
            setting.code = item.value.code;
            setting.name = '图片：' + item.value.name;
            setting.width = item.value.width || 200;
            setting.height = item.value.height || 200; // parseInt(100 / item.value.ratio, 0)
            setting.imgUrl = item.value.url;
            this.addWidget(setting)
        },
        deleteImg(item) {
            //
        },
        uploadImg() {
            console.log(1)
        },
        getCertImgList: function () {
            if (this.certTmplId === 0) {
                return
            }
            this.apis.get('system/certtmpl/getList?certTmplId=' + this.certTmplId)
                .then((res) => {
                    if (res.code === 1) {
                        let re = res.data;
                        this.certImgList = [];
                        re.forEach(item => {
                            this.certImgList.push({
                                id: item.certTmplId,
                                url: item.certTmplImgUrl,
                                width: item.imgWidth,
                                height: item.imgHeight,
                                ratio: 1
                            });
                        });
                        if (this.certImgList.length > 0) {
                            let item = {
                                value: {
                                    url: '',
                                    width: 100,
                                    height: 100
                                }
                            }
                            item.value.url = this.certImgList[0].url
                            item.value.width = this.certImgList[0].width
                            item.value.height = this.certImgList[0].height
                            this.selectImg2(item)
                        }
                    } else {
                        this.certImgList = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getSealImgList: function () {
            this.apis.get('system/org/getSealList?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    if (res.code === 1) {
                        let re = res.data;
                        this.sealImgList = [];
                        re.forEach(item => {
                            this.sealImgList.push({
                                id: item.orgId,
                                url: item.orgSealUrl,
                                ratio: 1
                            })
                        })
                    } else {
                        this.sealImgList = []
                    }
                }).catch(err => {
                console.log(err);
            });
        },

    }
}
</script>

<style lang="scss" scoped>
//@import '~STYLUS/page-design.styl'
.img-list-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .style-tab {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #262c33;

        .tab {
            flex: 1;
            padding: 10px;
            color: #ffffff;
            cursor: pointer;
            border-bottom: 2px solid #ffffff00;
            text-align: center;

            &.active-tab {
                color: #3B74F1;
                border-bottom: 2px solid #3B74F1;
            }
        }
    }
    .tab-content {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;

        .img-list {
            width: 100%;
            flex: 1;

            .img-item {
                width: 33.33%;
                height: auto;
                padding: 6px;
                cursor: pointer;

                &:hover {
                    outline: 1px solid #3B74F1;
                }

                .img {
                    width: 100%;
                }
            }
        }
    }

    .upload-btn-wrap {
        width: 100%;
        display: flex;
        justify-content: center;

        .upload-btn {
            cursor: pointer;
            width: 80%;
            padding: 10px;
            margin: 15px;
            text-align: center;
            border-radius: 5px;
            color: #ffffff;
            background-color: #3B74F1;

            &:hover {
                background-color: lighten(#3B74F1, 10%)
            }
        }
    }
}
</style>
