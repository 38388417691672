var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dShowRefLine),expression:"dShowRefLine"}],staticClass:"ref-line",attrs:{"id":"ref-line"}},[_vm._l((_vm.horizontal),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.attachHoriz(item.value)),expression:"attachHoriz(item.value)"}],key:item.id,staticClass:"horizontal",style:({
    left: '0px',
    top: item.value + 'px',
    width: _vm.dPage.width + 'px'
  })})}),_vm._l((_vm.vertical),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.attachVerti(item.value)),expression:"attachVerti(item.value)"}],key:item.id,staticClass:"vertical",style:({
    left: item.value + 'px',
    top: '0px',
    height: _vm.dPage.height + 'px'
  })})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }