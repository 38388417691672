<template>
    <el-dialog
        title="小程序接口权限申请"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" >
            <el-col :span="24">
                <el-form ref="editForm" label-width="140px" label-suffix=":">
                    <el-form-item label="api英文名">
                        {{interfaceInfo.apiName}}
                    </el-form-item>
                    <el-form-item label="api中文名">
                        {{interfaceInfo.apiChName}}
                    </el-form-item>
                    <el-form-item label="申请原因">
                        <el-input v-model="content" placeholder="申请原因"></el-input>
                    </el-form-item>
                    <el-form-item label="业务截图">
                        <div>截取相关业务的系统界面，证明可以使用此接口</div>
                        <SingleUploadImg @callback="uploadSignImg" :imageUrl="imgUrl"
                                         :width="120" :height="160"></SingleUploadImg>
                    </el-form-item>
                    <el-form-item label="api描述">
                        {{interfaceInfo.apiDesc}}
                    </el-form-item>
                    <el-form-item label="api文档链接">
                        {{interfaceInfo.apiLink}}
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>
            <el-button type="primary" @click="applyPrivacy">确 定</el-button>
<!--            v-if="info.canApply"-->
        </span>
    </el-dialog>
</template>

<script>
import SingleUploadImg from "@c/upload/SingleUploadImg.vue";
export default {
    components: {
        SingleUploadImg
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            editDialog: false,
            interfaceInfo: {},
            appId: '',
            content: '',
            imgUrl: ''
        }
    },
    mounted() {
    },
    methods: {
        open: function (appId, interfaceInfo) {
            this.editDialog = true;
            this.appId = appId;
            this.interfaceInfo = interfaceInfo
            if (this.interfaceInfo.apiName === 'wx.getFuzzyLocation') {
                this.content = "学员考试签到，获取打卡位置";
            } else if (this.interfaceInfo.apiName === 'wx.chooseAddress') {
                this.content = "学员报名后，填写邮寄地址";
            } else if (this.interfaceInfo.apiName === 'wx.chooseLocation') {
                this.content = "学员考试签到，获取打卡位置";
            } else if (this.interfaceInfo.apiName === 'wx.getLocation') {
                this.content = "学员考试到达考试现场后进行考试签到，记录打卡位置";
                this.imgUrl = 'https://files.zpbks.com/8666ffbe318845f9ac72b2f660464175.png';
            }
        },
        uploadSignImg: function (webPath, savePath) {
            this.imgUrl = webPath;
        },
        applyPrivacy: function () {

            let data = {
                appId: this.appId,
                apiName: this.interfaceInfo.apiName,
                content: this.content,
                imgUrl: this.imgUrl
            };
            this.apis.postForm('wx/open/mini/mgr/applyPrivacyInterface', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.$emit('callback')
                        this.editDialog = false;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
