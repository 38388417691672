<template>
    <el-dialog
    v-loading="tableLoading"
    title="教师选择"
    :visible.sync="teacherDialog"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :fullscreen="true"
    :modal="false"
    width="100%">
        <el-form label-width="80px" :inline="true">
            <el-form-item label="关键字">
                <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search"  @click="refreshTeacherTable" round>查询</el-button>
            </el-form-item>
        </el-form>
        <el-alert
            title="教师列表"
            type="info"
            show-icon>
        </el-alert>
        <el-row>
            <el-col :span="24">
                <el-table
                    border
                    :data="tableData"
                    style="width: 100%">
                        <el-table-column
                            label="#"
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column
                            prop="teacherCode"
                            label="教师编号">
                        </el-table-column>
                        <el-table-column
                            prop="teacherName"
                            label="教师姓名">
                        </el-table-column>
                        <el-table-column
                            label="教师照片">
                            <template slot-scope="{row}">
                                <div>
                                    <el-avatar :src="row.headImgUrl" @error="errorHandler"></el-avatar>
                                </div>
                            </template>
                        </el-table-column>
                    <el-table-column label="职称" prop="jobTitleName"></el-table-column>
                    <el-table-column label="职务描述" prop="jobTitleDesc" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column
                            align="center"
                            label="操作">
                            <template slot-scope="{row}">
                                <el-button type="primary" @click="setTeacherInfo(row.teacherId, row.teacherName)">选择</el-button>
                            </template>
                        </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="teacherDialog = false">取 消</el-button>
            <el-button type="primary" @click="teacherDialog = false">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
    export default {
        components: {
        },
        data () {
            return {
                teacherDialog: false,
                tableLoading: false,
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0'
                },
                //列表数据
                tableData: [],
            };
        },
        mounted () {
            this.$nextTick(function() {
            });
        },
        computed: {
        },
        methods: {
            open: function () {
                this.teacherDialog = true;
                this.getTeacherList();
            },
            getTeacherList: function () {
                this.tableLoading = true;
                this.apis.postForm('course/teacher/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTeacherTable: function () {
                this.queryParams.pageNumber = 0;
                this.getTeacherList();
            },
            setTeacherInfo: function (id, name) {
                this.$emit('callback', id, name);
                this.teacherDialog = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
