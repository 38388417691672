<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>申请列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true" label-width="80px">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item label="培训机构"  v-if="this.privRange.havePriv()">
                                    <el-select v-model="queryParams.orgId" placeholder="请选择培训机构">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="审核状态">
                                    <el-select v-model="queryParams.confirmStatus" style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="待审核" value="0"></el-option>
                                        <el-option label="已同意" value="1"></el-option>
                                        <el-option label="已拒绝" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="真实姓名" prop="trueName" min-width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="手机号" prop="phone" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="培训机构" prop="orgName" min-width="200px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="审核状态" prop="deleteStatus" min-width="100px" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.confirmStatus | colorFilter">
                                            {{ row.confirmStatus  | confirmStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作人" prop="modifyByname" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作时间" prop="modifyTime" min-width="200px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="180" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.confirmStatus === 0" type="text" size="mini" icon="el-icon-edit" @click="toConfirm(row.applyId,1)">同意</el-button>
                                        <el-button v-if="row.confirmStatus === 0" type="text" size="mini"  icon="el-icon-delete" @click="toConfirm(row.applyId, 2)">拒绝</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="信息编辑"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="名称" prop="name">
                                <el-input v-model="info.name" placeholder="请输入名称"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">取消</el-button>
                <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import _ from 'lodash';
    export default {
        name: "ApplyQuery",
        components: {
            Pagination,
            Breadcrumb,
        },
        filters: {
            confirmStatusFilter(status) {
                const statusMap = {
                    0: '待审核',
                    1: '已同意',
                    2: '已拒绝',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: '',
                    1: 'success',
                    2: 'danger',
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '营销管理'
                    },
                    {
                        path: '',
                        name: '申请管理'
                    }
                ],
                //视频列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    confirmStatus: '',
                    orgId: this.appCookies.getCookieOID(),
                    userId: this.appCookies.getCookieUID()
                },
                //下拉菜单
                orgList: [],
                //视频列表数据
                tableData: [],
                tableLoading: false,
                //编辑、新增表单
                editDrawer: false,
                info: {},
                //表单验证规则
                ruleValidate: {
                    // 视频基本信息
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                },
            };
        },
        mounted () {
            this.initTable();
            this.getOrgList();
        },
        computed: {
        },
        methods: {
            //获取动态下拉菜单
            getOrgList: _.debounce(function (val) {
                this.orgFinding = true;
                setTimeout(() => {
                    this.orgFinding = false;
                    this.apis.get('system/org/getList?keyword=' + val + '&orgId=' + this.appCookies.getCookieOID())
                        .then((res) => {
                            if (res.code === 1) {
                                this.orgList = res.data || []
                            } else {
                                console.log('发生错误:' + res.msg);
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }, 200)
            }, 500),
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('sale/apply/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "xxx/port";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDrawer = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            toEdit: function (keyId) {
                this.apis.get('xxx/port' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (keyId, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        keyId: keyId,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/video/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            toConfirm: function (id, confirmStatus) {
                let title = '确定同意此申请？';
                if (confirmStatus === 2) {
                    title = '确定拒绝此申请？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        applyId: id,
                        confirmStatus: confirmStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('sale/apply/confirm', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>

