<template>
    <div>
        <el-drawer
            title="知识点编辑"
            :visible.sync="pointDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-button-group>
                    <el-button class="filter-item" type="success" round @click="toAdd">新增</el-button>
                    <el-button class="filter-item" type="success" round @click="toCopy">复制到</el-button>
                </el-button-group>
                <el-row :gutter="32" class="table-container">
                    <el-col>

                        <el-table
                            :data="tableData"
                            style="width: 100%;"
                            ref="elementTable"
                            row-key="pointId"
                            highlight-current-row
                            :default-expand-all="true"
                            :indent="20"
                            stripe border :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                            :header-row-style="{height:'40px'}"
                            :header-cell-style="{padding:'0px', color:'#2c3e50'}"
                            :row-style="{height:'40px'}"
                            :cell-style="{padding:'8px', color:'#515a6e'}"
                        >
                            <el-table-column prop="pointName" label="名称" width="230" class-name="el-cell-bold" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="orderNum" label="排序号" align="center" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="modifyByname" label="操作人"  :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="modifyTime" label="操作时间"  :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                align="center"
                                width="300">
                                <template slot-scope="{row}">
                                    <el-button type="success" plain size="mini" icon="el-icon-edit" @click="toEdit(row.pointId)">修改</el-button>
                                    <el-button type="danger" plain size="mini"  icon="el-icon-delete" @click="toDelete(row.pointId, 1)">删除</el-button>
                                    <el-button type="success" plain size="mini" icon="el-icon-edit" @click="toAddChild(row.pointId)">添加下级</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="pointDrawer = false">关闭</el-button>
            </div>
        </el-drawer>
        <el-dialog
            title="编辑"
            :visible.sync="editModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
                <el-form-item label="知识点名称" prop="pointName">
                    <el-input v-model="info.pointName"></el-input>
                </el-form-item>
                <el-form-item label="上级" prop="parentId">
                    <SelectTree :tree-data="parentList" :selectId.sync="info.parentId"
                                :treeProps="treeProps" :nodeKey="'pointId'"
                                :onlySelectLeaf="false"></SelectTree>
                </el-form-item>
                <el-form-item label="排序号" prop="orderNum">
                    <el-input v-model="info.orderNum"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editModal = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="知识点复制-请选择要复制到的工种等级"
            :visible.sync="copyModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="copyInfo" :rules="ruleValidate" ref="copyForm" label-width="120px" label-suffix=":">
                <el-form-item label="工种等级" prop="gnvqCatId">
                    <GnvqSelect ref="gnvqSelect2" :gnvqCatId.sync="copyInfo.gnvqCatId" :gnvqId.sync="copyInfo.gnvqId" :levelId.sync="copyInfo.toLevelId"></GnvqSelect>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="copyModal = false">关 闭</el-button>
                <el-button type="primary" @click="copyData('copyForm')">确认复制</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import SelectTree from "@/components/tree/SelectTree";
import GnvqSelect from "@v/components/GnvqSelect";
    export default {
        components: {
            SelectTree,
            GnvqSelect
        },
        data() {
            return {
                queryParams: {
                    deleteStatus: 0,
                    pointType: 0,
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    baseCatId: '',
                    orgId: this.appCookies.getCookieOID()
                },
                tableData: [],
                info: {
                    orderNum: 0,
                    elementColor: ''
                },
                copyInfo: {
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    baseCatId: ''
                },
                copyModal: false,
                pointDrawer: false,
                editModal: false,
                parentList: [],
                ruleValidate: {
                    pointName: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ]
                },
                //知识点下拉菜单
                treeProps: {
                    value: 'pointId',
                    label: 'pointName',
                    children: 'children'
                },
            }
        },
        methods: {
            setData: function(gnvqCatId, gnvqId) {
                this.queryParams.gnvqCatId = gnvqCatId;
                this.queryParams.gnvqId = gnvqId;

                this.copyInfo.gnvqCatId = gnvqCatId;
                this.copyInfo.gnvqId = gnvqId;
            },
            /**
             *
             * @param pointType 知识点类型 1=公共课2=专业课
             * @param levelId
             * @param baseCatId 基础分类
             */
            show: function(pointType, levelId, baseCatId) {
                this.queryParams.pointType = pointType;
                this.queryParams.levelId = levelId;
                this.queryParams.baseCatId = baseCatId;
                this.initTable();
                this.pointDrawer = true;
                this.copyInfo.toLevelId = levelId;
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.get('gnvq/point/getList', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data
                            // this.queryParams.totalRecord = res.count
                            console.log(res);
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getParent: function () {
                this.apis.get('gnvq/point/getList', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.parentList = res.data || []
                            this.parentList.splice(0, 0, { pointId: '0', pointName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },

            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toAdd: function () {
                this.info = {
                    orderNum: 0,
                    parentId: 0,
                    levelId: this.queryParams.levelId,
                    pointType: this.queryParams.pointType,
                    baseCatId: this.queryParams.baseCatId
                };
                this.editModal = true;
                this.getParent();
            },
            toAddChild: function (id) {
                this.info = {
                    orderNum: 0,
                    parentId: id,
                    levelId: this.queryParams.levelId,
                    pointType: this.queryParams.pointType,
                    baseCatId: this.queryParams.baseCatId
                };
                this.getParent();
                this.editModal = true;
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "gnvq/point/save";
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME() ;
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editModal = false;
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (pointId) {
                this.getParent();
                this.apis.get('gnvq/point/getById?pointId=' + pointId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editModal = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, delState) {
                let title = '确定要删除此条数据？';
                if (delState === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                        let data = {
                            pointId: id
                        };
                        this.apis.postForm('gnvq/point/delete', data)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '操作成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }).catch(() => {
                    });
            },
            changeGnvq: function (gnvqCatId, gnvqId, levelId) {
                this.copyInfo.gnvqCatId = gnvqCatId;
                this.copyInfo.gnvqId = gnvqId;
                this.copyInfo.toLevelId = levelId;
            },
            toCopy: function () {
                this.copyModal = true;
            },
            copyData: function () {
                if (!this.copyInfo.toLevelId) {
                    this.$message({
                        message: '请选择要复制到的工种等级',
                        showClose: true, offset: 50, type: 'error'
                    });
                    return
                }
                if (this.queryParams.levelId === this.copyInfo.toLevelId) {
                    this.$message({
                        message: '不能相同的工种等级进行复制',
                        showClose: true, offset: 50, type: 'error'
                    });
                    return
                }

                this.$confirm('确定要复制数据?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                        let data = {
                            levelId: this.queryParams.levelId,
                            toLevelId: this.copyInfo.toLevelId
                        };
                        this.apis.postForm('gnvq/point/copy', data)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '复制成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.copyModal = false;
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }).catch(() => {
                    });
            },
        }
    }
</script>
<style lang="scss" scoped>
    .dd {

    }
</style>
