
export function getDate() {
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    return y + '-' + m + '-' + d;
}

export function getCurrYear() {
    let date = new Date();
    let y = date.getFullYear();
    return y + '';
}
export function getLastYear() {
    let date = new Date();
    let y = date.getFullYear() - 1;
    return y + '';
}
export function getCurrMonth() {
    let date = new Date();
    let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    return m;
}

export function getCurrDay() {
    let date = new Date();
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    return d;
}

export function isTel(s) {
    let phone_reg = new RegExp(/^\d{3}-?\d{7,8}|\d{4}-?\d{7,8}$/);
    if (!phone_reg.test(s)) {
        return false;
    } else {
        return true;
    }
}
export function isPhone(phone) {
    if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))) {
        return false;
    } else {
        return true;
    }
}
// 输入身份证的时候
export function isIdCard(idcard) {
    if (idcard.length === 18) {
        return true;
    }
    return false;
}
