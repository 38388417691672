<template>
    <el-drawer
        title="小程序信息"
        :visible.sync="editDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        :ref="'editDrawer' + appId"
        size="80%"
    >
        <div class="demo-drawer__content">
            <el-form :model="info" ref="editForm" label-width="200px" label-suffix=":">
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">基本信息</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="小程序名称">
                            {{info.nickname}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="APPID">
                            {{info.appid}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="主体名称">
                            {{info.principal_name}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="主体类型">
                            {{info.principal_type === 1 ? "企业" : "未知"}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="实名验证状态">
                            {{info.realname_status === 1 ? "实名验证成功" : info.account_type === 2 ? "实名验证中" : "实名验证失败"}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="帐号类型">
                            {{info.account_type === 1 ? "订阅号" : info.account_type === 2 ? "服务号" : "小程序"}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">服务器域名</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="request合法域名">
                            <span v-for="item in domainInfo.requestDomain" :key="item">{{item}}; </span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="uploadFile合法域名">
                            <span v-for="item in domainInfo.uploadDomain" :key="item">{{item}}; </span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="downloadFile合法域名">
                            <span v-for="item in domainInfo.downloadDomain" :key="item">{{item}}; </span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="socket合法域名">
                            <span v-for="item in domainInfo.wsRequestDomain" :key="item">{{item}}; </span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="udp合法域名">
                            <span v-for="item in domainInfo.wsrequestDomain" :key="item">{{item}}; </span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="tcp合法域名">
                            <span v-for="item in domainInfo.wsrequestDomain" :key="item">{{item}}; </span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="">
                            <el-button type="text" @click="modifyDomain">设置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">业务域名</span><span class="desc">配置为业务域名后，可调用web-view组件在小程序中打开</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="业务域名">
                            <span v-for="item in webDomain.webViewDomain" :key="item">{{item}}; </span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="">
                            <el-button type="text" @click="setWebViewDomain">设置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">获取隐私接口检测结果</span></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="没权限的隐私接口">
                            <div v-for="item in privacyInfo.without_conf_list" :key="item">{{item}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="没配置的隐私接口">
                            <div v-for="item in privacyInfo.without_auth_list" :key="item">{{item}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">体验版信息</span></el-col>
                </el-row>
                <el-row v-if="versionInfo.expInfo">
                    <el-col :span="12">
                        <el-form-item label="版本号">
                            {{versionInfo.expInfo.expVersion}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="版本描述">
                            {{versionInfo.expInfo.expDesc}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="提交时间">
                            {{ versionInfo.expInfo.expTime | parseTime('{y}-{m}-{d} {h}:{i}') }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
<!--                        <el-form-item label="操作设置">-->
<!--                        </el-form-item>-->
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="text-align: center; padding-bottom: 20px">
                        <el-button type="primary" @click="topublish">上传代码并生成体验版</el-button>
                        <el-button type="success" @click="getQrCode">获取体验版二维码</el-button>
                        <el-button type="info" @click="submitAudit">提交代码审核</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">审核版本</span></el-col>
                </el-row>
                <el-row v-if="auditInfo">
                    <el-col :span="24" style="padding: 0 40px 40px 40px">
                        <el-col :span="12">
                            <el-form-item label="版本号">
                                {{auditInfo.user_version}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="版本描述">
                                {{auditInfo.user_desc}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="提交时间">
                                {{ auditInfo.submit_audit_time | parseTime('{y}-{m}-{d} {h}:{i}') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="最新的审核id">
                                {{auditInfo.auditid}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="审核状态">
                                <span v-if="auditInfo.status==0">审核成功</span>
                                <span v-if="auditInfo.status==1">审核被拒绝</span>
                                <span v-if="auditInfo.status==2">审核中</span>
                                <span v-if="auditInfo.status==3">已撤回</span>
                                <span v-if="auditInfo.status==4">审核延后</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" v-if="auditInfo.reason">
                            <el-form-item label="原因">
                                {{auditInfo.reason}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="操作">
                                <el-button type="danger" v-if="auditInfo.status!==0 && auditInfo.status!==3" @click="undoCodeAudit">撤回审核</el-button>
                                <el-button type="danger" v-if="auditInfo.status===0" @click="release">发布</el-button>
                                <el-button type="danger" v-if="auditInfo.status===2" @click="speedAudit(auditInfo.auditid)">加急审核</el-button>

                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="本月审核额度">
                            {{auditQuota.rest}}/{{auditQuota.limit}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="本月加急次数">
                            {{auditQuota.speedupRest}}/{{auditQuota.speedupLimit}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="item_header"><span class="title">线上版本信息</span></el-col>
                </el-row>
                <el-row v-if="versionInfo.releaseInfo">
                    <el-col :span="12">
                        <el-form-item label="版本号">
                            {{versionInfo.releaseInfo.releaseVersion}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="版本描述">
                            {{versionInfo.releaseInfo.releaseDesc}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发布时间">
                            {{ versionInfo.releaseInfo.releaseTime | parseTime('{y}-{m}-{d} {h}:{i}') }}
                        </el-form-item>
                    </el-col>
                </el-row>
<!--                <el-row >-->
<!--                    <el-col :span="24" style="text-align: center; padding-bottom: 20px">-->
<!--                        <el-button type="warning">版本回退</el-button>-->
<!--                        <el-button type="danger" @click="setVisitStatus">暂停服务</el-button>-->
<!--                    </el-col>-->
<!--                </el-row>-->
            </el-form>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="editDrawer = false">关闭</el-button>
        </div>
        <PublishDialog ref="publishDialog" @callback="publishMiniApp"></PublishDialog>
        <QrDialog ref="qrDialog"></QrDialog>
    </el-drawer>
</template>

<script>
import PublishDialog from './PublishDialog';
import QrDialog from "./QrDialog.vue";
export default {
    components: {
        PublishDialog,
        QrDialog
    },
    filters: {
    },
    data () {
        return {
            appId: '',
            editDrawer: false,
            info: {
                orderNum: 0,
                head_image_info: {
                    head_image_url: ''
                },
                wx_verify_info: {
                    qualification_verify: ''
                },
                signature_info: {
                    signature: ''
                }
            },
            versionInfo: {
                expInfo: {},
                releaseInfo: {},
            },
            privacy: {
                interfaceList: [
                    {
                        apiName: '',
                        apiChName: '',
                        apiDesc: '',
                        status: '',
                        failReason: '',
                        groupName: '',
                    }
                ]
            },
            privacyInfo: {
                without_conf_list: [],
                without_auth_list: [],
            },
            auditInfo: {},
            auditQuota: {},
            domainInfo: {},
            webDomain: {},
            visitStatus: {},
        };
    },
    mounted () {
    },
    methods: {
        topublish: function() {
            this.$refs.publishDialog.open(this.appId)
        },
        getQrCode: function () {
            this.$refs.qrDialog.open(this.appId)
        },
        open: function (appId) {
            this.editDrawer = true;
            this.appId = appId;
            this.getById()
            this.getDomain()
            this.getWebViewDomain()
            this.getVersioninfo()
            this.getCodePrivacyInfo()
            this.getLatestAuditStatus()
            // this.getVisitStatus()
        },
        getById: function () {
            this.apis.get('wx/open/mini/mgr/getBasicInfo?appId=' + this.appId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.info = res.data;
                        this.editDrawer = true;
                    } else {
                        this.info = {}
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });

        },
        getDomain() {
            this.apis.get('wx/open/mini/mgr/getDomain?appId=' + this.appId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.domainInfo = res.data;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getWebViewDomain() {
            this.apis.get('wx/open/mini/mgr/getWebViewDomain?appId=' + this.appId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.webDomain = res.data;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getVersioninfo() {
            this.apis.get('wx/open/mini/code/getversioninfo?appId=' + this.appId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.versionInfo = res.data;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },

        getCodePrivacyInfo: function () {
            this.apis.postForm('wx/open/mini/code/getCodePrivacyInfo?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        this.privacyInfo = res.data;
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        submitAudit: function () {
            this.$confirm('确定要提交审核？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.apis.postForm('wx/open/mini/code/submitAudit?appId=' + this.appId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.privacyInfo = res.data;
                            this.getLatestAuditStatus()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        getLatestAuditStatus: function () {
            this.apis.postForm('wx/open/mini/code/getLatestAuditStatus?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        this.auditInfo = res.data;
                        this.getCodeAuditQuota()
                    } else {
                        this.auditInfo = {}
                        this.auditQuota = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getCodeAuditQuota: function () {
            this.apis.postForm('wx/open/mini/code/getCodeAuditQuota?appId=' + this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        this.auditQuota = res.data;
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        speedAudit: function (auditid) {
            this.$confirm('确定要加急代码审核？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.apis.postForm('wx/open/mini/code/speedAudit?appId=' + this.appId + '&auditid=' + auditid)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        undoCodeAudit: function () {
            this.$confirm('确定要撤回审核？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.apis.postForm('wx/open/mini/code/undoCodeAudit?appId=' + this.appId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.getLatestAuditStatus()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        release: function () {
            this.$confirm('确定要发布？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.apis.postForm('wx/open/mini/code/release?appId=' + this.appId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '发布成功',
                                showClose: true, offset: 50, type: 'success'
                            });
                            // this.getLatestAuditStatus()
                            this.getVersioninfo()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        publishMiniApp: function (version, versionDesc, templateId) {
            let data = {
                appId: this.appId,
                version: version,
                versionDesc: versionDesc,
                templateId: templateId
            }
            this.apis.postForm('wx/open/mini/code/publishMiniApp', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '发布成功',
                            showClose: true, offset: 50, type: 'success'
                        });
                        this.getLatestAuditStatus()
                        this.getVersioninfo()
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
        },
        modifyDomain: function () {
            this.$confirm('确定设置服务器域名？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.apis.postForm('wx/open/mini/mgr/modifyDomain?appId=' + this.appId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '设置成功',
                                showClose: true, offset: 50, type: 'success'
                            });
                            this.getWebViewDomain()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        setWebViewDomain: function () {
            this.$confirm('确定设置业务域名？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.apis.postForm('wx/open/mini/mgr/setWebViewDomain?appId=' + this.appId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '设置成功',
                                showClose: true, offset: 50, type: 'success'
                            });
                            this.getWebViewDomain()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        getVisitStatus: function () {
            this.apis.postForm('wx/open/mini/code/getVisitStatus', this.appId)
                .then((res) => {
                    if (res.code === 1) {
                        this.visitStatus = res.data
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        setVisitStatus: function () {
            this.$confirm('确定设置业务域名？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.apis.postForm('wx/open/mini/mgr/setWebViewDomain?appId=' + this.appId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '设置成功',
                                showClose: true, offset: 50, type: 'success'
                            });
                            this.getWebViewDomain()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        }
    }
}
</script>

<style scoped>

</style>
