<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never"  style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>资讯分类</span>
                    </div>
                    <el-table
                        fit
                        highlight-current-row
                        :show-header="false"
                        :data="categoryList"
                        @row-click="cellClick"
                        style="width: 100%">
                        <el-table-column>
                            <template slot-scope="{ row }">
                                <div style="font-size: 16px;">{{row.categoryName}}</div>
                                <div style="color: #808695;">{{row.categoryDesc}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>资讯列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:120px"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="queryParams.releaseFlag" filterable placeholder="请选择发布状态" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="未发布" value="0"></el-option>
                                        <el-option label="已发布" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select filterable v-model="queryParams.bannerStatus" placeholder="请选择是否轮播图" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="否" value="0"></el-option>
                                        <el-option label="是" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select filterable v-model="queryParams.deleteStatus" placeholder="请选择删除状态" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="标题" prop="articleTitle" min-width="250px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="文章图片" min-width="100px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <img :src="row.articleImgUrl" style='width: 30px;border-radius: 2px;'>
                                    </template>
                                </el-table-column>
                                <el-table-column label="轮播图" min-width="80px" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.bannerStatus | colorFilter2">
                                            {{ row.bannerStatus  | bannerStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="轮播图" prop="bannerImgUrl" min-width="80px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <img :src="row.bannerImgUrl" style='width: 30px;border-radius: 2px;'>
                                    </template>
                                </el-table-column>
                                <el-table-column label="访问量" prop="hitCount" min-width="80px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="类型"  min-width="100px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.category.categoryName}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="发布状态" min-width="80px" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-switch
                                            v-model="row.releaseFlag"
                                            :active-value="1"
                                            :inactive-value="0"
                                            active-color="#2d8cf0"
                                            inactive-color="#efefef"
                                            @change="toRelease(row)">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="删除状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="发布人" prop="articleAuthor" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="发布时间" prop="releaseDate" min-width="180px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="180" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.articleId)">编辑</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.articleId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" @click="toReview(row.articleId)">预览</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.articleId, 0)">恢复</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="信息编辑"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-row :gutter="32">
                        <el-col :span="12">
                            <el-form-item label="资讯标题" prop="articleTitle">
                                <el-input v-model="info.articleTitle" placeholder="请输入标题" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="来源/作者/发布人" prop="articleAuthor">
                                <el-input v-model="info.articleAuthor" placeholder="请输入来源/作者/发布人" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="简述" prop="articleAbs">
                        <el-input type="textarea" v-model="info.articleAbs" :rows="2" placeholder="请输入简述" />
                    </el-form-item>
                    <el-row :gutter="32">
                        <el-col :span="6">
                            <el-form-item label="所属类型" prop="categoryId">
                                <el-select v-model="info.categoryId" :transfer="true">
                                    <el-option value="" label="请选择"></el-option>
                                    <el-option v-for="item in categoryList" :value="item.categoryId" :label="item.categoryName" :key="item.categoryId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18">
                            <el-form-item label="文章图片">
                                <SingleUploadImg @callback="handleUpload" :imageUrl="info.articleImgUrl"
                                                 :width="192" :height="60"></SingleUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="6">
                            <el-form-item label="首页轮播图" prop="bannerStatus">
                                <el-radio-group v-model="info.bannerStatus">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18">
                            <el-form-item label="首页轮播图图片">
                                <SingleUploadImg @callback="handleUpload2" :imageUrl="info.bannerImgUrl"
                                                 :width="192" :height="60"></SingleUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-divider orientation="left">资讯内容</el-divider>
                        <el-col :span="24">
                            <editor ref="editor"></editor>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-divider orientation="left">附件</el-divider>
                        <el-col :span="4">
                            <el-upload
                                class="upload-demo"
                                ref="upload"
                                :headers="{'Authorization':accessToken}"
                                :data="{extPath: 'annex'}"
                                :action="getUploadUrl()"
                                :before-upload="beforeUpload"
                                :on-change="fileChange"
                                :on-success="annexHandleUpload"
                                :file-list="fileList"
                                :show-file-list="false">
                                <el-button slot="trigger" size="small" type="primary">上传附件</el-button>
                            </el-upload>
                        </el-col>
                        <el-col :span="20">
                            <el-table
                                :data="info.annexList"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                            >
                                <el-table-column
                                    type="index"
                                    width="55">
                                </el-table-column>
                                <el-table-column label="附件名称" prop="annexName" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="100" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button type="text" size="mini"  icon="el-icon-delete" @click="toDeleteAnnex(row)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- <Table :show-header="false" :columns="annexColumns" :data="info.annexList" size="small"></Table> -->
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">取消</el-button>
                <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
        <!-- 预览侧边栏 -->
        <el-drawer
            title="预览资讯"
            :visible.sync="viewDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-row>
                    <el-col :span="24" v-html="info.articleContent">
                    </el-col>
                </el-row>
                <el-row>
                    <el-divider orientation="left">附件</el-divider>
                    <el-col span="22" offset="1" v-for="(item, idx) in info.annexList" :key="idx">
                        <a :href="item.annexPathUrl">{{item.annexName}}</a>
                    </el-col>
                </el-row>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="viewDrawer = false">取消</el-button>
                <el-button  type="primary" @click="viewDrawer = false">确定</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import SingleUploadImg from "@c/upload/SingleUploadImg";
    import editor from '../../components/editor/editor'
    export default {
        components: {
            Pagination,
            Breadcrumb,
            SingleUploadImg,
            editor,
        },
        filters: {
            bannerStatusFilter(status) {
                const statusMap = {
                    0: '否',
                    1: '是',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
            colorFilter2(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success',
                };
                return statusMap[status]
            },
        },
        data () {
            const validateNumber = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('输入序号'));
                } else if (!Number.isInteger(+value)) {
                    callback(new Error('请输入数值'));
                } else {
                    callback();
                }
            };
            return {
                accessToken: 'Bearer ' + this.appCookies.getCookieAccessToken(),
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '资讯管理'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    categoryId: '',
                    releaseFlag: '',
                    bannerStatus: '',
                    deleteStatus: '0',
                    orgId: this.appCookies.getCookieOID()
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //动态下拉菜单
                categoryList: [],
                //编辑、新增表单
                editDrawer: false,
                info: {
                    articleContent: '',
                    articleImgUrl: '',
                    annexList: []
                },
                fileList: [],
                file: null,
                //表单验证规则
                ruleValidate: {
                    articleTitle: [
                        { required: true, message: '请输入资讯标题', trigger: 'blur' }
                    ],
                    categoryId: [
                        { required: true, message: '请选择所属类型', trigger: 'blur' }
                    ],
                    orderNum: [
                        { validator: validateNumber, trigger: 'blur' }
                    ],
                    bannerStatus: [
                        { required: true, type: "number", message: '是否是首页轮播图', trigger: 'blur' }
                    ],
                },
                viewDrawer: false,
            };
        },
        mounted () {
            this.initTable();
            this.getCategoryList();
            this.$nextTick(function() {
            });
        },
        computed: {
        },
        methods: {
            cellClick: function(row) {
                this.queryParams.categoryId = row.categoryId;
                this.refreshTable();
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('cms/article/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            //获取动态下拉菜单
            getCategoryList: function () {
                this.apis.get('cms/category/getList?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.categoryList = res.data;
                            this.categoryList.unshift({ categoryId: '', categoryName: "全部", categoryDesc: '显示宣布的分类资讯列表' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toAdd: function () {
                this.info = {
                    categoryId: this.queryParams.categoryId,
                    annexList: []
                };
                // console.log(this.info);
                this.editDrawer = true;
                this.$nextTick(function() {
                    this.$refs.editor.setHtml("");
                });
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "cms/article/save";
                    this.info.articleContent = this.$refs.editor.getHtml();
                    this.info.orgId = this.appCookies.getCookieOID();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDrawer = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            toEdit: function (keyId) {
                this.apis.get('cms/article/getById?articleId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.$nextTick(function() {
                                this.$refs.editor.setHtml(this.info.articleContent);
                            });
                            this.editDrawer = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toReview: function (keyId) {
                this.apis.get('cms/article/getById?articleId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.viewDrawer = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        articleId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('cms/article/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                }).catch(() => {
                });
            },
            toDeleteAnnex: function (row) {
                let title = '确定要删除此条数据？';
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.info.annexList.splice(row.index, 1);
                }).catch(() => {
                });
            },
            toRelease: function (row) {
                let data = {
                    articleId: row.articleId,
                    releaseFlag: row.releaseFlag,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('cms/article/release', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            getUploadUrl: function () {
                return process.env.VUE_APP_API_HOST + 'system/file/uploadFile';
            },
            handleUpload (webPath, savePath) {
                this.$set(this.info, 'articleImgUrl', webPath);
                this.$set(this.info, 'articleImg', savePath);
                this.$forceUpdate()
            },
            handleUpload2 (webPath, savePath) {
                this.$set(this.info, 'bannerImgUrl', webPath);
                this.$set(this.info, 'bannerImg', savePath);
                this.$forceUpdate()
            },
            fileChange(file, fileList) {
                // console.log(file)
                this.file = file.raw
            },
            beforeUpload(file) {
                return true;
            },
            annexHandleUpload (response, file, fileList) {
                let data = {
                    annexName: response.data.fileName,
                    annexPathUrl: response.data.webPath,
                    annexPath: response.data.savepath
                };
                this.info.annexList.push(data);
                this.$forceUpdate()
            },
            preview: function () {
                this.info.articleContent = this.$refs.editor.getHtml();
                this.contentModal = true;
            },
        }
    }
</script>

<style lang="scss" scoped>
    // div{
    //     font-size: 16px;
    // }
</style>
