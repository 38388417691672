<template>
    <div class="mini-containers">
        <el-row :gutter="32" class="row-container">
            <el-col :span="24">
                <div class="tip">
                    <p>
                        扫码授权即可获得试用小程序，可复用店铺认证信息快速完成小程序认证，转正的小程序享受免费认证优惠。
                    </p>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="32" class="row-container">
            <el-col :span="20" :offset="2" style="margin-bottom: 20px">
                创建并发布小程序
            </el-col>
            <el-col :span="20" :offset="2">
                <el-steps direction="vertical" :active="miniInfo.stepIndex+1">
                    <el-step v-for="(item, index) in activities"
                             :key="index"
                             :title="item.title"
                             :description="item.content">
                        <div slot="description" class="item-box">
                            <div class="content">
                                <div class="left">{{item.content}}</div>
                                <div class="right">
                                    <template v-if="item.stepIndex===1">
                                        <div style="display: flex">
                                            <el-button v-if="miniInfo.stepIndex===0 || !miniInfo.appId" type="primary" size="small" @click="showRegQr">{{item.btnName}}</el-button>
                                            <el-button v-if="miniInfo.stepIndex!==0 && miniInfo.appId" type="primary" size="small" @click="toPublish">发布体验版</el-button>
                                            <el-button v-if="miniInfo.stepIndex!==0 && miniInfo.appId" type="primary" size="small" @click="getTiyanQrcode">体验码</el-button>
                                        </div>
                                    </template>
                                    <template v-else-if="item.stepIndex===2">
                                        <div v-if="miniInfo.appStatus!==1 && miniInfo.appId" style="display: flex; flex-direction: column">
                                            <el-button type="primary" size="small" @click="verifyBetaWeapp">{{item.btnName}}</el-button>
                                            <span v-if="miniInfo.appStatus!==0">{{miniInfo.statusMsg}}</span>
                                        </div>
                                        <div v-if="miniInfo.appStatus===1 && miniInfo.appId" style="display: flex; flex-direction: column">
                                            <el-button disabled  type="danger" size="small">已转正</el-button>
                                        </div>
                                        <el-button v-if="miniInfo.appId" type="text" size="mini" icon="el-icon-view" @click="toEdit(miniInfo.appId)">小程序设置</el-button>
                                        <el-button v-if="miniInfo.appId" type="text" size="mini"  icon="el-icon-refresh-right" @click="mgrCode(miniInfo.appId)">版本管理</el-button>
                                        <el-button v-if="miniInfo.appId" type="text" size="mini"  icon="el-icon-refresh" @click="mgrTempl(miniInfo.appId, miniInfo.orgId)">消息模板</el-button>
                                    </template>
                                    <el-button v-else type="primary" size="small" disabled>{{item.btnName}}</el-button>
                                </div>
                            </div>
                            <div v-if="item.stepIndex===2">
                                <p>重要提醒：</p>
                                <p>1、试用小程序完成认证之后，不会把【的试用小程序】去掉，需要服务商调【设置名称】的接口来重置名称。且，如果在发布上线之后再修改名称，需要用户重新认证改名。</p>
                                <p>2、当小程序已经完成转正之后，仍然不可以扫码登录mp.weixin.qq.com，需要到“小程序助手”完成邮箱账号密码的补登记，才可以正常登录mp哈。操作详情请查看 <el-link target="_blank" type="danger" href="https://kf.qq.com/touch/sappfaq/200617VbQzaa200617aq67ru.html">查看</el-link></p>
                            </div>
                        </div>
                    </el-step>
                </el-steps>
            </el-col>
        </el-row>
        <el-dialog
            title="创建试用小程序"
            :visible.sync="regQrModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            close="clearTimer"
            width="400px">
            <div class="qr-container">
                <div class="qr-img">
                    <img :src="regQrUrl" alt="">
                </div>
                <div class="qr-title">
                    打开微信扫一扫
                </div>
                <div class="qr-desc">
                    请在手机根据提示完成小程序创建
                </div>
            </div>
            <div slot='footer'>
                <el-button type="text" @click='regQrModal=false'>取消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="小程序体验版二维码"
            :visible.sync="tiyanQrModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            close="clearTimer"
            width="400px">
            <div class="qr-container">
                <div class="qr-img">
                    <img :src="tiyanQrUrl" alt="">
                </div>
                <div class="qr-title">
                    打开微信扫一扫
                </div>
                <div class="qr-desc">
                    首先完成体验版的发布才能体验
                </div>
            </div>
            <div slot='footer'>
                <el-button type="text" @click='tiyanQrModal=false'>取消</el-button>
            </div>
        </el-dialog>
        <VerifyBetaWeappDialog ref="verifyBetaWeappDialog"></VerifyBetaWeappDialog>
        <MiniInfo ref="miniInfo"></MiniInfo>
        <MiniCode ref="miniCode"></MiniCode>
        <SubTmplate ref="subTmplate"></SubTmplate>
    </div>
</template>

<script>
import VerifyBetaWeappDialog from './VerifyBetaWeappDialog'
import MiniCode from "@v/miniapp/components/MiniCode.vue";
import MiniInfo from "@v/miniapp/components/MiniInfo.vue";
import SubTmplate from "@v/miniapp/components/SubTmplate.vue";
export default {
    components: {
        VerifyBetaWeappDialog,
        MiniCode,
        MiniInfo,
        SubTmplate
    },
    props: {
        openAppId: {
            type: [String, Number],
            default: ''
        },
    },
    watch: {
        openAppId: {
            deep: true,
            immediate: true,
            handler(val) {
                if (val) {
                    this.openAppId = val;
                    this.getById()
                }
            }
        }
    },
    data() {
        return {
            activities: [{
                title: '快速创建试用小程序',
                content: '小程序管理员扫码授权，即可立即生成试用小程序。试用小程序支持上架自定义商品等，暂不支持支付功能。\n' +
                    '请在7天内完成小程序转正操作，否则将被收回。',
                timestamp: '2018-04-12 20:46',
                size: 'large',
                type: 'primary',
                icon: 'el-icon-more',
                btnName: '快速创建',
                stepIndex: 1
            }, {
                title: '补充企业和法人信息，完成小程序转正',
                content: '小程序管理员扫码授权，即可立即生成试用小程序。试用小程序支持上架自定义商品、店铺装修等，暂不支持支付功能。\n' +
                    '请在7天内完成小程序转正操作，否则将被收回。',
                timestamp: '2018-04-03 20:46',
                color: '#0bbd87',
                btnName: '去转正',
                stepIndex: 2
            }, {
                title: '设置微信服务商模式账户 (非必须)',
                content: '开通微信支付能力，微信服务商模式账户的主体需与授权的主体一致。若跳过此步骤，用户将无法在小程序购买商品。',
                size: 'large',
                btnName: '去开通',
                stepIndex: 3
            }, {
                title: '补充小程序信息，提交审核并发布小程序',
                content: '提交微信审核（最长14个工作日），审核通过后即可立即发布。',
                btnName: '去发布',
                stepIndex: 4
            }],
            regQrModal: false,
            regQrUrl: '',
            loopTimer: null,
            miniInfo: {
                stepIndex: 0
            },
            tiyanQrModal: false,
            tiyanQrUrl: '',
        };
    },
    methods: {
        mgrTempl: function (appId, orgId) {
            this.$refs.subTmplate.open(appId, orgId)
        },
        showRegQr: function () {
            // this.regQrUrl = process.env.VUE_APP_API_HOST + '/wx/open/fastregbeta/getRegPageQr';
            let data = {
                userId: this.appCookies.getCookieUID(),
                token: this.appCookies.getCookieAccessToken()
            }
            this.apis.axios()({
                method: 'get',
                url: '/wx/open/fastregbeta/getRegPageQr',
                params: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                this.regQrUrl = URL.createObjectURL(res)
                this.regQrModal = true;
                this.startLoopTimer()
                if (res.type === 'application/json') {
                    this.$message({
                        message: '发生错误;'+ res.msg,
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                }
            }).catch(err => {
                console.log(err);
                this.mainLoading = false;
            });
        },
        getById: function () {
            this.apis.get('/wx/open/app/getById?openAppId=' + this.openAppId)
                .then((res) => {
                    if (res.code === 1) {
                        this.miniInfo = res.data || {};
                    } else {
                        this.miniInfo = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        clearTimer: function () {
            clearInterval(this.loopTimer);
            this.loopTimer = null
        },
        startLoopTimer: function () {
            let _this = this;
            _this.loopTimer = setInterval(() => {
                this.apis.get('/wx/open/app/getByOrgId?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            if (res.data && res.data.appId) {
                                this.miniInfo = res.data;
                                _this.clearTimer();
                                this.regQrModal = false
                            } else {
                                this.miniInfo = {
                                    stepIndex: 0
                                }
                            }
                        } else {
                            this.miniInfo = {
                                stepIndex: 0
                            }
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }, 3000);
        },
        toPublish: function () {
            this.$confirm('确定要发布？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    appId: this.miniInfo.appId,
                    templateId: '',
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('wx/open/mini/code/publishMiniApp', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '发布成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        getTiyanQrcode: function () {
            let data = {
                appId: this.miniInfo.appId
            }
            this.apis.axios()({
                method: 'get',
                url: '/wx/open/mini/mgr/getQrcode',
                params: this.$http.adornParams(data),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                this.tiyanQrUrl = URL.createObjectURL(res)
                this.tiyanQrModal = true;
                if (res.type === 'application/json') {
                    this.$message({
                        message: '发生错误;'+ res.msg,
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                }
            }).catch(err => {
                console.log(err);
                this.mainLoading = false;
            });
        },
        verifyBetaWeapp: function () {
            this.$refs.verifyBetaWeappDialog.open(this.miniInfo.appId)
        },
        mgrCode: function (appId) {
            this.$refs.miniCode.open(appId)
        },
        toEdit: function (appId) {
            this.$refs.miniInfo.open(appId)
        },
    }
};
</script>

<style scoped lang="scss">
.mini-containers {
    width: 100%;
    height: 100%;
}
::v-deep {
    .el-step__description {
        padding-right: 0;
    }
}
.item-box {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #DBDBDB;
    margin: 10px 0 20px 0;
    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        .left {
            flex: 1;
            width: 0;
        }
        .right {
            width: 150px;
            text-align: center;
        }
    }
}
.qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .qr-img {
        img {
            width: 150px;
            height: 150px;
        }
    }
    .qr-title {
        font-size: 16px;
        font-weight: bold;
        padding: 5px;
    }
    .qr-desc {
        padding: 5px;
        font-size: 14px;
    }
}
.tip {
    padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;
    p {
        font-size: 14px;
        color: #5e6d82;
        line-height: 1.5em;
    }
    a {
        color: #409eff;
        text-decoration: none;
    }
}
</style>
