<template>
    <div class="app-container"
         v-loading="mainLoading"
         :element-loading-text="mainLoadingText">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>报名列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width: 140px"></el-input>
                                </el-form-item>
                                <el-form-item label="" style="width: 120px">
                                    <el-select v-model="queryParams.classId">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="item in classList" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="width: 100px">
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-upload2" @click="toImport">导入报名信息</el-button>
                                    <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExport">导出报名信息</el-button>
                                    <el-button class="filter-item" type="success" round icon="el-icon-plus" @click="toAdd">报名录入</el-button>
                                    <el-button class="filter-item" type="success" round icon="el-icon-plus" @click="toBatchAdd">批量报名</el-button>
                                </el-button-group>
                                <el-link href="/model/importModel.xlsx" icon="el-icon-download" :underline="false" target="_blank" style="float: right; margin-right: 10px">下载模板</el-link>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="姓名" prop="trueName" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="性别" min-width="50" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.sex | sexFilter }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="身份证号" prop="idCard" min-width="170px" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="所属部门" prop="deptName" min-width="170px" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="联系电话" prop="phone" min-width="110px" align="center"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种" prop="gnvqName"  align="left"  min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName"  align="left" min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="班级" prop="className"  align="center" min-width="120" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="审核状态" min-width="80" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.confirmStatus | colorFilter">
                                            {{ row.confirmStatus  | confirmStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="删除状态" min-width="80" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="报名时间" width="140px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作人" prop="createByname" min-width="80" align="center"></el-table-column>
                                <el-table-column label="操作" align="center" width="140" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.orderId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.orderId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.orderId, 0)">恢复</el-button>
<!--                                        <el-button type="text" size="mini"  @click="toEdit(row.orderId)">查看</el-button>-->
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="报名信息编辑"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-divider content-position="left">工种信息</el-divider>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="工种等级" prop="categoryId">
                                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.categoryId" :gnvqId.sync="info.gnvqId" :levelId.sync="info.levelId"  @callback="getClassList2"></GnvqSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="12">
                            <el-form-item label="班级" prop="classId">
                                <el-select v-model="info.classId">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in classList2" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left">基本信息</el-divider>
                    <el-row :gutter="32">
                        <el-col :span="12">
                            <el-form-item>
                                <el-button type="success" plain round @click="readIdCard()">读取身份证信息</el-button>
                                请将身份证放到读卡器后点击
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="trueName">
                                <el-input v-model="info.trueName" placeholder="请输入姓名"></el-input>
                            </el-form-item>

                            <el-form-item label="性别" prop="sex">
                                <el-radio-group v-model="info.sex">
                                    <el-radio :label="1">男</el-radio>
                                    <el-radio :label="2">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="民族" prop="nationName">
                                <el-input v-model="info.nationName" placeholder="请输入民族"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号" prop="idCard">
                                <el-input v-model="info.idCard" placeholder="请输入身份证号"></el-input>
                            </el-form-item>

                            <el-form-item label="学员联系电话" prop="phone">
                                <el-input v-model="info.phone" placeholder="请输入学员联系电话"></el-input>
                            </el-form-item>

                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="家庭住址" prop="homeAddr">
                                <el-input v-model="info.homeAddr" placeholder="请输入家庭住址"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left">其他信息</el-divider>
                    <el-row :gutter="32">
                        <el-col :span="10">
                            <el-form-item label="所属部门" prop="deptName">
                                <el-input v-model="info.deptName" placeholder="请输入所属部门"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">提交保存</el-button>
            </div>
        </el-drawer>
        <el-drawer
            title="批量报名"
            :visible.sync="editBatchDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editBatchDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editBatchForm" label-width="140px" label-suffix=":">
                    <el-divider content-position="left">工种班级信息</el-divider>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="工种等级" prop="categoryId">
                                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.categoryId" :gnvqId.sync="info.gnvqId" :levelId.sync="info.levelId"  @callback="getClassList2"></GnvqSelect>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="班级" prop="classId">
                                <el-select v-model="info.classId">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in classList2" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left">选择员工</el-divider>
                    <el-row :gutter="32">
                        <el-col :span="24">
                            <StaffSelect ref="staffSelect"></StaffSelect>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editBatchDrawer = false">关 闭</el-button>
                <el-button type="primary" @click="saveBatchData('editBatchForm')">提交保存</el-button>
            </div>
        </el-drawer>
        <el-dialog
            title="导入学员信息"
            :visible.sync="importDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="importInfo" :rules="ruleValidate" ref="importForm" label-width="120px" label-suffix=":">
                <el-divider content-position="left">工种信息</el-divider>
                <el-row>
                    <el-col :span="16">
                        <el-form-item label="工种等级" prop="categoryId">
                            <GnvqSelect ref="gnvqSelect2" :gnvqCatId.sync="importInfo.categoryId" :gnvqId.sync="importInfo.gnvqId" :levelId.sync="importInfo.levelId"  @callback="getClassList3"></GnvqSelect>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="12">
                        <el-form-item label="班级" prop="classId">
                            <el-select v-model="importInfo.classId">
                                <el-option value="">请选择</el-option>
                                <el-option v-for="item in classList2" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider content-position="left">基本信息</el-divider>
                <el-row>
                    <el-col :span="22">
                        <el-form-item label="导入数据" prop="certCode">
                            <el-upload
                                class="upload-demo"
                                accept=".xls,.xlsx"
                                ref="upload"
                                action="#"
                                :limit="1"
                                :before-upload="beforeUpload"
                                :on-change="fileChange"
                                :file-list="fileList"
                                :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx文件</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importDialog = false">关 闭</el-button>
                <el-button type="primary" :loading="btnLoading" @click="importData('importForm')">导 入</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqSelect from "@v/components/GnvqSelect";
    import StaffSelect from "@v/system/components/StaffSelect";
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqSelect,
            StaffSelect
        },
        filters: {
            dataStatusFilter(status) {
                const statusMap = {
                    1: '已提交',
                    0: '暂存',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    1: '已删除',
                    0: '正常',
                };
                return statusMap[status]
            },
            confirmStatusFilter(status) {
                const statusMap = {
                    1: '已审核',
                    0: '未审核',
                };
                return statusMap[status]
            },
            payStatusFilter(status) {
                const statusMap = {
                    1: '已支付',
                    0: '未支付',
                };
                return statusMap[status]
            },
            payWayFilter(status) {
                const statusMap = {
                    1: '微信支付',
                    2: '支付宝',
                    3: '线下支付'
                };
                return statusMap[status]
            },
            sexFilter(status) {
                const statusMap = {
                    1: '男',
                    2: '女'
                };
                return statusMap[status]
            },
            taskTypeFilter(status) {
                const statusMap = {
                    '1000': '开放任务',
                    '2000': '内部任务'
                };
                return statusMap[status]
            },
            consumTypeFilter(status) {
                const statusMap = {
                    1: '单人领取',
                    2: '多人领取'
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            const validatePhone = (rule, value, callback) => {

                let phone_reg = new RegExp(/^\d{3}-?\d{7,8}|\d{4}-?\d{7,8}$/);
                if (value === undefined || value === null || value === '') {
                    callback(new Error('请输入联系电话'));
                } else if (!phone_reg.test(value) && !(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                    callback(new Error('联系电话格式不对'));
                } else {
                    callback();
                }
            };
            return {
                mainLoading: false,
                mainLoadingText: '正在导出数据，请稍等',
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '继续教育'
                    }
                ],
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    dataStatus: '',
                    confirmStatus: '',
                    payWay: '',
                    payStatus: '',
                    orgId: this.appCookies.getCookieTOID(),
                    companyId: this.appCookies.getCookieOID(),
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    identifyStatus: '',
                    classType: 'LEARN',
                    deptName: ''
                },
                gnvqTreeData: [],
                tableData: [],
                info: {
                    certList: [{
                        certName: '',
                        certFiles: []
                    }],
                },
                tableLoading: false,
                editDrawer: false,
                ruleValidate: {
                    categoryId: [
                        { required: true, type: 'number', message: '请选择工种类别', trigger: 'change' }
                    ],
                    gnvqId: [
                        { required: true, type: 'number', message: '请选择工种', trigger: 'change' }
                    ],
                    // levelId: [
                    //     { required: true, type: 'number', message: '请选择工种等级', trigger: 'change' }
                    // ],
                    classId: [
                        { required: true, message: '请选择班级', trigger: 'change' }
                    ],
                    // 报名信息
                    // 报名信息
                    trueName: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    sex: [
                        { required: true, type: 'number', message: '请选择性别', trigger: 'change' }
                    ],
                    idCard: [
                        { required: true, message: '请输入身份证号', trigger: 'blur' }
                    ],
                    phone: [
                        { required: true, validator: validatePhone, trigger: 'blur' }
                    ],
                    // deptName: [
                    //     { required: true, message: '请输入所属部门', trigger: 'blur' }
                    // ],
                },
                orgList: [],
                classList: [],
                classList2: [],
                eduList: [],
                certNameList: [],
                importDialog: false,
                importFile: null,
                fileList: [],
                importInfo: {},
                btnLoading: false,
                editBatchDrawer: false,
            };
        },
        mounted () {
            // this.initActiveXObject();
            this.initTable();
            this.getClassList();
            this.$nextTick(function() {
            });
        },
        methods: {
            toQuery: function (data) {
                // console.log(data)
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.refreshTable();
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('user/enrollOrder/queryByComp', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toAdd: function () {
                this.info = {
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    orgId: this.appCookies.getCookieTOID(),
                    classId: '',
                    companyId: this.appCookies.getCookieOID(),
                    confirmStatus: 1,
                    enrollType: 'COMPANY'
                };
                // this.$nextTick(function() {
                //     this.$refs.gnvqSelect.initData(this.info.categoryId, this.info.gnvqId, this.info.levelId);
                // });
                this.editDrawer = true;
            },
            toBatchAdd: function () {
                this.info = {
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    orgId: this.appCookies.getCookieTOID(),
                    classId: '',
                    companyId: this.appCookies.getCookieOID(),
                    confirmStatus: 1,
                    staffIds: [],
                    enrollType: 'COMPANY'
                };
                // this.$nextTick(function() {
                //     this.$refs.gnvqSelect.initData(this.info.categoryId, this.info.gnvqId, this.info.levelId);
                // });
                this.editBatchDrawer = true;
            },
            saveBatchData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let staffIds = this.$refs.staffSelect.getSelected();
                        if (staffIds.length === 0) {
                            this.$message({
                                message: '您未选择任何记录，请进行选择',
                                showClose: true, offset: 200, type: 'success'
                            });
                            return;
                        }
                        let url = "user/learnOrder/saveBatch";
                        this.info.staffIds = staffIds;
                        this.info.companyId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieTNAME();
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieTNAME();
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editBatchDrawer = false;
                                    this.initTable();
                                    this.$message({
                                        message: '报名成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "user/learnOrder/save";
                        this.info.companyId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieTNAME();
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieTNAME();
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editDrawer = false;
                                    this.initTable();
                                    this.$message({
                                        message: '报名成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (keyId) {
                this.apis.get('user/enrollOrder/getById?orderId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                            this.$nextTick(function() {
                                // this.$refs.gnvqSelect.initData(this.info.categoryId, this.info.gnvqId, this.info.levelId);
                                let lv = {
                                    levelId: this.info.levelId
                                }
                                this.getClassList2(lv);
                            });
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        orderId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('user/enrollOrder/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            getClassList: function () {
                let data = {
                    companyId: this.queryParams.companyId,
                    classType: 'LEARN',
                }
                this.apis.get('course/class/getAllList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getClassList2: function (levelInfo) {
                this.classList2 = [];
                let data = {
                    companyId: this.info.companyId,
                    levelId: levelInfo.levelId,
                    gnvqId: this.info.gnvqId,
                    classType: 'LEARN',
                    // operUserId: this.appCookies.getCookieUID()
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList2 = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getClassList3: function (levelInfo) {
                this.classList2 = [];
                let data = {
                    companyId: this.importInfo.companyId,
                    levelId: levelInfo.levelId,
                    gnvqId: this.importInfo.gnvqId,
                    classType: 'LEARN',
                    // operUserId: this.appCookies.getCookieUID()
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList2 = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            toImport: function () {
                this.importInfo = {
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    orgId: this.appCookies.getCookieTOID(),
                    classId: '',
                    companyId: this.appCookies.getCookieOID(),
                    confirmStatus: 1
                };
                this.importFile = null;
                this.importDialog = true;
            },
            beforeUpload(file) {
                //文件类型
                const isType = file.type === 'application/vnd.ms-excel'
                const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                const fileType = isType || isTypeComputer
                if (!fileType) {
                    this.$message.error('上传文件只能是xls/xlsx格式！')
                }
                this.importFile = file;

                return fileType;
            },
            fileChange(file, fileList) {
                console.log(file)
                this.importFile = file.raw
            },
            importData: async function (formRef) {

                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        if (this.importFile === null) {
                            this.$message({
                                message: '请选择上传的文件',
                                showClose: true,
                                offset: 200,
                                type: 'error'
                            });
                            return ;
                        }
                        this.btnLoading = true;
                        let formData = new FormData();
                        formData.append('file', this.importFile);
                        let url = "user/learnOrder/import";
                        this.importInfo.createBy = this.appCookies.getCookieUID();
                        this.importInfo.createByname = this.appCookies.getCookieUNAME() ;
                        this.importInfo.modifyBy = this.appCookies.getCookieUID();
                        this.importInfo.modifyByname = this.appCookies.getCookieUNAME() ;
                        formData.append("info", new Blob([JSON.stringify(this.importInfo)], { type: "application/json" }));
                        // 保存数据
                        this.mainLoading = true;
                        this.mainLoadingText = '正在导入数据，请稍等';
                        this.apis.postFile(url, formData)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.importDialog = false;
                                    this.$message({
                                        message: '导入成功',
                                        showClose: true,
                                        offset: 200,
                                        type: 'success'
                                    });
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true,
                                        offset: 200,
                                        type: 'error'
                                    });
                                }
                                this.mainLoading = false;
                                this.btnLoading = false;
                            }).catch(err => {
                            this.mainLoading = false;
                            this.btnLoading = false;
                            console.log(err);
                        });
                    }
                })
            },
            toExport: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'user/learnOrder/export',
                    data: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '报名信息.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            /**
             * 文件流下载
             */
            downFile(content, fileName) {
                let blob = new Blob([content], {
                    type: 'application/vnd.ms-excel'
                });
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = objectUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
