<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="form-row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="电脑端信息" name="tab01">
                        <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="网站标题" prop="websiteName">
                                        <el-input v-model="info.websiteName"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="PC域名" prop="pcDomainName">
                                        <el-input v-model="info.pcDomainName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="H5域名" prop="h5DomainName">
                                        <el-input v-model="info.h5DomainName"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="公安网备案号" prop="psIcpNo">
                                        <el-input v-model="info.psIcpNo"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="备案号" prop="icpNo">
                                        <el-input v-model="info.icpNo"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="客服电话" prop="kefuTel">
                                        <el-input v-model="info.kefuTel"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="课程预约电话" prop="yuyueTel">
                                        <el-input v-model="info.yuyueTel"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="商务合作电话" prop="hezuoTel">
                                        <el-input v-model="info.hezuoTel"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="工作时间" prop="workTime">
                                        <el-input v-model="info.workTime"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="联系邮箱" prop="email">
                                        <el-input v-model="info.email"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="LOGO图标（小）">
                                        <SingleUploadImg @callback="uploadLogoSquare" :imageUrl="info.logoSquareUrl"
                                                         :width="100" :height="100"></SingleUploadImg>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="LOGO图标（大）">
                                        <SingleUploadImg @callback="uploadLogoRectangle" :imageUrl="info.logoRectangleUrl"
                                                         :width="200" :height="100"></SingleUploadImg>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="客服微信二维码">
                                        <SingleUploadImg @callback="uploadKefuQr" :imageUrl="info.kefuQrImgUrl"
                                                         :width="100" :height="100"></SingleUploadImg>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="公众号二维码">
                                        <SingleUploadImg @callback="uploadMpQr" :imageUrl="info.mpQrImgUrl"
                                                         :width="100" :height="100"></SingleUploadImg>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32">
                                <el-col :span="24" style="text-align: center">
                                    <el-button size="medium" type="primary" @click="saveData('editForm')">保  存</el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import SingleUploadImg from "@c/upload/SingleUploadImg";
export default {
    components: {
        Breadcrumb,
        SingleUploadImg,
    },
    filters: {
        orgTypeFilter(status) {
            const statusMap = {
                SCHOOL: '培训机构',
                COMPANY: '公司',
            };
            return statusMap[status]
        },
        payWayFilter(status) {
            const statusMap = {
                0: '线上/线下',
                3: '仅线下',
            };
            return statusMap[status]
        },
        payWayColorFilter(status) {
            const statusMap = {
                0: 'success',
                3: 'warning'
            };
            return statusMap[status]
        },
        deleteStatusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger'
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            crumbData: [
                {
                    path: '',
                    name: '首页'
                },
                {
                    path: '',
                    name: '电脑端设置'
                }
            ],
            activeName: 'tab01',
            info: {
                orderNum: 0,
            },
            //表单验证规则
            ruleValidate: {
                // 视频基本信息
                websiteName: [
                    { required: true, message: '请输入网站标题', trigger: 'blur' }
                ],
                pcDomainName: [
                    { required: true, message: '请输入PC端域名', trigger: 'blur' }
                ],
            },
        };
    },
    mounted () {
        this.getByOrgId();
    },
    computed: {
    },
    methods: {
        getByOrgId: function () {
            this.apis.get('system/website/pc/getByOrgId?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "system/website/pc/save";
                    this.info.orgId = this.appCookies.getCookieOID();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    if (!this.requestId) {
                        this.requestId = new Date().getTime()
                    }
                    let extHeader = {
                        requestId: this.requestId
                    }
                    this.apis.postJson(url, this.info, false, extHeader)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.getByOrgId();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                            this.requestId = ''
                        }).catch(err => {
                        this.requestId = ''
                        console.log(err);
                    });
                }
            })
        },
        //上传图片组件的callback
        uploadLogoSquare: function (webPath, savePath,) {
            // alert(savePath)
            this.$set(this.info, 'logoSquareUrl', webPath);
            this.$set(this.info, 'logoSquare', savePath);
        },
        uploadLogoRectangle: function (webPath, savePath,) {
            // alert(savePath)
            this.$set(this.info, 'logoRectangleUrl', webPath);
            this.$set(this.info, 'logoRectangle', savePath);
        },
        uploadKefuQr: function (webPath, savePath,) {
            // alert(savePath)
            this.$set(this.info, 'kefuQrImgUrl', webPath);
            this.$set(this.info, 'kefuQrImg', savePath);
        },
        uploadMpQr: function (webPath, savePath,) {
            // alert(savePath)
            this.$set(this.info, 'mpQrImgUrl', webPath);
            this.$set(this.info, 'mpQrImg', savePath);
        },
    }
}
</script>

<style scoped>

</style>
