<template>
    <div style="width: 100%; min-height: 50vh">
        <el-tree ref="tagTree"
                 :data="treeDataList"
                 :props="{label: 'chapterName', children: 'children'}"
                 highlight-current
                 :default-expand-all="true"
                 :expand-on-click-node="false"
                 @node-click="treeSelectChange">
            <span class="custom-tree-node" slot-scope="{ node, data }">
                <i v-if="data.chapterId===''" class="el-icon-wallet"></i>
                <i v-else-if="!data.children" class="el-icon-tickets"></i>
                <i v-else-if="node.expanded" class="el-icon-folder-opened"></i>
                <i v-else class="el-icon-folder"></i>
                <span :title='node.label || "-"' class="name">{{node.label}}</span>
                <span class="oper" v-if="canEdit">
                    <el-button type="text" icon="el-icon-edit" size="mini" circle @click="() => toEdit(data)"></el-button>
                    <el-button type="text" icon="el-icon-circle-plus-outline" size="mini" circle @click="() => toAddChild(data)"></el-button>
                    <el-button type="text" icon="el-icon-remove-outline" size="mini" circle @click="() => remove(node, data)"></el-button>
                </span>
            </span>
        </el-tree>
        <el-dialog
            title="新建"
            :visible.sync="addDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
                <el-form-item label="章节名称" prop="chapterName">
                    <el-input v-model="info.chapterName"></el-input>
                </el-form-item>
                <el-form-item label="排序号" prop="orderNum">
                    <el-input v-model="info.orderNum"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="编辑"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
                <el-form-item label="章节名称" prop="chapterName">
                    <el-input v-model="info.chapterName"></el-input>
                </el-form-item>
                <el-form-item label="上级章节" prop="parentId">
                    <SelectTree :tree-data="treeDataList" :selectId.sync="info.parentId"
                                :treeProps="treeProps" :nodeKey="'chapterId'"
                                :onlySelectLeaf="false"></SelectTree>
                </el-form-item>
                <el-form-item label="排序号" prop="orderNum">
                    <el-input v-model="info.orderNum"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import SelectTree from "../../../../components/tree/SelectTree";
export default {
    components: {
        SelectTree
    },
    props: {
        ebookId: {
            type: [Number, String],
            default: ''
        },
        canEdit: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            queryParams: {
                pageNumber: 1,
                pageSize: 10,
                delState: '0',
                ebookId: ''
            },
            treeDataList: [],
            addDialog: false,
            editDialog: false,
            info: {
                ebookId: '',
                chapterId: '',
                chapterName: '',
                parentId: 0,
                contentTxt: ''
            },
            ruleValidate: {
                chapterName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
            },
            operType: 'ADD',
            currentNodeData: {},
            treeProps: {
                value: 'chapterId',
                label: 'chapterName',
                children: 'children'
            }
        }
    },
    mounted () {
    },
    watch: {
        ebookId: {
            handler: function(val, oldVal) {
                this.getTreeData();
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        treeSelectChange: function (data) {
            // { categoryId: '', categoryName: '全部分类' }
            this.$emit('callback', data)
        },
        getTreeData: function () {
            this.queryParams.ebookId = this.ebookId;
            this.apis.postForm('system/ebook/chapter/getTree', this.queryParams)
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.treeDataList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toAddRoot: function () {
            this.info = {
                chapterId: '',
                chapterName: '',
                parentId: 0,
                orderNum: this.treeDataList.length + 1
            }
            this.operType = 'ADD';
            this.addDialog = true;
        },
        toAddChild: function (data) {
            this.currentNodeData = data;
            this.info = {
                chapterId: '',
                chapterName: '',
                parentId: data.chapterId,
                orderNum: this.currentNodeData.children.length + 1,
            }
            this.operType = 'ADD';
            this.addDialog = true;
        },
        toEdit: function (data) {
            this.currentNodeData = data;
            this.apis.get('system/ebook/chapter/getById?chapterId=' + data.chapterId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data || {
                            chapterId: data.chapterId,
                            chapterName: data.chapterName,
                            parentId: data.parentId,
                            orderNum: data.orderNum
                        }
                        this.operType = 'EDIT';
                        this.editDialog = true;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "system/ebook/chapter/save";
                    this.info.ebookId = this.ebookId;
                    this.info.createUid = this.appCookies.getCookieUID();
                    this.info.createUname = this.appCookies.getCookieTNAME() ;
                    this.info.updateUid = this.appCookies.getCookieUID();
                    this.info.updateUname = this.appCookies.getCookieTNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.addDialog = false;
                                if (this.operType === 'EDIT') {
                                    // this.currentNodeData.chapterName = this.info.chapterName;
                                    this.getTreeData();
                                } else {
                                    const newChild = {
                                        chapterId: res.data,
                                        chapterName: this.info.chapterName,
                                        parentId: this.info.parentId,
                                        children: []
                                    };
                                    if (newChild.parentId === 0) {
                                        if (!this.treeDataList) {
                                            this.treeDataList = []
                                        }
                                        this.treeDataList.push(newChild);
                                    } else {
                                        if (!this.currentNodeData.children) {
                                            this.$set(this.currentNodeData, 'children', []);
                                        }
                                        this.currentNodeData.children.push(newChild);
                                    }
                                }
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        remove: function (node, nodeData) {
            this.$confirm('确定要删除?删除后不可恢复', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let chapterIds = [];
                chapterIds.push(nodeData.chapterId)
                this.findChildIds(nodeData, chapterIds);
                let data = {
                    chapterIds: chapterIds,
                    delState: 1,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieTNAME()
                };
                this.apis.postJson('system/ebook/chapter/deleteBatch', data)
                    .then((res) => {
                        if (res.code === 1) {
                            const parent = node.parent;
                            const children = parent.data.children || parent.data;
                            const index = children.findIndex(d => d.chapterId === nodeData.chapterId);
                            children.splice(index, 1);
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        findChildIds: function (nodeData, chapterIds) {
            let that = this;
            if (nodeData && nodeData.children && nodeData.children.length > 0) {
                for (let idx = 0; idx < nodeData.children.length; idx++) {
                    let child = nodeData.children[idx];
                    chapterIds.push(child.chapterId);
                    that.findChildIds(child, chapterIds);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
        .name {
            font-size: 15px;
            line-height: 30px;
            margin-left: 3px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .oper {
            position: absolute;
            right: 0;
            display: none;
            text-align: right;
            background: #FFFFFF;
            .el-button + .el-button {
                margin-left: 0px;
            }
        }
    }
    .custom-tree-node:hover .oper{
        display: block;
    }
</style>
