<template>
    <el-dialog
        title="小程序转正"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" >
            <el-col :span="24">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-form-item  label="企业名" prop="enterpriseName" >
                        <el-input v-model="info.enterpriseName" placeholder="请输入"></el-input>
                        企业名（需与工商部门登记信息一致)；如果是“无主体名称个体工商户”则填“个体户+法人姓名”，例如“个体户张三”
                    </el-form-item>
                    <el-form-item  label="企业代码"  prop="code">
                        <el-input v-model="info.code" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item  label="企业代码类型"   prop="codeType">
                        <el-select v-model="info.codeType" placeholder="请选择" style="width: 100%;">
                            <el-option label="统一社会信用代码（18 位）" value="1"></el-option>
                            <el-option label="组织机构代码（9 位 xxxxxxxx-x）" value="2"></el-option>
                            <el-option label="营业执照注册号(15 位)" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  label="法人姓名"  prop="legalPersonaName">
                        <el-input v-model="info.legalPersonaName" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item  label="法人身份证号"  prop="legalPersonaIdcard">
                        <el-input v-model="info.legalPersonaIdcard" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item  label="第三方联系电话"  prop="componentPhone">
                        <el-input v-model="info.componentPhone" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item  label="法人微信号"  prop="legalPersonaWechat">
                        <el-input v-model="info.legalPersonaWechat" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>

            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>

            <el-button v-if="!success" type="primary"  @click="saveData('editForm')">确 定</el-button>
            <span v-else>转正提交成功；法人微信号将会收到校验信息，需在24小时内完成校验</span>
        </span>
    </el-dialog>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            editDialog: false,
            appId: '',
            info: {
                appId: ''
            },
            success: false,
            //表单验证规则
            ruleValidate: {
                enterpriseName: [
                    { required: true, message: '请输入企业名', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                codeType: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                legalPersonaName: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                legalPersonaIdcard: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                componentPhone: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                legalPersonaWechat: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
            },
        }
    },
    mounted() {
    },
    methods: {
        open: function (appId) {
            this.editDialog = true;
            this.appId = appId;
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "/wx/open/fastregbeta/verfifyBetaMiniprogram";
                    this.info.appId = this.appId;
                    // 保存数据
                    this.apis.postForm(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '转正提交成功；法人微信号将会收到校验信息，需在24小时内完成校验',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.success = true
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
