<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="24">
                <el-row  class="query-container">
                    <el-col :span="24">
                        <el-form :inline="true">
                            <el-form-item label="">
                                <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="queryParams.operType" placeholder="请选择">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="新增" value="ADD"></el-option>
                                    <el-option label="删除" value="DELETE"></el-option>
                                    <el-option label="修改" value="UPDATE"></el-option>
                                    <el-option label="查询" value="QUERY"></el-option>
                                    <el-option label="登录" value="LOGIN"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                            </el-form-item>

                        </el-form>
                    </el-col>
                </el-row>

            </el-col>
        </el-row>
        <el-row :gutter="8" class="row-container"  style="min-height: calc(100vh - 180px)">
            <el-col :span="24">
                <el-row  class="table-container">
                    <el-col :span="24">
                        <el-table
                            :key="tableKey"
                            v-loading="tableLoading"
                            :data="tableData"
                            border
                            fit
                            highlight-current-row
                            style="width: 100%;"
                            ref="elementTable"
                            :indent="20"
                            @sort-change="sortChange"
                        >
                            <el-table-column label="操作人" prop="id" align="left" width="200" :show-overflow-tooltip="true">
                                <template slot-scope="{row}">
                                    <span>{{ row.operUname }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="描述" align="left" :show-overflow-tooltip="true">
                                <template slot-scope="{row}">
                                    <span>{{ row.operDesc}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="IP" min-width="60px" align="center">
                                <template slot-scope="{row}">
                                    <span>{{ row.ip}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="地址" min-width="110px" align="center">
                                <template slot-scope="{row}">
                                    <span>{{ row.ipName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作类型" width="110px" align="center">
                                <template slot-scope="{row}">
                                    {{ row.operType  | typeFilter}}
                                </template>
                            </el-table-column>
                            <el-table-column label="操作时间" width="140px" align="center">
                                <template slot-scope="{row}">
                                    <span>{{ row.operTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                    :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                    @pagination="initTable"/>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    // import { parseTime } from '@/utils'
    import Pagination from '@/components/Pagination' // secondary package based on el-pagination
    import Breadcrumb from "../../components/Breadcrumb";
    export default {
        components: {
            Pagination,
            Breadcrumb
        },
        filters: {
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'info',
                    2: 'danger'
                };
                return statusMap[status]
            },
            typeFilter(status) {
                const statusMap = {
                    'ADD': '新增',
                    'DELETE': '删除',
                    'UPDATE': '修改',
                    'LOGIN': '登录',
                    'QUERY': '查询'
                };
                return statusMap[status]
            }
        },
        data() {
            return {
                crumbData: [
                    {
                        path: '/',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '日志查询'
                    }
                ],
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    operType: '',
                    delState: '0',
                    sort: ''
                },
                tableKey: 0,
                tableData: [],
                tableLoading: false,
                sortOptions: [{
                    label: 'ID Ascending',
                    key: '+id'
                }, {
                    label: 'ID Descending',
                    key: '-id'
                }],
                statusOptions: ['published', 'draft', 'deleted'],
                info: {},
                treeNormalizer(node) {
                    return {
                        id: node.menuId,
                        label: node.menuName
                    }
                }
            }
        },
        mounted() {
            this.initTable();
        },
        methods: {
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('system/logs/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data
                            this.queryParams.totalRecord = res.count
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
