<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>客户机构列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择删除状态"  style="width: 100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" size="mini" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                height="calc(100vh - 300px)"
                                style="width: 100%;"
                                :header-cell-style="{background:'#eef1f6', color:'#606266'}"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="机构名称" prop="orgName" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="简称" prop="shortName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="机构类型" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.orgType  | orgTypeFilter}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="收款方式" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.payWay | payWayColorFilter">
                                            {{ row.payWay  | payWayFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="联系人姓名" prop="linkName" min-width="80px" align="left"></el-table-column>
                                <el-table-column label="联系人电话" prop="linkPhone" min-width="100px" align="left"></el-table-column>
                                <el-table-column label="区域" prop="orgAddress" width="150px" align="left">
                                    <template slot-scope="{row}">
                                        <span>{{ row.provinceName}}{{ row.cityName}}{{ row.districtName}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="地址" prop="orgAddress" min-width="200px" align="left" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <span>{{ row.orgAddress}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="创建人" prop="modifyByname" width="100px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="创建时间" prop="modifyTime" width="120" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.modifyTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="120" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.orgId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0 && row.orgId!==appCookies.getCookieOID()" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.orgId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1 && row.orgId!==appCookies.getCookieOID()" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.orgId, 0)">恢复</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="信息编辑"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="机构编码" prop="orgCode">
                                <el-input v-model="info.orgCode"></el-input>
                            </el-form-item>
                    </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="机构名称" prop="orgName">
                                <el-input v-model="info.orgName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="机构简称" prop="shortName">
                                <el-input v-model="info.shortName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col :span="24">
                            <el-form-item label="所在地" prop="trainAddress">
                                <AreaSelect
                                    :provinceId.sync="info.provinceId"
                                    :cityId.sync="info.cityId"
                                    :districtId.sync="info.districtId">
                                </AreaSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="详细地址" prop="orgAddress">
                        <el-input v-model="info.orgAddress"></el-input>
                    </el-form-item>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="联系人姓名" prop="linkName">
                                <el-input v-model="info.linkName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系人电话" prop="linkPhone">
                                <el-input v-model="info.linkPhone"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="机构类型" prop="orgType" >
                                <el-radio-group v-model="info.orgType">
                                    <el-radio label="SCHOOL">培训机构</el-radio>
                                    <el-radio label="COMPANY">公司</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="收款方式" prop="payWay" >
                                <el-radio-group v-model="info.payWay">
                                    <el-radio :label="0">线上线下</el-radio>
                                    <el-radio :label="3">仅线下</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="印章图片">
                        <SingleUploadImg @callback="uploadOrgSealImg" :imageUrl="info.orgSealUrl"
                                            :width="100" :height="100"></SingleUploadImg>
                    </el-form-item>
                    <el-row :gutter="32">
                        <el-col :span="24">
                            <el-divider content-position="left">机构简介</el-divider>
                            <editor ref="editor" :height="300"></editor>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">取消</el-button>
                <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import SingleUploadImg from "@c/upload/SingleUploadImg";
    import editor from '../../components/editor/editor'
    import AreaSelect from '@/views/components/AreaSelect'
    export default {
        name: 'OrgQuery',
        components: {
            Pagination,
            Breadcrumb,
            SingleUploadImg,
            editor,
            AreaSelect
        },
        filters: {
            orgTypeFilter(status) {
                const statusMap = {
                    SCHOOL: '培训机构',
                    COMPANY: '公司',
                };
                return statusMap[status]
            },
            payWayFilter(status) {
                const statusMap = {
                    0: '线上/线下',
                    3: '仅线下',
                };
                return statusMap[status]
            },
            payWayColorFilter(status) {
                const statusMap = {
                    0: 'success',
                    3: 'warning'
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '培训机构'
                    }
                ],
                //视频列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    orgId: this.appCookies.getCookieOID(),
                },
                //视频列表数据
                tableData: [],
                tableLoading: false,
                //编辑、新增表单
                editDrawer: false,
                info: {
                    orderNum: 0,
                },
                //表单验证规则
                ruleValidate: {
                    // 视频基本信息
                    orgCode: [
                        { required: true, message: '请输入机构编码', trigger: 'blur' }
                    ],
                    orgName: [
                        { required: true, message: '请输入机构名称', trigger: 'blur' }
                    ],
                    shortName: [
                        { required: true, message: '请输入机构简称', trigger: 'blur' }
                    ],
                    orgType: [
                        { required: true, message: '请选择机构类型', trigger: 'blur' }
                    ],
                    payWay: [
                        { required: true, message: '请选择收款方式', trigger: 'blur' }
                    ],
                },
            };
        },
        mounted () {
            this.initTable();
        },
        computed: {
        },
        methods: {
            //上传图片组件的callback
            uploadOrgSealImg: function (webPath, savePath,) {
                // alert(savePath)
                this.$set(this.info, 'orgSealUrl', webPath);
                this.$set(this.info, 'orgSeal', savePath);
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('system/org/queryCustom', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toAdd: function () {
                this.info = {
                    orderNum: 0
                };
                this.editDrawer = true;
                // this.$refs.editForm.resetFields();

                this.$nextTick(function() {
                    this.$refs.editor.setHtml("");
                });
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "system/org/save";
                    if (!this.info.orgId) {
                        this.info.parentId = this.appCookies.getCookieOID();
                    }
                    this.info.orgDesc = this.$refs.editor.getHtml();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDrawer = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
            },
            toEdit: function (keyId) {
                this.apis.get('system/org/getById?orgId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                            this.$nextTick(function() {
                                this.$refs.editor.setHtml(this.info.orgDesc);
                            });
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (keyId, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        orgId: keyId,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('system/org/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
