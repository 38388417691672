<template>
    <el-row :gutter="32" class="row-container">
        <el-col :span="12">
            <el-result style="background-color: #f0f2f5" icon="success" title="已经拥有微信小程序" subTitle="授权已有的微信小程序，完成服务商模式账户设置，快速获得您的小程序。">
                <template slot="extra">
                    <el-button type="primary" size="medium" @click="gotoPage('AUTHORIZED')">立即授权微信小程序</el-button>
                </template>
            </el-result>
        </el-col>
        <el-col :span="12">
            <el-result style="background-color: #f0f2f5" icon="warning" title="还没有微信小程序（企业或个体户）" subTitle="扫码授权即可获得试用小程序，可复用认证信息快速完成小程序认证，转正的小程序享受免费认证优惠。">
                <template slot="extra">
                    <el-button type="primary" size="medium" @click="gotoPage('BETAREG')">快速注册</el-button>
                </template>
            </el-result>
        </el-col>
    </el-row>
</template>

<script>
export default {
    data() {
        return {
            authUrl: ''
        };
    },
    methods: {
        gotoPage: function(val) {
            this.$emit('callback', val)
            // this.$router.push({
            //     path: url
            // });
        },
    }
};
</script>

<style scoped lang="scss">
.mini-containers {
    width: 100%;
    height: 100%;
}
.item-box {
    display: flex;
    flex-direction: column;
    .title {
        font-weight: bold;
    }
    .content {
        display: flex;
        flex-direction: row;
        .left {
            flex: 1;
        }
        .right {
            width: 150px;
        }
    }
}
</style>
