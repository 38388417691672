<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="form-row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="基本信息" name="tab01">
                        <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="机构编码" prop="orgCode">
                                        <el-input v-model="info.orgCode"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="机构名称" prop="orgName">
                                        <el-input v-model="info.orgName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="机构简称" prop="shortName">
                                        <el-input v-model="info.shortName"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32">
                                <el-col :span="24">
                                    <el-form-item label="所在地" prop="trainAddress">
                                        <AreaSelect
                                            :provinceId.sync="info.provinceId"
                                            :cityId.sync="info.cityId"
                                            :districtId.sync="info.districtId">
                                        </AreaSelect>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="详细地址" prop="orgAddress">
                                <el-input v-model="info.orgAddress"></el-input>
                            </el-form-item>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="联系人姓名" prop="linkName">
                                        <el-input v-model="info.linkName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="联系人电话" prop="linkPhone">
                                        <el-input v-model="info.linkPhone"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="机构类型" prop="orgType" >
                                        <el-radio-group v-model="info.orgType">
                                            <el-radio label="SCHOOL">培训机构</el-radio>
                                            <el-radio label="COMPANY">公司</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="收款方式" prop="payWay" >
                                        <el-radio-group v-model="info.payWay">
                                            <el-radio :label="0">线上线下</el-radio>
                                            <el-radio :label="3">仅线下</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="印章图片">
                                <SingleUploadImg @callback="uploadOrgSealImg" :imageUrl="info.orgSealUrl"
                                                 :width="100" :height="100"></SingleUploadImg>
                            </el-form-item>

                            <el-row :gutter="32">
                                <el-col :span="24" style="text-align: center">
                                    <el-button size="medium" type="primary" @click="saveBase('editForm')">保  存</el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="机构介绍" name="tab02">
                        <el-form :model="descInfo" :rules="ruleValidate" ref="descForm" label-width="140px" label-suffix=":">
                            <el-row :gutter="32">
                                <el-col :span="24">
                                    <el-divider content-position="left">机构简介</el-divider>
                                    <editor ref="editor" :height="400"></editor>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32">
                                <el-col :span="24" style="text-align: center">
                                    <el-button size="medium" type="primary" @click="saveDesc('descForm')">保  存</el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="小程序设置" name="tab03">
                        <MiniappConfig ref="miniappConfig" :orgId="info.orgId"></MiniappConfig>
                    </el-tab-pane>
                    <el-tab-pane label="公众号设置" name="tab04">
                       <MPConfig ref="mpConfig" :orgId="info.orgId"></MPConfig>
                    </el-tab-pane>
                    <el-tab-pane label="支付设置" name="tab05">
                        <PayConfig ref="payConfig" :orgId="info.orgId"></PayConfig>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import SingleUploadImg from "@c/upload/SingleUploadImg";
import editor from "@c/editor/editor";
import AreaSelect from "@v/components/AreaSelect";
import MiniappConfig from "./components/MiniappConfig"
import MPConfig from "./components/MPConfig.vue"
import PayConfig from "./components/PayConfig"
export default {
    components: {
        Breadcrumb,
        SingleUploadImg,
        editor,
        AreaSelect,
        MiniappConfig,
        MPConfig,
        PayConfig
    },
    filters: {
        orgTypeFilter(status) {
            const statusMap = {
                SCHOOL: '培训机构',
                COMPANY: '公司',
            };
            return statusMap[status]
        },
        payWayFilter(status) {
            const statusMap = {
                0: '线上/线下',
                3: '仅线下',
            };
            return statusMap[status]
        },
        payWayColorFilter(status) {
            const statusMap = {
                0: 'success',
                3: 'warning'
            };
            return statusMap[status]
        },
        deleteStatusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger'
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            crumbData: [
                {
                    path: '',
                    name: '首页'
                },
                {
                    path: '',
                    name: '培训机构'
                }
            ],
            activeName: 'tab01',
            info: {
                orderNum: 0,
            },
            descInfo: {},
            //表单验证规则
            ruleValidate: {
                // 视频基本信息
                orgCode: [
                    { required: true, message: '请输入机构编码', trigger: 'blur' }
                ],
                orgName: [
                    { required: true, message: '请输入机构名称', trigger: 'blur' }
                ],
                shortName: [
                    { required: true, message: '请输入机构简称', trigger: 'blur' }
                ],
                orgType: [
                    { required: true, message: '请选择机构类型', trigger: 'blur' }
                ],
                payWay: [
                    { required: true, message: '请选择收款方式', trigger: 'blur' }
                ],
            },
            currOrgId: this.appCookies.getCookieOID()
        };
    },
    mounted () {
        let orgId = this.$route.query.orgId;
        if (orgId) {
            this.currOrgId = orgId
        }
        this.getBaseById();
        this.getDescById();
    },
    computed: {
    },
    methods: {
        getBaseById: function () {
            if (this.currOrgId) {
                this.apis.get('system/org/getBaseById?orgId=' + this.currOrgId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                            this.$nextTick(function() {
                                this.$refs.editor.setHtml(this.info.orgDesc);
                            });
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }
        },
        getDescById: function () {
            if (this.currOrgId) {
                this.apis.get('system/org/getDescById?orgId=' + this.currOrgId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.descInfo = res.data;
                            this.$nextTick(function() {
                                this.$refs.editor.setHtml(this.descInfo.orgDesc);
                            });
                        } else {
                            this.descInfo = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }
        },
        saveBase: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "system/org/saveBase";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.getBaseById();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        saveDesc: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "system/org/saveDesc";
                    this.info.orgDesc = this.$refs.editor.getHtml();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.getDescById();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        //上传图片组件的callback
        uploadOrgSealImg: function (webPath, savePath,) {
            // alert(savePath)
            this.$set(this.info, 'orgSealUrl', webPath);
            this.$set(this.info, 'orgSeal', savePath);
        },
    }
}
</script>

<style scoped>

</style>
