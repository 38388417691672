<template>
    <div id="text-list-wrap" class="text-list-wrap">
        <div class="title">
            通用字段
        </div>
        <ul class="basic-text-wrap">
            <li
                class="basic-text-item"
                v-for="(item, index) in basicTextList"
                :key="index"
                :style="{
                    fontSize: item.fontSize / 2 + 'px',
                    fontWeight: item.fontWeight
                }"
                @click="selectBasicText(item)">
                {{ item.name }}
            </li>
        </ul>
        <div class="title">
            证书模板字段
        </div>
        <ul class="basic-text-wrap">
            <li
                class="basic-text-item"
                v-for="(item, index) in certTextList"
                :key="index"
                :style="{
                    fontSize: item.fontSize / 2 + 'px',
                    fontWeight: item.fontWeight
                }"
                @click="selectBasicText(item)">
                {{ item.name }}
            </li>
        </ul>
        <div class="title">
            分享模板字段
        </div>
        <ul class="basic-text-wrap">
            <li
                class="basic-text-item"
                v-for="(item, index) in shareTextList"
                :key="index"
                :style="{
                    fontSize: item.fontSize / 2 + 'px',
                    fontWeight: item.fontWeight
                }"
                @click="selectBasicText(item)">
                {{ item.name }}
            </li>
        </ul>
        <div class="other-text-wrap">
        </div>
    </div>
</template>

<script>

// 文本组件列表
import wText from "../widgets/wText/wText";
import {
    mapActions
} from 'vuex'

const NAME = 'text-list-wrap'
export default {
    name: NAME,
    data() {
        return {
            basicTextList: [
                {
                    code: 'FIX_TEXT',
                    name: '固定文本',
                    text: '固定文本',
                    fontSize: 40,
                    fontWeight: 'normal' // bold  normal
                },
            ],
            certTextList: [
                {
                    code: 'TRUE_NAME',
                    name: '姓名',
                    text: '李海兵',
                    fontSize: 40,
                    fontWeight: 'normal' // bold  normal
                },
                {
                    code: 'GNVQ_NAME',
                    name: '工种',
                    text: '高级人力资源师',
                    fontSize: 40,
                    fontWeight: 'normal' // bold  normal
                },
                {
                    code: 'LEVEL_NAME',
                    name: '等级',
                    text: '高级',
                    fontSize: 40,
                    fontWeight: 'normal'
                },
                {
                    code: 'CLASS_YEAR',
                    name: '开班-年份',
                    text: '2022',
                    fontSize: 40,
                    fontWeight: 'normal'
                },
                {
                    code: 'CLASS_NUM',
                    name: '开班-期号',
                    text: '2',
                    fontSize: 40,
                    fontWeight: 'normal'
                },
                {
                    code: 'CLASS_ISSUE',
                    name: '开班第几期',
                    text: '2022-02',
                    fontSize: 40,
                    fontWeight: 'normal'
                },
                {
                    code: 'YEAR',
                    name: '发证年',
                    text: '2021',
                    fontSize: 40,
                    fontWeight: 'normal'
                },
                {
                    code: 'MONTH',
                    name: '发证月',
                    text: '10',
                    fontSize: 40,
                    fontWeight: 'normal'
                },
                {
                    code: 'DATE',
                    name: '发证日期',
                    text: '2021-10-18',
                    fontSize: 30,
                    fontWeight: 'normal'
                },
                {
                    code: 'SCORE',
                    name: '证书成绩',
                    text: '优秀',
                    fontSize: 30,
                    fontWeight: 'normal'
                },
                {
                    code: 'SCHOOL_NAME',
                    name: '发证机构',
                    text: '云南良才培训学院',
                    fontSize: 30,
                    fontWeight: 'normal'
                },
                {
                    code: 'ID_CARD',
                    name: '身份证号',
                    text: '372500199004180219',
                    fontSize: 24,
                    fontWeight: 'normal'
                },
                {
                    code: 'CERT_NO',
                    name: '证书编号',
                    text: '210001H00001',
                    fontSize: 24,
                    fontWeight: 'normal'
                },
            ],
            shareTextList: [
                {
                    code: 'TRUE_NAME',
                    name: '分享人姓名',
                    text: '张三丰',
                    fontSize: 30,
                    fontWeight: 'normal' // bold  normal
                },
                {
                    code: 'PHONE',
                    name: '手机号',
                    text: '13866998877',
                    fontSize: 30,
                    fontWeight: 'normal' // bold  normal
                },
                {
                    code: 'CLASS_COURSE_NAME',
                    name: '课程/班级名称',
                    text: '焊工初级课程',
                    fontSize: 30,
                    fontWeight: 'normal' // bold  normal
                },
                {
                    code: 'CLASS_YEAR',
                    name: '开班-年份',
                    text: '2022',
                    fontSize: 30,
                    fontWeight: 'normal'
                },
                {
                    code: 'CLASS_NUM',
                    name: '开班-期号',
                    text: '2',
                    fontSize: 30,
                    fontWeight: 'normal'
                },
                {
                    code: 'CLASS_ISSUE',
                    name: '开班第几期',
                    text: '2022-02',
                    fontSize: 30,
                    fontWeight: 'normal'
                },
            ],
        }
    },
    methods: {
        ...mapActions([
            'addWidget'
        ]),
        selectBasicText(item) {
            let setting = JSON.parse(JSON.stringify(wText.setting))
            setting.code = item.code;
            setting.name = '文本：' + item.name;
            setting.text = item.text;
            setting.fontSize = item.fontSize;
            setting.fontWeight = item.fontWeight;
            this.addWidget(setting)
        }
    }
}
</script>

<style lang="scss" scoped>
//@import '~STYLUS/page-design.styl'
.text-list-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .title {
        padding: 5px 20px;
    }
    .basic-text-wrap {
        width: 100%;
        border-bottom: 1px solid #092625;
        padding: 10px 0;
        .basic-text-item {
            width: 100%;
            text-align: center;
            padding: 5px 0;
            color: #ffffff;
            border-top: 1px solid #ffffff00;
            border-bottom: 1px solid #ffffff00;
            cursor: pointer;

            &:hover {
                border-top: 1px solid #3B74F1;
                border-bottom: 1px solid #3B74F1;
                background-color: #262c33;
            }
        }
    }

    .other-text-wrap {
        width: 100%;
        flex: 1;
        overflow: auto;
    }
}
</style>
