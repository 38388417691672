<template>
    <div v-loading="mainLoading"
         :element-loading-text="mainLoadingText" class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never"  style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>对账明细表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item style="width: 160px"  v-if="this.privRange.havePriv()">
                                    <el-select v-model="queryParams.companyId" placeholder="请选择培训机构" clearable>
                                        <el-option label="全部" value=""></el-option>
                                        <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.classId" placeholder="请选择班级" clearable style="width:120px">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="item in classList" :value="item.classId" :label="item.yearNum + '-' + item.classNum"  :key="item.classId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-date-picker style="width: 140px"
                                        v-model="queryParams.startDate"
                                        type="date"
                                        placeholder="选择日期"
                                        @change="queryParams.startDate=$event">
                                    </el-date-picker>
                                    <span style="padding: 0 10px">至</span>
                                    <el-date-picker style="width: 140px"
                                        v-model="queryParams.endDate"
                                        type="date"
                                        placeholder="选择日期"
                                        @change="queryParams.endDate=$event">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item style="width: 130px">
                                    <el-select v-model="queryParams.payWay" placeholder="请选择支付方式">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="线下支付" value="3"></el-option>
                                        <el-option label="微信支付" value="1"></el-option>
                                        <el-option label="支付宝支付" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-download" @click="toExport">导出</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="培训机构" prop="companyName" min-width="160px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种" prop="gnvqName" min-width="140px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName" min-width="80px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="班级" prop="className" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="姓名" prop="trueName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="身份证号" prop="idCard" min-width="140px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="联系电话" prop="phone" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="报名金额" prop="priceValue" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="支付方式" prop="payWayName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="支付时间" prop="payDate" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="审核状态" prop="confirmName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                            </el-table>

                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="信息编辑"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            direction="rtl"
            :wrapperClosable="false"
            :destroy-on-close="true"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-row :gutter="32">
                        <el-col :span="8">
                            <el-form-item label="名称" prop="name">
                                <el-input v-model="info.name" placeholder="请输入名称"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">取消</el-button>
                <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    export default {
        name: "AccountDtl",
        components: {
            Pagination,
            Breadcrumb,
            GnvqTree
        },
        filters: {
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                mainLoading: false,
                mainLoadingText: '正在导出数据，请稍等',
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '财务管理'
                    },
                    {
                        path: '',
                        name: '对账明细'
                    }
                ],
                //视频列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    startDate: '',
                    endDate: '',
                    payWay: '',
                    payStatus: '',
                    companyId: this.appCookies.getCookieOID(),
                    orgId: this.appCookies.getCookieTOID(),
                    userId: this.appCookies.getCookieUID(),
                    classId: '',
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                },
                //视频列表数据
                tableData: [],
                orgList: [],
                classList: [],
                tableLoading: false,
                //编辑、新增表单
                editDrawer: false,
                info: {},
                //表单验证规则
                ruleValidate: {
                    // 视频基本信息
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                },
            };
        },
        mounted () {
            this.initTable();
            this.getOrgList();
        },
        computed: {
        },
        methods: {
            toQuery: function (data) {
                // console.log(data)
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.getClassList();
                this.refreshTable();
            },
            getOrgList: function () {
                if (!this.privRange.havePriv()){
                    return
                }
                this.apis.get('system/org/getListForSelect/' + this.appCookies.getCookieUID() + '?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.orgList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            getClassList: function () {
                if (!this.queryParams.companyId || !this.queryParams.levelId || !this.queryParams.gnvqId){
                    this.classList = [];
                    return;
                }
                let data = {
                    companyId: this.queryParams.companyId,
                    levelId: this.queryParams.levelId,
                    gnvqId: this.queryParams.gnvqId,
                }
                this.apis.get('course/class/getList', data)
                    .then((res) => {
                        console.log(res)
                        if (res.code === 1) {
                            this.classList = res.data || [];
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('account/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            //导出
            toExport: function () {
                this.mainLoading = true;
                this.mainLoadingText = '正在导出数据，请稍等';
                this.apis.axios()({
                    method: 'post',
                    url: 'account/export',
                    params: this.$http.adornParams(this.queryParams),
                    'responseType': 'blob'
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '导出数据失败',
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    this.downFile(res, year.toString() + timestamp.toString() + '.xlsx')
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            downFile(content, fileName) {
                let blob = new Blob([content], {
                    type: 'application/vnd.ms-excel'
                });
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = objectUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
