<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never"  style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>字典类型</span>
                    </div>
                    <el-table
                        fit
                        highlight-current-row
                        :show-header="false"
                        :data="dictTypeList"
                        @row-click="cellClick"
                        style="width: 100%">
                        <el-table-column
                            prop="typeName"
                            label="日期">
                            <template slot-scope="{row}">
                                <span>【{{ row.typeCode}}】{{ row.typeName}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>字典列表</span>
                    </div>
                    <el-row  class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row  class="table-container">
                        <el-col :span="24">
                            <el-table
                                :key="tableKey"
                                v-loading="tableLoading"
                                :data="tableData"
                                border
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="elementTable"
                                :indent="20"
                                @sort-change="sortChange">
                                <el-table-column label="编号" min-width="60px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.dictCode}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="名称" align="left" width="200" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <span>{{ row.dictName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="类型" min-width="150" align="center">
                                    <template slot-scope="{row}">
                                        {{ row.typeName}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="排序号" width="80px" align="center">
                                    <template slot-scope="{row}">
                                        {{ row.orderNum }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" min-width="100" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | statusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作时间" width="140px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.modifyTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="180" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <div v-if="row.systemStandard===0">
                                            <el-button type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.dictId)">修改</el-button>
                                            <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.dictId, 1)">删除</el-button>
                                            <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.dictId, 0)">恢复</el-button>
                                            <el-button v-if="row.deleteStatus===0 && row.typeCode === '0004'" type="text" size="mini" icon="el-icon-edit" @click="toPointEditor(row.dictId)">知识点</el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog
            title="字典编辑"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
                <el-form-item label="字典编码" prop="dictCode">
                    <el-input v-model="info.dictCode"></el-input>
                </el-form-item>
                <el-form-item label="字典名称" prop="dictName">
                    <el-input v-model="info.dictName"></el-input>
                </el-form-item>
                <el-form-item label="字典类型" prop="typeCode">
                    <el-select v-model="info.typeCode" placeholder="请选择">
                        <el-option
                            v-for="item in dictTypeList"
                            :key="item.typeCode"
                            :label="item.typeName"
                            :value="item.typeCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序号" prop="orderNum">
                    <el-input v-model="info.orderNum"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>
        <!--知识点编辑 -->
        <GnvqLevelPointEditor ref="pointEditor"></GnvqLevelPointEditor>
    </div>
</template>

<script>
    // import { parseTime } from '@/utils'
    import Pagination from '@/components/Pagination' // secondary package based on el-pagination
    import Breadcrumb from "../../components/Breadcrumb";
    import GnvqLevelPointEditor from "../gnvq/GnvqLevelPointEditor";
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqLevelPointEditor
        },
        filters: {
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger'
                };
                return statusMap[status]
            },
            statusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            }
        },
        data() {
            return {
                crumbData: [
                    {
                        path: '/main',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '字典管理'
                    }
                ],
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    typeCode: '',
                    deleteStatus: '0',
                    orgId: this.appCookies.getCookieOID(),
                    sort: ''
                },
                tableKey: 0,
                tableData: [],
                tableLoading: false,
                sortOptions: [{
                    label: 'ID Ascending',
                    key: '+id'
                }, {
                    label: 'ID Descending',
                    key: '-id'
                }],
                statusOptions: ['published', 'draft', 'deleted'],
                info: {},
                ruleValidate: {
                    dictCode: [
                        { required: true, message: '请输入编码', trigger: 'blur' }
                    ],
                    dictName: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                    typeCode: [
                        { required: true, message: '请选择类型', trigger: 'change' }
                    ],
                    orderNum: [
                        { required: true, message: '请输入排序号', trigger: 'blur' }
                    ],
                },
                editDialog: false,
                dictTypeList: [],
            }
        },
        mounted() {
            this.getDictTypeList();
            this.initTable();
        },
        methods: {
            getDictTypeList: function () {
                this.apis.get('system/dicttype/getList')
                    .then((res) => {
                        if (res.code === 1) {
                            this.dictTypeList = res.data
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            cellClick: function(row) {
                this.queryParams.typeCode = row.typeCode;
                this.refreshTable();
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('system/dict/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            sortChange(data) {
                const { prop, order } = data;
                console.log(prop, order)
                // if (prop === 'id') {
                //     this.sortByID(order)
                // }
            },
            getSortClass: function(key) {
                const sort = this.queryParams.sort;
                return sort === `+${key}` ? 'ascending' : 'descending'
            },
            toAdd: function () {
                this.info = {
                    typeCode: this.queryParams.typeCode,
                    orderNum: 0
                };
                this.editDialog = true;
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "system/dict/save";
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME() ;
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editDialog = false;
                                    this.$message({
                                        message: '操作成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (keyId) {
                this.apis.get('system/dict/getById?dictId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDialog = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {

                this.$confirm('确定要删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        dictId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieTNAME()
                    };
                    this.apis.postForm('system/dict/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.initTable();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            toPointEditor: function (dictId) {
                this.$refs.pointEditor.show(1, '', dictId);
            },
        }
    }
</script>
<style lang="scss" scoped>
</style>
