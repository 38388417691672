<template>
    <div v-loading="mainLoading"
         :element-loading-text="mainLoadingText" class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>支付单查询</span>
                        <div style="float: right; padding: 0px 0">
                            <!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
                            <!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-date-picker
                                        v-model="queryParams.startDate"
                                        type="date"
                                        style="width: 140px"
                                        placeholder="选择日期"
                                        @change="queryParams.startDate=$event">
                                    </el-date-picker>
                                    <span style="padding: 0 10px">至</span>
                                    <el-date-picker
                                        v-model="queryParams.endDate"
                                        type="date"
                                        style="width: 140px"
                                        placeholder="选择日期"
                                        @change="queryParams.endDate=$event">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item style="width: 140px">
                                    <el-select v-model="queryParams.payStatus" filterable placeholder="支付状态" >
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="已支付" value="0"></el-option>
                                        <el-option label="未支付" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="width: 140px">
                                    <el-select v-model="queryParams.payWay" filterable placeholder="请选择支付方式">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="线下支付" value="3"></el-option>
                                        <el-option label="微信支付" value="1"></el-option>
                                        <el-option label="支付宝支付" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="width: 140px">
                                    <el-select v-model="queryParams.deleteStatus" filterable placeholder="取消状态">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已取消" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>

                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="退款单单号" prop="refundCode" min-width="110px" align="center"></el-table-column>
                                <el-table-column label="退款金额" prop="refundValue" width="100" align="center"></el-table-column>
                                <el-table-column label="申请退款时间" prop="createTime" width="160" align="center"></el-table-column>
                                <el-table-column label="退款时间" prop="refundDate" width="160" align="center"></el-table-column>
                                <el-table-column label="退款方式" min-width="80" align="center">
                                    <template slot-scope="{row}">
                                        {{ row.refundWay  | payWayFilter}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="申请人" prop="applyUser" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="退款状态" min-width="80" align="center">
                                    <template slot-scope="{row}">
                                        {{ row.refundStatus  | payStatusFilter}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="退款原因" prop="refundReason" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="备注" prop="remarks" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="80" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.refundStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toRefund(row.refundId)">确认</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                    <!-- 分页组件 -->
                    <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                @pagination="initTable"/>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
export default {
    name: "AccountSum",
    components: {
        Pagination,
        Breadcrumb,
    },
    filters: {
        payStatusFilter(status) {
            const statusMap = {
                2: '已退款',
                1: '退款中',
                0: '待确认',
            };
            return statusMap[status]
        },
        payWayFilter(status) {
            const statusMap = {
                1: '微信',
                2: '支付宝',
                3: '线下'
            };
            return statusMap[status]
        },
        orderTypeFilter(status) {
            const statusMap = {
                'ENROLL': '考试报名',
                'BUY': '购买课程',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'danger',
                1: 'success'
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            mainLoading: false,
            mainLoadingText: '正在导出数据，请稍等',
            crumbData: [
                {
                    path: '',
                    name: '首页'
                },
                {
                    path: '',
                    name: '财务管理'
                },
                {
                    path: '',
                    name: '退款管理'
                }
            ],
            //视频列表查询数据
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                startDate: '',
                endDate: '',
                payWay: '',
                orderStatus: 1,
                deleteStatus: '',
                orgId: this.appCookies.getCookieOID()
            },
            //视频列表数据
            tableData: [],
            tableLoading: false,
        };
    },
    mounted () {
        this.initTable();
    },
    computed: {
    },
    methods: {
        //获取列表
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('/user/refund/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        toRefund: function (refundId) {
            this.$confirm('确定进行退款？', "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                let data = {
                    refundId: refundId,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                }
                this.apis.postForm('user/refund/refund', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '提交成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.refundModal = false;
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.row-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    .img {
        height: 100px;
        width: 140px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }
    }
    .item-info {
        height: 100%;
        flex: 1;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            .label {
                min-width: 80px;
                text-align: right;
            }
            .value {
                text-align: left;
            }
            .strong {
                font-weight: bold;
            }
        }
    }
}
</style>
