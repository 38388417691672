<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container" v-loading="mainLoading"
                :element-loading-text="mainLoadingText">
            <!-- 列表主体 -->
            <el-col :span="24">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>任务列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:150px"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="queryParams.resultStatus" placeholder="请选择状态" style="width:150px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="执行中" value="0"></el-option>
                                        <el-option label="已完成" value="1"></el-option>
                                        <el-option label="发生错误" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="任务名称" prop="taskName" min-width="160px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="创建时间" min-width="100px" align="left">
                                    <template slot-scope="{row}">
                                        <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="结束时间" min-width="100px" align="left">
                                    <template slot-scope="{row}">
                                        <span>{{ row.modifyTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.resultStatus | colorFilter">
                                            {{ row.resultStatus  | dataStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="信息" prop="resultMsg" min-width="160px" align="left" :show-overflow-tooltip="true"></el-table-column>

                                <el-table-column label="操作" align="center" width="100" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.resultStatus===1"  type="text" size="mini" icon="el-icon-edit" @click="download(row.taskId, row.taskName, row.filePath)">下载</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    export default {
        components: {
            Pagination,
            Breadcrumb,
        },
        filters: {
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    1: 'success',
                    0: 'danger',
                    2: 'danger',
                };
                return statusMap[status]
            },
            dataStatusFilter(status) {
                const statusMap = {
                    0: '执行中',
                    1: '已完成',
                    2: '发生错误',
                };
                return statusMap[status]
            },
        },
        data () {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '下载任务'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    deleteStatus: 0,
                    resultStatus: '',
                    userId: this.appCookies.getCookieUID(),
                },
                orgId: this.appCookies.getCookieOID(),
                //列表数据
                tableData: [],
                tableLoading: false,
                //机构下拉菜单
                orgList: [],
                //编辑、新增表单
                editDrawer: false,
                info: {},
                mainLoading: false,
                mainLoadingText: '正在下载中，请稍等',
            };
        },
        mounted () {
            this.initTable();
            this.$nextTick(function() {
            });
        },
        computed: {
        },
        methods: {
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('/system/export/task/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            download: function (taskId, taskName, filePath) {
                this.mainLoading = true;
                this.mainLoadingText = '正在下载，请稍等';
                let data = {
                    taskId: taskId
                }
                this.apis.axios()({
                    method: 'post',
                    url: "/export/jobs/publish/download",
                    params: this.apis.adornParams(data),
                    'responseType': 'blob',
                    useToken: true
                }, false, 'json').then((res) => {
                    if (res.type === 'application/json') {
                        this.$message({
                            message: '下载失败',
                            showClose: true, offset: 50, type: 'error'
                        });
                        this.mainLoading = false;
                        return false
                    }
                    let date = new Date();
                    // let year = date.getFullYear();
                    let timestamp = date.valueOf();
                    if (filePath.indexOf(".zip")) {
                        this.downFile(res, taskName + timestamp.toString() + '.zip')
                    } else {
                        this.downFile(res, taskName + timestamp.toString() + '.xlsx')
                    }
                    this.mainLoading = false;
                }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            /**
             * 文件流下载
             */
            downFile(content, fileName) {
                let type = 'application/vnd.ms-excel'
                if (fileName.indexOf(".zip")) {
                    type = 'application/zip'
                }
                let blob = new Blob([content], {
                    type: type
                });
                let objectUrl = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = objectUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
