<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="24">
                <el-row :gutter="8" class="query-container">
                    <el-col :span="24">
                        <el-form :inline="true">
                            <el-form-item label="">
                                <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                            </el-form-item><el-form-item style="width: 120px">
                            <el-select v-model="queryParams.delState" placeholder="请选择状态">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="正常" value="0"></el-option>
                                <el-option label="已删除" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                            </el-form-item>
                            <el-button-group style="float: right">
                                <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                            </el-button-group>
                        </el-form>
                    </el-col>
                </el-row>
                <el-row :gutter="8" class="table-container">
                    <el-col>
                        <el-table
                            :key="tableKey"
                            v-loading="tableLoading"
                            :data="tableData"
                            fit
                            height="calc(100vh - 240px)"
                            highlight-current-row
                            style="width: 100%;"
                            ref="queryTable"
                            :indent="20"
                        >
                            <el-table-column label="编码" prop="ebookCode" width="150px" align="left"></el-table-column>
                            <el-table-column label="名称" prop="ebookName" align="left"  :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="状态" min-width="100" align="center">
                                <template slot-scope="{row}">
                                    <el-tag :type="row.delState | colorFilter">
                                        {{ row.delState  | statusFilter}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作时间" width="140px" align="center">
                                <template slot-scope="{row}">
                                    <span>{{ row.updateTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                                <template slot-scope="{row}">
                                    <el-button v-if="row.delState===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.ebookId)">修改</el-button>
                                    <el-button type="text" size="mini"  icon="el-icon-delete" @click="toEditorContent(row.ebookId)">内容编辑</el-button>
                                    <el-button v-if="row.delState===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.ebookId, 1)">删除</el-button>
                                    <el-button v-if="row.delState===1" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.ebookId, 0)">恢复</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                    :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                    :first-zero="0"
                                    @pagination="initTable"/>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-dialog
            :title="editDialogTitle"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="createForm" label-width="120px" label-suffix=":">
                <el-form-item label="编码" prop="ebookCode">
                    <el-input v-model="info.ebookCode"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="ebookName">
                    <el-input v-model="info.ebookName"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('createForm')">保 存</el-button>
            </span>
        </el-dialog>
        <EbookContentEdit ref="ebookContentEdit"></EbookContentEdit>
    </div>
</template>

<script>
// import { parseTime } from '@/utils'
import Pagination from '@/components/Pagination'
import Breadcrumb from "../../../components/Breadcrumb";
import EbookContentEdit from './components/EbookContentEdit'
export default {
    components: {
        Pagination,
        Breadcrumb,
        EbookContentEdit
    },
    filters: {
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger'
            };
            return statusMap[status]
        },
        typeFilter(status) {
            const statusMap = {
                1: '体育局',
                9: '公司',
            };
            return statusMap[status]
        },
        statusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        }
    },
    data() {
        return {
            crumbData: [
                {
                    path: '/dashboard',
                    name: '首页'
                },
                {
                    path: '',
                    name: '手册管理'
                }
            ],
            queryParams: {
                userId: this.appCookies.getCookieUID(),
                pageNumber: 1,
                pageSize: 10,
                totalRecord: 0,
                typeCode: '',
                keyword: '',
                delState: '0'
            },
            tableKey: 0,
            tableData: [],
            tableLoading: false,
            info: {
                ebookCode: '',
                ebookName: ''
            },
            ruleValidate: {
                ebookName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
            },
            editDialogTitle: '',
            editDialog: false,
            ebookTypeList: []
        }
    },
    mounted() {
        this.initTable();
    },
    methods: {
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('system/ebook/info/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 1;
            this.initTable();
        },
        toAdd: function () {
            this.info = {};
            this.editDialogTitle = '新增';
            this.editDialog= true;
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "system/ebook/info/save";
                    this.info.createUid = this.appCookies.getCookieUID();
                    this.info.createUname = this.appCookies.getCookieTNAME();
                    this.info.updateUid = this.appCookies.getCookieUID();
                    this.info.updateUname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (keyId) {
            this.apis.get('system/ebook/info/getById?ebookId=' + keyId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        this.editDialogTitle = '修改';
                        this.editDialog = true;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toEditorContent: function (ebookId) {
            this.$refs.ebookContentEdit.open(ebookId)
        },
        toDelete: function (id, delState) {
            this.$confirm('确定要删除此条记录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    ebookId: id,
                    delState: delState,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieTNAME()
                };
                this.apis.postForm('system/ebook/info/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
