<template>
    <div class="mini-containers">
        <el-row :gutter="32" class="row-container">
            <el-col :span="24">
                <div class="tip">
                    <p>
                        授权已有的微信小程序，完成服务商模式账户设置，快速获得您的小程序。
                    </p>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="32" class="row-container">
            <el-col :span="20" :offset="2" style="margin-bottom: 20px">
                授权并发布小程序
            </el-col>
            <el-col :span="20" :offset="2">
                <el-steps direction="vertical" :active="0">
                    <el-step v-for="(item, index) in activities"
                             :key="index"
                             :title="item.title"
                             :description="item.content">
                        <div slot="description" class="item-box">
                            <div class="content">
                                <div class="left">{{item.content}}</div>
                                <div class="right">
                                    <el-link v-if="miniInfo.authorized===0 && authUrl!==''" :href="authUrl" type="primary" :underline="false">立即授权</el-link>
                                    <div v-if="item.stepIndex===2" style="display: flex;">
                                        <el-button v-if="miniInfo.appId" type="text" size="mini" icon="el-icon-view" @click="toEdit(miniInfo.appId)">小程序设置</el-button>
                                        <el-button v-if="miniInfo.appId" type="text" size="mini"  icon="el-icon-refresh-right" @click="mgrCode(miniInfo.appId)">版本管理</el-button>
                                        <el-button v-if="miniInfo.appId" type="text" size="mini"  icon="el-icon-refresh" @click="mgrTempl(miniInfo.appId, miniInfo.orgId)">消息模板</el-button>
                                    </div>
                                    <template v-if="item.stepIndex===1">
                                        <div style="display: flex; flex-direction: column">
                                            <div style="display: flex;">
                                                <el-link v-if="miniInfo.stepIndex===0 && authUrl!==''" :href="authUrl" type="primary" :underline="false">立即授权</el-link>
<!--                                                <el-link v-else type="primary" :href="authUrl" :underline="false">更新授权</el-link>-->
                                                <el-button v-if="miniInfo.stepIndex!==0" type="primary" size="small" @click="toPublish">发布体验版</el-button>
                                                <el-button v-if="miniInfo.stepIndex!==0" type="primary" size="small" @click="getTiyanQrcode">体验码</el-button>
                                            </div>
                                        </div>
                                    </template>
                                    <el-button v-else type="primary" size="small" disabled>{{item.btnName}}</el-button>
                                </div>
                            </div>
                        </div>
                    </el-step>
                </el-steps>
            </el-col>
        </el-row>
        <QrDialog ref="qrDialog"></QrDialog>
        <MiniInfo ref="miniInfo"></MiniInfo>
        <MiniCode ref="miniCode"></MiniCode>
        <SubTmplate ref="subTmplate"></SubTmplate>
    </div>
</template>

<script>
import { Message } from "element-ui";
import QrDialog from "@v/miniapp/components/QrDialog.vue";
import MiniCode from "@v/miniapp/components/MiniCode.vue";
import MiniInfo from "@v/miniapp/components/MiniInfo.vue";
import SubTmplate from "@v/miniapp/components/SubTmplate.vue";
export default {
    components: {
        QrDialog,
        MiniCode,
        MiniInfo,
        SubTmplate
    },
    props: {
        openAppId: {
            type: [String, Number],
            default: ''
        },
        authUrl: {
            type: String,
            default: ''
        },
    },
    watch: {
        authUrl: {
            deep: true,
            immediate: true,
            handler(val) {
                this.authUrl = val;
            }
        },
        openAppId: {
            deep: true,
            immediate: true,
            handler(val) {
                if (val) {
                    this.openAppId = val;
                    this.getById()
                }
            }
        }
    },
    data() {
        return {
            activities: [{
                title: '已注册微信小程序，立即授权发布',
                content: '使用微信小程序管理员账号扫码进行授权，授权过程中请勾选所有权限以确保小程序功能完整性。如未注册小程序，可从公众号后台免费微信认证创建小程序或直接前往“微信公众平台”注册企业主体的小程序账号。',
                btnName: '立即授权',
                stepIndex: 1
            }, {
                title: '设置微信服务商模式账户 (非必须)',
                content: '开通微信支付能力，微信服务商模式账户的主体需与授权的小鹅通主体一致。若跳过此步骤，用户将无法在小程序购买商品。 ',
                btnName: '去开通',
                stepIndex: 2
            }, {
                title: '完成所有准备，提交审核并发布小程序',
                content: '提交微信审核（最长14个工作日），审核通过后即可立即发布。',
                btnName: '去发布',
                stepIndex: 3
            }],
            authorizationInfo: {},
            authInfo: {},
            miniInfo: {
                stepIndex: 0
            },
            tiyanQrModal: false,
            tiyanQrUrl: '',
        };
    },
    mounted() {
        if (this.$route.query.auth_code) {
            this.getPreAuthCode(this.$route.query.auth_code);
        }
        // this.getPreAuthUrl()
    },
    methods: {
        mgrTempl: function (appId, orgId) {
            this.$refs.subTmplate.open(appId, orgId)
        },
        mgrCode: function (appId) {
            this.$refs.miniCode.open(appId)
        },
        toEdit: function (appId) {
            this.$refs.miniInfo.open(appId)
        },
        getById: function () {
            this.apis.get('/wx/open/app/getById?openAppId=' + this.openAppId)
                .then((res) => {
                    if (res.code === 1) {
                        this.miniInfo = res.data || {};
                    } else {
                        this.miniInfo = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getPreAuthUrl: function () {
            this.apis.get('/wx/open/auth/getPreAuthUrl')
                .then((res) => {
                    if (res.code === 1) {
                        this.authUrl = res.data;
                    } else {
                        Message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        })
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getPreAuthCode: function (auth_code) {
            this.apis.get('/wx/open/auth/getPreAuthCode?auth_code=' + auth_code)
                .then((res) => {
                    if (res.code === 1) {
                        this.authorizationInfo = res.data;
                        this.getAuthorizerInfo(this.authorizationInfo.authorizationInfo.authorizerAppid);
                    } else {
                        Message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        })
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getAuthorizerInfo: function (appId) {
            let data = {
                appId: appId,
                userId: this.appCookies.getCookieUID(),
                orgId: this.appCookies.getCookieOID(),
            }
            this.apis.get('/wx/open/auth/getAuthorizerInfo', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.authInfo = res.data;
                    } else {
                        Message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        })
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toPublish: function () {
            this.$confirm('确定要发布？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    appId: this.miniInfo.appId,
                    templateId: '',
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('wx/open/mini/code/publishMiniApp', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '发布成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        getTiyanQrcode: function () {
            this.$refs.qrDialog.open(this.miniInfo.appId)
        },
    }
};
</script>

<style scoped lang="scss">
.mini-containers {
    width: 100%;
    height: 100%;
}
::v-deep {
    .el-step__description {
        padding-right: 0;
    }
}
.item-box {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #DBDBDB;
    margin: 10px 0 20px 0;
    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        .left {
            flex: 1;
            width: 0;
        }
        .right {
            width: 150px;
            text-align: center;
        }
    }
}

.tip {
    padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;
    p {
        font-size: 14px;
        color: #5e6d82;
        line-height: 1.5em;
    }
    a {
        color: #409eff;
        text-decoration: none;
    }
}
</style>
