<template>
    <el-dialog
        title="发布体验版"
        :visible.sync="editDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="1050px"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" >
            <el-col :span="24">
                <el-form :model="info" ref="editForm" label-width="80px" label-suffix=":">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="版本号">
                                <el-input v-model="info.version" placeholder="请输入版本号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="16">
                            <el-form-item label="版本描述">
                                <el-input v-model="info.versionDesc" placeholder="请输入版本描述"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tempListData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                :header-cell-style="{background:'#eef1f6', color:'#606266'}"
                                ref="queryTable"
                                :indent="20"
                                :default-sort = "{prop: 'userVersion', order: 'descending'}"
                            >
                                <el-table-column label="选择" align="center" width="100">
                                    <template scope="scope">
                                    <el-radio :label="scope.$index" v-model="radio"
                                                @change.native="getCurrentRow(scope.row)">&nbsp;{{scope.row.templateId}}</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column label="小程序名称" prop="sourceMiniProgram" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="描述" prop="userDesc" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="版本" prop="userVersion" width="150px" align="left"></el-table-column>
                                <el-table-column label="更新时间" prop="createTime" width="120" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false">关 闭</el-button>
            <el-button type="primary"  @click="confirm">确 定</el-button>
<!--            v-if="info.canApply"-->
        </span>
    </el-dialog>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            tempListData: [],
            tableLoading: false,
            editDialog: false,
            appId: '',
            info: {
                appId: '',
                version: '',
                versionDesc: '',
                templateId: '',
            },
            templateSelection: {},
            radio: '',
            tableData: []
        }
    },
    mounted() {
    },
    methods: {
        getCurrentRow(row){
            // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
            this.templateSelection = row
            this.info.templateId = this.templateSelection.templateId
            console.log(this.info.templateId)
            this.info.version = this.templateSelection.userVersion
            this.info.versionDesc = this.templateSelection.userDesc
        },
        queryTempList: function (templateType) {
            this.tableLoading = true;
            this.apis.postForm('wx/open/mini/mgr/getTemplateList?templateType=' + templateType)
                .then((res) => {
                    if (res.code === 1) {
                        this.tempListData = res.data;
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        open: function (appId) {
            this.editDialog = true;
            this.appId = appId;
            this.info = {
                version: '',
                versionDesc: '',
                templateId: '',
            }
            this.queryTempList(0);
        },
        confirm: function () {
            // check 是否输入
            if (this.info.version === '') {
                this.$message({
                    message: '请输入版本号',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return
            } else if (this.info.versionDesc === ''){
                this.$message({
                    message: '请输入版本描述',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return
            } else if (this.info.templateId === '') {
                this.$message({
                    message: '请选择发布的模板',
                    showClose: true,
                    offset: 200,
                    type: 'error'
                });
                return
            }
            this.$confirm('确定要发布体验版？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('callback', this.info.version, this.info.versionDesc, this.info.templateId)
                this.editDialog = false
            }).catch(() => {
            });
        }
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
