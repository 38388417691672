<template>
    <div class="app-container"
         v-loading="mainLoading"
         :element-loading-text="mainLoadingText">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row class="row-container">
            <el-col :span="24">
                <el-form :inline="true">
                    <el-form-item label="">
                        <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="queryParams.certTmplType" placeholder="类型"  style="width: 140px">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="证书" value="CERT"></el-option>
                            <el-option label="分享(H5/小程序)" value="SHARE"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="queryParams.deleteStatus" placeholder="删除状态"  style="width: 120px">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="正常" value="0"></el-option>
                            <el-option label="已删除" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
                    </el-form-item>
                    <el-button-group style="float: right">
                        <el-button class="filter-item" type="success" round icon="el-icon-plus" @click="toAdd">添加证书模板</el-button>
                    </el-button-group>
                </el-form>
            </el-col>
        </el-row>
        <el-row class="row-container table-container">
            <el-col>
                <el-table
                    :key="tableKey"
                    v-loading="tableLoading"
                    :data="tableData"
                    fit
                    border
                    highlight-current-row
                    style="width: 100%;"
                    height="calc(100vh - 250px)"
                    ref="queryTable">
                    <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                    <el-table-column label="模板名称" prop="certTmplName" min-width="120px" align="left" :show-overflow-tooltip="true"></el-table-column>

                    <el-table-column label="模板类型" width="140px" align="center">
                        <template slot-scope="{row}">
                            <span>{{ row.certTmplType | tmplTypeFilter }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="模板图片" prop="certTmplImgUrl" width="200" align="center">
                        <template slot-scope="{row}">
                            <el-image v-if="row.certTmplImgUrl"
                                      style="width: auto; height: 30px"
                                      :src="row.certTmplImgUrl"
                                      fit="fill"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="启用/禁用" width="100" align="center">
                        <template slot-scope="{row}">
                            <el-switch
                                v-model="row.useStatus"
                                :active-value="1"
                                :inactive-value="0"
                                @change="updUseStatus(row)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" width="160px" align="center">
                        <template slot-scope="{row}">
                            {{row.createTime}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="260" fixed="right" class-name="small-padding fixed-width">
                        <template slot-scope="{row}">
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit"
                                       @click="toEdit(row.certTmplId)">修改</el-button>
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit"
                                       @click="toDesign(row.certTmplId)">模板设计</el-button>
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit"
                                       @click="toTest(row.certTmplId, row.certTmplType)">测试</el-button>
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-delete"
                                       @click="toDelete(row.certTmplId, 1)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                            :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                            @pagination="initTable"/>
            </el-col>
        </el-row>
        <el-drawer
            title="模板信息"
            :visible.sync="editDrawer"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            size="50%">
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="模板名称" prop="certTmplName">
                                <el-input v-model="info.certTmplName" placeholder="请输入模板名称"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="模板类型" prop="certTmplType">
                                <el-select v-model="info.certTmplType" placeholder="请选择模板类型">
                                    <el-option label="证书" value="CERT"></el-option>
                                    <el-option label="分享(H5/小程序)" value="SHARE"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="模板图片" required>
                                <SingleUploadImg @callback="callUploadImg" :imageUrl="info.certTmplImgUrl" :height="140" :width="180"></SingleUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer=false">关闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </div>
        </el-drawer>
        <el-drawer
            title="模板设计"
            :visible.sync="tmplDesignDrawer"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            size="90%">
            <div class="demo-drawer__content">
                <PageDesign ref="pageDesign" @callback="updConf"></PageDesign>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="tmplDesignDrawer=false">关闭</el-button>
            </div>
        </el-drawer>
        <el-dialog
            title="预览"
            :visible.sync="previewDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <img :src="certImgUrl" style="width: auto; height: 500px;">
            <span slot="footer" class="dialog-footer">
                <el-button @click="previewDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    // import { parseTime } from '@/utils'
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import SingleUploadImg from "../../components/certEditor/upload/SingleUploadImage";
    import PageDesign from "../../components/certEditor/Index.vue";
    export default {
        components: {
            Pagination,
            SingleUploadImg,
            PageDesign,
            Breadcrumb
        },
        filters: {
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger'
                };
                return statusMap[status]
            },
            statusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            confirmFilter(status) {
                const statusMap = {
                    0: '确认中',
                    1: '确认通过',
                    2: '不通过',
                };
                return statusMap[status]
            },
            tmplTypeFilter(type) {
                const typeMap = {
                    'CERT': '证书模板',
                    'SHARE': 'H5/小程序分享'
                };
                return typeMap[type]
            }
        },
        data() {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '图片模板'
                    }
                ],
                loginUserId: this.appCookies.getCookieUID(),
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    certTmplType: '',
                    orgId: this.appCookies.getCookieOID()
                },
                tableKey: 0,
                tableData: [],
                tableLoading: false,
                info: {},
                editDrawer: false,
                mainLoading: false,
                mainLoadingText: '正在处理中，请稍等',
                ruleValidate: {
                    certTmplName: [
                        { required: true, message: '请输入模板名称', trigger: 'blur' }
                    ],
                    certTmplType: [
                        { required: true, message: '请选择模板类型', trigger: 'change' }
                    ],
                },
                tmplDesignDrawer: false,
                previewDialog: false,
                certImgUrl: ''
            }
        },
        mounted() {
            this.refreshTable();
        },
        methods: {
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('system/certtmpl/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toAdd: function () {
                this.info = {
                    orderNum: 0
                };
                this.editDialogTitle = '新建';
                this.editDrawer = true;
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "system/certtmpl/save";
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME();
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME();
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.initTable();
                                    this.$message({
                                        message: '操作成功',
                                        showClose: true,
                                        offset: 200,
                                        type: 'success'
                                    });
                                    this.editDrawer = false;
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true,
                                        offset: 200,
                                        type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (keyId) {
                this.apis.get('system/certtmpl/getById?certTmplId=' + keyId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            updUseStatus: function (row) {
                // console.log(row)
                let data = {
                    certTmplId: row.certTmplId,
                    useStatus: row.useStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieTNAME()
                };
                this.apis.postForm('system/certtmpl/updUseStatus', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (id, deleteStatus) {
                this.$confirm('确定要删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        certTmplId: id,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('system/certtmpl/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '操作成功',
                                    showClose: true,
                                    offset: 200,
                                    type: 'success'
                                });
                                this.initTable();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true,
                                    offset: 200,
                                    type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            callUploadImg: function (webPath, savePath, width, height) {
                this.info.certTmplImgUrl = webPath;
                this.info.certTmplImg = savePath;
                this.info.imgWidth = width;
                this.info.imgHeight = height;
            },
            toDesign: function (keyId) {
                let that = this;
                this.apis.get('system/certtmpl/getById?certTmplId=' + keyId)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data;
                            this.tmplDesignDrawer = true;
                            this.$nextTick(function() {
                                that.$refs.pageDesign.initData(this.info.certTmplId, this.info.certTmplConfJson)
                            });
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toTest: function (certTmplId, certTmplType) {
                this.mainLoading = true;
                let data = {
                    certTmplId: certTmplId,
                    userId: this.appCookies.getCookieUID()
                }
                let url = 'user/cert/jieye/genCertTest';
                if (certTmplType==='SHARE') {
                    url = 'user/class/share/img/testShareImg';
                }
                this.apis.postForm(url, data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.certImgUrl = res.data;
                            this.previewDialog = true;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                        }
                        this.mainLoading = false;
                    }).catch(err => {
                    this.mainLoading = false;
                    console.log(err);
                });
            },
            updConf: function (certTmplConfJson) {
                let url = "system/certtmpl/updConf";
                this.info.certTmplConfJson = certTmplConfJson;
                this.info.modifyBy = this.appCookies.getCookieUID();
                this.info.modifyByname = this.appCookies.getCookieUNAME();

                // 保存数据
                this.apis.postJson(url, this.info)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                            this.info = {};
                            this.tmplDesignDrawer = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 200,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sched-list {
        width: 100%;
        /*tr {*/
        /*    height: 40px;*/
        /*}*/
        /*td {*/
        /*    border: 1px solid #EBEEF5;*/
        /*    padding: 5px 5px;*/
        /*    text-align: center;*/
        /*    min-height: 40px;*/
        /*}*/
        .custom-table {
            display: table;
            box-sizing: border-box;
            text-indent: initial;
            border-spacing: 2px;
            border-color: grey;
            border-collapse: collapse;
            width: 100%;
            background-color: #fff;
            font-size: 14px;
            line-height: 1.5em;

            tr {
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
            }

            th {
                display: table-cell;
                vertical-align: inherit;
                border-bottom: 1px solid #dcdfe6;
                padding: 10px 10px;
                max-width: 250px;
                text-align: center;
            }

            td {
                display: table-cell;
                vertical-align: inherit;
                border-bottom: 1px solid #dcdfe6;
                padding: 10px 10px;
                max-width: 250px;
                text-align: center;
            }

        }
    }
</style>
