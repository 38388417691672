<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="24">
                <el-row  class="table-container">
                    <el-col :span="24">
                        <el-form :inline="true">
                            <el-form-item label="">
                                <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width: 150px"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态" style="width: 100px">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="正常" value="0"></el-option>
                                    <el-option label="已删除" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
                            </el-form-item>
                            <el-button-group style="float: right">
                                <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                            </el-button-group>
                        </el-form>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="8" class="row-container">
            <el-col>
                <el-row  class="table-container">
                    <el-col :span="24">
                        <el-table
                            :key="tableKey"
                            v-loading="tableLoading"
                            :data="tableData"
                            fit
                            highlight-current-row
                            style="width: 100%;"
                            height="calc(100vh - 260px)"
                            ref="queryTable"
                            :indent="20"
                            @sort-change="sortChange"
                        >
                            <el-table-column label="编号" prop="roleCode" min-width="60px" align="center"></el-table-column>
                            <el-table-column label="名称" prop="roleName" min-width="100px" align="left"
                                             :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="数据权限" width="150px">
                                <template slot-scope="{row}">
                                    <el-tag>{{ row.rangeCode | rangeFilter }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="排序号" prop="orderNum" width="80px" align="center"></el-table-column>
                            <el-table-column label="描述" prop="roleDesc" min-width="280px" align="left"
                                             :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="状态" min-width="100" align="center">
                                <template slot-scope="{row}">
                                    <el-tag :type="row.deleteStatus | colorFilter">
                                        {{ row.deleteStatus  | statusFilter }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作时间" width="140px" align="center">
                                <template slot-scope="{row}">
                                    <span>{{ row.modifyTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="240" fixed="right"
                                             class-name="small-padding fixed-width">
                                <template slot-scope="{row}">
                                    <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit"
                                               @click="toEdit(row.roleId)">修改
                                    </el-button>
                                    <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-delete"
                                               @click="toDelete(row.roleId, 1)">删除
                                    </el-button>
                                    <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.roleId, 0)">恢复</el-button>

                                    <el-button v-if="row.deleteStatus===0" type="text" size="mini" @click="toSetPriv(row.roleId)">权限设置
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                    :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                    @pagination="initTable"/>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <el-dialog
            title="角色编辑"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="50%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
                <el-form-item label="编码" prop="roleCode">
                    <el-input v-model="info.roleCode"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="roleName">
                    <el-input v-model="info.roleName"></el-input>
                </el-form-item>
                <el-form-item label="数据权限" prop="rangeCode">
                    <span slot="label">
                        数据权限
                        <el-tooltip effect="dark" content="数据级权限（可访问或操作哪些数据）" placement="top">
                          <i class="el-icon-question"/>
                        </el-tooltip>
                        :
                   </span>
                    <el-radio-group v-model="info.rangeCode">
                        <el-radio v-for="item in rangeList" :label="item.code" :key="item.code">{{
                                item.name
                            }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="描述" prop="roleDesc">
                    <el-input v-model="info.roleDesc" type="textarea" :autosize="{ minRows: 2}"></el-input>
                </el-form-item>
                <el-form-item label="排序号" prop="orderNum">
                    <el-input v-model="info.orderNum"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>

        <el-drawer
            title="权限设置"
            :visible.sync="editDrawer"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="50%"
        >
            <div class="demo-drawer__content">
                <el-tree ref="menuTree"
                         node-key="id"
                         :data="menuData"
                         :props="{label: 'name', children: 'children'}"
                         :default-checked-keys="checkedKeys"
                         :check-on-click-node="true"
                         :expand-on-click-node="false"
                         show-checkbox
                         default-expand-all
                         highlight-current>
                </el-tree>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="treeExpand()">展开/合并</el-button>
                <el-button @click="treeSelectAll()">全选/反选</el-button>
                <el-button @click="editDrawer=false">取 消</el-button>
                <el-button type="primary" @click="savePriv">确 定</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
// import { parseTime } from '@/utils'
import Pagination from '@/components/Pagination'
import Breadcrumb from "../../components/Breadcrumb";

const rangeOptions = [
    {
        code: 90,
        name: '全部数据'
    },
    {
        code: 80,
        name: '本机构和客户数据'
    },
    {
        code: 70,
        name: '本机构数据'
    },
    {
        code: 50,
        name: '负责班级数据'
    },
];
// arr to obj, such as { CN : "China", US : "USA" }
const rangeKeyValue = rangeOptions.reduce((acc, cur) => {
    acc[cur.code] = cur.name
    return acc
}, {})

export default {
    components: {
        Pagination,
        Breadcrumb
    },
    filters: {
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger'
            };
            return statusMap[status]
        },
        statusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        rangeFilter(type) {
            return rangeKeyValue[type]
        }
    },
    data() {
        return {
            rangeList: rangeOptions,
            crumbData: [
                {
                    path: '/main',
                    name: '首页'
                },
                {
                    path: '',
                    name: '角色管理'
                }
            ],
            queryParams: {
                pageNumber: 0,
                pageSize: 10,
                totalRecord: 0,
                typeCode: '',
                keyword: '',
                deleteStatus: '0'
            },
            tableKey: 0,
            tableData: [],
            tableLoading: false,
            info: {},
            ruleValidate: {
                roleCode: [
                    {
                        required: true,
                        message: '请输入编码',
                        trigger: 'blur'
                    }
                ],
                roleName: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                rangeCode: [
                    {
                        required: true,
                        message: '请选择数据权限',
                        trigger: 'change'
                    }
                ],
                orderNum: [
                    {
                        required: true,
                        message: '请输入排序号',
                        trigger: 'blur'
                    }
                ],
            },
            editDialog: false,
            selectOptions: [],
            treeNormalizer(node) {
                return {
                    id: node.menuId,
                    label: node.menuName
                }
            },
            editDrawer: false,
            menuData: [],
            checkedKeys: [],
            expandAll: false,
            selectAll: false,
            currRoleId: ''
        }
    },
    mounted() {
        this.initTable();
    },
    methods: {
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('system/role/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        sortChange(data) {
            const {
                prop,
                order
            } = data;
            console.log(prop, order)
            // if (prop === 'id') {
            //     this.sortByID(order)
            // }
        },
        getSortClass: function (key) {
            const sort = this.queryParams.sort;
            return sort === `+${key}` ? 'ascending' : 'descending'
        },
        getTreeList: function () {
            this.apis.postForm('system/menu/info/getTree', { isAll: 0 })
                .then((res) => {
                    if (res.code === 1) {
                        this.selectOptions = res.data;
                        this.selectOptions.splice(0, 0, {
                            menuId: 0,
                            menuName: '请选择'
                        });
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toAdd: function () {
            this.info = {
                orderNum: 0
            };
            // this.getTreeList();
            this.editDialog = true;
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "system/role/save";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME() ;
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true,
                                    offset: 200,
                                    type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true,
                                    offset: 50,
                                    type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (keyId) {
            // this.getTreeList();
            this.apis.get('system/role/getById?roleId=' + keyId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        this.editDialog = true;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDelete: function (id, deleteStatus) {
            this.$confirm('确定要删除此条记录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    roleId: id,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieTNAME()
                };
                this.apis.postForm('system/role/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true,
                                offset: 200,
                                type: 'success'
                            });
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true,
                                offset: 50,
                                type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        toSetPriv: function (id) {
            this.apis.get('system/role/getMenuTree?halfSelect=0&roleId=' + id)
                .then((res) => {
                    if (res.code === 1) {
                        this.menuData = res.data;
                        this.currRoleId = id;
                        this.editDrawer = true;
                        this.checkedKeys = [];
                        this.findSelected(this.menuData)
                        console.log(this.checkedKeys)
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        findSelected: function (treeData) {
            let _this = this;
            treeData.forEach(function (item) {
                if (item.checked === true) {
                    _this.checkedKeys.push(item.id);
                }
                if (item.children) {
                    _this.findSelected(item.children);
                }
            });
        },
        treeExpand: function () {
            this.expandAll = !this.expandAll;
            for (let i = 0; i < this.menuData.length; i++) {
                this.$refs.menuTree.store.nodesMap[this.menuData[i].id].expanded = this.expandAll
            }
        },
        treeSelectAll: function () {
            this.selectAll = !this.selectAll;
            if (this.selectAll) {
                //全选
                this.$refs.menuTree.setCheckedNodes(this.menuData);
            } else {
                //取消选中
                this.$refs.menuTree.setCheckedKeys([]);
            }
        },
        savePriv: function () {
            // console.log(menuIds)
            let url = "system/rolemenu/save";
            let menuDt = {}
            let menuIds = this.$refs.menuTree.getCheckedKeys();
            let menuHalfIds = this.$refs.menuTree.getHalfCheckedKeys();
            menuDt.roleId = this.currRoleId;
            menuDt.menuIds = menuIds;
            menuDt.menuHalfIds = menuHalfIds;
            menuDt.createUid = this.appCookies.getCookieUID();
            menuDt.createUname = this.appCookies.getCookieTNAME();
            menuDt.updateUid = this.appCookies.getCookieUID();
            menuDt.updateUname = this.appCookies.getCookieTNAME();
            // 保存数据
            this.apis.postJson(url, menuDt)
                .then((res) => {
                    if (res.code === 1) {
                        this.editDrawer = false;
                        this.$message({
                            message: '操作成功',
                            showClose: true,
                            offset: 200,
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 50,
                            type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
